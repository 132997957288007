import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { IUploadedFile } from "../components/TaskFiles/TaskFilesPopup/TaskFilesPopup";
import { createTaskComment } from "components/pages/Tasks/model/thunks";

import { useTypedSelector } from "app/store/typedUseSelector";
import { IFileFromS3 } from "widgets/FilesFromStorage/FilesFromStorage";

import { personFullNameWithId } from "types/personsTypes";

export const useTaskComments = (taskId: number, currentUser: personFullNameWithId, isNewTask?: boolean) => {
  const textInputRef = useRef<HTMLTextAreaElement>(null);

  const [commentText, setCommentText] = React.useState("");
  const [files, setFiles] = useState<IUploadedFile[]>([]);
  const [filesFromStorage, setFilesFromStorage] = useState<IFileFromS3[]>([]);

  const { id: myId } = useTypedSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const userFirstNameLastName = `${currentUser.first_name} ${currentUser.last_name}`;

  const writeTaskCommentHandler = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentText(e.target.value);
  }, []);

  const createNewTaskCommentHandler = React.useCallback(
    (e: { preventDefault: () => void; stopPropagation: () => void }) => {
      if (!commentText) return;

      e?.preventDefault();
      e?.stopPropagation();

      if (isNewTask) return;
      dispatch(
        createTaskComment(
          commentText,
          taskId,
          myId,
          new Date().toISOString(),
          userFirstNameLastName,
          files,
          filesFromStorage.map((el) => el.id)
        )
      );
      setCommentText("");
      setFiles([]);
      setFilesFromStorage([]);
    },
    [commentText, isNewTask, taskId, currentUser.id, userFirstNameLastName, files, filesFromStorage]
  );

  const attachFiles = (files: IUploadedFile[]) => {
    setFiles((prev) => [...prev, ...files]);
  };

  const removeFile = (id: number) => {
    setFiles((prev) => prev.filter((file) => file.id !== id));
  };

  return {
    createNewTaskCommentHandler,
    writeTaskCommentHandler,
    commentText,
    textInputRef,
    files,
    attachFiles,
    removeFile,
    setFiles,
    filesFromStorage,
    setFilesFromStorage,
  };
};
