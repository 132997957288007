import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { loadWorkers, workersSelector } from "redux/modules/common/building/workers";

import WorkerCard from "components/pages/Workers/components/Turnout/components/Week/components/WorkerCard/WorkerCard";

import { ReactComponent as InfoIcon } from "images/chartTreeInfoIcon.svg";

import styles from "./ChartTreeBubbleWorkerInfo.module.scss";

export interface ChartTreeBubbleWorkerInfoProps {
  workerId: number;
  buildingId: number;
}

const LOAD_WORKERS_PARAMS = {
  limit: 999,
  offset: 0,
};

const ChartTreeBubbleWorkerInfo: React.FC<ChartTreeBubbleWorkerInfoProps> = ({ workerId, buildingId }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const workers = useSelector(workersSelector);
  const workersQuerySent = useRef(0);

  const [currentWorker, setCurrentWorker] = useState();

  useEffect(() => {
    if (!workers?.results?.length) {
      if (workersQuerySent.current < 1) {
        dispatch(loadWorkers(buildingId, LOAD_WORKERS_PARAMS));
        workersQuerySent.current += 1;
      }
      return;
    }
    const worker = workers.results.find((worker: { id: string }) => Number(worker.id) === Number(workerId));
    if (worker) {
      setCurrentWorker(worker);
    } else if (workersQuerySent.current < 1) {
      dispatch(loadWorkers(buildingId, LOAD_WORKERS_PARAMS));
      workersQuerySent.current += 1;
    }
  }, [workers, buildingId]);

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  if (!currentWorker) {
    return null;
  }

  return (
    <>
      <InfoIcon className={styles.info} onClick={openModal} />
      <WorkerCard isOpen={open} onClose={closeModal} worker={currentWorker} buildingId={buildingId} />
    </>
  );
};

export default React.memo(ChartTreeBubbleWorkerInfo);
