import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { IRouterParamsWithObjectId } from "types/routerTypes";

import { useQueryParams } from "utils/hooks/useQueryParams";

const FOLDER_IDS = "folderIds";

export const useFileStorageNavigation = () => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const history = useHistory();
  const location = useLocation();
  const folderIdsFromParams =
    useQueryParams(FOLDER_IDS)
      ?.split("/")
      .filter((el) => !!el)
      .map((el) => +el) || [];

  const [foldersIdsArray, setFoldersIdsArray] = useState<number[]>(folderIdsFromParams);
  const [activeFolderName, setActiveFolderName] = useState("");
  const cachedObjectId = useRef<string>(objectId);

  const isAllObjects = objectId === "0";

  useEffect(() => {
    if (!isAllObjects) return;

    setActiveFolderName("");
  }, [isAllObjects]);

  useEffect(() => {
    if (objectId === cachedObjectId.current) return;
    setFoldersIdsArray([]);
    cachedObjectId.current = "None";
  }, [objectId]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.set(FOLDER_IDS, foldersIdsArray.join("/"));
    history.replace({ search: params.toString() });
  }, [foldersIdsArray, location.search]);

  const backHandler = useCallback(() => {
    setFoldersIdsArray((prev) => {
      const tempArr = [...prev];
      tempArr.pop();
      return tempArr;
    });
  }, [setFoldersIdsArray]);

  const openFolderHandler = useCallback(
    (folderId: number, folderName: string) => {
      setFoldersIdsArray((prev) => [...prev, folderId]);
      setActiveFolderName(folderName);
    },
    [setFoldersIdsArray]
  );

  const currentFolderId = foldersIdsArray[foldersIdsArray.length - 1];

  const foldersDepth = foldersIdsArray.length;

  const clearFolderNavigation = () => {
    setFoldersIdsArray([]);
    setActiveFolderName("");
    const params = new URLSearchParams(location.search);
    params.set(FOLDER_IDS, "");
    history.replace({ search: params.toString() });
  };

  return {
    backHandler,
    openFolderHandler,
    currentFolderId,
    foldersDepth,
    activeFolderName,
    clearFolderNavigation,
  };
};
