import React from "react";

import styles from "./Label.module.scss";

interface IProps {
  children: React.ReactNode;
}

const Label: React.FC<IProps> = ({ children }) => {
  return <label className={styles.label}>{children}</label>;
};

export default Label;
