import React, { FC, MouseEventHandler, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { bindJournalExecTicketToEstimate } from "redux/modules/common/building/journal/journalExecution/thunksV2";
import { IExpendituresInJournalFulfillment } from "redux/modules/common/building/journal/journalExecution/types";
import { journalGroupDetailInfoSelector } from "redux/modules/common/building/journal/selectors";
import { getGroupDetailInfo, patchTicket } from "redux/modules/common/building/journal/thunks";

import MatchingIcon, { MatchingStatusesEnum } from "_LEGACY/UI/MatchingIcon/MatchingIcon";
import AddRelationToProduct from "components/UI/_TODO/AddRelationToProduct";
import ExpenditureGroupModal from "components/UI/_TODO/ExpenditureGroupModal/ui/ExpenditureGroupModal";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { IMatchingInfo } from "components/pages/Journal/components/JournalDelivery/components/JournalTickets/JournalTickets";
import ProductInfo from "components/pages/Journal/components/JournalDelivery/components/ProductInfo";

import { ExpenditureTypeEnum } from "types/enums/ExpenditureTypeEnum";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

interface IProps {
  ticket: IExpendituresInJournalFulfillment["items"][0];
  styles: Record<string, string>;
  isShowGroupModal: boolean;
  setIsShowGroupModal: (s: boolean) => void;
}

const JournalExecMatching: FC<IProps> = ({ ticket, styles, isShowGroupModal, setIsShowGroupModal }) => {
  const dispatch = useDispatch();
  const objectId = useObjectId();
  const isExpenditureGroup = ticket.type === "group";
  const ticketItem = ticket.expenditure ?? ticket.group ?? ticket.linked;
  const groupDetailInfo = useSelector(journalGroupDetailInfoSelector);

  const openGroupModal = (event: React.MouseEvent) => {
    event.stopPropagation();

    dispatch(getGroupDetailInfo(objectId, (ticketItem as any).group_id));
    setIsShowGroupModal(true);
  };

  const [isOpenProductInfo, setIsOpenProductInfo] = useState<any>(undefined);
  const matchingHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (isExpenditureGroup) {
      openGroupModal(event);
    } else {
      if (ticketItem?.is_default) {
        setIdAddRelation({ id: (ticketItem as any).expenditure_id } as any);
      } else {
        setIsOpenProductInfo({ id: true });
      }
    }
  };

  const [idAddRelation, setIdAddRelation] = React.useState<IMatchingInfo | null>(null);

  const bindHandler = (inner_id: number) => {
    dispatch(bindJournalExecTicketToEstimate(objectId, ticketItem?.facts_id!, inner_id));
  };

  return (
    <div onClick={stopEventPropagation}>
      <MatchingIcon
        status={
          isExpenditureGroup
            ? MatchingStatusesEnum.group
            : !ticketItem?.is_default
            ? MatchingStatusesEnum.auto
            : MatchingStatusesEnum.outOfEstimate
        }
        onClick={matchingHandler}
        className={styles.checkFile}
      />
      <ProductInfo
        isOpen={isOpenProductInfo}
        closeModal={() => setIsOpenProductInfo(undefined)}
        objectId={objectId}
        chosenMatchingProduct={ticket}
        directlyCantEdit={false}
      />
      {isShowGroupModal && groupDetailInfo && (
        <ExpenditureGroupModal
          isOpen={isShowGroupModal}
          onClose={() => setIsShowGroupModal(false)}
          group={groupDetailInfo}
          isShowButtons={false}
        />
      )}
      <AddRelationToProduct
        key={idAddRelation?.id}
        idAddRelation={idAddRelation}
        handleClose={() => setIdAddRelation(false as any)}
        objectId={objectId}
        onSubmit={bindHandler as any}
        updateProducts={undefined as any}
        type={undefined}
        typeForFilter={ExpenditureTypeEnum.work}
        externalOnSelect={undefined as any}
      />
    </div>
  );
};

export default JournalExecMatching;
