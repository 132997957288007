import cn from "classnames";
import React from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";

import { addSection, addSubSection } from "redux/modules/common/building/materials/specification";

import ButtonBase from "shared/ui/controls/ButtonBase";
import InputBase from "shared/ui/inputs/InputBase";

import CrossRoundedIcon from "images/icons/CrossRoundedIcon";

import styles from "./Chapter.module.scss";

//убрать этот цирк с titlebtn
//type: section | subSection
const Chapter = ({ objId, close, label = "раздел", name, id, titleBtn, placeholder = "", type }) => {
  const dispatch = useDispatch();

  const submitRequisite = (value) => {
    if (!titleBtn) {
      if (type === "section") {
        dispatch(addSection(objId, value));
      }
      if (type === "subSection") {
        dispatch(addSubSection(objId, { ...value, parent_id: id }));
      }
      close();
      return;
    }
    if (titleBtn === "Добавить раздел") {
      dispatch(addSection(objId, value));
    }

    if (titleBtn === "Добавить подраздел") {
      dispatch(addSubSection(objId, { ...value, parent_id: id }));
    }
    close();
  };

  return (
    <div className={styles.container}>
      <Form
        onSubmit={(values) => submitRequisite(values)}
        render={({ handleSubmit, pristine, hasValidationErrors }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.topSide}>
              {!type && (
                <div className={styles.block1Title}>
                  <span className={styles.title}>Добавить {label}</span>
                  <div className={styles.closeIcon} onClick={close}>
                    <CrossRoundedIcon />
                  </div>
                </div>
              )}
              <div className={styles.field3}>
                <Field
                  name="name"
                  label={name ? name : "Наименование раздела"}
                  component={InputBase}
                  classNameInput={styles.select}
                  placeholder={placeholder || "Введите наименование раздела"}
                />
              </div>
            </div>
            <div className={cn(styles.actionBlock)}>
              <ButtonBase primary medium type="submit" disabled={pristine || hasValidationErrors}>
                Добавить
              </ButtonBase>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default Chapter;
