import cn from "classnames";
import React from "react";
import { Field } from "react-final-form";

import RadioSquares from "../../../RadioSquares/RadioSquares";
import TaskFiles from "../../../TaskFiles/TaskFiles";

import { TASKS_REMIND_OPTIONS, TASKS_REPEAT_OPTIONS, TASK_MODAL_TYPES } from "../../../../constants";

import styles from "./ModalAddTask.module.scss";

const ModalAddTaskExtraFields: React.FC<{
  isVisible?: boolean;
  buildingId: number;
}> = ({ isVisible = true, buildingId }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div>
        <Field
          name={"remember_task"}
          allowNull
          render={({ input, meta }) => (
            <RadioSquares
              options={TASKS_REMIND_OPTIONS}
              label={"Напоминание:"}
              defaultValue={meta.initial}
              onClick={input.onChange}
              value={input.value}
            />
          )}
        />
        <Field
          name={"repeat_task"}
          allowNull
          render={({ input, meta }) => (
            <RadioSquares
              options={TASKS_REPEAT_OPTIONS}
              label={"Повтор:"}
              defaultValue={meta.initial}
              onClick={input.onChange}
              value={input.value}
            />
          )}
        />
      </div>

      <TaskFiles files={[]} variant={TASK_MODAL_TYPES.ADD} task={{ building: buildingId } as any} />
    </div>
  );
};

export default ModalAddTaskExtraFields;
