import axios from "axios";
import cn from "classnames";
import React, { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";

import { IJournalAcceptanceTicketItem } from "redux/modules/common/building/journal/acceptance/types";
import { journalActions } from "redux/modules/common/building/journal/actions";

import InputBase, { VALUE_TYPES } from "../../../../../../../../../shared/ui/inputs/InputBase";
import { useConfirmModal } from "entities/ConfirmationModal/ConfirmationContext";
import { useCheckIsConstructingControl } from "features/constructingControl";
import AcceptCheckBtn from "shared/ui/controls/AcceptCheckBtn/AcceptCheckBtn";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import blackApproveIcon from "../../../../../../../../../images/icons/blackCheckIcon";

import styles from "./JournalAprovingCountCell.module.scss";

const AcceptableIcon = ({ disabled, title }: { disabled?: boolean; title: string }) => {
  return (
    <div title={title} className={cn({ [styles.acceptableIcon]: !disabled })}>
      {blackApproveIcon}
    </div>
  );
};

interface IProps {
  haveApproveTicketPermission: boolean;
  ticket: IJournalAcceptanceTicketItem;
  objectId: string;
  isGroup?: boolean;
  onAccept: (count: string) => void;
  isShared: boolean;
}

const CCAccept = ({
  count,
  onAccept,
  isConstuctionControl,
  isPending,
  isAccepted,
  hasUnresolvedRemarks,
}: {
  count: string | number;
  onAccept: (count: any) => void;
  isConstuctionControl?: boolean;
  isPending?: boolean;
  isAccepted?: boolean;
  hasUnresolvedRemarks: boolean;
}) => {
  return (
    <AcceptCheckBtn
      isPending={isPending}
      onClick={() => {
        !isAccepted && onAccept(count);
      }}
      title={
        isAccepted
          ? "Принято стройконтролем"
          : isConstuctionControl
          ? "Принять стройконтролем"
          : "Не принято стройконтролем"
      }
      disabled={!isConstuctionControl || hasUnresolvedRemarks}
      isAccepted={isAccepted}
    />
  );
};

const JournalAcceptCountCell: React.FC<IProps> = ({
  haveApproveTicketPermission,
  ticket,
  objectId,
  isGroup,
  onAccept,
  isShared,
}) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState("");
  const ticketItem = ticket?.expenditure ?? ticket?.group;

  const sum = ticketItem?.confirmed_count;

  const isConstructionControlEnabled = ticketItem.cc_confirmed !== undefined;
  const isConstructionControlAccepted = !!ticketItem.cc_confirmed;
  const isConstuctionControl = useCheckIsConstructingControl();

  const hasAllApproves = +ticketItem?.confirmed_approvals === +ticketItem?.total_approvals;
  const canApprove = haveApproveTicketPermission && hasAllApproves && !isConstuctionControl;

  const onCountChange = (e: ChangeEvent<HTMLInputElement>) => setCount(e.target.value);

  const { openConfirmModal, closeConfirmModal } = useConfirmModal();

  const openCCAccept = () => {
    openConfirmModal({
      isOpen: true,
      onClose: closeConfirmModal,
      action: acceptCC,
      title: "Принять работу стройконтролем?",
      acceptButtonText: "Принять",
      className: styles.confirmModal,
    });
  };

  const [isCCPending, setIsCCPending] = useState(false);
  const acceptCC = () => {
    //TODO перенести
    setIsCCPending(true);
    axios
      .post(`/building/${objectId}/tickets/${ticketItem?.id}/cc_confirm/`)
      .then(() => {
        dispatch(journalActions.changeInvalidateKey());
        closeConfirmModal();
      })
      .catch(errorCatcher)
      .finally(() => {
        setIsCCPending(false);
      });
  };

  if (ticketItem?.is_confirmed) {
    return (
      <div title={sum} className={styles.container}>
        <div className={styles.inputWrapper}>{sum}</div>
        <span>
          <AcceptCheckBtn disabled={!canApprove} title={"Передано в выполнение"} isAccepted />
        </span>
        {isConstructionControlEnabled && (
          <CCAccept
            isPending={isCCPending}
            onAccept={openCCAccept}
            count={ticketItem.count}
            isConstuctionControl={isConstuctionControl}
            isAccepted={isConstructionControlAccepted}
            hasUnresolvedRemarks={ticketItem?.count_remarks_in_work > 0}
          />
        )}
      </div>
    );
  }

  if (isShared) {
    return (
      <span
        className={cn(styles.approveButton, styles.sharedApprovedButton)}
        onClick={(e) => {
          stopEventPropagation(e);
          //openCCAccept();
        }}
      >
        <AcceptableIcon title={""} />
      </span>
    );
  }

  if (!ticketItem?.is_confirmed)
    return (
      <div onClick={stopEventPropagation} className={styles.container}>
        {(isConstuctionControl || isShared) && <div className={styles.inputWrapper} />}
        {!isShared && !isConstuctionControl && (
          <InputBase
            className={cn(styles.inputWrapper)}
            classNameInput={cn(styles.input, {
              [styles.active]: count,
            })}
            onChange={onCountChange}
            value={count}
            disabled={!canApprove}
            valueType={VALUE_TYPES.NUMBER}
          />
        )}
        <AcceptCheckBtn
          disabled={!canApprove}
          title={canApprove ? "Принять" : "Не принято"}
          className={styles.approveButton}
          onClick={() => {
            canApprove && onAccept(count);
          }}
        />
        {isConstructionControlEnabled && (
          <CCAccept
            isPending={isCCPending}
            onAccept={openCCAccept}
            count={ticketItem.count}
            isConstuctionControl={isConstuctionControl}
            isAccepted={isConstructionControlAccepted}
            hasUnresolvedRemarks={ticketItem?.count_remarks_in_work > 0}
          />
        )}
      </div>
    );
  return null;
};

export default React.memo(JournalAcceptCountCell);
