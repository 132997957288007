import { message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import Spinner from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import BlueLabel from "shared/ui/dataDisplay/BlueLabel/BlueLabel";
import TableReusableHead, { TableReusableHeaderCell } from "shared/ui/dataDisplay/TableReusable/TableReusableHead";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";
import Checkbox, { LabelPlacement } from "shared/ui/inputs/Checkbox/Checkbox";
import InputBase, { INPUT_BASE_VARIANTS, VALUE_TYPES } from "shared/ui/inputs/InputBase";
import RowGrid from "shared/ui/layout/RowGrid/RowGrid";
import Modal from "shared/ui/modal/Modal";

import { errorCatcher } from "utils/helpers/errorCatcher";

import styles from "./SpecificationSplitModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  expenditureId?: number;
  itemId: number;
}

const colsWidth = [35, 20, 30, 15];

const SpecificationSplitModal = ({ isOpen, onClose, itemId, expenditureId }: IProps) => {
  const objectId = useObjectId();
  const [material, setMaterial] = useState<Material>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!isOpen) return;
    setIsLoading(true);
    axios
      .get(`/building/${objectId}/materials/specification/${itemId}/ `)
      .then(({ data }) => {
        setMaterial(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [objectId, itemId, isOpen]);

  const [targetCount, setTargetCount] = useState<string>();
  const [isAllCount, setIsAllCount] = useState(false);

  const onCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setIsAllCount(true);
      setTargetCount(material?.count);
    } else {
      setIsAllCount(false);
      setTargetCount("");
    }
  };

  const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== material?.count) {
      setIsAllCount(false);
    }
    setTargetCount(e.target.value);
  };

  const isSubmitDisabled = isLoading || !targetCount;

  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);
  const onSubmit = () => {
    if (+(targetCount ?? 0) > +(material?.count ?? 0)) {
      message.error("Выбрано количество больше доступного");
    }
    setIsPending(true);
    axios
      .patch(`/building/${objectId}/materials/specification/${itemId}/`, {
        estimate_expenditure_id: expenditureId,
        count: targetCount,
      })
      .then(() => {
        dispatch({ type: "specification/INVALIDATE_KEY" });
        onClose();
      })
      .catch(errorCatcher)
      .finally(() => {
        setIsPending(false);
      });
  };

  return (
    <Modal title="Ресурсы для связи" isOpen={isOpen} onClose={onClose} className={styles.modal}>
      <div className={styles.content}>
        {isLoading && (
          <div className={styles.spinner}>
            <Spinner isStatic />
          </div>
        )}
        {!!material && (
          <div className={styles.table}>
            <TableReusableHead isSmall className={styles.head}>
              <RowGrid percents={colsWidth}>
                <TableReusableHeaderCell>Наименование</TableReusableHeaderCell>
                <TableReusableHeaderCell isCentered>Ед. изм.</TableReusableHeaderCell>
                <TableReusableHeaderCell isCentered>Доступное количество</TableReusableHeaderCell>
                <TableReusableHeaderCell isCentered>Связать</TableReusableHeaderCell>
              </RowGrid>
            </TableReusableHead>
            <div className={styles.row}>
              {/*  */}
              <RowGrid percents={colsWidth}>
                <div className={styles.nameCell}>
                  <div>{material.name}</div>
                </div>
                <div className={styles.centered}>{material.measure}</div>
                <div className={styles.centered}>{material.count}</div>
                <div>
                  <InputBase
                    variant={INPUT_BASE_VARIANTS.SECONDARY}
                    valueType={VALUE_TYPES.NUMBER}
                    value={targetCount}
                    onChange={onInput}
                  />
                </div>
              </RowGrid>
            </div>
            {!material.estimate_expenditure?.justification && (
              <BlueLabel className={styles.hz}>{material.estimate_expenditure?.justification}</BlueLabel>
            )}
            <div className={styles.linkAll}>
              <RowGrid percents={colsWidth}>
                <div />
                <div />
                <div />
                <Checkbox checked={isAllCount} onCheck={onCheck} labelPlacement={LabelPlacement.RIGHT}>
                  Связать все
                </Checkbox>
              </RowGrid>
            </div>
          </div>
        )}
        <div className={styles.bottom}>
          <ButtonBase primary disabled={isSubmitDisabled} onClick={onSubmit} isLoading={isPending}>
            Сохранить
          </ButtonBase>
        </div>
      </div>
    </Modal>
  );
};

export default SpecificationSplitModal;

type Material = {
  id: number;
  number: string;
  name: string;
  count: string;
  price: string;
  measure: string;
  brand: string;
  producer: string;
  is_consumable: false;
  created_from_estimate: true;
  estimate_expenditure: {
    justification?: "";
  };
};
