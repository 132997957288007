import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CLEAR_MATERIAL_SUBSECTIONS,
  getMaterialsSubSections,
  isLoadingSectionSelector,
  materialSubSectionsSelector,
} from "redux/modules/common/building/materials/materials";

import SubSection from "../components/SubSection";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBack from "shared/ui/controls/ButtonBack/ButtonBack";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import materialsIcon from "images/icons/navigation/materials.svg";

import styles from "./../Estimate.module.scss";

interface IProps {
  sectionId: number;
  onClick: (sectionId: number) => void;
  onBack: () => void;
}

const SubSectionsLevel: React.FC<IProps> = ({ sectionId, onClick, onBack }) => {
  const objectId = useObjectId();
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSectionSelector);
  const materialSubSections = useSelector(materialSubSectionsSelector);

  useLayoutEffect(() => {
    dispatch(getMaterialsSubSections(objectId, sectionId));
  }, [objectId, sectionId]);

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_MATERIAL_SUBSECTIONS });
    };
  }, []);

  if (isLoading) return <Spinner />;

  return (
    <>
      <div className={styles.containerBtn}>
        <ButtonBack onClick={onBack} className={styles.button} />
        <span>{materialSubSections.name}</span>
      </div>
      {materialSubSections.length === 0 ? (
        <EmptyPlaceholder img={materialsIcon} />
      ) : (
        <>
          {materialSubSections.subsections &&
            materialSubSections.subsections.map((subSection: any) => (
              <div key={subSection.id}>
                <SubSection blockOpenExpenditure={() => onClick(subSection.id)} data={subSection} key={subSection.id} />
              </div>
            ))}
        </>
      )}
    </>
  );
};

export default SubSectionsLevel;
