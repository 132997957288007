import React, { FC } from "react";

import BottomControls from "../../../../WorkOrMaterialsModals/components/BottomControls/BottomControls";

import ButtonBase from "../../../../../../../shared/ui/controls/ButtonBase";

import Check from "../../../../../../../images/icons/Check";

import styles from "../Footer.module.scss";

interface IProps {
  isShowButtons?: boolean;
  haveUngroupGroupsPermission: boolean;
  haveEditGroupsPermission: boolean;
  isEditing: boolean;
  hasChanges: boolean;
  isPending?: boolean;

  toggleEdit: () => void;
  handleCompleteEdit: () => void;
  handleUngroup?: () => void;
  onClose: () => void;
  onCreate: () => void;
}

export const CreationControls: FC<IProps> = ({
  isShowButtons,
  haveUngroupGroupsPermission,
  isPending,
  haveEditGroupsPermission,
  isEditing,
  hasChanges,
  toggleEdit,
  handleCompleteEdit,
  handleUngroup,
  onClose,
  onCreate,
}) => {
  return (
    <BottomControls
      isExists={isShowButtons && haveUngroupGroupsPermission}
      wrapperClassName={styles.bottomControlsWrapper}
      className={styles.bottomControls}
    >
      <ButtonBase secondary onClick={onClose}>
        Отмена
      </ButtonBase>
      <ButtonBase type="submit" primary className={styles.create} onClick={onCreate} isLoading={isPending}>
        <Check />
        Сгруппировать
      </ButtonBase>
    </BottomControls>
  );
};
