import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useParams } from "react-router-dom";

import Building from "../../../../components/pages/Building/Building";
import Calendar from "../../../../components/pages/Calendar";
import TemplatedOrder from "../../../../components/pages/Order/TemplatedOrder";
import PaymentsList from "../../../../components/pages/PaymentsList";
import TemplatedRequisition from "../../../../components/pages/Requisition/TemplatedRequisition";
import RequisitionsList from "../../../../components/pages/RequisitionsList/RequisitionsList";
import SuppliesList from "../../../../components/pages/SuppliesList";
import Workers from "../../../../components/pages/Workers/Workers";
import { useLoadUIIndicators } from "../../../../hooks/uiIndicators/useLoadUIIndicators";
import PlanFact from "../../../../pages/PlanFact/PlanFactSummary/PlanFactSummary";
import Beton from "components/pages/Beton/Beton";
import FilesStorage from "components/pages/FilesStorage/FilesStorage";
import Journal from "components/pages/Journal";
import ManufacturingProduct from "components/pages/ManufacturingProduct/ManufacturingProduct";
import Materials from "components/pages/Materials/Materials";
import Stock from "components/pages/Stock/Stock";
import { ProfilePage } from "features/templates/Header/components/Profile/components/ProfilePage/ProfilePage";

import TemplateBase from "../../../../features/templates/TemplateBase/TemplateBase";
import EmptyPlaceholder from "../../../../shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import DocumentsRoutes from "../DocumentsRoutes/DocumentsRoutes";
import ForbiddenPage from "../ForbiddenPage/ForbiddenPage";
import SettingsRoutes from "../SettingsRoutes/SettingsRoutes";
import Chat from "widgets/Chat/ChatV2";
import { isChatV2ConnectedSelector } from "widgets/Chat/model/selectors";

import { VIEW_FILE_STORAGE, VIEW_SETTINGS_SECTION } from "../../../../constants/permissions/generalPermissions";
import {
  VIEW_MANUFACTURING_BILL_ATTACH_FILES,
  VIEW_MANUFACTURING_BILL_ATTACH_PAYMENT_DOC,
  VIEW_MANUFACTURING_BILL_DELETE_FILES,
  VIEW_MANUFACTURING_BILL_EDITING,
  VIEW_MANUFACTURING_BILL_MARK_PAYMENT_AS_PAYED,
  VIEW_MANUFACTURING_BILL_SECTION,
  VIEW_MANUFACTURING_CHART,
  VIEW_MANUFACTURING_DRAWINGS,
  VIEW_MANUFACTURING_DRAWINGS_CREATE,
  VIEW_MANUFACTURING_DRAWINGS_DELETE,
  VIEW_MANUFACTURING_DRAWINGS_EDIT,
  VIEW_MANUFACTURING_DRAWINGS_SETS_CREATE,
  VIEW_MANUFACTURING_DRAWINGS_SETS_DELETE,
  VIEW_MANUFACTURING_DRAWINGS_SETS_EDIT,
  VIEW_MANUFACTURING_EDIT_REQUISITION_BEFORE_APPROVE,
  VIEW_MANUFACTURING_JOURNAL,
  VIEW_MANUFACTURING_KS_LISTS,
  VIEW_MANUFACTURING_MATERIALS_SECTION,
  VIEW_MANUFACTURING_ORDER_AUTOMATIC_AGREEMENT_TRANSITION,
  VIEW_MANUFACTURING_ORDER_FILES_ATTACH,
  VIEW_MANUFACTURING_ORDER_FILES_DELETE,
  VIEW_MANUFACTURING_ORDER_INVOICE_DIFFERENCE,
  VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_ATTACH,
  VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_DELETE,
  VIEW_MANUFACTURING_ORDER_SECTION,
  VIEW_MANUFACTURING_PACKING_LIST_ACCEPT,
  VIEW_MANUFACTURING_PACKING_LIST_ATTACH_FILES,
  VIEW_MANUFACTURING_PACKING_LIST_DELETE_FILES,
  VIEW_MANUFACTURING_PACKING_LIST_SECTION,
  VIEW_MANUFACTURING_PLAN_FACT_SECTION,
  VIEW_MANUFACTURING_PROJECT,
  VIEW_MANUFACTURING_PROJECT_ADD_FILES,
  VIEW_MANUFACTURING_PROJECT_DELETE_FILES,
  VIEW_MANUFACTURING_PROJECT_PLAN,
  VIEW_MANUFACTURING_PROJECT_PLAN_ADD_WORK,
  VIEW_MANUFACTURING_PROJECT_PROGRESS,
  VIEW_MANUFACTURING_PROJECT_PROGRESS_ADD_WORK,
  VIEW_MANUFACTURING_REQUISITION_ADD_APPROVERS,
  VIEW_MANUFACTURING_REQUISITION_ADD_NEW_PROVIDER,
  VIEW_MANUFACTURING_REQUISITION_ADD_VIEWERS,
  VIEW_MANUFACTURING_REQUISITION_ASSIGN_EXECUTOR,
  VIEW_MANUFACTURING_REQUISITION_CANCEL_APPROVING,
  VIEW_MANUFACTURING_REQUISITION_COMPLETE,
  VIEW_MANUFACTURING_REQUISITION_CREATING_PRODUCT_ADDITIONAL,
  VIEW_MANUFACTURING_REQUISITION_CREATING_PRODUCT_OUT_OF_ESTIMATE,
  VIEW_MANUFACTURING_REQUISITION_CREATING_REQUISITION,
  VIEW_MANUFACTURING_REQUISITION_DELETE_APPROVERS,
  VIEW_MANUFACTURING_REQUISITION_DELETE_PRODUCT_FILES,
  VIEW_MANUFACTURING_REQUISITION_DELETE_VIEWERS,
  VIEW_MANUFACTURING_REQUISITION_HANDLING_MODE,
  VIEW_MANUFACTURING_REQUISITION_LOGS,
  VIEW_MANUFACTURING_REQUISITION_PAGE,
  VIEW_MANUFACTURING_REQUISITION_PROCESS,
  VIEW_MANUFACTURING_REQUISITION_PROCESS_ORDERS,
  VIEW_MANUFACTURING_REQUISITION_PROCESS_PAYMENTS,
  VIEW_MANUFACTURING_REQUISITION_REASSIGN_EXECUTOR,
  VIEW_MANUFACTURING_REQUISITION_SECTION,
  VIEW_MANUFACTURING_REQUISITION_TAKE_TO_WORK,
  VIEW_MANUFACTURING_SECTION,
  VIEW_MANUFACTURING_SHARE_PLAN_WORK,
  VIEW_MANUFACTURING_SHARE_PROGRESS_WORK,
  VIEW_MANUFACTURING_STOCKS_SECTION,
  VIEW_MANUFACTURING_WORKERS,
} from "../../../../constants/permissions/manufacturingPermissions";
import { ROUTES } from "../../../../constants/routes";
import { IRouterParamsWithObjectId } from "../../../../types/routerTypes";
import { chatV2Rooms } from "widgets/Chat/constants";

import { useLastObjectId } from "../../../../utils/hooks/uselastObjectId";
import useValidateCurrentBuildingAvailable from "./hooks/useValidateCurrentBuildingAvailable";
import { usePermissionsObject } from "features/permissions/hooks/usePermissionsObject";
import usePermission from "hooks/usePermission";

import objectsIcon from "images/icons/moduleMenuIcon/fiveSquareIcon.svg";

const ManufacturingRoutes = ({ match }) => {
  const dispatch = useDispatch();
  const haveViewSettingsSectionPermission = usePermission(VIEW_SETTINGS_SECTION);
  const haveViewFileStoragePermission = usePermission(VIEW_FILE_STORAGE);
  const haveViewKsListsTabPermission = usePermission(VIEW_MANUFACTURING_KS_LISTS);

  const isChatV2Connected = useSelector(isChatV2ConnectedSelector);

  useLoadUIIndicators();

  useLastObjectId();
  useValidateCurrentBuildingAvailable(match);

  const requisitionPermissions = usePermissionsObject({
    viewProcessRequisition: VIEW_MANUFACTURING_REQUISITION_PROCESS,
    viewProcessOrders: VIEW_MANUFACTURING_REQUISITION_PROCESS_ORDERS,
    viewProcessPayments: VIEW_MANUFACTURING_REQUISITION_PROCESS_PAYMENTS,
    viewAddOutOfEstimateProducts: VIEW_MANUFACTURING_REQUISITION_CREATING_PRODUCT_OUT_OF_ESTIMATE,
    viewAddAdditionalProducts: VIEW_MANUFACTURING_REQUISITION_CREATING_PRODUCT_ADDITIONAL,
    viewEditRequisitionBeforeApprove: VIEW_MANUFACTURING_EDIT_REQUISITION_BEFORE_APPROVE,
    viewAssignRequisitionExecutor: VIEW_MANUFACTURING_REQUISITION_ASSIGN_EXECUTOR,
    viewRequisitionTakeToWork: VIEW_MANUFACTURING_REQUISITION_TAKE_TO_WORK,
    viewRequisitionReassign: VIEW_MANUFACTURING_REQUISITION_REASSIGN_EXECUTOR,
    viewAddRequisitionApprovers: VIEW_MANUFACTURING_REQUISITION_ADD_APPROVERS,
    viewAddRequisitionViewers: VIEW_MANUFACTURING_REQUISITION_ADD_VIEWERS,
    viewDeleteRequisitionApprovers: VIEW_MANUFACTURING_REQUISITION_DELETE_APPROVERS,
    viewDeleteRequisitionViewers: VIEW_MANUFACTURING_REQUISITION_DELETE_VIEWERS,
    viewRequisitionUnApprove: VIEW_MANUFACTURING_REQUISITION_CANCEL_APPROVING,
    viewRequisitionLogs: VIEW_MANUFACTURING_REQUISITION_LOGS,
    viewRequisitionInProcessingMode: VIEW_MANUFACTURING_REQUISITION_HANDLING_MODE,
    viewDeleteRequisitionProductsFiles: VIEW_MANUFACTURING_REQUISITION_DELETE_PRODUCT_FILES,
    viewAddRequisitionProductsFiles: true,
    viewRequisitionProductsComments: true,
    viewAddRequisitionProductsComments: true,
    viewCreateRequisitionProductsProvider: VIEW_MANUFACTURING_REQUISITION_ADD_NEW_PROVIDER,
    viewOrderInvoiceDifference: VIEW_MANUFACTURING_ORDER_INVOICE_DIFFERENCE,
    viewAddOrderRequestsFiles: VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_ATTACH,
    viewDeleteOrderRequestsFiles: VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_DELETE,
    viewCompleteRequisition: VIEW_MANUFACTURING_REQUISITION_COMPLETE,
  });

  const requisitionsListPermissions = usePermissionsObject({
    viewCreateRequisition: VIEW_MANUFACTURING_REQUISITION_CREATING_REQUISITION,
  });

  const orderPermissions = usePermissionsObject({
    viewOrderInvoiceDifference: VIEW_MANUFACTURING_ORDER_INVOICE_DIFFERENCE,
    autoTransitionToPayment: VIEW_MANUFACTURING_ORDER_AUTOMATIC_AGREEMENT_TRANSITION,
    addFiles: VIEW_MANUFACTURING_ORDER_FILES_ATTACH,
    deleteFiles: VIEW_MANUFACTURING_ORDER_FILES_DELETE,
    viewAddOrderRequestsFiles: VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_ATTACH,
    viewDeleteOrderRequestsFiles: VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_DELETE,
  });

  const billPermissions = usePermissionsObject({
    viewBillInEdit: VIEW_MANUFACTURING_BILL_EDITING,
    addFiles: VIEW_MANUFACTURING_BILL_ATTACH_FILES,
    deleteFiles: VIEW_MANUFACTURING_BILL_DELETE_FILES,
    viewAddBillPaymentsFile: VIEW_MANUFACTURING_BILL_ATTACH_PAYMENT_DOC,
    viewBillPaymentsMakePaid: VIEW_MANUFACTURING_BILL_MARK_PAYMENT_AS_PAYED,
  });

  const packingListPermissions = usePermissionsObject({
    viewPackingListAccept: VIEW_MANUFACTURING_PACKING_LIST_ACCEPT,
    addFiles: VIEW_MANUFACTURING_PACKING_LIST_ATTACH_FILES,
    deleteFiles: VIEW_MANUFACTURING_PACKING_LIST_DELETE_FILES,
  });

  const projectPermissions = usePermissionsObject({
    canViewPlan: VIEW_MANUFACTURING_PROJECT_PLAN,
    canViewProgress: VIEW_MANUFACTURING_PROJECT_PROGRESS,
    canAddPlan: VIEW_MANUFACTURING_PROJECT_PLAN_ADD_WORK,
    canAddProgress: VIEW_MANUFACTURING_PROJECT_PROGRESS_ADD_WORK,
    canSharePlan: VIEW_MANUFACTURING_SHARE_PLAN_WORK,
    canShareProgress: VIEW_MANUFACTURING_SHARE_PROGRESS_WORK,
    canAddFiles: VIEW_MANUFACTURING_PROJECT_ADD_FILES,
    canDeleteFiles: VIEW_MANUFACTURING_PROJECT_DELETE_FILES,

    canViewDrawings: VIEW_MANUFACTURING_DRAWINGS,
    canAddDrawingSets: VIEW_MANUFACTURING_DRAWINGS_SETS_CREATE,
    canEditDrawingSets: VIEW_MANUFACTURING_DRAWINGS_SETS_EDIT,
    canDeleteDrawingSets: VIEW_MANUFACTURING_DRAWINGS_SETS_DELETE,
    canAddDrawings: VIEW_MANUFACTURING_DRAWINGS_CREATE,
    canEditDrawings: VIEW_MANUFACTURING_DRAWINGS_EDIT,
    canDeleteDrawings: VIEW_MANUFACTURING_DRAWINGS_DELETE,
  });

  const documentsPermissions = useMemo(
    () => ({
      viewBillsList: true,
      viewPackingLists: true,
      viewBill: true,
      viewPackingListPage: true,
      viewPackingListsVatCalculation: true,
      viewContractsList: true,
      viewDeleteContract: true,
      viewAddContractFiles: true,
      viewDeleteContractFiles: true,
      viewExportList: true,
      viewDeleteExports: true,
      viewKsLists: haveViewKsListsTabPermission,
    }),
    [haveViewKsListsTabPermission]
  );

  const haveViewManufacturingSectionPermission = usePermission(VIEW_MANUFACTURING_SECTION);
  const haveViewChartPermission = usePermission(VIEW_MANUFACTURING_CHART);
  const haveViewProjectPermission = usePermission(VIEW_MANUFACTURING_PROJECT);
  const haveViewJournalPermission = usePermission(VIEW_MANUFACTURING_JOURNAL);
  const haveViewWorkersPermission = usePermission(VIEW_MANUFACTURING_WORKERS);
  const haveViewRequisitionPermission = usePermission(VIEW_MANUFACTURING_REQUISITION_SECTION);
  const haveViewOrderPermission = usePermission(VIEW_MANUFACTURING_ORDER_SECTION);
  const haveViewPackingListPermission = usePermission(VIEW_MANUFACTURING_PACKING_LIST_SECTION);
  const haveViewPlanFactPermission = usePermission(VIEW_MANUFACTURING_PLAN_FACT_SECTION);
  const haveViewMaterialsPermission = usePermission(VIEW_MANUFACTURING_MATERIALS_SECTION);
  const haveViewRequisitionPagePermission = usePermission(VIEW_MANUFACTURING_REQUISITION_PAGE);
  const haveViewStocksPagePermission = usePermission(VIEW_MANUFACTURING_STOCKS_SECTION);

  const ableTab = useMemo(() => {
    if (haveViewProjectPermission) return `${match.url}/estimate`;
    if (haveViewChartPermission) return `${match.url}/manufacturing/`;
    if (haveViewJournalPermission) return `${match.url}/journal/`;
    if (haveViewStocksPagePermission) return `${match.url}/stocks/`;
    if (haveViewWorkersPermission) return `${match.url}/workers/`;
    if (haveViewRequisitionPermission) return `${match.url}/requisitions/`;
    if (haveViewMaterialsPermission) return `${match.url}/materials/`;
    if (haveViewPlanFactPermission) return `${match.url}/plan-fact/`;
    return `${match.url}/stocks`;
  }, [haveViewChartPermission, haveViewProjectPermission, match.url]);

  const { objectId } = useParams();

  const hasValidObjectId = !!+objectId;

  if (!haveViewManufacturingSectionPermission) {
    return <ForbiddenPage />;
  }

  if (!hasValidObjectId) {
    return (
      <>
        <Switch>
          <Route exact path={`${match.path}/events/calendar`} component={Calendar} />
          <Route exact path={`${match.path}/events/payments`} component={PaymentsList} />
          <Route exact path={`${match.path}/events/supplies`} component={SuppliesList} />
          <Route exact path={`${match.path}/profile`} component={ProfilePage} />
          {haveViewSettingsSectionPermission && <Route path={`${match.path}/settings`} component={SettingsRoutes} />}
        </Switch>
        {haveViewFileStoragePermission && <Route exact path={`${match.path}/files/`} component={FilesStorage} />}
        <Route
          path={`${match.path}/documents`}
          render={(props) => (
            <DocumentsRoutes
              {...props}
              documentsPermissions={documentsPermissions}
              billPermissions={billPermissions}
              packingListPermissions={packingListPermissions}
            />
          )}
        />
        <TemplateBase>
          <EmptyPlaceholder text={"Нет проектов"} img={objectsIcon} />
        </TemplateBase>
      </>
    );
  }

  return (
    <>
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => (ableTab ? <Redirect replace to={ableTab} /> : <ForbiddenPage />)}
        />
        <Route
          path={`${match.path}/estimate`}
          render={(props) =>
            haveViewProjectPermission ? <Building {...props} permissions={projectPermissions} /> : <ForbiddenPage />
          }
        />
        <Route
          exact
          path={`${match.path}/manufacturing/`}
          component={haveViewChartPermission ? ManufacturingProduct : ForbiddenPage}
        />
        <Route exact path={[`${match.path}/stocks`, `${match.path}/stocks/:tab`]}>
          <Stock
          //customBreadCrumbs={<CustomBreadcrumbs title="Производство" subtitle="Склад"></CustomBreadcrumbs>}
          //generateTabRoute={generateTabRoute}
          />
        </Route>
        <Route exact path={[`${match.path}/beton`, `${match.path}/beton/:tab`]} component={Beton} />
        <Route exact path={`${match.path}/events/calendar`} component={Calendar} />
        <Route exact path={`${match.path}/events/payments`} component={PaymentsList} />
        <Route exact path={`${match.path}/events/supplies`} component={SuppliesList} />
        <Route exact path={`${match.path}/profile`} component={ProfilePage} />
        <Route path={`${match.path}/journal`} component={haveViewJournalPermission ? Journal : ForbiddenPage} />
        <Route path={`${match.path}/workers`} component={haveViewWorkersPermission ? Workers : ForbiddenPage} />
        <Route
          path={[`${match.path}/materials/:tab`, `${match.path}/materials/`]}
          component={haveViewMaterialsPermission ? Materials : ForbiddenPage}
        />
        <Route
          path={ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT}
          component={haveViewPlanFactPermission ? PlanFact : ForbiddenPage}
        />
        <Route
          exact
          path={`${match.path}/requisitions/:activeTab/:requisitionId`}
          render={(props) =>
            haveViewRequisitionPermission && haveViewRequisitionPagePermission ? (
              <TemplatedRequisition {...props} permissions={requisitionPermissions} />
            ) : (
              <ForbiddenPage />
            )
          }
        />
        <Route
          path={`${match.path}/requisitions`}
          render={(props) =>
            haveViewRequisitionPermission ? (
              <RequisitionsList {...props} permissions={requisitionsListPermissions} />
            ) : (
              <ForbiddenPage />
            )
          }
        />
        <Route
          exact
          path={`${match.path}/orders/:orderId`}
          render={(props) =>
            haveViewOrderPermission ? <TemplatedOrder {...props} permissions={orderPermissions} /> : <ForbiddenPage />
          }
        />
        <Route
          path={`${match.path}/documents`}
          render={(props) => (
            <DocumentsRoutes
              {...props}
              documentsPermissions={documentsPermissions}
              billPermissions={billPermissions}
              packingListPermissions={packingListPermissions}
            />
          )}
        />
        {haveViewSettingsSectionPermission && <Route path={`${match.path}/settings`} component={SettingsRoutes} />}
      </Switch>
      {haveViewFileStoragePermission && <Route exact path={`${match.path}/files/`} component={FilesStorage} />}
      <Route path={`${match.path}/journal`}>
        {haveViewJournalPermission && isChatV2Connected && (
          <Chat room={chatV2Rooms.FACT_WORK} building_id={match.params.objectId} />
        )}
      </Route>
      <Route path={`${match.path}/workers`}>
        {haveViewWorkersPermission && isChatV2Connected && (
          <Chat room={chatV2Rooms.EMPLOYEES} building_id={match.params.objectId} />
        )}
      </Route>
      <Route path={`${match.path}/estimate`}>
        {haveViewProjectPermission && isChatV2Connected && (
          <Chat room={chatV2Rooms.PROJECT} building_id={match.params.objectId} />
        )}
      </Route>
      <Route path={`${match.path}/manufacturing/`}>
        {haveViewChartPermission && isChatV2Connected && (
          <Chat room={chatV2Rooms.SCHEDULE} building_id={match.params.objectId} />
        )}
      </Route>
      <Route path={`${match.path}/stocks`}>
        {haveViewChartPermission && isChatV2Connected && (
          <Chat room={chatV2Rooms.STOCK} building_id={match.params.objectId} />
        )}
      </Route>
    </>
  );
};

export default React.memo(ManufacturingRoutes);
