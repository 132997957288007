import classNames from "classnames";
import React from "react";

import TableReusableHead, { TableReusableHeaderCell } from "shared/ui/dataDisplay/TableReusable/TableReusableHead";

import styles from "./ObjectsRow.module.scss";

interface IProps {
  onChangeFilter: (name: string, value: string) => void;
  className?: string;
}

const ObjectsHeaderRow: React.FC<IProps> = ({ onChangeFilter, className }) => {
  return (
    <TableReusableHead className={classNames(styles.newHeader, styles.head, className)} isSticky>
      <TableReusableHeaderCell
        filterTitle="Наименование объекта"
        filterType="text"
        filterField="name"
        onChangeFilter={onChangeFilter}
      >
        Наименование объекта
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterTitle="Организация"
        filterType="text"
        filterField="entity_name"
        onChangeFilter={onChangeFilter}
      >
        Организация
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterTitle="Заказчик"
        filterType="text"
        filterField="customer"
        onChangeFilter={onChangeFilter}
      >
        Заказчик
      </TableReusableHeaderCell>
      <div />
    </TableReusableHead>
  );
};

export default React.memo(ObjectsHeaderRow);
