import { Dispatch } from "redux";

import { tasksV2Api } from "./api";
import { tasksV2Actions } from "./slice";
import { RootState } from "app/store/rootReducer";

import { errorCatcher } from "utils/helpers/errorCatcher";

export const deleteTaskComment = (id: number) => (dispatch: Dispatch, getState: () => RootState) => {
  dispatch(tasksV2Actions.setPending({ key: String(id), status: true }));
  tasksV2Api
    .deleteComment(id)
    .then(() => {
      dispatch(tasksV2Actions.invalidateKey());
    })
    .catch(errorCatcher);
};

export const editTaskComment =
  (id: number, data: { text: string }) => (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(tasksV2Actions.setPending({ key: String(id), status: true }));
    tasksV2Api
      .patchComment(id, data)
      .catch(errorCatcher)
      .finally(() => {
        dispatch(tasksV2Actions.invalidateKey());
      });
  };

export const attachFilesToTaskFromStorage = (taskId: number, filesIds: number[]) => (dispatch: Dispatch) => {
  if (!filesIds?.length) return;
  //
  const requests = filesIds.map((el) => tasksV2Api.attachFileFromStorageToTask(taskId, el));
  Promise.allSettled(requests)
    .then(() => {
      dispatch(tasksV2Actions.invalidateKey());
    })
    .catch(errorCatcher)
    .finally(() => {});
};
