import { IUploadedFile } from "components/pages/Tasks/components/TaskFiles/TaskFilesPopup/TaskFilesPopup";

import { IFile, IFileNames } from "types/interfaces/Files";

export const serializeFilesByName = (
  file: (IFile & IFileNames) | IUploadedFile,
  filesArray?: (IFile & IFileNames)[]
) => {
  if (Array.isArray(filesArray)) {
    filesArray.map((file) => {
      return {
        ...file,
        //@ts-ignore
        name: file.originalname || file.name || file.file_name || file.file.name,
        link: file.link || file.file,
      };
    });
  } else {
    //@ts-ignore
    let link = file.link ?? file.file_path;
    if (!link && file.file) {
      //@ts-ignore
      link = typeof file.file === "string" ? file.file : file.file.link ?? file.file.file;
    }
    return {
      ...file,
      //@ts-ignore
      name: file.originalname || file.name || file.file_name || file.file?.name,
      link: link as unknown as string,
    };
  }
};
