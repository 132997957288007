import cn from "classnames";
import React from "react";

import ButtonBase from "../../../../../../../shared/ui/controls/ButtonBase";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";

import PaperClipIcon from "../../../../../../../images/icons/PaperClipIcon";

import styles from "./AddFile.module.scss";

export const VARIANTS = {
  BUTTON: "button",
  BOOK: "book",
};

const AddFile = ({ variant, openFileDialog, disabled, isLoading, title = "Прикрепить файл" }) => {
  if (variant === VARIANTS.BOOK)
    return (
      <div
        className={cn(styles.addFile, "fileBtn", {
          [styles.disabled]: disabled || isLoading,
        })}
        onClick={openFileDialog}
      >
        <div className={styles.iconContainer}>
          {!isLoading && <PaperClipIcon width={12} fill="#000" />}
          {isLoading && <Spinner isStatic isSmallGray />}
        </div>
        <div className={cn(styles.text)}>{title}</div>
      </div>
    );

  if (variant === VARIANTS.BUTTON)
    return (
      <ButtonBase
        className={cn(styles.button, {
          [styles.disabled]: disabled,
        })}
        onClick={openFileDialog}
        secondary
        medium
        disabled={disabled || isLoading}
      >
        {!isLoading && <PaperClipIcon className={styles.buttonIcon} width={8} fill="#000" />}
        {isLoading && <Spinner isStatic isSmallGray />}
        {title}
      </ButtonBase>
    );

  return null;
};

export default React.memo(AddFile);
