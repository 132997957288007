import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { setCurrentUser } from "redux/modules/_TODO/auth";

import TaskComments from "../Tasks/components/TaskComments/TaskComments";
import TaskCommentsContainer from "../Tasks/components/TaskComments/TaskCommentsContainer";
import TaskFiles from "../Tasks/components/TaskFiles/TaskFiles";
import MainTabArea from "components/pages/SimplifiedTask/components/MainTabArea/MainTabArea";
import { useGetTask } from "components/pages/Tasks/hooks/useGetTask";

import { approveTask } from "../Tasks/model/thunks";
import { useTypedSelector } from "app/store/typedUseSelector";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import TabBarNotLinks, { ITab } from "shared/ui/controls/TabBar/TabBarNotLinks";
import ExpandableFileInput from "shared/ui/inputs/ExpandableFileInput/ExpandableFileInput";
import Paper from "shared/ui/layout/Paper/Paper";

import { TASK_MODAL_TYPES } from "../Tasks/constants";

import { useUpdateTask } from "../Tasks/hooks/useUpdateTask";

import { serializeFiles } from "utils/serializeFiles";

import styles from "./SimplifiedTask.module.scss";

export interface ISimplifiedTaskProps {
  taskId: number | string;
}

export const MAIN_TAB_ID = "main";
export const ADDITIONAL_TAB_ID = "add";

export const TABS = [{ text: "Основные сведения", className: styles.tab, id: MAIN_TAB_ID }];

const SimplifiedTask: FC<ISimplifiedTaskProps> = ({ taskId }) => {
  const dispatch = useDispatch();

  const { id: myId } = useTypedSelector((state) => state.auth.user);

  const { certainTask, isCertainTaskLoading } = useGetTask(+taskId, true);

  const { updateTaskStatusDirectly } = useUpdateTask({
    taskId: certainTask?.id,
    placementType: "byAllBuildings",
    onSuccess: () => {},
  });

  const isMeExecutor = +certainTask?.executor === +myId;
  const isMeReviewer = certainTask?.viewers.some((id) => +id === +myId);
  const isMeCreator = +certainTask?.creator === +myId;
  const approvers = certainTask?.approves_data;

  const showApproveButton = useMemo(() => {
    return approvers?.some((approver) => +approver.approver.id === +myId && approver.status !== "approved");
  }, [approvers, myId]);

  const approveId = useMemo(() => {
    return approvers?.find((approver) => +approver.approver.id === +myId)?.id;
  }, [approvers, myId]);

  const hideStatusBtns = useMemo(() => {
    return approvers?.some((el) => el.status !== "approved");
  }, [approvers]);

  const showStartWorkButton = isMeExecutor && certainTask?.status === "to_do" && !hideStatusBtns;
  const showSendToReviewButton = isMeExecutor && certainTask?.status === "in_work" && !hideStatusBtns;
  const showAcceptButton = (isMeCreator || isMeReviewer) && certainTask?.status === "completed" && !hideStatusBtns;
  const showToReworkButton = (isMeCreator || isMeReviewer) && certainTask?.status === "completed" && !hideStatusBtns;

  useEffect(() => {
    dispatch(setCurrentUser());
  }, []);

  if (isCertainTaskLoading) {
    return (
      <div data-testid="spinner">
        <Spinner />
      </div>
    );
  }

  if (!certainTask) return null;

  return (
    <div className={styles.container}>
      <Paper>
        <div className={styles.content} data-testid="content">
          <header className={styles.header}>
            <h1 className={styles.title} data-testid="taskTitle">
              {certainTask.name}
            </h1>
          </header>
          <div className={styles.grid}>
            <MainTabArea
              deadline={certainTask.deadline}
              priority={certainTask.priority}
              buildingName={certainTask.building_obj.name}
              sectionName={certainTask.section_obj ? certainTask.section_obj.name : null}
              executor={certainTask.executor_user}
              viewers={certainTask.viewers_data}
              status={certainTask.status}
              creator={certainTask.creator_user}
              description={certainTask.description}
              rangeClassName={styles.rangeContainer}
              id={certainTask.id}
              approves_data={certainTask.approves_data}
            />
            <div>
              <TaskCommentsContainer taskId={certainTask.id} comments={certainTask.task_comments} />
              <br />
              <TaskFiles
                files={certainTask.task_files}
                taskId={certainTask.id}
                variant={TASK_MODAL_TYPES.EDIT}
                task={{ building: certainTask.building }}
                popupClassName={styles.filesPopup}
              />
              <div className={styles.btns}>
                {showToReworkButton && (
                  <ButtonBase secondary onClick={() => updateTaskStatusDirectly("to_do")}>
                    На доработку
                  </ButtonBase>
                )}
                {showAcceptButton && (
                  <ButtonBase primary onClick={() => updateTaskStatusDirectly("closed")}>
                    Принять задачу
                  </ButtonBase>
                )}
                {showStartWorkButton && (
                  <ButtonBase primary onClick={() => updateTaskStatusDirectly("in_work")}>
                    Взять в работу
                  </ButtonBase>
                )}
                {showSendToReviewButton && (
                  <ButtonBase primary onClick={() => updateTaskStatusDirectly("completed")}>
                    Отправить на проверку
                  </ButtonBase>
                )}
                {showApproveButton && (
                  <ButtonBase primary onClick={() => dispatch(approveTask(taskId, approveId!))}>
                    Согласовать
                  </ButtonBase>
                )}
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default React.memo(SimplifiedTask);
