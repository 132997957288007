import React, { useEffect, useState } from "react";

import FileViewerItem from "./FileViewerItem";
import Portal from "shared/ui/atoms/Portal";
import Spinner from "shared/ui/atoms/Spinner/Spinner";

import { IFile } from "types/interfaces/Files";

import useEscapeHandler from "utils/hooks/useEscapeHandler";

import styles from "./FileViewer.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  files: IFile[];
  startIndex?: number;
  isLoading?: boolean;
}

const FileViewer: React.FC<IProps> = ({ isOpen, onClose, files, startIndex = 0, isLoading }) => {
  useEscapeHandler(onClose);

  const [activeIndex, setActiveIndex] = useState(startIndex);

  useEffect(() => {
    setActiveIndex(startIndex);
  }, [startIndex]);

  if (!isOpen) return null;

  return (
    <Portal>
      <div className={styles.container}>
        <div className={styles.shadow} onClick={onClose}></div>
        {files.map((el, i, arr) => (
          <FileViewerItem
            isHidden={activeIndex !== i}
            key={el.id}
            isFirst={i === 0}
            isLast={i === arr.length - 1}
            onClose={onClose}
            file={el}
            setIndex={setActiveIndex}
          />
        ))}
        {isLoading && <Spinner className={styles.spinner} />}
      </div>
    </Portal>
  );
};

export default FileViewer;
