import cn from "classnames";
import moment from "moment";
import React, { FC, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { useTasksModal } from "components/pages/Tasks/hooks/useTasksModal";
import { ICertainTaskResponse } from "components/pages/Tasks/model/types";
import { deleteTaskComment, editTaskComment } from "components/pages/Tasks/model_v2/thunks";

import { ITaskComments } from "../../../TaskComments/TaskComments";
import { renderTextWithLinks } from "../../../TaskComments/TaskOneComment";
import TaskCommentFiles from "./TaskCommentFiles/TaskCommentFiles";
import { ReactComponent as ClipIcon } from "./clip.svg";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import Actions from "shared/ui/controls/Actions/Actions";
import FilesClipButton from "shared/ui/controls/FilesClipButton/FilesClipButton";
import Avatar from "shared/ui/dataDisplay/Avatar/Avatar";
import InputBase from "shared/ui/inputs/InputBase";

import styles from "./TaskComentsTab.module.scss";

interface IProps {
  comment: Pick<ITaskComments, "comments">["comments"][number];
  isMine?: boolean;
  isPending?: boolean;
}

const TaskCommentV2: FC<IProps> = ({ comment, isMine, isPending }) => {
  const dispatch = useDispatch();
  const { onClose } = useTasksModal();

  const [isEditing, setIsEditing] = useState(false);
  const [commentText, setCommentText] = useState(() => comment.text);

  const showActions = useMemo(() => {
    if (isPending) return false;
    if (isEditing) return false;
    if (!isMine) return false;
    return moment().diff(moment(comment.created_at)) / 60_000 < 30;
  }, [comment, isPending, isEditing, isMine]);

  const [isFiles, setIsFiles] = useState(false);

  return (
    <div className={cn(styles.root, isMine && styles.mine)}>
      <Avatar person={comment.creator_name} />
      <div className={styles.column}>
        <div className={styles.text}>
          {!isEditing ? (
            renderTextWithLinks(commentText, onClose)
          ) : (
            <div className={styles.edit}>
              <InputBase
                classNameInput={styles.editInput}
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                onEnter={() => {
                  dispatch(editTaskComment(comment.id, { text: comment.text }));
                  setIsEditing(false);
                }}
                icon={
                  <Actions
                    canSave
                    canRemove
                    onRemoveDirectly={() => {
                      setCommentText(comment.text);
                      setIsEditing(false);
                    }}
                    onSave={() => {
                      dispatch(editTaskComment(comment.id, { text: commentText }));
                      setIsEditing(false);
                    }}
                  />
                }
              />
            </div>
          )}
          {!!comment.files_count && !isEditing && (
            <div className={styles.filesCount}>
              <FilesClipButton count={comment.files_count} onClick={() => setIsFiles(true)} />
              {isFiles && <TaskCommentFiles onClose={() => setIsFiles(false)} commentId={comment.id} />}
            </div>
          )}
        </div>
        <div className={styles.meta}>
          <span>{comment.creator_name}</span>
          <div className={styles.metaRight}>
            <span>
              {moment(comment.created_at).format("DD/MM/YYYY")} в {moment(comment.created_at).format("HH:mm")}
            </span>
            {showActions && (
              <Actions
                className={styles.actions}
                canEdit
                canRemove
                onEdit={() => {
                  setIsEditing(true);
                }}
                onRemove={() => {
                  dispatch(deleteTaskComment(comment.id));
                }}
              />
            )}
            {isPending && (
              <div className={styles.pending}>
                <Spinner isSmallGray isStatic />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskCommentV2;
