import cn from "classnames";
import React, { useImperativeHandle, useMemo, useRef } from "react";

import SharedBrace from "components/pages/Manufacturing/components/Month/components/DiagramInterval/components/SharedBrace/SharedBrace";
import { getIntervalDatesLabel } from "components/pages/Manufacturing/utils";

import ChartIntervalPopup from "../ChartIntervalPopup/ChartIntervalPopup";
import type { IChartIntervalProps } from "./ChartInterval.typings";
import { chartIntervalCn } from "./const";
import { useChartIntervalSize } from "./useChartIntervalSize";

import { useChartIntervalPopup } from "../ChartIntervalPopup/ChartIntervalPopup.hooks/useChartIntervalPopup";

import styles from "./ChartInterval.module.scss";

const ChartInterval: React.FC<IChartIntervalProps> = ({
  start,
  end,
  id,
  forwardRef,
  innerBeforeContent,
  children,
  innerAfterContent,
  afterContent,
  beforeContent,
  openModal,
  intervalData,
  popupType,
  intervalLeftRem,
  intervalWidthRem,
  remarksCount,
  sharedStatus,
  zIndex,
  hasShift,
  openShiftModal,
  className,
  inlinePopup,
}) => {
  const intervalRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(forwardRef, () => intervalRef.current, []);
  const { leftRem, widthRem, isInvisible } = useChartIntervalSize({
    start,
    end,
    disabled: intervalWidthRem !== undefined && intervalLeftRem !== undefined,
  });

  const intervalDates = useMemo(() => getIntervalDatesLabel(start, end), [start, end]);

  const { popupRef, movePopup } = useChartIntervalPopup({ containerRef: intervalRef, inlinePopup });

  if (isInvisible) return null;

  return (
    <>
      {beforeContent}
      <div
        className={cn(styles.diagramIntervalWrapper, chartIntervalCn, className)}
        style={{
          width: `${intervalWidthRem ?? widthRem}rem`,
          transform: `translateX(${intervalLeftRem ?? leftRem}rem)`,
          zIndex,
        }}
        id={id}
        ref={intervalRef}
      >
        {innerBeforeContent}
        {children}
        {sharedStatus ? <SharedBrace status={sharedStatus} /> : null}
        {innerAfterContent}
        {popupType ? (
          <ChartIntervalPopup
            ref={popupRef}
            intervalDates={intervalDates}
            data={intervalData}
            type={popupType}
            remarksCount={remarksCount}
            openModal={openModal}
            hasShift={hasShift}
            openShiftModal={openShiftModal}
            movePopup={movePopup}
            usePortal={!inlinePopup}
            placement={inlinePopup ? "bottom-start" : undefined}
          >
            <div className={styles.popupAnchor} />
          </ChartIntervalPopup>
        ) : null}
      </div>
      {afterContent}
    </>
  );
};

export default React.memo(ChartInterval);
