import React, { FC, memo, useCallback, useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import EmptyDraft from "../../../../../../../ProHandler/components/Body/components/Sections/components/Draft/Draft";
import EmptyMessage from "../../../../../../../ProHandler/components/EmptyMessage/EmptyMessage";

import Spinner from "../../../../../../../../../../shared/ui/atoms/Spinner/Spinner";
import {
  checkedItemsIdsSelector,
  estimateStateIdSelector,
  invalidateKeySelector,
  modeSelector,
  sectionsAreLoadingSelector,
  sectionsSelector,
} from "../../../../../../model/selectors";
import { simpleHandlerActions } from "../../../../../../model/slice";
import { loadSections } from "../../../../../../model/thunks";
import { CommonSectionView } from "../Section/CommonSectionView/CommonSectionView";

import ISection from "../../../../../../../../../../types/interfaces/Section";
import { EEstimateStatesIds } from "../../../../../../../ProHandler/enums";

import { useSectionActions } from "../../hooks/useSectionActions";

import styles from "../../SectionsList.module.scss";

/**
 * Обычный вид списка разделов (секций, ЛСР)
 */
export const CommonSectionsListView: FC = memo(() => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();

  const estimateStateId = useSelector(estimateStateIdSelector);
  const mode = useSelector(modeSelector);
  const sectionsAreLoading = useSelector(sectionsAreLoadingSelector);

  const invalidateKey = useSelector(invalidateKeySelector);

  const sections = useSelector(sectionsSelector);
  const checkedItemsIds = useSelector(checkedItemsIdsSelector);

  const { buildingId } = useParams<{ buildingId?: string }>();

  useEffect(() => {
    if (buildingId && estimateStateId) {
      dispatch(loadSections(buildingId, estimateStateId));
    }
  }, [estimateStateId, buildingId, invalidateKey]);

  const onCreateSectionButtonClick = useCallback(() => {
    dispatch(simpleHandlerActions.setIsSectionAdding(true));
  }, []);

  const onSectionClick = useCallback(
    (section: ISection) => {
      batch(() => {
        dispatch(simpleHandlerActions.setActiveSection(section));
        dispatch(simpleHandlerActions.clearCheckedItems());
      });
      history.push(`${match.url}/${section.id}`);
    },
    [history]
  );

  const onSectionCheck = useCallback(
    (section: ISection) => {
      dispatch(simpleHandlerActions.toggleCheckedItem({ id: section.id, name: section.name }));
    },
    [estimateStateId]
  );

  const { getSectionActions } = useSectionActions();

  if (sectionsAreLoading) {
    return <Spinner isStatic />;
  }

  // Если секции не null и пустой массив – значит успешно прошел запрос
  // и вернулся пустой массив
  if (sections && sections.length === 0) {
    // Для стейта "Черновик" показываем специальный компонент
    // из которого можно нажать на кнопку создания новой секции
    if (estimateStateId === EEstimateStatesIds.DRAFT) {
      return (
        <EmptyDraft
          onCreateSection={onCreateSectionButtonClick}
          titleClassName={styles.emptyTitle}
          buttonClassName={styles.emptyButton}
        />
      );
    }
    return <EmptyMessage message={`Чтобы продолжить, перенесите разделы и/или позиции`} />;
  }

  if (sections && sections.length) {
    return (
      <>
        {sections.map((section) => (
          <CommonSectionView
            key={section.id}
            onClick={onSectionClick}
            section={section}
            estimateStateId={estimateStateId!}
            onCheck={onSectionCheck}
            checked={checkedItemsIds.includes(section.id)}
            mode={mode}
            actions={getSectionActions(section)}
            isCheckboxVisible //={estimateStateId !== EEstimateStatesIds.PRODUCTION}
          />
        ))}
      </>
    );
  }

  return null;
});
