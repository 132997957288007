import { IAcceptedExpenditure } from "../../../../../../components/pages/Journal/components/JournalDone/components/JournalExecTicket/components/AcceptTicketModal/AcceptTicketModal";
import { ItaskFile } from "components/pages/Tasks/model/types";

import { IIdAndName } from "../../../../../../types/idAndName";
import { ExpenditureTypeEnum } from "types/enums/ExpenditureTypeEnum";
import { IFile, IFilesBase64Format } from "types/interfaces/Files";
import { IGroupInJournalDone, ITicketInJournalDone } from "types/interfaces/Tickets";

export interface IJournalExecutionInitialState {
  sections: Record<string, ISectionsByBuildingInJournalExecution>;
  ticketsBySections: Record<string, ITicketsBySectionInJournalExecution>;
  sectionsWithRemarks: Record<string, any>;
  remarksBySections: Record<string, any>;
  createRemark: Record<string, boolean>;
  editRemark: Record<string, boolean>;
  remarksList: IRemarksListBySectionInJournalExecution;
  detailRemarkInfo: IRemarkDetailInfoBySectionInJournalExecution;
  resolveOrReturnRemark: Record<string, boolean>;
  deleteRemark: Record<string, boolean>;
  acceptRemark: Record<string, boolean>;
  invalidateKey: number;
  reportInvalidateKey: number;
  ticketActingInfo: StateResults<ITicketActingInfo>;
  groupTicketActingInfo: StateResults<IGroupTicketActingInfo>;
  ///////////// V2
  sectionsV2: Record<string, ISectionInJournalFulfillment[]>;
  expendituresV2: Record<string, IExpendituresInJournalFulfillment>;
  groups: Record<string, IJournalFulFillmentGroup>;
  contentOfExpenditures: Record<string, null | Omit<IJournalFulFillmentGroup, "expenditures">>;
  materialsInvalidateKey: number;
  sectionsV2Loading: Record<string, boolean>;
  expendituresV2Loading: Record<string, boolean>;
}

export interface IGroupTicketActingInfo {
  expenditure: IGroupInJournalDone | null;
  materials: ITicketActingExpenditure[];
  services: ITicketActingExpenditure[];
  expenditures: ITicketActingExpenditure[];
}

interface ISectionsByBuildingInJournalExecution {
  results: ISectionInJournalExec[];
  isLoading: boolean;
  filters: any;
}

interface ITicketsBySectionInJournalExecution {
  results: ITicketInJournalDone[];
  isLoading?: boolean;
}

export interface ISectionInJournalExec {
  id: number;
  name: string;
  is_default: boolean;
  parent_name: string | null;
}

export interface ISectionsFilterJournalExec {
  search?: string;
  parent_name?: string;
  id?: number;
}
export interface ICreateRemarkData {
  name?: string;
  description: string;
  estimate_expenditure: number | null;
  estimate_group: number | null;
  responsible: number;
  resolve_date: string;
  files?: IFilesBase64Format[];
}

export interface ICreateDeliveryRemarkData {
  name?: string;
  description: string;
  work_interval_id: number | null;
  group_interval_id: number | null;
  responsible: number;
  resolve_date: string;
  files?: IFilesBase64Format[];
}

export interface IResolveRemarkData {
  resolve_comment?: string;
  files?: IFilesBase64Format[];
}

export interface IReturnRemarkData {
  check_comment?: string;
  files?: IFilesBase64Format[];
}

export interface IEditRemarkData {
  name?: string;
  description: string;
  resolve_date: string;
  responsible: number;
  author: number;
  files?: IFile[];
  uploaded_files?: IFilesBase64Format[];
}

interface IRemarksListBySectionInJournalExecution {
  results: IRemarksList[];
  isLoading: boolean;
}

interface StateResults<T> {
  results: T;
  isLoading: boolean;
  isApproving: boolean;
}

export enum REMARK_STATUSES {
  created = "К устранению",
  review = "На проверке",
  accepted = "Принято",
}

export type RemarkStatus = "created" | "accepted" | "review";

export interface IRemarksList {
  author_id: number;
  author_name: string;
  created_at: string;
  description: string;
  id: number;
  name: string;
  resolve_date: string;
  responsible: number;
  responsible_name: string;
  row_number: number;
  status: RemarkStatus;
  regulations: string;
  with_files?: { id: number; originalname: string; file: string }[];
}

interface IRemarkDetailInfoBySectionInJournalExecution {
  result: IDetailRemarkInfo | null;
  isLoading: boolean;
}

export interface IDetailRemarkInfo {
  author: number;
  author_name: string;
  corrections: IRemarkCorrections[];
  created_at: string;
  description: string;
  files: IFile[];
  id: number;
  name: string;
  resolve_date: string;
  accepted_at: string;
  responsible: number;
  regulations: string;
  responsible_name: string;
  status: RemarkStatus;
}

export interface IRemarkCorrections {
  check_comment: string;
  check_date: string;
  check_files: IFile[];
  created_at: string;
  remark_correction_id: number;
  need_work: boolean;
  resolve_comment: string;
  resolve_files: IFile[];
}

export interface RemarksIndicators {
  count_remarks: number;
  count_remarks_created: number;
  count_remarks_review: number;
  count_remarks_accepted: number;
}

export interface ISubsectionWithRemarks extends RemarksIndicators, IIdAndName {}

export interface ISectionWithRemarks extends RemarksIndicators, IIdAndName {
  subsections: ISubsectionWithRemarks[];
}

export interface IRemarkBySection extends IRemarksList {
  expenditure_section: IIdAndName & { parent: IIdAndName };
  expenditure: IIdAndName;
}

export type RemarkBySectionFilters =
  | Record<"name" | "author" | "resolve_date_before" | "resolve_date_after" | "status" | "responsible", string>
  | {};

export interface ITicketActingInfo {
  expenditure: ITicketActingExpenditure | null;
  materials: ITicketActingExpenditure[];
  services: ITicketActingExpenditure[];
}

export interface IAcceptedTicketActingInfo {
  expenditure: ITicketActingExpenditure;
  materials: IAcceptedExpenditure[];
  services: IAcceptedExpenditure[];
}

export interface IAcceptedGroupTicketActingInfo extends IAcceptedTicketActingInfo {
  expenditures: ITicketActingExpenditure[];
}

export interface ITicketActingExpenditure {
  id: number;
  name: string;
  measure: string;
  sum_accepted_count: string;
  sum_accepted_amount: string;
  sum_accepted_amount_for_one: number;
  price: number;
  justification: string;
}

export interface IAcceptTicketToActingData {
  exclude_work: boolean;
  materials: IAcceptTicketToActingExpenditure[] | null;
  services: IAcceptTicketToActingExpenditure[] | null;
}

export interface IAcceptGroupTicketToActingData {
  group_count: string;
  materials: IAcceptTicketToActingExpenditure[];
  services: IAcceptTicketToActingExpenditure[];
  year: number;
  month: number;
}

export interface IAcceptTicketToActingExpenditure {
  count: string;
  expenditure: number;
}

export interface ISectionInJournalFulfillment extends IIdAndName {
  subsections: IIdAndName[];
}

export interface IExpendituresInJournalFulfillment {
  parent_id: number;
  parent_name: string;
  id: number;
  name: string;
  stock_id: number;
  items: [
    {
      number: number;
      type: "group" | "expenditure" | "linked";
      group?: IItemInExpenditures & {
        group_id: number;
        count_expenditures: number;
      };
      linked?: IItemInExpenditures & {
        justification: string;
        expenditure_id: number;
        works: IItemInExpenditures[];
      };
      expenditure?: IItemInExpenditures & {
        justification: string;
        expenditure_id: number;
      };
    }
  ];
}

export interface IAookPreviewInJournalExpenditure {
  id: number;
  act_date: string;
  status: "draft" | "close";
}

interface IItemInExpenditures {
  amount: string;
  amount_materials: string;
  count_remarks_in_work: 0;
  count_remarks_accepted: 0;
  cc_confirmed?: boolean;
  actual_aooks: IAookPreviewInJournalExpenditure[];
  old_aook: IAookPreviewInJournalExpenditure | null;
  aosr?: IAookPreviewInJournalExpenditure & {
    is_old: boolean;
  };
  ear?: {
    status: "gray" | "green" | "blue";
    count: null;
  };
  fulfillment_count: number;
  name: string;
  measure: string;
  count: string;
  replacement?: {
    has_drawing: boolean;
    has_covering_letter: boolean;
  };
  indicators: {
    estimate_count: string;
    accepted_count: string;
    completed_count: string;
  };
  attachments?: IAttachmentsIndicators;
  facts_id: number[];
  is_default?: boolean;
}

export interface IJournalFulFillmentGroup {
  expenditures: {
    attachments?: IAttachmentsIndicators;
    expenditure_id: number;
    justification: string;
    name: string;
    measure: string;
    count: string;
    amount: string;
    amount_materials: string;
    aosr?: { id: number; act_date: string; status: number; is_old: boolean };
    actual_aooks: { id: number; act_date: string; status: number }[];
    old_aook?: { id: number; act_date: string; status: number };
    replacement?: { has_drawing: boolean; has_covering_letter: string };
    indicators: {
      estimate_count: string;
      accepted_count: string;
      completed_count: string;
    };
  }[];
  materials: {
    attachments?: IAttachmentsIndicators;
    interval_id: number;
    name: string;
    measure: string;
    count: string;
    confirmed_count: string;
    is_confirmed: boolean;
    confirmed_user: null | IIdAndName;
    confirmed_amount_type: "estimate" | "fact";
    confirmed_amount_estimate: string;
    confirmed_amount_fact: string;
    expenditure_type: ExpenditureTypeEnum;
    expenditure_id: number;
  }[];

  services: {
    name: string;
    measure: string;
    count: string;
    amount: string;
    attachments?: IAttachmentsIndicators;
    expenditure_type: ExpenditureTypeEnum;
    expenditure_id: number;
  }[];
}

interface IAttachmentsIndicators {
  has_expired: boolean;
  count: number;
}
