import classNames from "classnames";
import React, { FC, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { setObjectDetail } from "redux/modules/common/building/object/nowObject";

import Cropper from "./Cropper";
import objectImg from "./object.jpg";
import { objectsListActions } from "features/objectsList/model/slice";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import FilesClipButton from "shared/ui/controls/FilesClipButton/FilesClipButton";
import Modal from "shared/ui/modal/Modal";

import { _TEMP_useBuildingDetailDataById } from "hooks/useBuildingDetailDataById";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import PaperClipIcon from "images/icons/PaperClipIcon";

import styles from "./ObjectAvatar.module.scss";

interface IProps {
  avatar?: string;
  objectId?: number;
}

const ObjectAvatar: FC<IProps> = ({ objectId }) => {
  const dispatch = useDispatch();
  const { buildingData, isBuildingLoading } = _TEMP_useBuildingDetailDataById(String(objectId));
  const [isModal, setIsModal] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""));
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const [imgLoading, setIsImgLoaidng] = useState(true);
  const showSpinner = isBuildingLoading || imgLoading;
  const avatar = buildingData?.avatar;

  const [isCropLoading, setIsCropLoading] = useState(false);
  const onSubmit = (fileInB64: string) => {
    setIsCropLoading(true);
    dispatch(
      setObjectDetail({ avatar: fileInB64, id: objectId }, () => {
        setIsCropLoading(false);
        setIsModal(false);
        dispatch(objectsListActions.invalidateKey());
        setImgSrc("");
      })
    );
  };

  return (
    <div className={styles.imgWrapper} onClick={stopEventPropagation}>
      <div className={styles.controls}>
        <FilesClipButton onClick={() => setIsModal(true)} />
      </div>
      {showSpinner && (
        <div className={styles.spinner}>
          <Spinner isSmall />
        </div>
      )}
      <img
        key={avatar}
        className={classNames(showSpinner && styles.hidden)}
        src={avatar || objectImg}
        alt="Object"
        onLoad={() => {
          setIsImgLoaidng(false);
        }}
      />
      {isModal && (
        <Modal
          isOpen={isModal}
          onClose={() => setIsModal(false)}
          title={avatar ? "Изменить фото" : "Добавить фото"}
          className={styles.modal}
        >
          <div className={styles.content}>
            {imgSrc && <Cropper src={imgSrc} onSubmit={onSubmit} isLoading={isCropLoading} />}
            {!imgSrc && (
              <div
                className={styles.uploader}
                onClick={() => {
                  inputRef.current?.click();
                }}
              >
                <div>
                  <PaperClipIcon />
                  Выберите файл
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}
      <input type="file" ref={inputRef} className={styles.hidden} onChange={onSelectFile} />
    </div>
  );
};

export default ObjectAvatar;
