import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useTypedSelector } from "app/store/typedUseSelector";

import { summaryUtils } from "./utils";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

interface IProps {
  year: number;
}

export const useSummaryTreeSelector = ({ year }: IProps) => {
  const { objectId, projectId } = useParams<Record<"objectId" | "projectId", string>>();
  const { dataTree, loadings, totals } = useTypedSelector((state) => state.financeReducer.summary);

  const displayingTree = useMemo(() => {
    if (projectId === "0") {
      return dataTree[year];
    }
    if (objectId === "0" && projectId !== "0") {
      return summaryUtils.extractTreeByProject(dataTree[year], +projectId);
    }
    return summaryUtils.extractTreeByObject(dataTree[year], +projectId, +objectId);
  }, [dataTree, objectId, projectId, year]);

  const tableData = useMemo(() => {
    return summaryUtils.extractTableData(displayingTree);
  }, [displayingTree]);

  const showSpinner = useMemo(() => {
    if (projectId === "0") {
      return loadings[generateStorageKey({ type: "all", year })];
    }
    if (objectId === "0" && projectId !== "0") {
      return loadings[generateStorageKey({ projectId, type: "project" })];
    }
    return loadings[generateStorageKey({ buildingId: objectId, type: "object" })];
  }, [objectId, projectId, loadings, year]);

  const displayingTotals = useMemo(() => {
    if (projectId === "0") {
      return null;
    }
    if (objectId === "0" && projectId !== "0") {
      return totals[generateStorageKey({ type: "project", id: projectId, year })];
    }
    return totals[generateStorageKey({ type: "object", id: objectId, year })];
  }, [totals, projectId, objectId, year]);

  return {
    displayingTree,
    tableData,
    showSpinner,
    displayingTotals,
  };
};
