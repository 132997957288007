import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactTextareaAutosize from "react-textarea-autosize";

import { userSelector } from "redux/modules/_TODO/auth";

import { useTaskComments } from "components/pages/Tasks/hooks/useTaskComments";
import { ICertainTaskResponse } from "components/pages/Tasks/model/types";

import { ITaskComments } from "../../../TaskComments/TaskComments";
import TaskFilesPopup from "../../../TaskFiles/TaskFilesPopup/TaskFilesPopup";
import TaskCommentV2 from "./TaskCommentV2";
import { ReactComponent as ClipIcon } from "./clip.svg";
import emptyIcon from "./empty.svg";
import { useTaskCommentsLayout } from "./useTaskCommentsLayout";
import { useTypedSelector } from "app/store/typedUseSelector";

import styles from "./TaskComentsTab.module.scss";

interface IProps extends Pick<ITaskComments, "comments"> {
  taskId: number;
  task: ICertainTaskResponse;
}

const TaskComentsTab = ({ taskId, comments, task }: IProps) => {
  const currentUser = useTypedSelector(userSelector);
  const { id: myId } = useTypedSelector((state) => state.auth.user);
  const {
    createNewTaskCommentHandler,
    writeTaskCommentHandler,
    commentText,
    textInputRef,
    setFiles,
    filesFromStorage,
    setFilesFromStorage,
    files,
  } = useTaskComments(taskId, currentUser, false);

  //TODO naming
  const { a, b, height, listRef } = useTaskCommentsLayout({ comments, commentText });

  const [isFilesPopup, setFilesPopup] = useState(false);
  const pendings = useTypedSelector((state) => state.tasksV2Reducer.pendings);

  const attachedCount = files.length + filesFromStorage.length;

  return (
    <div ref={a}>
      {!!comments.length && (
        <div className={styles.list} style={{ height }} ref={listRef}>
          {comments.map((el) => (
            <TaskCommentV2
              key={el.id}
              comment={el}
              isMine={el.creator === myId}
              isPending={pendings[el.id] || el.id < 1}
            />
          ))}
        </div>
      )}
      {!comments.length && (
        <div className={styles.empty} style={{ height }}>
          <img src={emptyIcon} />
          <span>Нет комментариев</span>
        </div>
      )}

      <div className={styles.inputWrapper} ref={b}>
        <ReactTextareaAutosize
          placeholder="Введите текст комментария"
          minRows={3}
          maxRows={5}
          ref={textInputRef}
          value={commentText}
          onChange={writeTaskCommentHandler}
          className={styles.textarea}
        />
        <button className={styles.clip} onClick={() => setFilesPopup(true)}>
          {!!attachedCount && <div className={styles.attachedCount}>{attachedCount}</div>}
          <ClipIcon />
        </button>
        <button className={styles.send} onClick={createNewTaskCommentHandler}>
          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="13.5" cy="13.5" r="13.5" fill="#4FB1EB" />
            <path d="M6 10L22 9L10 20V15L12.5 12.5L9 13L6 10Z" fill="white" />
          </svg>
        </button>
      </div>
      {isFilesPopup && (
        <TaskFilesPopup
          task={task}
          setFiles={setFiles}
          onClose={() => {
            setFilesPopup(false);
          }}
          selectdFilesIds={filesFromStorage.map((el) => el.id)}
          setFilesFromStorage={setFilesFromStorage}
          files={files}
        />
      )}
    </div>
  );
};

export default TaskComentsTab;
