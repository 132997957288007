import axios from "axios";

import { IConstControlAccForObject, IConstControlInBuilding, JournalConstControlSteps } from "./types";
import { IListResponseData } from "types/ListResponse";

export const settingsObjectApi = {
  getConstrControlAccounts: (objectId: string, type: keyof IConstControlInBuilding | null) => {
    const typeParam = type ? { [type]: true } : {};
    return axios.get<IListResponseData<IConstControlAccForObject>>(
      `/construction_control/building/${objectId}/accounts/`,
      { params: { limit: 500, ...typeParam } }
    );
  },
  getBuildingConstrControl: (objectId: string) => {
    return axios.get<IConstControlInBuilding>(`/construction_control/building/${objectId}/`);
  },
  setBuildingConstrControl: (objectId: string, accountId: any, journal_step: JournalConstControlSteps) => {
    return axios.post<IConstControlInBuilding>(`/construction_control/building/${objectId}/set/`, {
      account_id: accountId,
      journal_step,
    });
  },
  deleteBuildingConstrControl: (objectId: string, accountId: any, journal_step: JournalConstControlSteps) => {
    return axios.delete<IConstControlInBuilding>(`/construction_control/building/${objectId}/unset/`, {
      data: {
        account_id: accountId,
        journal_step,
      },
    });
  },
};
