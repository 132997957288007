import cn from "classnames";
import classNames from "classnames";
import React, { FC, SetStateAction, useEffect, useMemo, useState } from "react";

import InputFiles from "components/UI/atoms/_TODO/InputFiles/InputFiles_V2";
import { ICertainTaskResponse } from "components/pages/Tasks/model/types";

import ButtonBase from "shared/ui/controls/ButtonBase";
import TabBarNotLinks from "shared/ui/controls/TabBar/TabBarNotLinks";
import FilesFromStorage, { IFileFromS3 } from "widgets/FilesFromStorage/FilesFromStorage";

import CrossIcon from "images/icons/CrossIcon";

import styles from "./TaskFilesPopup.module.scss";

export interface IUploadedFile {
  file: File;
  id: number;
}

const tabs = [
  { id: "disk", text: "Диск OSLA" },
  { id: "upload", text: "На компьютере" },
];

interface IProps {
  onClose: () => void;
  setFiles: React.Dispatch<SetStateAction<IUploadedFile[]>>;
  task: ICertainTaskResponse;
  selectdFilesIds: number[];
  setFilesFromStorage: React.Dispatch<SetStateAction<IFileFromS3[]>>;
  popupClassName?: string;
  files?: IUploadedFile[];
}

const TaskFilesPopup: FC<IProps> = ({
  onClose,
  setFiles,
  task,
  selectdFilesIds,
  setFilesFromStorage,
  popupClassName,
  files,
}) => {
  const [tab, setTab] = useState(tabs[0].id);
  const [uploadedFiles, setUploadedFiles] = useState<IUploadedFile[]>([]);

  useEffect(() => {
    if (files?.length) {
      setUploadedFiles(files);
    }
  }, [files]);

  const attachFiles = (files: IUploadedFile[]) => {
    setUploadedFiles((prev) => [...prev, ...files]);
  };

  const removeFile = (id: number) => {
    setUploadedFiles((prev) => prev.filter((file) => file.id !== id));
  };

  const onSubmit = () => {
    setFiles(uploadedFiles);
    onClose();
  };

  const onCancel = () => {
    setUploadedFiles([]);
    onClose();
  };

  const selectedMap = useMemo(() => {
    return selectdFilesIds.reduce<Record<string, boolean>>((acc, cur) => {
      acc[cur] = true;
      return acc;
    }, {});
  }, [selectdFilesIds]);

  const filesCount = uploadedFiles.length + selectdFilesIds.length;

  if (!task.building) return null;

  return (
    <div className={classNames(styles.modal, popupClassName)}>
      <button className={styles.close} onClick={onClose}>
        <CrossIcon />
      </button>
      <div className={styles.title}>Выберите файл</div>
      <TabBarNotLinks activeId={tab} tabs={tabs} onClick={setTab} isOnlyTabsView className={styles.tabs} />
      <div className={styles.meta}>
        <div>Список вложений: {filesCount ? <b>{filesCount}</b> : "нет вложений"}</div>
      </div>
      {tab === "upload" && (
        <InputFiles
          value={uploadedFiles}
          uploadFilesDirectly={attachFiles}
          removeFileDirectly={removeFile}
          className={styles.filesField}
        />
      )}
      {tab === "disk" && (
        <FilesFromStorage
          objectId={task.building}
          setFilesFromStorage={setFilesFromStorage}
          selectedIdsMap={selectedMap}
        />
      )}
      <div className={styles.bottom}>
        <ButtonBase secondary onClick={onCancel}>
          Отменить
        </ButtonBase>
        <ButtonBase primary onClick={onSubmit} /* disabled={!uploadedFiles.length && !selectdFilesIds.length} */>
          Подтвердить
        </ButtonBase>
      </div>
    </div>
  );
};

export default TaskFilesPopup;
