import React, { useEffect, useState } from "react";

import AxesAndMarksForm from "./AxesAndMarksForm";
import AxesAndMarksInfo from "./ui/AxesAndMarksInfo/AxesAndMarksInfo";

import { ICreateAxesArg } from "./model/types";

import { axesUtils } from "./utils";

interface IProps {
  isOpen: boolean;
  expenditureIds?: number[];
  onSubmit: (vals: ICreateAxesArg) => void;
  savingAxes: ICreateAxesArg | null;
  setIsDirty: (status: boolean) => void;
}

const CreateAxesAndMarks: React.FC<IProps> = ({ isOpen = true, expenditureIds, onSubmit, savingAxes, setIsDirty }) => {
  if (savingAxes) {
    return <AxesAndMarksInfo info={axesUtils.getInfoForPreview(savingAxes)} />;
  }

  return (
    <AxesAndMarksForm setIsDirty={setIsDirty} expenditureIds={expenditureIds} isOpen={isOpen} onSubmit={onSubmit} />
  );
};

export default CreateAxesAndMarks;
