type DateDistanceTracker = {
  max: string | null;
  min: string | null;
  maxUnix: number | undefined;
  minUnix: number | undefined;
  track: (start: string, end: string) => void;
  getMaxDistance: () => { start: string | null; end: string | null };
};

function createDateDistanceTracker(): DateDistanceTracker {
  const tracker: DateDistanceTracker = {
    max: null,
    min: null,
    maxUnix: undefined,
    minUnix: undefined,

    track(start: string, end: string): void {
      const startUnix = new Date(start).getTime();
      const endUnix = new Date(end).getTime();

      if (isNaN(startUnix) || isNaN(endUnix)) {
        throw new Error("Invalid date format. Use YYYY-MM-DD");
      }

      if (this.maxUnix === undefined || endUnix > this.maxUnix) {
        this.max = end;
        this.maxUnix = endUnix;
      }

      if (this.minUnix === undefined || startUnix < this.minUnix) {
        this.min = start;
        this.minUnix = startUnix;
      }
    },

    getMaxDistance(): { start: string | null; end: string | null } {
      return {
        start: this.min,
        end: this.max,
      };
    },
  };

  return tracker;
}

export default createDateDistanceTracker;
