import moment from "moment";
import React, { PropsWithChildren, ReactNode } from "react";

import LabelTemplate from "_LEGACY/UI/__TODO/LabeledItem/LabelTemplate/LabelTemplate";
import TaskCommentsContainer from "components/pages/Tasks/components/TaskComments/TaskCommentsContainer";

import PriorityRange from "../../../PriorityRange/PriorityRange";
import TaskApproversView from "../../../TaskApprovers/TaskApproversView";
import { renderTextWithLinks } from "../../../TaskComments/TaskOneComment";
import TaskFiles from "../../../TaskFiles/TaskFiles";
import TaskHistory from "../../../TaskHistory/TaskHistory";
import TaskPerson from "../../../TasksModalV2/ui/TaskPerson/TaskPerson";
import TaskComentsTab from "../TaskComentsTab/TaskComentsTab";
import TaskModalDisplayDeadline from "./ui/TaskModalDisplayDeadline/TaskModalDisplayDeadline";
import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";
import TabBarNotLinks from "shared/ui/controls/TabBar/TabBarNotLinks";

import {
  COMMENTS_MODAL_TAB,
  EXTRA_MODAL_TAB,
  MAIN_MODAL_TAB,
  TASK_MODAL_TYPES,
  TASK_REMEMBER,
  TASK_REPEAT,
  TASK_STATUSES,
  getTaskModalTabs,
} from "../../../../constants";

import { useTasksModal } from "../../../../hooks/useTasksModal";
import { useUpdateTask } from "../../../../hooks/useUpdateTask";

import getShortFullName from "../../../../../../../utils/formatters/getShortFullName";

import styles from "./ModalViewTask.module.scss";

interface IProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const ModalViewTask: React.FC<IProps> = ({ activeTab, setActiveTab }) => {
  const { taskId, placementType, listType, list_id, onClose } = useTasksModal(); /* @ts-ignore */
  const { updateTaskStatusDirectly, isTaskStatusEditable, certainTask } = useUpdateTask({
    taskId,
    placementType,
    listType,
    list_id,
  });

  if (!certainTask) return null;

  const {
    id,
    description,
    deadline,
    priority,
    building_obj,
    section_obj,
    executor_user,
    viewers,
    creator_user,
    repeat_task,
    remember_task,
    task_files,
    task_comments,
    viewers_data,
    status,
  } = certainTask;

  const task = certainTask;

  return (
    <div className={styles.root}>
      <TabBarNotLinks
        isOnlyTabsView
        tabs={getTaskModalTabs(TASK_MODAL_TYPES.VIEW)}
        activeId={activeTab}
        onClick={setActiveTab}
        className={styles.tabs}
      />
      <div className={styles.viewContainer}>
        {activeTab === MAIN_MODAL_TAB && (
          <>
            <Value label="Полное наименование задачи:">{task.name}</Value>
            {task.description && (
              <Value label="Краткое описание:">{renderTextWithLinks(task.description, onClose)}</Value>
            )}
            <Value label="Крайний срок: ">
              <TaskModalDisplayDeadline task={task as any} />
            </Value>
            <Value label="Исполнитель:">
              <TaskPerson person={task.executor_user} />
            </Value>
            {viewers?.length > 0 && (
              <Value
                label={
                  <>
                    Контролер(ы): <b>{viewers.length}</b>
                  </>
                }
              >
                <div className={styles.personsRow}>
                  {viewers_data.map((viewer) => (
                    <TaskPerson person={viewer} key={viewer.id} />
                  ))}
                </div>
              </Value>
            )}
            <TaskFiles
              files={task_files.map((file) => ({ ...file, name: file.file_name })) || []}
              taskId={id}
              variant={TASK_MODAL_TYPES.VIEW}
              task={certainTask}
            />
            {/* <TaskCommentsContainer comments={task_comments || []} taskId={id} />
            <TaskApproversView taskId={id} approvers={certainTask?.approves_data} /> */}
          </>
        )}
        {activeTab === EXTRA_MODAL_TAB && (
          <>
            <TaskHistory taskId={certainTask.id} />
          </>
        )}
        {activeTab === COMMENTS_MODAL_TAB && (
          <>
            <TaskComentsTab taskId={certainTask.id} comments={certainTask.task_comments} task={certainTask as any} />
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(ModalViewTask);

function Value({ children, label }: PropsWithChildren & { label: ReactNode }) {
  return (
    <LabeledItem label={label} isDefaultHeight={false} className={styles.labeledItem}>
      <div className={styles.value}>{children}</div>
    </LabeledItem>
  );
}
