import cn from "classnames";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import Checkbox from "../../../../../../../_LEGACY/UI/__TODO/Checkbox/Checkbox";

import { NavLinkIcon } from "../../../../../../../shared/ui/controls/NavLinkIcon/NavLinkIcon";
import FileRow from "../../../../../../../shared/ui/dataDisplay/FileRow/FileRow";
import Calendar from "../../../../../../../shared/ui/inputs/Calendar/Calendar";
import FileUploader from "../../../../../../../shared/ui/inputs/FileUploader/FileUploader";
import { addPaymentFile, changePaymentDate, payPayment } from "../../../model/thunks";
import FileViewer from "widgets/FileViewer/FileViewer";

import { IBillDetailed } from "../../../model/types";

import { useUrlModule } from "utils/hooks/useUrlModule";
import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import { splitThousands } from "../../../../../../../utils/formatters/splitThousands";

import calendarBlue from "images/icons/calendarBlue.svg";

import styles from "./BillPayment.module.scss";

interface IProps {
  payment: IBillDetailed["payments"][number];
  parentId: string;
  permissions: Record<string, any>;
  isSimplified?: boolean;
}

function BillPayment({ parentId, payment, permissions, isSimplified }: IProps) {
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState(false);
  const [localPaid, setLocalPaid] = React.useState(payment.paid);
  const [isPaying, setIsPaying] = React.useState(false);
  const urlAdapter = useUrlModuleWithObjectId();
  const urlModule = useUrlModule();

  const dateHandler = (date: string) => {
    dispatch(changePaymentDate(parentId, payment.id, moment(date).format("YYYY-MM-DD")));
  };

  const checkHandler = () => {
    setChecked((prev) => !prev);
    if (!payment.file || isPaying || localPaid) return;
    setIsPaying(true);
    dispatch(payPayment(parentId, payment.id));
  };

  const fileHandler = (e: any) => {
    dispatch(addPaymentFile(parentId, payment.id, e.target.files[0]));
  };

  const serializedFile = useMemo(
    () => ({
      name: payment.file_name,
      link: payment.file,
    }),
    [payment.file, payment.file_name]
  );

  useEffect(() => {
    setLocalPaid(payment.paid);
  }, [payment.paid]);

  const [isViewer, setIsViewer] = useState(false);

  const actDetails = useMemo(() => {
    if (payment.packinglist) {
      const packingListNumber = payment.packinglist?.number ? payment.packinglist.number : "Ожидается";
      return {
        id: payment.packinglist.id,
        title: `УПД №${packingListNumber}`,
        link: `/${urlAdapter}/documents/packing-lists/${payment.packinglist.id}`,
        isDisabled: false,
      };
    }
    if (payment.acts[0]) {
      const packingListNumber = payment.acts[0]?.number ? payment.acts[0].number : "Ожидается";
      return {
        id: payment.acts[0].id,
        title: `Акт №${packingListNumber}`,
        link: `/${urlAdapter}/documents/service-acts/${payment.acts[0].id}`,
        isDisabled: urlModule === "objects",
      };
    }
  }, [payment, urlAdapter, urlModule]);

  return (
    <div className={styles.container}>
      <div className={cn(styles.headline, { [styles.disabled]: payment.paid })}>
        <div className={styles.text}>Платеж</div>
        <div>
          <Calendar
            icon={calendarBlue}
            classNameSelect={cn(styles.calendar, { [styles.disabled]: payment.paid })}
            value={moment(payment.date)}
            format={"DD.MM.YY"}
            /* @ts-ignore */
            setValue={(val) => dateHandler(val)}
            disabled={payment.paid}
            /* @ts-ignore */
            variant="brandColorTransparent"
          />
        </div>
      </div>
      {localPaid ? (
        <div className={styles.isPaid}>Оплачен</div>
      ) : (
        <div className={styles.isWaiting}>Ожидает оплаты</div>
      )}
      <div className={styles.terms}>
        {payment.payment_term?.payment_type_name} {payment.payment_term?.percent}%
      </div>
      {actDetails && !isSimplified ? (
        <NavLinkIcon
          className={styles.upd}
          title={actDetails.title}
          to={actDetails.link}
          disabled={actDetails.isDisabled}
        />
      ) : (
        <span className={styles.textUpd} title={actDetails?.title}>
          {actDetails?.title}
        </span>
      )}
      <div className={styles.amount}>Сумма: {splitThousands(payment.amount)}</div>
      {!payment.file ? (
        /* @ts-ignore */
        permissions.viewAddBillPaymentsFile && <FileUploader changeHandler={fileHandler} />
      ) : (
        /* @ts-ignore */
        <FileRow file={serializedFile} download={() => setIsViewer(true)} />
      )}
      {!localPaid && permissions.viewBillPaymentsMakePaid && (
        <div className={`${!payment.file ? styles.disabledPay : ""} ${styles.pay}`} onClick={checkHandler}>
          <div style={{ display: "flex" }}>
            <Checkbox checked={checked} disabled={!payment.file} />
            <div style={{ alignSelf: "center", marginLeft: 7 }}>Пометить оплаченным</div>
          </div>
        </div>
      )}
      <FileViewer isOpen={isViewer} onClose={() => setIsViewer(false)} files={[serializedFile] as any} />
    </div>
  );
}

export default React.memo(BillPayment);
