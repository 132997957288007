import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";

import Select from "components/UI/atoms/Select";
import LabeledSwitch from "components/UI/atoms/_TODO/LabeledSwitch";
import EditProjectHeadline from "components/pages/Settings/EditProject/components/EditProjectHeadline/EditProjectHeadline";
import { useEditProject } from "components/pages/Settings/EditProject/hooks/useEditProject";
import { projectsV2DetailedSelector, projectsV2IsLoadingSelector } from "pages/Projects/model/selectors";
import { createProject, editProject, getDetailedProject } from "pages/Projects/model/thunks";
import { ICreateProject } from "pages/Projects/model/types";

import { DEFAULT_PROJECT_FIELDS } from "./constnts";
import ProjectMainSettings from "./ui/ProjectMainSettings/ProjectMainSettings";
import ProjectSettingsControlTab from "./ui/ProjectSettingsControlTab/ProjectSettingsControlTab";
import ProjectSettingsDefaultTab from "./ui/ProjectSettingsDefaultTab/ProjectSettingsDefaultTab";
import ProjectStockSettings from "./ui/ProjectStockSettings/ProjectStockSettings";
import { useTypedSelector } from "app/store/typedUseSelector";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";
import TabBar from "shared/ui/controls/TabBar/TabBar";
import TabBarNotLinks from "shared/ui/controls/TabBar/TabBarNotLinks";
import InputBase from "shared/ui/inputs/InputBase";

import { useLocationArray } from "utils/hooks/useLocationArray";
import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import { composeFieldValidators, maxLength, required } from "utils/formHelpers/validations";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

import styles from "./Project.module.scss";

const getTabs = (isNew: boolean, baseRoute: string) => {
  return [
    {
      id: `default`,
      text: "О проекте",
      link: `/${baseRoute}/passport`,
    },
    {
      id: `stock`,
      text: "Склад",
      isHidden: isNew,
      link: `/${baseRoute}/stock`,
    },
    {
      id: `control`,
      text: "Стройконтроль",
      link: `/${baseRoute}/control`,
      isHidden: isNew,
    },
  ];
};

const projectTypes = {
  business: "Бизнес",
  business_pro: "БизнесПро",
} as const;

const Project = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dateStart, setDateStart] = useState(moment());
  const [dateEnd, setDateEnd] = useState(moment());
  const [isDirty, setIsDirty] = useState(false);
  const { projectId } = useParams<Record<string, string>>();
  const isNew = projectId === "new";
  const baseUrl = useUrlModuleWithObjectId();
  const locationArr = useLocationArray();

  const { entitiesList } = useEditProject("");

  const availableTypeProjects = useTypedSelector((state) => state.auth.user.available_type_projects);

  const onSubmit = (vals: ICreateProject) => {
    const newVals = Object.entries(vals).reduce<ICreateProject>((acc, [key, val]) => {
      if (val === null) {
        return {
          ...acc,
          [key]: "",
        };
      } else {
        return {
          ...acc,
          [key]: val,
        };
      }
    }, {} as ICreateProject);

    if (newVals.stock !== undefined) {
      delete newVals.stock;
    }
    if (isNew) {
      const isSettings = locationArr.includes("settings");
      dispatch(
        createProject(
          {
            ...newVals,
            date_end: moment(dateEnd).format("YYYY-MM-DD"),
            date_start: moment(dateStart).format("YYYY-MM-DD"),
          },
          (id) =>
            history.push(
              isSettings ? `/${baseUrl}/settings/project/${id}/stock` : `/${baseUrl}/projects/edit-project/${id}/stock`
            )
        )
      );
    } else {
      dispatch(
        editProject(projectId, {
          ...DEFAULT_PROJECT_FIELDS,
          ...newVals,
          date_end: moment(dateEnd).format("YYYY-MM-DD"),
          date_start: moment(dateStart).format("YYYY-MM-DD"),
        })
      );
    }
  };

  const invalidateKey = useTypedSelector((state) => state.projectsV2.invalidateKey);
  useEffect(() => {
    if (isNew) return;
    dispatch(getDetailedProject(projectId));
  }, [projectId, isNew, invalidateKey]);
  const pendingKey = generateStorageKey({ type: "pending" });
  const project = useSelector(projectsV2DetailedSelector)[projectId];
  const isLoading = useSelector(projectsV2IsLoadingSelector)[projectId];
  const isPending = useSelector(projectsV2IsLoadingSelector)[pendingKey];

  useEffect(() => {
    if (project?.date_start && project?.date_end) {
      setDateStart(moment(project?.date_start));
      setDateEnd(moment(project?.date_end));
    }
  }, [project]);

  const tabs = useMemo(() => {
    const isSettings = locationArr.includes("settings");
    return getTabs(
      isNew,
      isSettings
        ? `${baseUrl}/settings/project/${projectId}`
        : isNew
        ? `${baseUrl}/projects/create-project/${projectId}`
        : `${baseUrl}/projects/edit-project/${projectId}`
    );
  }, [isNew, baseUrl, projectId, locationArr]);

  const match = useRouteMatch();
  /* const [activeTab, setActiveTab] = useState("default"); */

  /* const tabs = useMemo(() => {
    return getTabs(isNew);
  }, [isNew]); */

  return (
    <TemplateBase>
      <Form
        onSubmit={onSubmit}
        initialValues={project}
        render={({ handleSubmit, dirty, form, hasValidationErrors }) => (
          <>
            <EditProjectHeadline
              isPending={isPending}
              isDirty={dirty}
              name={isLoading ? "" : isNew ? "Новый проект" : project?.name}
              submit={handleSubmit}
              isNew={isNew}
              resetForm={() => {
                form.restart();
              }}
              type="project"
            />
            <div className={styles.wrapper}>
              <div className={styles.row}>
                <Field
                  name="name"
                  validate={composeFieldValidators(required(), maxLength(255))}
                  label="*Наименование проекта"
                  component={InputBase}
                />
                <Field
                  name="entity"
                  validate={composeFieldValidators(required())}
                  render={({ input, meta }) => (
                    <Select
                      label="*Организация"
                      input={input}
                      meta={meta}
                      options={(entitiesList as any)?.map((el: any) => ({ name: el.name, id: el.id })) || []}
                    />
                  )}
                />
              </div>
              <TabBar tabs={tabs} className={styles.mb32} isOnlyTabsView />
              <Switch>
                <Route exact path={match.path}>
                  <Redirect to={`${match.url}/passport`} />
                </Route>
                <Route exact path={`${match.path}/passport`}>
                  <ProjectMainSettings
                    setDateEnd={setDateEnd}
                    setDateStart={setDateStart}
                    dateEnd={dateEnd}
                    dateStart={dateStart}
                    setIsDirty={setIsDirty}
                  />
                </Route>
                <Route exact path={`${match.path}/stock`}>
                  <ProjectStockSettings stock={project?.stock} />
                </Route>
                <Route exact path={`${match.path}/control`}>
                  <ProjectSettingsControlTab objectId="1488" project={project} />
                </Route>
              </Switch>
            </div>
          </>
        )}
      />
    </TemplateBase>
  );
};

export default Project;
