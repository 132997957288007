import classNames from "classnames";
import React, { SetStateAction, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { objectsBreadcrumbsSelector } from "redux/modules/common/building/objects";
import {
  fileStorageObjectsSelector,
  filesByFolderIdSelector,
  filesByObjectIdSelector,
} from "redux/modules/common/filesStorage/selectors";
import { getFileStorageItems, getStorageObjects } from "redux/modules/common/filesStorage/thunks";
import { IMergedItemInStorage } from "redux/modules/common/filesStorage/types";

import FileItem from "components/pages/FilesStorage/components/FileItem/FileItem";
import fileSvg from "components/pages/FilesStorage/components/FileItem/assets/files.svg";
import { useFileStorageNavigation } from "components/pages/FilesStorage/useFileStorageNavigation";

import Spinner from "shared/ui/atoms/Spinner/Spinner";
import Checkbox from "shared/ui/inputs/Checkbox/Checkbox";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import OpenLink from "images/icons/OpenLink";

import styles from "./FilesFromStorage.module.scss";

export interface IFileFromS3 {
  name: string;
  id: number;
  link: string;
}

interface IProps {
  objectId: number;
  selectedIdsMap?: Record<string, boolean>;
  setFilesFromStorage?: React.Dispatch<SetStateAction<IFileFromS3[]>>;
}

const FilesFromStorage = ({ objectId, selectedIdsMap, setFilesFromStorage }: IProps) => {
  const dispatch = useDispatch();

  const { currentFolderId, backHandler, openFolderHandler, clearFolderNavigation } = useFileStorageNavigation();

  const filesByFolder = useSelector(filesByFolderIdSelector)[currentFolderId];
  const filesByObject = useSelector(filesByObjectIdSelector)[objectId];
  const fileStorage = currentFolderId ? filesByFolder : filesByObject;

  useEffect(() => {
    dispatch(getFileStorageItems(String(objectId), currentFolderId));
  }, [objectId, currentFolderId]);

  useEffect(() => {
    dispatch(getStorageObjects());
    return () => {
      clearFolderNavigation();
    };
  }, []);

  const isEmpty = fileStorage?.isLoaded && !fileStorage?.items?.length;

  return (
    <div className={styles.root}>
      <div className={classNames(styles.list, isEmpty && styles.emptyList)}>
        {!!currentFolderId && (
          <div onClick={backHandler} className={classNames(styles.item, styles.back)}>
            <OpenLink color={"gray"} width={undefined} />
            <span>Назад</span>
          </div>
        )}
        {fileStorage?.items?.map((el, i) => (
          <div
            className={classNames(
              styles.item,
              selectedIdsMap?.[el.id] && styles.selectedFile,
              el.isFolder && styles.folder
            )}
            key={el.id + String(el.isFolder)}
            onClick={() => {
              if (el.isFolder) return;
              if (selectedIdsMap?.[el.id]) {
                setFilesFromStorage?.((prev) => prev.filter((p) => p.id !== el.id));
              } else {
                setFilesFromStorage?.((prev) => [...prev, el]);
              }
            }}
          >
            {selectedIdsMap?.[el.id] && !el.isFolder && (
              <div className={styles.checkbox}>
                <Checkbox onCheck={() => {}} checked />
              </div>
            )}
            <FileItem
              isEditable={false}
              name={el.name}
              link={el.link}
              id={el.id}
              isFolder={el.isFolder}
              onOpenFolder={openFolderHandler}
              filesCount={el.filesCount}
              foldersCount={el.foldersCount}
              //onFileClick={() => onClickFile(el)}
            />
          </div>
        ))}
        {!fileStorage?.isLoaded && !fileStorage?.items?.length && <Spinner />}
        {isEmpty && <EmptyPlaceholder className={styles.empty} text="Нет файлов" img={fileSvg} />}
      </div>
    </div>
  );
};

export default FilesFromStorage;
