import cn from "classnames";
import moment from "moment";
import React, { SetStateAction } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";

import { DocumentsTypesEnum, ICreatePassport } from "redux/modules/common/passportsAndCertificates/types";

import ConfirmBlock from "components/UI/_TODO/AddingListInModal/components/ConfirmBlock/ConfirmBlock";
import Select from "components/UI/atoms/Select";
import { attachmentModalType } from "components/modals/ExpenditureFilesModal/CommonFilesModal";

import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import FilesClipButton from "shared/ui/controls/FilesClipButton/FilesClipButton";
import FileRow from "shared/ui/dataDisplay/FileRow/FileRow";
import Calendar from "shared/ui/inputs/Calendar/Calendar";
import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";
import InputBase from "shared/ui/inputs/InputBase";

import { documentTypesOptionsByExpenditureType, passportYearsLengthOptions } from "../constants";
import { IExpenditure } from "types/interfaces/Expenditure";

import { checkFieldExistInExpenditureAttachments, getInitialDocType } from "../utils";
import { composeFieldValidators, required } from "utils/formHelpers/validations";

import styles from "./PassportForm.module.scss";

interface IProps {
  isPending: boolean;
  expenditureType:
    | IExpenditure["expenditure_type"]
    | attachmentModalType
    | "betonIncome"
    | "betonExpense"
    | "betonExpenseFinal";
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  onSubmit: (values: ICreatePassport & { range?: number }) => void;
  canAdd?: boolean;
}

const PassportForm: React.FC<IProps> = ({ isPending, expenditureType, setIsOpen, isOpen, onSubmit, canAdd = true }) => {
  const [dateStart, setDateStart] = React.useState(moment().format("YYYY-MM-DD"));
  const [dateEnd, setDateEnd] = React.useState(moment().format("YYYY-MM-DD"));
  const dispatch = useDispatch();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const closeForm = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setIsOpen(false);
    },
    [setIsOpen]
  );

  const openForm = React.useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const submitForm = React.useCallback(
    (values: ICreatePassport & { range?: number }) => {
      if (dateStart) {
        values.start_at = moment(dateStart).format("YYYY-MM-DD");
      }
      if (dateEnd && !values.range) {
        values.end_at = moment(dateEnd).format("YYYY-MM-DD");
      }
      if (values.date) {
        values.date = moment(values.date).format("YYYY-MM-DD");
        if (!values.start_at) {
          values.start_at = moment(values.date).format("YYYY-MM-DD");
        }
      }
      if (values.range) {
        values.end_at = moment(values.date).add(+values.range, "years").format("YYYY-MM-DD");
        delete values["range"];
      }
      if (![DocumentsTypesEnum.certificate_of_conformity, DocumentsTypesEnum.quality_passport].includes(values.type)) {
        delete values.end_at;
        delete values.start_at;
      }

      onSubmit?.(values);
    },
    [onSubmit, dateStart, dateEnd]
  );

  if (!expenditureType || !documentTypesOptionsByExpenditureType[expenditureType]) return null;

  if (isPending) return <Spinner isStatic />;

  return (
    <>
      {!isOpen && canAdd && (
        <div className={styles.add}>
          <AddButton text="Добавить" textPosition="left" onClick={openForm} />
        </div>
      )}
      {isOpen && (
        <Form
          initialValues={{ type: getInitialDocType[expenditureType], date: moment().format("YYYY-MM-DD") }}
          onSubmit={submitForm}
          render={({ values, handleSubmit }) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <ConfirmBlock
                onAccept={handleSubmit}
                onDecline={closeForm}
                isWithoutCount
                disabled={isPending}
                className={styles.controls}
              />
              <div className={cn(styles.row)}>
                <div className={styles.w100p}>
                  <Field
                    name="type"
                    validate={composeFieldValidators(required())}
                    render={({ input, meta }) => (
                      <Select
                        options={documentTypesOptionsByExpenditureType[expenditureType]}
                        label="Тип документа"
                        input={input}
                        meta={meta}
                      />
                    )}
                  />
                </div>
              </div>
              {checkFieldExistInExpenditureAttachments(values.type, "title") && (
                <div className={cn(styles.row)}>
                  <Field
                    name="title"
                    /* @ts-ignore */
                    component={InputBase}
                    label="Наименование"
                    validate={composeFieldValidators(required())}
                    className={styles.w100p}
                  />
                </div>
              )}
              <div className={cn(styles.row)}>
                {checkFieldExistInExpenditureAttachments(values.type, "number") && (
                  <Field
                    name="number"
                    /* @ts-ignore */
                    component={InputBase}
                    label="Номер"
                    validate={composeFieldValidators(required())}
                    className={styles.w30p}
                  />
                )}
                {checkFieldExistInExpenditureAttachments(values.type, "date") && (
                  <Field
                    name="date"
                    validate={composeFieldValidators(required())}
                    render={({ input, meta }) => (
                      <div className={styles.w30p}>
                        <label>Дата выдачи </label>
                        <Calendar
                          value={input.value}
                          setValue={input.onChange}
                          onFocus={input.onBlur}
                          onBlur={input.onFocus}
                          error={meta.touched && meta.error}
                        />
                      </div>
                    )}
                  />
                )}
                {checkFieldExistInExpenditureAttachments(values.type, "start_at") && (
                  <Field
                    name="start_at"
                    render={({ input, meta }) => (
                      <div className={styles.w45p}>
                        <label>Срок действия </label>
                        <CalendarRange
                          defaultDateEnd={dateEnd}
                          defaultDateStart={dateStart}
                          setDefaultDateEnd={setDateEnd}
                          setDefaultDateStart={setDateStart}
                        />
                      </div>
                    )}
                  />
                )}
                {checkFieldExistInExpenditureAttachments(values.type, "range") && (
                  <Field
                    name="range"
                    validate={composeFieldValidators(required())}
                    render={({ input, meta }) => (
                      <div className={cn(styles.inputWrapper, styles.w30p)}>
                        <Select
                          options={passportYearsLengthOptions}
                          input={input}
                          meta={meta}
                          label="Действует"
                          placeholder="-"
                          containerClassName={styles.w100p}
                        />
                      </div>
                    )}
                  />
                )}
                <Field
                  name="file"
                  validate={composeFieldValidators(required())}
                  render={({ input, meta }) => (
                    <div
                      className={styles.inputWrapper}
                      onClick={() => {
                        input.onFocus();
                        input.onBlur();
                      }}
                    >
                      <FilesClipButton onClick={() => fileInputRef.current?.click()} className={styles.filesBtn} />
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          input.onBlur();
                          const targetFiles = Array.from(e.target.files || []);
                          input.onChange(targetFiles[0]);
                        }}
                        className={styles.filesInput}
                      />
                      {meta.touched && meta.error && (
                        <span className={cn(styles.error, styles.fileError)}>{meta.error}</span>
                      )}
                    </div>
                  )}
                />
              </div>
              <Field
                name="file"
                render={({ input }) =>
                  input.value ? (
                    <FileRow
                      file={input.value}
                      canRemove
                      onDirectlyRemove={() => {
                        input.onChange(undefined);
                      }}
                      className={styles.fileRow}
                    />
                  ) : null
                }
              />
            </form>
          )}
        />
      )}
    </>
  );
};

export default React.memo(PassportForm);
