import { RFC_2822 } from "moment";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { summaryUtils } from "pages/Finance/Summary/model/utils";
import { financeUtils } from "pages/Finance/common/model/commonFinanceUtils";

import { permissionsTreeSelector } from "features/permissions/model/selectors";

import { IPermissionExtended, IPermissionsTree } from "features/permissions/model/types";

import { instructionsUtils } from "../utils";

interface IProps {
  fullActivePermission?: IPermissionExtended;
  descriptions?: Record<string, IPermissionExtended>;
}

export const useInstructionsSelector = ({ descriptions, fullActivePermission }: IProps) => {
  const tree = useSelector(permissionsTreeSelector);

  const sectionInstruction = useMemo(() => {
    if (fullActivePermission?.parentChain?.length! < 3) return null;
    return descriptions?.[fullActivePermission?.parentChain?.[1].alias!];
  }, [fullActivePermission, descriptions]);

  const tabNames = useMemo(() => {
    const res: string[] = [];
    tree?.groups.forEach((mod) => {
      mod.groups.forEach((page) => {
        page.groups.forEach((tab) => {
          res.push(tab.permissions.at(-1)?.alias!);
        });
      });
    });
    return res;
  }, [tree]);

  const siblings2 = useMemo<IPermissionsTree[]>(() => {
    //я ебал
    if (fullActivePermission?.parentChain?.[0]?.name === "Настройки") {
      if (fullActivePermission?.parentChain?.length! === 1) {
        const isEmpty =
          !fullActivePermission?.groups?.length && !fullActivePermission?.preParentGroup?.groups?.[0]?.name;
        if (isEmpty) return [];
        return [
          {
            name: fullActivePermission?.title,
            groups: [
              {
                name: fullActivePermission?.title,
                permissions: fullActivePermission?.preParentGroup?.groups
                  ?.find((gr) => gr.permissions?.at(-1)?.alias! === fullActivePermission?.alias)
                  ?.groups?.map((el) => ({
                    title: el.permissions?.at(-1)?.title,
                    alias: el.permissions?.at(-1)?.alias,
                  })),
              },
            ],
          },
        ];
      }
    }
    if (fullActivePermission?.parentChain?.[0]?.name === "Верхнее меню") {
      if (true /* fullActivePermission?.parentChain?.length! === 1 */) {
        const isEmpty =
          !fullActivePermission?.groups?.length && !fullActivePermission?.preParentGroup?.groups?.[0]?.name;
        if (isEmpty) return [];
        return [
          {
            name: fullActivePermission?.title,
            groups: [
              {
                name: fullActivePermission?.title,
                permissions: fullActivePermission?.preParentGroup?.groups?.find(
                  (gr) => gr.permissions?.at(-1)?.alias! === fullActivePermission?.alias
                )?.groups?.[0]?.permissions,
              },
            ],
          },
        ];
      }
    }
    if (fullActivePermission?.parentChain?.length! === 1) {
      return [
        {
          name: fullActivePermission?.title,
          groups: [
            {
              name: fullActivePermission?.title,
              permissions: fullActivePermission?.preParentGroup?.groups
                ?.find((gr) => gr.permissions?.at(-1)?.alias! === fullActivePermission?.alias)
                ?.groups?.map((el) => ({ title: el.name, alias: el.permissions?.at(-1)?.alias })),
            },
          ],
        },
      ] as IPermissionsTree[];
    }
    if (fullActivePermission?.parentChain?.length! === 2) {
      const tabs = fullActivePermission?.preParentGroup?.groups
        ?.find((gr) => gr.permissions?.at(-1)?.alias! === fullActivePermission?.alias)
        ?.groups?.map((g) => ({
          ...g,
          groups: [
            {
              name: fullActivePermission?.title,
              permissions: g.permissions.filter((el) => el.showing_in_instructions),
            },
          ],
        })) as IPermissionsTree[];

      return [{ name: fullActivePermission?.title!, groups: tabs, permissions: [] }] as any;
    }

    if (fullActivePermission?.parentChain?.length! < 3) return [] as IPermissionsTree[];

    const pageAlias = fullActivePermission?.parentChain?.at(-2)?.alias;

    const page = descriptions?.[pageAlias!];

    if (!page) {
      return [];
    }

    if (false) {
    }

    return page?.groups
      ?.filter((f) => f?.permissions?.at(-1)?.alias! === fullActivePermission?.alias!)
      ?.map((gr) => {
        return {
          ...gr,
          name: gr.name || fullActivePermission?.title,
          permissions: gr.permissions.filter((el) => el.showing_in_instructions),
          groups: gr.groups.map((subGroup) => ({
            ...subGroup,
            permissions: subGroup.permissions.filter((el) => el.showing_in_instructions),
          })),
        };
        /* if (gr?.permissions?.at(-1)?.alias! === fullActivePermission?.alias!) {
          return {
            ...gr,
            name: gr.name || fullActivePermission?.title,
            permissions: gr.permissions.filter((el) => el.showing_in_instructions),
            groups: gr.groups.map((subGroup) => ({
              ...subGroup,
              permissions: subGroup.permissions.filter((el) => el.showing_in_instructions),
            })),
          };
        } else {
          return {
            ...gr,
            permissions: gr.permissions.filter((el) => el.showing_in_instructions),
            name: gr.name || gr.permissions.at(-1)?.title,
            groups: [
              {
                name: fullActivePermission?.title,
                permissions: gr.permissions.filter((el) => el.showing_in_instructions),
              },
              ...gr.groups,
            ],
          };
        } */
      }) as IPermissionsTree[];

    return [];
  }, [fullActivePermission, descriptions]);

  return { sectionInstruction, siblings: siblings2, tabNames };
};
