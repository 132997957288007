import cn from "classnames";
import React from "react";
import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";

import CalendarRange from "../../../../shared/ui/inputs/CalendarRange/CalendarRange";
import InputBase from "../../../../shared/ui/inputs/InputBase";
import { Switch } from "shared/ui/inputs/Switch/Switch";

import useFormFocus from "../../../../utils/hooks/useFormFocus";

import { composeFieldValidators, maxLength, required } from "../../../../utils/formHelpers/validations";

import styles from "./EditProject.module.scss";

export interface IProps {
  dateStart: string;
  dateEnd: string;
  setDefaultDateStart: React.Dispatch<React.SetStateAction<string>>;
  setDefaultDateEnd: React.Dispatch<React.SetStateAction<string>>;
  isDisabled: boolean;
}

const EditProjectPassport: React.FC<IProps> = ({
  dateStart,
  dateEnd,
  setDefaultDateStart,
  setDefaultDateEnd,
  isDisabled = true,
}) => {
  const inputRootRef = useFormFocus(undefined, isDisabled);

  return (
    <div ref={inputRootRef} className={cn(styles.fullWidth, { [styles.disabled]: isDisabled })}>
      <div className={styles.fullWidth}>
        <Field
          name="object_name"
          label="*Наименование объекта"
          validate={composeFieldValidators(required(), maxLength(1024, "Не более 1024 символов"))}
          render={({ input, meta: { touched, error } }) => (
            <div className={styles.fullWidth}>
              <label>*Наименование объекта</label>
              <TextareaAutosize {...input} />
              {touched && error && <div className={styles.errorMessage}>{error}</div>}
            </div>
          )}
        />
      </div>
      <div className={styles.row}>
        <Field
          name="address"
          label="*Адрес"
          component={InputBase}
          validate={composeFieldValidators(required(), maxLength(255))}
          classNameInput={styles.colorInput}
        />
        <Field
          name="permit_build"
          label="Разрешение на строительство"
          component={InputBase}
          validate={composeFieldValidators(maxLength(255))}
          classNameInput={styles.colorInput}
        />
      </div>
      <div className={styles.row}>
        <Field
          name="responsible_full_name"
          label="*Ответственный"
          component={InputBase}
          validate={composeFieldValidators(required(), maxLength(255))}
          classNameInput={styles.colorInput}
        />
        <div className={styles.date}>
          <label className={styles.calend}>Сроки строительства</label>
          <Field
            name="construction_date"
            component={CalendarRange as any}
            defaultDateStart={dateStart}
            setDefaultDateStart={setDefaultDateStart}
            defaultDateEnd={dateEnd}
            setDefaultDateEnd={setDefaultDateEnd}
          />
        </div>
      </div>
      <div className={styles.row}>
        <Field
          name="customer"
          label="Заказчик"
          component={InputBase}
          validate={composeFieldValidators(maxLength(255))}
          classNameInput={styles.colorInput}
        />
        <Field
          name="developer"
          label="Застройщик"
          component={InputBase}
          validate={composeFieldValidators(maxLength(255))}
          classNameInput={styles.colorInput}
        />
      </div>
      <div className={styles.row}>
        <Field
          name="general_contractor"
          label="Генеральный подрядчик"
          component={InputBase}
          validate={composeFieldValidators(maxLength(255))}
          classNameInput={styles.colorInput}
        />
        <Field
          name="technical_supervision"
          label="Технический надзор"
          component={InputBase}
          validate={composeFieldValidators(maxLength(255))}
          classNameInput={styles.colorInput}
        />
      </div>
      <div className={styles.row}>
        <Field
          name="allow_shift"
          render={({ input, meta }) => (
            <Switch
              className={styles.allowShift}
              label="Анализ планов работ"
              checked={input.value ?? false}
              onChange={(e) => {
                input.onChange(!input.value);
              }}
            />
          )}
        />
        <Field
          name="new_close_dates"
          render={({ input, meta }) => (
            <Switch
              className={styles.allowShift}
              label="Разнесение по фактам"
              checked={input.value ?? false}
              onChange={(e) => input.onChange(!input.value)}
            />
          )}
        />
        <Field
          name="is_simple_parser"
          render={({ input, meta }) => (
            <Switch
              className={styles.allowShift}
              label={`Смета: стандарт`}
              labelRight="упрощенная"
              checked={input.value ?? false}
              onChange={(e) => input.onChange(!input.value)}
            />
          )}
        />
      </div>
    </div>
  );
};

export default React.memo(EditProjectPassport);
