import { NotificationGroupType } from "./types/types";

export const NOTIFICATION_GROUP_NAMES: Record<Exclude<NotificationGroupType, "estimate">, string> = {
  remarks: "Замечания",
  other: "Другие",
  order: "Заказ",
  payment: "Оплата",
  packinglist: "УПД",
  requisition: "Заявка",
  tasks_track: "Отслеживание задач",
  stock: "Склад",
  construction_control: "Стройконтроль",
};
