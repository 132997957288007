import { tasksFieldsType } from "components/pages/Tasks/model/types";

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Checkpoint } from "widgets/AddCheckpoint";

import { CheckpointList } from "../types";

export interface ViewCheckpointState {
  isLoading: Record<string, boolean>;
  isOpen: boolean;
  objectId: string | undefined;
  list: CheckpointList;
  details: Checkpoint | null;
  dates: Record<"start" | "end", string | undefined>;
}

const initState = (): ViewCheckpointState => ({
  isLoading: {},
  isOpen: false,
  objectId: undefined,
  list: [],
  details: null,
  dates: {
    start: "",
    end: "",
  },
});

const initialState: ViewCheckpointState = initState();

export const viewCheckpointStateSlice = createSlice({
  name: "viewCheckpointState",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<{ key: string; status: boolean }>) => {
      const { key, status } = action.payload;
      state.isLoading[key] = status;
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    setDates: (state, action: PayloadAction<{ startDate: string | undefined; endDate: string | undefined }>) => {
      const { startDate, endDate } = action.payload;
      state.dates.start = startDate;
      state.dates.end = endDate;
    },
    setObjectId: (state, action: PayloadAction<string | undefined>) => {
      state.objectId = action.payload;
    },
    setList: (state, action: PayloadAction<CheckpointList>) => {
      state.list = action.payload;
    },
    setDetails: (state, action: PayloadAction<Checkpoint | null>) => {
      state.details = action.payload;
    },
    drop: (state) => {
      state.isOpen = false;
      state.details = null;
      state.objectId = undefined;
      state.list = [];
      state.isLoading = {};
      state.dates = {
        start: undefined,
        end: undefined,
      };
    },
  },
});

export const viewCheckpointStateActions = viewCheckpointStateSlice.actions;

export default viewCheckpointStateSlice.reducer;
