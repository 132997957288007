import React, { useEffect, useState } from "react";

import { tasksApi } from "components/pages/Tasks/model/api";

import FileViewer from "widgets/FileViewer/FileViewer";

import { IFile } from "types/interfaces/Files";

interface IProps {
  onClose: () => void;
  commentId?: number;
}

const TaskCommentFiles = ({ onClose, commentId }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);

  useEffect(() => {
    setIsLoading(true);
    tasksApi
      .getCommentFiles(commentId!)
      .then(({ data }) => {
        setFiles(data.results);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [commentId]);

  return <FileViewer isOpen onClose={onClose} files={files} isLoading={isLoading} />;
};

export default TaskCommentFiles;
