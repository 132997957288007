import { CHART_EXTRA_YEARS } from "../constants";

const datesDistanceMaxYearRange = (datesRange: { start: string | null; end: string | null } | undefined) => {
  return {
    maxYear: (datesRange?.end ? new Date(datesRange.end).getFullYear() : new Date().getFullYear()) + CHART_EXTRA_YEARS,
    minYear:
      (datesRange?.start ? new Date(datesRange.start).getFullYear() : new Date().getFullYear()) - CHART_EXTRA_YEARS,
  };
};

export default datesDistanceMaxYearRange;
