import axios from "axios";
import React, { useEffect, useState } from "react";

import StatusElement from "shared/ui/dataDisplay/StatusElement/StatusElement";
import FileViewer from "widgets/FileViewer/FileViewer";

import styles from "./JournalActingFilesRow.module.scss";

interface IProps {
  files?: any;
}

const JournalActingFilesRow = ({ files }: IProps) => {
  const [fileViewerIndex, setFileViewerIndex] = useState(-1);

  return (
    <div className={styles.row}>
      {files?.map((el: any, i: number) => (
        <StatusElement
          status="success"
          text={el.type_display}
          key={el.file}
          onClick={() => {
            setFileViewerIndex(i);
          }}
        />
      ))}
      <FileViewer
        files={files}
        isOpen={fileViewerIndex > -1}
        onClose={() => setFileViewerIndex(-1)}
        startIndex={fileViewerIndex}
      />
    </div>
  );
};

export default JournalActingFilesRow;
