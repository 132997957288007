export const chartIntervalCn = "chart_interval";
export const chartPlanIntervalCn = "chart_plan_interval";
export const chartWorkIntervalCn = "chart_work_interval";

export enum CHART_Z_INDEX {
  BASE = 1,
  SECTION_PLAN = 2,
  CRITICAL_PLAN = 3,
  WORK = 4,
  WORK_BRACE = 5,
  HIGHLIGHTED_ARROW = 88,
  DRAGGED_ARROW = 89,
}
