import cn from "classnames";
import moment from "moment";
import React from "react";

import Select, { ISelectProps } from "../../../../components/UI/atoms/Select";

import { YEAR_SELECT_MIN_YEAR, YEAR_SELECT_UPPER_BOUND_FROM_CURRENT_YEAR } from "../../../../constants/constant";

import styles from "./YearSelect.module.scss";

export interface IYearSelectProps extends Omit<ISelectProps, "options"> {
  maxYear?: number;
  minYear?: number;
}

const YearSelect: React.FC<IYearSelectProps> = ({ className, maxYear, minYear, ...props }) => {
  const generatedYears = React.useMemo(() => {
    const res = [];
    for (
      let i = maxYear || moment().year() + YEAR_SELECT_UPPER_BOUND_FROM_CURRENT_YEAR;
      i >= (minYear || Math.min(+(props?.value || YEAR_SELECT_MIN_YEAR), YEAR_SELECT_MIN_YEAR));
      i--
    ) {
      res.push({ id: i, name: i, label: i });
    }
    return res;
  }, [props?.value]);

  return (
    <Select options={generatedYears} className={cn(styles.year, className)} isScrolledToDefaultOption {...props} />
  );
};

export default YearSelect;
