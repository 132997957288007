import cn from "classnames";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { chartStatusesSelector } from "redux/modules/common/chart/selectors";
import { CHART_TABS } from "redux/modules/common/chart/types";

import ChartLegendEdit from "../ChartLegendEdit/ChartLegendEdit";

import { ReactComponent as SettingsIcon } from "images/icons/settings.svg";

import styles from "./ChartLegend.module.scss";

export interface IChartLegendProps {
  tab: CHART_TABS;
}

export interface IStatusBoxProps {
  title: string;
  color: string;
  className?: string;
}

const StatusBox: React.FC<IStatusBoxProps> = ({ title, color, className }) => {
  return (
    <div className={styles.box} title={title}>
      <div className={cn(styles.divider, className)} style={{ backgroundColor: color }} />
      <div className={styles.item}>{title}</div>
    </div>
  );
};

const ChartLegend: React.FC<IChartLegendProps> = ({ tab }) => {
  const chartStatuses = useSelector(chartStatusesSelector);
  const [edit, setEdit] = useState(false);
  const openEdit = () => setEdit(true);
  const closeEdit = useCallback(() => setEdit(false), []);
  if (edit) {
    return <ChartLegendEdit tab={tab} onClose={closeEdit} />;
  }
  return (
    <div className={styles.container}>
      {Object.entries(chartStatuses[tab] || {}).map(([status, details]) => (
        <StatusBox key={status} title={details.title} color={details.color} />
      ))}
      {
        // TODO: убрать ограничение на вкладку "Работы" при поддержке на бэкенде настройки статусов из других вкладок
        tab === CHART_TABS.WORK ? <SettingsIcon className={styles.settingsIcon} onClick={openEdit} /> : null
      }
    </div>
  );
};

export default React.memo(ChartLegend);
