import cn from "classnames";
import React, { memo } from "react";
import { useSelector } from "react-redux";

import { modeSelector } from "../../../../../../../model/selectors";

import { ESimpleHandlerMode } from "../../../../../../../types";

import { useIsOpenedInApproveForm } from "../../../../../../../hooks/useIsOpenedInApproveForm";

import styles from "./TableHeader.module.scss";

interface IProps {
  canConfirm?: boolean;
}

export const TableHeader = memo<IProps>(({ canConfirm }) => {
  const mode = useSelector(modeSelector);

  const isOpenedInApproveForm = useIsOpenedInApproveForm();

  return (
    <div
      className={cn(
        styles.listHeader,
        isOpenedInApproveForm && styles.approveForm,
        styles[canConfirm ? "canConfirm" : mode]
      )}
    >
      {mode === ESimpleHandlerMode.VIEW && (
        <>
          <div className={cn(styles.column, styles.isLeft)}>№</div>
          <div className={cn(styles.column, styles.isLeft)}>Наименование</div>
          <div className={styles.column}>Количество</div>
          <div className={styles.column}>Цена,&nbsp;₽</div>
          <div className={styles.column}>Стоимость,&nbsp;₽</div>
          <div className={styles.column}></div>
        </>
      )}
      {mode === ESimpleHandlerMode.BUDGET && (
        <>
          <div className={cn(styles.column, styles.isLeft)}>№</div>
          <div className={cn(styles.column, styles.isLeft)}>Наименование</div>
          <div className={styles.column}>Количество</div>
          <div className={styles.column}>По смете,&nbsp;₽</div>
          <div className={styles.column}>Скидка от подрядчика</div>
          <div className={styles.column}>С учетом скидки,&nbsp;₽</div>
        </>
      )}
      {mode === ESimpleHandlerMode.ESTIMATED_PRICE && (
        <>
          <div className={cn(styles.column, styles.isLeft)}>№</div>
          <div className={cn(styles.column, styles.isLeft)}>Наименование</div>
          <div className={cn(styles.column, styles.withBorder)}>Количество</div>
          <div className={cn(styles.column, styles.withBorder)}>Бюджет,&nbsp;₽</div>
          <div className={cn(styles.column, styles.withBorder)}>Расчетная цена</div>
          <div className={cn(styles.column, styles.estimatedPriceColumn)}>
            <span>Разница</span>
            <div className={styles.diff}>
              <div className={styles.diffPercent}>в %</div>
              <div>в ₽</div>
            </div>
          </div>
        </>
      )}
      {mode === ESimpleHandlerMode.MARKUP && (
        <>
          <div className={cn(styles.column, styles.isLeft)}>№</div>
          <div className={cn(styles.column, styles.isLeft)}>Наименование</div>
          <div className={styles.column}>Количество</div>
          <div className={styles.column}>По смете,&nbsp;₽</div>
          <div className={styles.column}>Наценка</div>
          <div className={styles.column}>С учетом наценки,&nbsp;₽</div>
        </>
      )}
    </div>
  );
});

TableHeader.displayName = "TableHeader";
