import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import Instructions from "./Instructions/Instructions";
import Learning from "./Learning/Learning";
import { getLearning } from "./Learning/model/thunks";
import instructionsIcon from "./assets/instructionsIcon.svg";
import { useTypedSelector } from "app/store/typedUseSelector";
import MenuItem from "features/templates/NavigationBar/MenuItem";

import useOnClickOutside from "hooks/useOnClickOutside";

import styles from "./Help.module.scss";

const Help = () => {
  const learning = useTypedSelector((state) => state.learning.data);
  const dispatch = useDispatch();
  const [whichModalOpen, setWhichModalOpen] = useState<null | "instructions" | "learning">(null);
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);

  const openCloseHandler = () => {
    setIsOpen((prev) => !prev);
  };

  const closeHandler = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    dispatch(getLearning());
  }, []);

  useOnClickOutside(containerRef, closeHandler);

  const showLearning = !!learning?.results?.length;

  const [openedKey, setOpenedkey] = useState(0);

  return (
    <>
      {/* @ts-ignore */}
      <MenuItem
        isExists
        title={"Обучение"}
        link={undefined}
        jsxIcon={<img src={instructionsIcon} className={styles.icon} />}
        isBottom
        isWithoutUnderline
        icon={undefined}
        isDirectlyActive={undefined}
        className={styles.container}
        onClick={openCloseHandler}
        isTooltipHidden={isOpen}
        ref={containerRef}
      >
        {isOpen && (
          <div className={styles.popover}>
            {showLearning && (
              <div
                className={styles.menuitem}
                onClick={() => {
                  setWhichModalOpen("learning");
                  setOpenedkey((p) => p + 1);
                }}
              >
                Обучение
              </div>
            )}
            <div
              className={styles.menuitem}
              onClick={() => {
                setWhichModalOpen("instructions");
                setOpenedkey((p) => p + 1);
              }}
            >
              Инструкции пользователя
            </div>
          </div>
        )}
      </MenuItem>
      <Instructions
        openedKey={openedKey}
        onClose={() => setWhichModalOpen(null)}
        isOpen={whichModalOpen === "instructions"}
      />
      <Learning
        onOpen={() => setWhichModalOpen("learning")}
        openedKey={openedKey}
        onClose={() => setWhichModalOpen(null)}
        isOpen={whichModalOpen === "learning"}
      />
    </>
  );
};

export default Help;
