import { summaryUtils } from "pages/Finance/Summary/model/utils";
import { IFinanceTreeItem } from "pages/Finance/common/model/interfaces";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IForecastObjectResponse, IForecastProjectListResponse } from "./types";

import { forecastUtils } from "./utils";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

interface IState {
  dataTree: Record<string, IFinanceTreeItem[]>;
  loadings: Record<string, boolean>;
  totals: Record<string, IFinanceTreeItem[]>;
}

const initialState: IState = {
  dataTree: {},
  loadings: {},
  totals: {},
};

const financeForecastSlice = createSlice({
  name: "financeForecast",
  initialState,
  reducers: {
    setProjects: (
      state,
      { payload: { data, year } }: PayloadAction<{ data: IForecastProjectListResponse[]; year: number }>
    ) => {
      const oldTree = [...(state.dataTree[year] ?? [])];
      const context = { oldTree };
      state.dataTree = { ...state.dataTree, [year]: data.map((el) => summaryUtils.mapProjectData(el, context)) };
    },
    closeItem: (state, { payload: { item, year } }: PayloadAction<{ item: IFinanceTreeItem; year: number }>) => {
      const oldTree = [...(state.dataTree[year] ?? [])];
      state.dataTree = { ...state.dataTree, [year]: summaryUtils.closeTree(oldTree, item)! };
    },
    openItem: (state, { payload: { item, year } }: PayloadAction<{ item: IFinanceTreeItem; year: number }>) => {
      const oldTree = [...(state.dataTree[year] ?? [])];
      state.dataTree = { ...state.dataTree, [year]: summaryUtils.openTree(oldTree, item)! };
    },
    setObject: (
      state,
      {
        payload: { data, objectId, projectId, year },
      }: PayloadAction<{ data: IForecastObjectResponse; projectId: number; objectId: number; year: number }>
    ) => {
      state.dataTree = {
        ...state.dataTree,
        [year]: forecastUtils.setObject(state.dataTree[year], data, projectId, objectId),
      };
      const key = generateStorageKey({ type: "object", id: objectId, year });
      state.totals[key] = summaryUtils.extractObjectTotal(data, year);
    },
    setProject: (
      state,
      {
        payload: { data, projectId, year },
      }: PayloadAction<{ data: IForecastProjectListResponse[]; projectId: number; year: number }>
    ) => {
      state.dataTree = { ...state.dataTree, [year]: summaryUtils.setProject(state.dataTree[year], data, projectId) };
      const key = generateStorageKey({ type: "project", id: projectId, year });
      state.totals[key] = summaryUtils.extractProjectTotal(data, year);
    },
    setLoading: (state, { payload: { key, status } }: PayloadAction<{ key: string; status: boolean }>) => {
      state.loadings[key] = status;
    },
    setAnyExtras: (
      state,
      {
        payload: { data, objectId, projectId, year },
      }: PayloadAction<{ data: IForecastObjectResponse; projectId: number; objectId: number; year: number }>
    ) => {
      state.dataTree = {
        ...state.dataTree,
        [year]: forecastUtils.updateExtras(state.dataTree[year], data, projectId, objectId),
      };
    },
  },
});

export const forecastActions = financeForecastSlice.actions;
export default financeForecastSlice.reducer;
