import cn from "classnames";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  chartScrollMonthSelector,
  chartScrollYearSelector,
  chartTreeDatesDistanceSelector,
} from "redux/modules/common/chart/selectors";
import { CHART_VIEW_MODE } from "redux/modules/common/chart/types";

import { ChartContext } from "../../Chart";
import moment, { Moment } from "moment/moment";
import ButtonBase from "shared/ui/controls/ButtonBase";
import TabBarNotLinks from "shared/ui/controls/TabBar/TabBarNotLinks";
import ContentWithCount from "shared/ui/dataDisplay/ContentWithCount/ContentWithCount";
import MonthsYearsSlider from "shared/ui/inputs/MonthsYearsSlider/MonthsYearsSlider";
import SendMultiplePlansForApprove from "widgets/SendMultiplePlansForApprove/SendMultiplePlansForApprove";
import { openViewCheckpointModalList } from "widgets/ViewCheckpoint/model/thunks";

import { useUrlModule } from "utils/hooks/useUrlModule";

import datesDistanceMaxYearRange from "../../utils/datesDistanceMaxYearRange";
import { useChartTabs } from "./ChartControls.utils/useChartTabs";
import { useChartViewModeTabs } from "./ChartControls.utils/useChartViewModeTabs";

import { ReactComponent as AddCheckpointIcon } from "images/icons/blueDoneSvg.svg";
import { ReactComponent as ApproveIcon } from "images/icons/required-day-icon-blue.svg";

import styles from "./ChartControls.module.scss";

export interface IChartControlsProps {
  handleDateChange: (newDate: Moment) => void;
  projectId: string;
}

const ChartControls: React.FC<IChartControlsProps> = ({ handleDateChange, projectId }) => {
  const dispatch = useDispatch();
  const { chartTabs, tab, setTab } = useChartTabs();
  const { chartViewModeTabs, chartViewMode, setChartViewMode } = useChartViewModeTabs();
  const year = useSelector(chartScrollYearSelector);
  const month = useSelector(chartScrollMonthSelector);
  const datesDistance = useSelector(chartTreeDatesDistanceSelector);
  const { maxYear, minYear } = datesDistanceMaxYearRange(datesDistance);

  useEffect(() => {
    if (year < minYear || year > maxYear) {
      handleDateChange(moment());
    }
  }, [year, minYear, maxYear]);

  const module = useUrlModule();

  const { yearSelectedMonths } = useContext(ChartContext);

  const selectedDate = useMemo(
    () =>
      moment()
        .year(+year)
        .month(
          chartViewMode === CHART_VIEW_MODE.MONTHS && month > yearSelectedMonths
            ? month - yearSelectedMonths + 1
            : month
        )
        .date(1),
    [year, month, chartViewMode, yearSelectedMonths]
  );

  const yearViewLeftArrowHandler = useCallback(() => {
    if (selectedDate.month() === 0) {
      handleDateChange(
        moment()
          .year(selectedDate.year() - 1)
          .month(12 - yearSelectedMonths)
          .date(1)
      );
    } else {
      handleDateChange(moment(selectedDate).subtract(1, "month"));
    }
  }, [selectedDate, yearSelectedMonths]);

  const yearViewRightArrowHandler = useCallback(() => {
    if (selectedDate.month() <= 11 - yearSelectedMonths) {
      handleDateChange(moment(selectedDate).add(1, "month"));
    } else {
      handleDateChange(
        moment()
          .year(selectedDate.year() + 1)
          .month(0)
          .date(1)
      );
    }
  }, [selectedDate, yearSelectedMonths]);

  const openAllCheckpointsModal = useCallback(() => {
    dispatch(openViewCheckpointModalList({ objectId: projectId }));
  }, [projectId]);

  const isViewModeOnlySelect = chartViewModeTabs.length > 2;
  const isTabsOnlySelect = chartTabs.length > 2;

  return (
    <div className={styles.controls}>
      <TabBarNotLinks tabs={chartTabs} activeId={tab || ""} onClick={setTab} isOnlySelectView={isTabsOnlySelect} />
      <MonthsYearsSlider
        maxYear={maxYear}
        minYear={minYear}
        date={selectedDate}
        onChange={handleDateChange}
        className={styles.datePicker}
        selectingMonthsCount={chartViewMode === CHART_VIEW_MODE.MONTHS ? yearSelectedMonths : undefined}
        onDirectlyClickLeftArrow={chartViewMode === CHART_VIEW_MODE.MONTHS ? yearViewLeftArrowHandler : undefined}
        onDirectlyClickRightArrow={chartViewMode === CHART_VIEW_MODE.MONTHS ? yearViewRightArrowHandler : undefined}
      />
      {module === "constructing" || module === "objects" ? (
        <SendMultiplePlansForApprove
          noMargin
          render={(count) => (
            <ContentWithCount
              count={count}
              isCountDisplayed={count > 0}
              containerClassName={styles.approvePlansContainer}
              absoluteContainerClassName={styles.approvePlansCount}
            >
              <ApproveIcon className={styles.controlIcon} />
            </ContentWithCount>
          )}
        />
      ) : null}
      <ButtonBase onClick={openAllCheckpointsModal} secondary>
        <AddCheckpointIcon title={"Контрольные точки"} className={cn(styles.controlIcon, styles.checkIcon)} />
      </ButtonBase>
      <TabBarNotLinks
        tabs={chartViewModeTabs}
        activeId={chartViewMode}
        onClick={setChartViewMode}
        containerClassName={styles.viewTabsContainer}
        className={!isViewModeOnlySelect ? styles.viewTabs : undefined}
        isOnlySelectView={isViewModeOnlySelect}
      />
    </div>
  );
};

export default React.memo(ChartControls);
