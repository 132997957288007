import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { dataStateSelector, getUsersList } from "redux/modules/common/settings/ourCompanyTabs/employees";

import { IDetailedProject } from "pages/Projects/model/types";

import { editStock } from "../../model/thunks";
import ConfirmModal from "entities/ConfirmationModal/ConfirmModal";
import EmployeeModal from "entities/EmployeeModal/EmployeeModal";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import EmployeeElem from "shared/ui/dataDisplay/EmployeeElem/EmployeeElem";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { IEmployee } from "types/personsTypes";

import getShortFullName from "utils/formatters/getShortFullName";

import warehouses from "images/icons/navigation/warehouses.svg";

import styles from "./ProjectStockSettings.module.scss";

interface IProps {
  stock?: IDetailedProject["stock"];
}

const ProjectStockSettings: React.FC<IProps> = ({ stock }) => {
  const dispatch = useDispatch();
  const employees = useSelector(dataStateSelector);

  useEffect(() => {
    dispatch(getUsersList());
  }, []);

  const employesForSelect: IEmployee[] = useMemo(() => {
    const usedEmployeesMap = stock?.access_users?.reduce<Record<string, boolean>>((acc, cur) => {
      return {
        ...acc,
        [cur.id]: true,
      };
    }, {});
    return employees.results?.filter((el: IEmployee) => !usedEmployeesMap?.[el.id]);
  }, [employees, stock]);

  const [isAddingModal, setIsAddingModal] = useState(false);
  const [deletingId, setDeletingId] = useState(0);

  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const onAddAccess = () => {
    const oldUsers = stock?.access_users?.map((el) => el.id);
    const selectedUsers = [...(oldUsers ?? []), selectedIds[0]];
    dispatch(
      editStock(
        stock?.id!,
        { access_users: selectedUsers as any },
        {
          successCallback: () => {
            setIsAddingModal(false);
          },
        }
      )
    );
  };

  const [pendingIds, setPendingIds] = useState<(number | string)[]>([]);
  const onDeleteAccess = () => {
    setPendingIds((prev) => [...prev, deletingId]);
    const oldUsers = stock?.access_users?.map((el) => el.id)?.filter((el) => el !== deletingId);
    dispatch(editStock(stock?.id!, { access_users: oldUsers as any }, { successMessage: "Удалено" }));
    setDeletingId(0);
  };

  const [isAddResponsible, setIsAddResponsible] = useState(false);
  const [selectedResponsibles, setSelectedResponsibles] = useState<number[]>(
    stock?.responsible?.id ? [stock?.responsible?.id] : []
  );
  const onAddResponsible = () => {
    dispatch(
      editStock(
        stock?.id!,
        { responsible: selectedResponsibles[0] as any },
        {
          successCallback: () => {
            setIsAddResponsible(false);
          },
        }
      )
    );
  };
  const [isDeletingResponsible, setIsDeletingResponsble] = useState(false);

  useEffect(() => {
    setPendingIds([]);
    if (stock?.responsible?.id) {
      setSelectedResponsibles([stock.responsible.id]);
    } else {
      setSelectedResponsibles([]);
    }
  }, [stock]);

  const onDeleteResponsible = () => {
    const id = stock?.responsible?.id;
    if (!id) return;
    setPendingIds((prev) => [...prev, "responsible"]);
    dispatch(editStock(stock?.id!, { responsible: null }, { successMessage: "Удалено" }));
    setIsDeletingResponsble(false);
  };

  if (!stock) return <EmptyPlaceholder text="Нет склада" img={warehouses} />;

  return (
    <div>
      <div className={styles.list}>
        <div className={styles.title}>
          <span>Ответственный по складу</span>
          {stock.responsible && (
            <button className={styles.change} onClick={() => setIsAddResponsible(true)}>
              Изменить
            </button>
          )}
        </div>
        {stock.responsible && (
          <div className={styles.grid}>
            <EmployeeElem
              employeName={getShortFullName(stock.responsible)}
              canDelete
              onDelete={() => setIsDeletingResponsble(true)}
              onAdd={() => setIsAddResponsible(true)}
              isLoading={pendingIds.includes("responsible")}
              content={stock.responsible?.roles?.[0]?.name}
              disabled={!!pendingIds.length}
              className={styles.responsible}
            />
          </div>
        )}
        {!stock.responsible && (
          <div className={styles.holder}>
            <AddButton text="Добавить" onClick={() => setIsAddResponsible(true)} />
          </div>
        )}
      </div>
      <div className={styles.list}>
        <div className={styles.title}>
          <span>Доступ к складу</span>
          <AddButton text="Добавить" onClick={() => setIsAddingModal(true)} />
        </div>
        <div className={styles.grid}>
          {stock.access_users?.map((el, i, arr) => (
            <EmployeeElem
              key={el.id}
              employeName={getShortFullName(el)}
              content={el.roles?.[0]?.name}
              canDelete
              onDelete={() => setDeletingId(el.id)}
              isLoading={pendingIds.includes(el.id)}
              disabled={!!pendingIds.length}
            />
          ))}
        </div>
      </div>
      <EmployeeModal
        employees={employesForSelect}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        onSubmit={onAddAccess}
        isOpen={isAddingModal}
        onClose={() => setIsAddingModal(false)}
      />
      <ConfirmModal isOpen={!!deletingId} onClose={() => setDeletingId(0)} action={onDeleteAccess} variant="secondary">
        Вы действительно хотите удалить?
      </ConfirmModal>
      <EmployeeModal
        employees={employees?.results}
        selectedIds={selectedResponsibles}
        setSelectedIds={setSelectedResponsibles}
        onSubmit={onAddResponsible}
        isOpen={isAddResponsible}
        onClose={() => setIsAddResponsible(false)}
      />
      <ConfirmModal
        isOpen={isDeletingResponsible}
        onClose={() => setIsDeletingResponsble(false)}
        action={onDeleteResponsible}
        variant="secondary"
      >
        Вы действительно хотите удалить?
      </ConfirmModal>
    </div>
  );
};

export default ProjectStockSettings;
