import classNames from "classnames";
import React, { useMemo } from "react";

import Select, { ISelectProps } from "components/UI/atoms/Select";

import { TASK_PRIORITY } from "../../constants";

import styles from "./PriorityRange.module.scss";

interface IProps extends Omit<ISelectProps, "options" | "renderOption" | "diplayingValue"> {}

const options = Object.entries(TASK_PRIORITY).map(([id, name]) => ({ id, name }));

const colors: Record<TASK_PRIORITY, string> = {
  [TASK_PRIORITY.gray]: "gray",
  [TASK_PRIORITY.light_green]: "lightgreen",
  [TASK_PRIORITY.orange]: "orange",
  [TASK_PRIORITY.red]: "red",
  [TASK_PRIORITY.yellow]: "#E1CD1F",
};

const PrioritySelect = ({ value, ...props }: IProps) => {
  const diplayingValue = useMemo(() => {
    const option = options.find((el) => el.id === value);

    if (!option) return undefined;

    return (
      <div className={classNames(styles.option, styles.value)}>
        <div className={styles.indicator} style={{ background: colors[option?.name as TASK_PRIORITY] }} />
        <span>{option?.name}</span>
      </div>
    );
  }, [value]);

  return (
    <Select
      options={options}
      renderOption={(option, onClick) => (
        <div key={option.id} className={styles.option} onClick={() => onClick?.(option.id, option.name)}>
          <div className={styles.indicator} style={{ background: colors[option.name as TASK_PRIORITY] }} />
          <span>{option.name}</span>
        </div>
      )}
      value={value}
      displayIngValue={diplayingValue}
      {...props}
    />
  );
};

export default PrioritySelect;
