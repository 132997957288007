import React from "react";
import { Link } from "react-router-dom";

import EventEntry from "components/UI/atoms/EventItem/EventEntry";
import EventItemBody, { IEventItemBodyProps } from "components/UI/atoms/EventItem/EventItemBody";
import { eventsNames } from "components/pages/Calendar/constants";
import { useTasksModal } from "components/pages/Tasks/hooks/useTasksModal";

import { EVENT_TYPES, TaskEvent } from "types/interfaces/Event";

interface IProps {
  event: TaskEvent;
  statusSwitcher: IEventItemBodyProps["eventStatusSwitcher"];
}

const EventItemTask = ({ event, statusSwitcher }: IProps) => {
  const { openViewModal } = useTasksModal();
  return (
    <EventItemBody
      status={event.task.status}
      name={`${eventsNames.task} №${event?.task?.number}`}
      eventType={EVENT_TYPES.task}
      isCompleted={event.is_completed}
      eventStatusSwitcher={statusSwitcher}
    >
      <EventEntry
        label={"Название: "}
        value={
          <Link
            onClick={(e) => {
              e.preventDefault();
              openViewModal({ placementType: "byCertainBuilding", id: event.task.id });
            }}
            to={`/tasks/all/0/${event.task.id}`}
          >
            {event.task.name}
          </Link>
        }
      />
      <EventEntry label={"Проект:"} value={event.task.building_name} />
      <EventEntry label={"Исполнитель:"} value={event.executor.name} />
    </EventItemBody>
  );
};

export default EventItemTask;
