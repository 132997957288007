import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { updateExpenditure } from "redux/modules/common/building/materials/materials";

import BottomSliderMaterials from "../../../../components/BottomSliderMaterials";
import Icon from "_LEGACY/UI/_LEGACY_Icon/Icon";
import { TableOslaLocal } from "_LEGACY/UI/_LEGACY_TableOslaCustom/TableOslaLocal";
import { TableHeader } from "_LEGACY/UI/_LEGACY_TotoRowTable/TableHeader/TableHeader";
import Select from "components/UI/atoms/Select/index";

import InputBase, { INPUT_BASE_VARIANTS, VALUE_TYPES } from "../../../../../../../shared/ui/inputs/InputBase";
import UpdateExpenditureModal from "../UpdateExpenditureModal";

import { measuresMaterials } from "constants/constant";
import { LOCALIZATION_CONFIG } from "constants/localization";

import { transformDigitToFinancial } from "../../../../../../../utils/formatters/transformDigitToFinancial";

import settings from "images/icons/settings.svg";

import styles from "./Expenditure.module.scss";

const Expenditure = ({ materialArray, isSwitch, objectId, data }) => {
  const dispatch = useDispatch();
  const [section, setSection] = useState(null);
  const [countValue, setCountValue] = useState({});
  const [priceValue, setPriceValue] = useState({});
  const [isModalUpdate, setIsModalUpdate] = useState(false);
  const [measureValue, setMeasureValue] = useState("measure");

  const nameBlock = (row, cell) => {
    return (
      <div>
        <span>{row}</span>
        <div className={styles.labelBox}>
          <div className={styles.blueLabel}>{cell.justification || "Цена поставщика"}</div>
        </div>
      </div>
    );
  };

  const measureUpdate = (id, data) => {
    dispatch(updateExpenditure(objectId, id, { measure: data.name }));
  };

  const countUpdate = (id, data) => {
    dispatch(updateExpenditure(objectId, id, { count: data }));
  };

  const handleChangeCountValue = ({ target: { value } }, rowId) => {
    setCountValue((prev) => ({ ...prev, [rowId]: value }));
  };

  const priceUpdate = (id, data) => {
    dispatch(updateExpenditure(objectId, id, { price: data }));
  };

  const handleChangePriceValue = ({ target: { value } }, rowId) => {
    setPriceValue((prev) => ({ ...prev, [rowId]: value }));
  };

  const measureBlock = (cell, row) => {
    if (isSwitch) {
      return (
        <Select
          options={measuresMaterials}
          value={row.measure}
          onChange={(id) => {
            setMeasureValue(measuresMaterials.find((item) => item?.id === id));
            measureUpdate(
              row.id,
              measuresMaterials.find((item) => item?.id === id)
            );
          }}
        />
      );
    } else {
      return <span>{row.measure}</span>;
    }
  };

  const countBlock = (cell, row) => {
    if (isSwitch) {
      return (
        <InputBase
          classNameInput={styles.input}
          value={countValue[row.id] || cell}
          valueType={VALUE_TYPES.NUMBER}
          onBlur={(e) => countUpdate(row.id, e.target.value)}
          onChange={(event) => handleChangeCountValue(event, row.id)}
        />
      );
    } else {
      return <span>{transformDigitToFinancial(row.count, { dropZeros: true, withFloat: true })}</span>;
    }
  };

  const priceBlock = (cell, row) => {
    if (isSwitch) {
      return (
        <InputBase
          classNameInput={styles.input}
          value={priceValue[row.id] || cell}
          valueType={VALUE_TYPES.NUMBER}
          variant={INPUT_BASE_VARIANTS.SECONDARY}
          onBlur={(e) => priceUpdate(row.id, e.target.value)}
          onChange={(event) => handleChangePriceValue(event, row.id)}
        />
      );
    } else {
      return <span>{transformDigitToFinancial(row.price, { dropZeros: true, withFloat: true })}</span>;
    }
  };

  const amountBlock = (cell, row) => {
    if (isSwitch) {
      return (
        <div className={styles.lastRow}>
          {/*<InputBase*/}
          {/*  classNameInput={styles.input}*/}
          {/*  value={priceValue[row.id] || cell}*/}
          {/*  valueType={VALUE_TYPES.NUMBER}*/}
          {/*  variant={INPUT_BASE_VARIANTS.SECONDARY}*/}
          {/*  onBlur={(e) => priceUpdate(row.id, e.target.value)}*/}
          {/*  onChange={(event) => handleChangePriceValue(event, row.id)}*/}
          {/*/>*/}
          <span>{transformDigitToFinancial(row.estimate_amount, { dropZeros: true, withFloat: true })}</span>
          <div
            className={styles.iconBox}
            onClick={() => {
              setSection(row);
              setIsModalUpdate(true);
            }}
          >
            <Icon icon={settings} />
          </div>
          <div>&nbsp;&nbsp;</div>
        </div>
      );
    } else {
      return <span>{transformDigitToFinancial(row.estimate_amount, { dropZeros: true, withFloat: true })}</span>;
    }
  };

  const closePositionEditModal = () => {
    setIsModalUpdate(false);
  };

  return (
    <div className={styles.tableWrapper}>
      <BottomSliderMaterials isOpen={isModalUpdate} closeFunc={closePositionEditModal}>
        <UpdateExpenditureModal data={data} objId={objectId} section={section} close={closePositionEditModal} />
      </BottomSliderMaterials>
      <TableOslaLocal
        data={materialArray}
        pagination={false}
        rowClass={styles.tableRowClass}
        headerClass={styles.tableHeaderClass}
      >
        <TableHeader
          style={{ width: "2%", textAlign: "center", paddingLeft: "1.25rem" }}
          cellStyle={{ textAlign: "center", width: "2%", paddingLeft: "1.25rem" }}
          data={"number"}
        >
          <div className={styles.tableTextBold}>№</div>
        </TableHeader>

        <TableHeader
          data={"name"}
          formatter={nameBlock}
          style={{ width: "35%", textAlign: "left", padding: "0 3.88rem" }}
          cellStyle={{ textAlign: "left", width: "35%", padding: "1rem 3.88rem" }}
        >
          <div className={styles.tableTextBold}>Наименование</div>
        </TableHeader>

        <TableHeader
          data={measureValue}
          formatter={measureBlock}
          style={{ width: "13%", textAlign: "center" }}
          cellStyle={{ textAlign: "center", width: "13%", padding: isSwitch ? "1rem 5rem" : "0" }}
        >
          <div className={styles.tableTextBold}>Ед. изм.</div>
        </TableHeader>
        <TableHeader
          data={"count"}
          formatter={countBlock}
          style={{ width: "8%", textAlign: "left" }}
          cellStyle={{ textAlign: "left", width: "8%" }}
        >
          <div className={styles.tableTextBold}>Количество</div>
        </TableHeader>
        <TableHeader
          data={"price"}
          formatter={priceBlock}
          style={{ width: "8%", textAlign: "left" }}
          cellStyle={{ textAlign: "left", width: "8%" }}
        >
          <div className={styles.tableTextBold}>Цена,&nbsp;{LOCALIZATION_CONFIG.currency}</div>
        </TableHeader>
        <TableHeader
          data={"estimate_amount"}
          formatter={amountBlock}
          style={{ width: "8%", textAlign: "left" }}
          cellStyle={{ textAlign: "left", width: "8%" }}
        >
          <div className={styles.tableTextBold}>Сумма,&nbsp;{LOCALIZATION_CONFIG.currency}</div>
        </TableHeader>
      </TableOslaLocal>
    </div>
  );
};

export default Expenditure;
