import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getDetail,
  isObjectDetailLoadingSelector,
  objectDetailSelector,
} from "../../../redux/modules/common/building/object/nowObject";

import TemplateSimple from "../../../_LEGACY/UI/_LEGACY_TemplateSimple/TemplateSimple";

import Spinner from "../../../shared/ui/atoms/Spinner/Spinner";
import { aggregationLoadingSelector } from "./Aggregations/model/selectors";
import { loadAggregations } from "./Aggregations/model/thunks";
import ProHandler from "./ProHandler/ProHandler";
import { SimpleHandler } from "./SimpleHandler";

import { IHandlerPageProps } from "../types/HandlerProps";

const HandlerPage: FC<IHandlerPageProps> = (props) => {
  const dispatch = useDispatch();

  const objectIsLoading = useSelector(isObjectDetailLoadingSelector);
  const object = useSelector(objectDetailSelector);

  const aggregationsAreLoading = useSelector(aggregationLoadingSelector);

  const { buildingId } = useParams<{ buildingId: string }>();

  useEffect(() => {
    // Подгружаем данные объекта
    dispatch(getDetail(buildingId));
    // Подгружаем данные для табов состояний (Aggregations)
    dispatch(loadAggregations(buildingId));
  }, [buildingId]);

  if (objectIsLoading || aggregationsAreLoading) {
    return (
      <TemplateSimple dataTestId="page_handler">
        <Spinner />
      </TemplateSimple>
    );
  }

  if (!object) return null;

  return (
    object && (
      <TemplateSimple dataTestId="page_handler">
        {object.is_simple_parser && <SimpleHandler {...props} />}

        {!object.is_simple_parser && <ProHandler {...props} />}
      </TemplateSimple>
    )
  );
};

export default HandlerPage;
