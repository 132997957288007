import { message } from "antd";
import { Dispatch } from "redux";

import { journalApi } from "../journalApi";
import { journalExecutionActions } from "./actions";
import { journalExecutionApi } from "./journalExecutionApi";
import { RootState } from "app/store/rootReducer";

import { generateJornalTicketSectionKey } from "../utils";
import { errorCatcher } from "utils/helpers/errorCatcher";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const getJournalFullfillmentSections =
  (buildingId: string, year: number, month: number) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateStorageKey({ buildingId, year, month });
    if (!getState().journalExecution.sectionsV2[key]) {
      dispatch(journalExecutionActions.setSectionsV2Loading(key, true));
    }
    journalExecutionApi
      .getSectionsV2(buildingId, year, month)
      .then(({ data }) => {
        dispatch(journalExecutionActions.setSectionsV2(data, key));
      })
      .finally(() => {
        dispatch(journalExecutionActions.setSectionsV2Loading(key, false));
      });
  };

export const getJournalFulfillmentExpenditures =
  (buildingId: string, sectionId: number, year: number, month: number) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateJornalTicketSectionKey(buildingId, year, month, sectionId);
    if (!getState().journalExecution.expendituresV2[key]) {
      dispatch(journalExecutionActions.setExpendituresV2Loading(key, true));
    }
    journalExecutionApi
      .getExpendituresV2(buildingId, sectionId, year, month)
      .then(({ data }) => {
        dispatch(journalExecutionActions.setExpendituresV2(data, key));
      })
      .finally(() => {
        dispatch(journalExecutionActions.setExpendituresV2Loading(key, false));
      });
  };

export const getJournalFulfillmentGroupInfo =
  (buildingId: string, groupId: number, year: number, month: number) => (dispatch: Dispatch) => {
    const key = generateJornalTicketSectionKey(buildingId, year, month, groupId);
    //loading
    journalExecutionApi
      .getGroup(buildingId, groupId, year, month)
      .then(({ data }) => {
        dispatch(journalExecutionActions.setGroup(data, key));
      })
      .finally(() => {
        //loading
      });
  };

export const getJournalFulfillmentContentOfExpenditure =
  (buildingId: string, expenditureId: number, year: number, month: number) => (dispatch: Dispatch) => {
    const key = generateJornalTicketSectionKey(buildingId, year, month, expenditureId);
    //loading
    journalExecutionApi
      .getContentOfExpenditure(buildingId, expenditureId, year, month)
      .then(({ data }) => {
        dispatch(journalExecutionActions.setContentOfExpenditure(data, key));
      })
      .finally(() => {
        //loading
      });
  };

export const bindJournalExecTicketToEstimate = (objectId: string, factIds: number[], expId: number) => {
  return (dispatch: Dispatch) => {
    Promise.all(factIds.map((id) => journalApi.patchTicket(objectId, id, { expenditure_id: expId })))
      .then(() => {
        dispatch(journalExecutionActions.changeInvalidateKey());
        message.success("Продукт сопоставлен");
      })
      .catch(errorCatcher);
  };
};
