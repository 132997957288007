import React, { FC, useRef, useState } from "react";

import { canvasPreview } from "./canvasPreview";
import ReactCrop, { Crop, PixelCrop, centerCrop, convertToPixelCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ButtonBase from "shared/ui/controls/ButtonBase";

import { useDebounceEffect } from "utils/hooks/useDebouncedEffect";

import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";

import styles from "./ObjectAvatar.module.scss";

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

interface IProps {
  src?: string;
  onSubmit?: (fileInB64: string) => void;
  isLoading?: boolean;
}

const ROTATE = 0;
const SCALE = 1;
const ASPECT = 14 / 9;

const Cropper: FC<IProps> = ({ src, onSubmit, isLoading }) => {
  const [imgSrc, setImgSrc] = useState(src);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, ASPECT));
  }

  async function onDownloadCropClick() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error("Crop canvas does not exist");
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    //@ts-ignore
    const offscreen = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY);
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );

    const blob = await offscreen.convertToBlob({
      type: "image/png",
    });

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }
    blobUrlRef.current = URL.createObjectURL(blob);

    const [fileInB64] = await getArrayFilesInBase64([blob]);

    onSubmit?.(fileInB64.file as string);
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, SCALE, ROTATE);
      }
    },
    100,
    [completedCrop]
  );

  return (
    <div className={styles.cropperRoot}>
      <div className={styles.cropperWrapper}>
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={ASPECT}
          minHeight={100}
        >
          <div className={styles.picWrapper}>
            <img
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              style={{ transform: `scale(${SCALE}) rotate(${ROTATE}deg)` }}
              onLoad={onImageLoad}
              className={styles.img}
            />
          </div>
        </ReactCrop>
      </div>

      <ButtonBase onClick={onDownloadCropClick} primary isLoading={isLoading}>
        Сохранить
      </ButtonBase>
      <canvas
        ref={previewCanvasRef}
        style={{
          objectFit: "contain",
          width: completedCrop?.width,
          height: completedCrop?.height,
        }}
        className={styles.previewCanvas}
      />
      <a
        href="#hidden"
        ref={hiddenAnchorRef}
        download
        style={{
          position: "absolute",
          top: "-200vh",
          visibility: "hidden",
        }}
      >
        Hidden download
      </a>
    </div>
  );
};

export default Cropper;
