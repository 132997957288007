import { useState } from "react";
import { useDispatch } from "react-redux";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { createAxes } from "../model/thunks";

import { DEFAULT_CREATE_AXES_PARAMS } from "../constants";
import { ICreateAxesArg } from "../model/types";

interface IProps {
  planOrFact: "plan" | "fact";
  groupOrWork: "group" | "work";
}

export const useCreateAxes = ({ planOrFact, groupOrWork }: IProps) => {
  const dispatch = useDispatch();
  const buildingId = useObjectId();

  const [savingAxes, setSavingAxes] = useState<ICreateAxesArg | null>(null);

  const onSaveAxes = (expenditureId: number) => {
    if (!savingAxes) return;
    const key = `${planOrFact}_${groupOrWork}`;
    const data: ICreateAxesArg = {
      ...DEFAULT_CREATE_AXES_PARAMS,
      [key]: expenditureId,
      drawing: savingAxes.drawing,
      axis: savingAxes.axis,
    };
    dispatch(createAxes(buildingId, data));
    setSavingAxes(null);
  };

  const onSubmit = (vals: ICreateAxesArg) => {
    setSavingAxes(vals);
  };

  const [isDirty, setIsDirty] = useState(false);

  return {
    savingAxes,
    onSaveAxes,
    onSubmit,
    setAxesDirty: setIsDirty,
    isAxesNotSubmitted: isDirty,
  };
};
