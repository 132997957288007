import { message } from "antd";
import axios from "axios";
import _, { debounce } from "lodash";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { getSpecificationsExpenditures } from "redux/modules/common/building/materials/specification";

import { IEstimateTarget } from "components/UI/_TODO/Expenditure/components/AddRelationsModal/AddRelationModalSections/AddRelationModalSections";
import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { SPECIFICATION } from "components/pages/Materials/constants";

import SpecificationSplitModal from "../SpecificationSplitModal/SpecificationSplitModal";
import SpecificationRelationsSections from "./SpecificationRelationsSections/SpecificationRelationsSections";
import { IStructWithRecursiveChildren, useSpecificationRelationsData } from "./useSpecificationRelationsData";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import TabBarNotLinks from "shared/ui/controls/TabBar/TabBarNotLinks";
import { InputSearchRound } from "shared/ui/inputs/InputSearchRound/InputSearchRound";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";

import { errorCatcher } from "utils/helpers/errorCatcher";

import styles from "./SpecificationAddRelationsModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  itemId: number;
  subSectionId: number;
}

export interface IEstimateTargetWithType extends IEstimateTarget {
  typeId: number;
}

const SpecificationAddRelationsModal: FC<IProps> = ({ isOpen, onClose, itemId, subSectionId }) => {
  const [selectedEstimateTarget, setSelectedEstimateTarget] = useState<IEstimateTargetWithType | null>(null);

  const submitDisabled = !selectedEstimateTarget;

  const { actualData, isLoading, tab, tabs, setTab } = useSpecificationRelationsData({ isOpen });

  const [search, setSearch] = useState("");

  const onChangeSearchInput = useCallback((e: any) => {
    setSearch(e.target.value);
  }, []);

  const debouncedOnChangeSearchInput = useMemo(() => debounce(onChangeSearchInput, 300), [onChangeSearchInput]);

  const searchedSections = useMemo(() => filterSectionsBySearch(actualData ?? [], search), [actualData, search]);

  const onSelectExpenditure = (target: IEstimateTargetWithType) => {
    setSelectedEstimateTarget(target);
  };

  const dispatch = useDispatch();
  const objectId = useObjectId();
  const [isPending, setIsPending] = useState(false);
  const [isSplitting, setIsSplitting] = useState(false);
  const handleSubmit = () => {
    if (!selectedEstimateTarget) return;
    if (tab === "rd") {
      setIsSplitting(true);
      onClose();
      return;
    }
    setIsPending(true);
    axios
      .patch(`/building/${objectId}/materials/specification/${itemId}/`, {
        estimate_expenditure_id: selectedEstimateTarget.expenditureId,
      })
      .then((response) => {
        setIsPending(false);
        message.success("Продукт сопоставлен");
        dispatch(getSpecificationsExpenditures(objectId, SPECIFICATION, subSectionId, false));
        onClose();
      })
      .catch(errorCatcher);
  };

  return (
    <>
      <SliderModal isOpen={isOpen} closeHandler={onClose} className={styles.sliderClassName}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.titleBlock}>
              <div className={styles.title}>Связь</div>
            </div>
            <div className={styles.searchBlock}>
              <InputSearchRound onChange={debouncedOnChangeSearchInput} className={styles.inputClassName} />
              <ButtonBase
                isLoading={isPending}
                className={styles.btn}
                primary
                onClick={handleSubmit}
                disabled={submitDisabled}
              >
                Связать
              </ButtonBase>
            </div>
          </div>
          <div className={styles.contentWrapper}>
            {!isLoading && (
              <div className={styles.tabs}>
                <TabBarNotLinks tabs={tabs} activeId={tab} onClick={setTab} isOnlyTabsView />
              </div>
            )}
            <div className={styles.content}>
              {!isLoading ? (
                <SpecificationRelationsSections
                  selectedEstimateTarget={selectedEstimateTarget}
                  sections={searchedSections}
                  opened={!!search.length}
                  onCheckExpenditure={onSelectExpenditure}
                />
              ) : (
                <Spinner isStatic />
              )}
            </div>
          </div>
          <BottomControls isEmpty isExists />
        </div>
      </SliderModal>
      <SpecificationSplitModal
        isOpen={isSplitting}
        onClose={() => setIsSplitting(false)}
        expenditureId={selectedEstimateTarget?.expenditureId}
        itemId={itemId}
      />
    </>
  );
};

export default SpecificationAddRelationsModal;

export const filterSectionsBySearch = (
  sections: IStructWithRecursiveChildren[],
  search: string = ""
): IStructWithRecursiveChildren[] => {
  if (!sections) return [];
  const sectionsCopy = _.cloneDeep(sections);
  const filteredSections = sectionsCopy.filter((section) => {
    const filteredSubSections = section.children?.filter((subSection) => {
      const filteredExpenditures = subSection.children?.filter((expenditure) => {
        return expenditure.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      });
      if (!!filteredExpenditures?.length) {
        subSection.children = filteredExpenditures;
        return true;
      } else {
        return false;
      }
    });
    if (!!filteredSubSections?.length) {
      section.children = filteredSubSections;
      return true;
    } else {
      return false;
    }
  });

  return filteredSections;
};
