import React, { SyntheticEvent, useMemo, useState } from "react";

import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";

import PopoverChart, { IPopoverChartProps } from "../PopoverChart/PopoverChart";

import { ICurrencyIndicators } from "../../../../../types";
import { COLORS_CURRENCY as COLORS } from "../constants";
import { LOCALIZATION_CONFIG } from "constants/localization";

import { splitThousands } from "../../../../../../../../utils/formatters/splitThousands";

import styles from "./CurrencyPopover.module.scss";

export interface ICurrencyPopoverProps {
  indicators: ICurrencyIndicators;
}

const CurrencyPopover: React.FC<ICurrencyPopoverProps> = ({ indicators }) => {
  const [open, setOpen] = useState(false);
  /* @ts-ignore */
  const { chartData, dataAll }: Pick<IPopoverChartProps, "chartData" | "dataAll"> = useMemo(() => {
    const { estimate_amount, amount_completed, amount_issued, amount_on_stock, amount_to_pay, amount_accepted } =
      indicators;
    const finalAmount = Number(estimate_amount) || 0;
    const finalCompleted = Number(amount_completed) || 0;
    const finalAccepted = Number(amount_accepted) || 0;
    const finalToPay = Number(amount_to_pay) || 0;
    const finalOnStock = Number(amount_on_stock) || 0;
    const finalIssued = Number(amount_issued) || 0;
    const dataAll = {
      name: "Бюджет",
      value: finalAmount,
    };
    return {
      chartData: [
        amount_completed !== undefined && {
          name: "Выполнено",
          value: finalCompleted,
          key: "completed",
          percent: (dataAll.value && Math.round((finalCompleted * 100) / dataAll.value)) || 0,
        },
        amount_on_stock !== undefined && {
          name: `На складе`,
          value: finalOnStock,
          key: "on_stock",
          percent: (dataAll.value && Math.round((finalOnStock * 100) / dataAll.value)) || 0,
        },
        amount_issued !== undefined && {
          name: `Выдано`,
          value: finalIssued,
          key: "issued",
          percent: (dataAll.value && Math.round((finalIssued * 100) / dataAll.value)) || 0,
        },
        amount_accepted !== undefined && {
          name: "Принято",
          value: finalAccepted,
          key: "accepted",
          percent: (dataAll.value && Math.round((finalAccepted * 100) / dataAll.value)) || 0,
        },
        amount_to_pay !== undefined && {
          name: "К оплате",
          value: finalToPay,
          key: "to_pay",
          percent: (dataAll.value && Math.round((finalToPay * 100) / dataAll.value)) || 0,
        },
      ].filter((x) => x),
      dataAll,
    };
  }, [indicators]);

  const handleInteraction = (isOpenNew: boolean, e: SyntheticEvent<HTMLElement, Event> | undefined) => {
    e?.stopPropagation();
    setOpen(isOpenNew);
  };

  return (
    <PopoverOverlay
      isOpen={open}
      openType="click"
      onInteraction={handleInteraction}
      placement="bottom"
      popoverBorderColor="primary"
      content={
        <div className={styles.currencyPopoverContainer} /*onMouseLeave={() => setOpen(false)}*/>
          <span className={styles.currencyPopoverTitle}>Прогресс выполнения</span>
          <div className={styles.currencyPopoverItem}>
            <span>Бюджет:</span>
            <span>{splitThousands(dataAll?.value)}</span>
          </div>
          <PopoverChart dataAll={dataAll} chartData={chartData} colors={COLORS} />
          <div className={styles.legend}>
            {chartData.map((x, index) => (
              <React.Fragment key={index}>
                <div className={styles.currencyPopoverItemMarker}>
                  {/* @ts-ignore */}
                  <div className={styles.square} style={{ backgroundColor: COLORS[x.key] }} />
                  <span>{x.name}:</span>
                </div>
                <span>{`${x.value > 0 && x.percent < 1 ? "<1" : x.percent}%`}</span>
                <span>{splitThousands(x.value)}</span>
              </React.Fragment>
            ))}
          </div>
        </div>
      }
    >
      <div className={styles.currency} onMouseLeave={() => setOpen(false)}>
        {LOCALIZATION_CONFIG.currency}
      </div>
    </PopoverOverlay>
  );
};

export default React.memo(CurrencyPopover);
