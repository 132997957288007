import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IFileFromS3 } from "widgets/FilesFromStorage/FilesFromStorage";

interface TasksV2State {
  invalidationKey: number;
  loadings: Record<string, boolean>;
  pendings: Record<string, boolean>;
  persistedFilesFromStorage: IFileFromS3[];
}

export const initialState: TasksV2State = {
  invalidationKey: 0,
  loadings: {},
  pendings: {},
  persistedFilesFromStorage: [],
};

const tasksV2Slice = createSlice({
  name: "tasksV2",
  initialState,
  reducers: {
    invalidateKey: (state) => {
      state.invalidationKey++;
    },
    setIsLoading: (state, action: PayloadAction<{ status: boolean; key: string }>) => {
      const { status, key } = action.payload;
      state.loadings[key] = status;
    },
    setPending: (state, action: PayloadAction<{ status: boolean; key: string }>) => {
      const { status, key } = action.payload;
      state.pendings[key] = status;
    },
    clearPendings: (state) => {
      state.pendings = {};
    },
    setPersistedFilesFromStorage: (state, action: PayloadAction<IFileFromS3[]>) => {
      state.persistedFilesFromStorage = action.payload;
    },
  },
});

export const tasksV2Actions = tasksV2Slice.actions;

export const tasksV2Reducer = tasksV2Slice.reducer;
