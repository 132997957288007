import cn from "classnames";
import moment from "moment";
import React, { useMemo } from "react";

import styles from "../../Intervals.module.scss";

const IntervalBlock = ({ item, fullCount, intervalsColor, onClickInterval, isExpendituresGroup, isHideCount }) => {
  const currentCount = useMemo(() => {
    if (!isExpendituresGroup) return item.sum_count?.toString();

    if (item?.confirmed_count && item?.is_confirmed) {
      return item?.confirmed_count?.toString();
    } else {
      return item?.count?.toString();
    }
  }, [isExpendituresGroup, item]);

  const percents = useMemo(() => {
    return !isNaN(Math.round(currentCount / (fullCount / 100))) ? Math.round(currentCount / (fullCount / 100)) : 0;
  }, [fullCount, item, currentCount]);

  const displayingString = useMemo(() => {
    if (!isExpendituresGroup) {
      return moment(item.dates?.[0])?.format("DD.MM-") + moment(item.dates?.[1])?.format("DD.MM");
    } else {
      const startOfWeek = moment(item.start_at).clone().startOf("week");
      const endOfWeek = moment(item.end_at).clone().endOf("week");
      return moment(startOfWeek)?.format("DD.MM-") + moment(endOfWeek)?.format("DD.MM");
    }
  }, [isExpendituresGroup, item]);

  return (
    <div className={styles.outer}>
      <div
        className={cn(styles.intervalBlock, styles[intervalsColor])}
        title={!isHideCount && currentCount}
        onClick={() =>
          onClickInterval(
            isExpendituresGroup ? item.id : item.expenditure_id,
            moment(isExpendituresGroup ? item.start_at : item.dates?.[0])?.format("YYYY-MM-DD"),
            moment(isExpendituresGroup ? item.end_at : item.dates?.[1])?.format("YYYY-MM-DD"),
            item.section_id,
            item.interval_id
          )
        }
      >
        {currentCount && !isHideCount && (
          <div className={styles.count}>
            {currentCount?.length < 6 ? currentCount : currentCount?.slice(0, 5) + "..."}
          </div>
        )}
        {currentCount && !isHideCount && <div className={styles.devider} />}
        <div className={styles.date}>{displayingString}</div>
      </div>
      <div className={styles.bar}>
        <div className={styles.percents} style={{ width: `${percents}%` }}></div>
      </div>
    </div>
  );
};

export default React.memo(IntervalBlock);
