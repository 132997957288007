import { useEffect, useState } from "react";

import { chartIntervalCn, chartPlanIntervalCn, chartWorkIntervalCn } from "./const";

const checkIntersection = (rootBounds: DOMRect, elBounds: DOMRect) =>
  /* [++++]
  [-----] */
  (rootBounds.left < elBounds.right && rootBounds.left > elBounds.left) ||
  /* [++++]
       [-----] */
  (rootBounds.right < elBounds.right && rootBounds.right > elBounds.left) ||
  // включает полное совпадение отрезков плана и факта
  /* [+++++++++]
       [-----] */
  (rootBounds.left <= elBounds.left && rootBounds.right >= elBounds.right) ||
  /*    [++]
      [------] */
  (rootBounds.left > elBounds.left && rootBounds.right < elBounds.right);

export interface IUseChartIntervalIntersectionProps {
  /** Реф внешнего элемента интервала; родитель этого элемента обязательно содержит все соседние отрезки */
  intervalRef: React.RefObject<HTMLDivElement>;
  /** Ретриггер проверки пересечения */
  recheck?: any;
  /** Отслеживать типы пересекающихся отрезков – проверяет все пересечения в строке */
  trackIntersectingTypes?: boolean;
  active: boolean;
}

export const useChartIntervalIntersection = ({
  intervalRef,
  recheck,
  trackIntersectingTypes,
  active,
}: IUseChartIntervalIntersectionProps) => {
  const [isIntersecting, setIntersecting] = useState(false);
  const [isIntersectingWorks, setIntersectingWorks] = useState<boolean>();
  const [isIntersectingPlans, setIntersectingPlans] = useState<boolean>();

  useEffect(() => {
    if (!intervalRef.current || !active) return;
    const intervalsBranch = intervalRef.current.parentElement as Element;
    const rootBounds = intervalRef.current.getBoundingClientRect();
    try {
      const branchIntervalsElements = Array.from(intervalsBranch.querySelectorAll(`.${chartIntervalCn}`));
      if (trackIntersectingTypes) {
        for (let i = 0; i < branchIntervalsElements.length; i++) {
          const el = branchIntervalsElements[i];
          if (!el || el === intervalRef.current) continue;
          // Досрочный выход, если уже найдены все типы пересечений
          if (isIntersectingPlans && isIntersectingWorks) break;
          const elBounds = el.getBoundingClientRect();
          if (checkIntersection(rootBounds, elBounds)) {
            setIntersecting(true);
            if (el.classList.contains(chartWorkIntervalCn)) {
              setIntersectingWorks(true);
            } else if (el.classList.contains(chartPlanIntervalCn)) {
              setIntersectingPlans(true);
            }
          }
        }
      } else {
        setIntersecting(
          branchIntervalsElements?.some((el) => {
            if (!el || el === intervalRef.current) return false;
            const elBounds = el.getBoundingClientRect();
            return checkIntersection(rootBounds, elBounds);
          }) || false
        );
      }
    } catch (e) {
      console.error(e);
    }
  }, [recheck, intervalRef.current, trackIntersectingTypes, active]);
  return { isIntersecting, isIntersectingWorks, isIntersectingPlans };
};
