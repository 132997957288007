import classNames from "classnames";
import React from "react";

import Avatar from "shared/ui/dataDisplay/Avatar/Avatar";
import { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import getShortFullName from "utils/formatters/getShortFullName";

import styles from "./TaskPerson.module.scss";

interface IProps {
  person: any;
  canDelete?: boolean;
  onDelete?: any;
  className?: string;
}

const TaskPerson = ({ person, canDelete, onDelete, className }: IProps) => {
  return (
    <div className={classNames(styles.root, className)}>
      <Avatar person={person} className={styles.avatar} />
      <TableReusableCell className={styles.name} isNoBreak>
        {getShortFullName(person)}
      </TableReusableCell>
      {canDelete && (
        <button onClick={onDelete} className={styles.delete}>
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_424_4440)">
              <path d="M6.64893 8.07422V12.2822" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10.016 8.07422V12.2822" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M1.59998 3.86719H15.065" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M3.28296 6.39062V13.1236C3.28296 13.7933 3.54898 14.4355 4.02251 14.9091C4.49604 15.3826 5.13829 15.6486 5.80796 15.6486H10.857C11.1886 15.6488 11.5171 15.5835 11.8235 15.4567C12.13 15.3299 12.4085 15.1439 12.6431 14.9094C12.8776 14.6749 13.0637 14.3965 13.1907 14.0901C13.3176 13.7837 13.383 13.4553 13.383 13.1236V6.39062"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.80798 2.183C5.80798 1.73664 5.9853 1.30856 6.30092 0.992939C6.61655 0.677316 7.04462 0.5 7.49098 0.5H9.17398C9.62034 0.5 10.0484 0.677316 10.364 0.992939C10.6797 1.30856 10.857 1.73664 10.857 2.183V3.866H5.80798V2.183Z"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_424_4440">
                <rect width="16" height="16.148" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      )}
    </div>
  );
};

export default TaskPerson;
