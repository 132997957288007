import axios from "axios";

import { IUploadedFile } from "../components/TaskFiles/TaskFilesPopup/TaskFilesPopup";

import {
  ICertainTaskResponse,
  IcreateTask,
  ItaskAggregationsParams,
  ItaskIndicatorsByBuilding,
  ItaskResponse,
  IupdateTask,
  listOfTaskListsType,
  tasksFieldsFilterType,
  tasksFieldsType,
  tasksListFilterType,
} from "./types";
import { IListResponseData } from "types/ListResponse";
import { IFile } from "types/interfaces/Files";

export const tasksApi = {
  getTasks: (params: tasksListFilterType) => {
    return axios.get<IListResponseData<ItaskResponse>>(`/tasks/`, { params });
  },
  getTasksLists: (params?: tasksListFilterType) => {
    return axios.get<IListResponseData<listOfTaskListsType>>(`/tasks/lists/`, { params });
  },
  patchTasksList: (params: listOfTaskListsType) => {
    return axios.put(`/tasks/list/${params.id}/`, { list_name: params.list_name, user: params.user });
  },
  deleteTasksList: (id: number, delete_tasks: string) => {
    return axios.delete(`/tasks/list/${id}/`, { params: { delete_tasks } });
  },
  createTasksList: (data: Omit<listOfTaskListsType, "id" | "tasks_count">) => {
    return axios.post<{ id: number }>(`/tasks/lists/`, data);
  },
  deleteTask: (id: number) => {
    return axios.delete(`/task/${id}/`);
  },
  getTask: (id: number) => {
    return axios.get<ICertainTaskResponse>(`/task/${id}/`);
  },
  createTask: (data: IcreateTask) => {
    return axios.post<ICertainTaskResponse>(`/tasks/`, data);
  },
  createTaskComment: (text: string, task: number, creator: number, date: string) => {
    return axios.post(`/tasks/comments/`, { text, task, creator, date });
  },
  postTaskFiles: (files: IUploadedFile[], taskId: number) => {
    const formData = new FormData();
    files.forEach((el) => {
      formData.append("files", el.file as any);
    });
    formData.append("task", taskId.toString());
    return axios.post(`/tasks/files/`, formData);
  },
  deleteTaskFile: (fileId: number) => {
    return axios.delete(`/tasks/file/${fileId}/`);
  },
  getTasksFields: (params?: tasksFieldsFilterType) => {
    return axios.get<tasksFieldsType>(`/tasks/fields/`, { params });
  },
  updateTask: (id: number, data: IupdateTask) => {
    return axios.patch<ICertainTaskResponse>(`/task/${id}/`, data);
  },
  getByBuildingIndicators: (buildingId: number, params: tasksListFilterType) => {
    return axios.get<ItaskIndicatorsByBuilding>(`/tasks/${buildingId}/indicators/`, { params });
  },
  getTasksAggregation: (params: ItaskAggregationsParams) => {
    return axios.get(`/tasks/aggregations-indicators/`, { params });
  },
  getTaskHistory: (taskId: number | string) => {
    return axios.get(`/task/${taskId}/history/`);
  },
  addApprovers: (taskId: number | string, ids: number[]) => {
    const data = ids.map((id) => ({
      approver: id,
    }));
    return axios.post(`/task/${taskId}/approve/`, data);
  },
  getApprovers: (taskId: number | string) => {
    return axios.get(`/task/${taskId}/approve/`);
  },
  approve: (taskId: number | string, approve: number) => {
    return axios.patch(`/task/${taskId}/approve/${approve}/`, {
      status: "approved",
    });
  },
  deleteComment: (id: number) => {
    return axios.delete(`/tasks/comment/${id}/`);
  },
  patchComment: (id: number, data: { text: string }) => {
    return axios.patch(`/tasks/comment/${id}/`, data);
  },
  getCommentFiles: (commentId: number) => {
    return axios.get<IListResponseData<IFile>>(`tasks/comment/${commentId}/file/`, { params: { limit: 100 } });
  },
  attachFileToComment: (commentId: number, files: File[]) => {
    const formData = new FormData();
    files.forEach((el) => {
      formData.append("file", el);
    });
    return axios.post(`tasks/comment/${commentId}/file/`, formData);
  },
  attachFileFromStorageToComment: (commentId: number, file: number) => {
    return axios.post(`/tasks/comment/${commentId}/file-from-disk/`, { file });
  },
  attachFileFromStorageToTask: (task: number, file: number) => {
    return axios.post(`/tasks/file-from-disk/`, { file, task });
  },
};
