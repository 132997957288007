import classNames from "classnames";
import React, { PropsWithChildren } from "react";

import styles from "./TaskLabel.module.scss";

interface IProps extends PropsWithChildren {
  className?: string;
}

const TaskLabel = ({ children, className }: IProps) => {
  return <div className={classNames(styles.root, className)}>{children}</div>;
};

export default TaskLabel;
