import cn from "classnames";
import React, { ReactNode } from "react";

import styles from "./RowGrid.module.scss";

interface IProps {
  children: ReactNode;
  percents: number[];
  gap?: string;
  className?: string;
}

const getColumns = (percents: number[], gap: string) => {
  return percents.reduce<string>((acc, cur, i, arr) => {
    const isHalfOffset = i === 0 || i === arr.length - 1;
    return acc + `calc(${cur}% - ${isHalfOffset ? `(${gap} * 0.5)` : gap})`;
  }, "");
};

const RowGrid: React.FC<IProps> = ({ children, percents, gap = "1rem", className }) => {
  return (
    <div className={cn(styles.root, className)} style={{ gap, gridTemplateColumns: getColumns(percents, gap) }}>
      {children}
    </div>
  );
};

export default RowGrid;
