import cn from "classnames";
import React, { MouseEventHandler, useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { downloadChartFile } from "redux/modules/common/building/manufacturing/thunks";
import { chartActionsSelector, chartScrollYearSelector, chartTabSelector } from "redux/modules/common/chart/selectors";
import { updateChartActions } from "redux/modules/common/chart/thunks";
import { CHART_TABS, ChartActions } from "redux/modules/common/chart/types";

import Icon from "_LEGACY/UI/_LEGACY_Icon/Icon";

import ButtonBase from "shared/ui/controls/ButtonBase";
import { Switch } from "shared/ui/inputs/Switch/Switch";

import { CHART_ACTIONS_VISIBILITY_BY_TAB } from "../../constants";
import { IRouterParamsWithObjectId } from "types/routerTypes";

import useOnClickOutside from "hooks/useOnClickOutside";

import diagramLinkingIcon from "images/diagramLinkingIcon.svg";
import arrowDownSelect from "images/icons/arrowDownSelect.svg";
import closeNotyItem from "images/icons/closeNotyItem.svg";
import { ReactComponent as DownloadIcon } from "images/icons/download_16.svg";
import diagramEditIcon from "images/icons/edit_outline_24.svg";

import styles from "./ChartActions.module.scss";

const ChartActionsComponent: React.FC = () => {
  const year = useSelector(chartScrollYearSelector);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const chartActions = useSelector(chartActionsSelector);
  const tab = useSelector(chartTabSelector);
  const { objectId: projectId } = useParams<IRouterParamsWithObjectId>();

  const containerRef = useRef<HTMLDivElement>(null);
  const onClose = useCallback(() => setIsOpen(false), []);

  useOnClickOutside(containerRef, onClose);

  const onActionChange = (name: ChartActions, value: boolean) => {
    dispatch(updateChartActions({ name, value }));
  };

  const toggleOpen: MouseEventHandler = useCallback((e) => {
    e.stopPropagation();
    setIsOpen((prevState) => !prevState);
  }, []);

  const canDownloadChart = tab === CHART_TABS.WORK && Boolean(projectId);
  const downloadButtonClickHandler = () => {
    downloadChartFile(projectId, year.toString());
  };

  const disableLinkingEditing = useCallback(() => onActionChange(ChartActions.linking_editing_enabled, false), []);
  const disablePlansEditing = useCallback(() => onActionChange(ChartActions.plans_editing_enabled, false), []);

  if (!tab || !chartActions) return null;

  const visibility = CHART_ACTIONS_VISIBILITY_BY_TAB[tab];
  /** Строгое сравнение с undefined специально.
  Если action === undefined, он не отображается в "Действиях с графиком"  */
  const checkSwitchVisibility = (key: ChartActions) => chartActions[key] !== undefined && visibility[key];

  const hasLinkingSwitch = checkSwitchVisibility(ChartActions.linking_editing_enabled);

  const hasShowExpendituresSwitch = checkSwitchVisibility(ChartActions.show_expenditures_in_tree);

  const hasPlansEditingSwitch = checkSwitchVisibility(ChartActions.plans_editing_enabled);

  // const hasOutOfEstimateSwitch = checkSwitchVisibility(ChartActions.out_of_estimate_enabled)

  const isLinkingEditingEnabled = hasLinkingSwitch && chartActions[ChartActions.linking_editing_enabled];

  const isPlansEditingEnabled = hasPlansEditingSwitch && chartActions[ChartActions.plans_editing_enabled];

  // const isOutOfEstimateEnabled = hasOutOfEstimateSwitch && chartActions[ChartActions.out_of_estimate_enabled];
  return (
    <div className={styles.diagramActions} ref={containerRef}>
      {isLinkingEditingEnabled && (
        <div className={styles.modeBubble} onClick={disableLinkingEditing}>
          <Icon icon={diagramLinkingIcon} className={styles.modeBubbleIcon} />
          <span>Работа со связями</span>
          <Icon icon={closeNotyItem} className={styles.modeBubbleCloseIcon} />
        </div>
      )}
      {isPlansEditingEnabled && (
        <div className={styles.modeBubble} onClick={disablePlansEditing}>
          <Icon icon={diagramEditIcon} className={styles.modeBubbleIcon} />
          <span>Работа с планом</span>
          <Icon icon={closeNotyItem} className={styles.modeBubbleCloseIcon} />
        </div>
      )}
      <div className={styles.actionsContainer}>
        <div className={cn(styles.head, { [styles.isOpen]: isOpen })} onClick={toggleOpen}>
          <span>Действия c графиком</span>
          <Icon icon={arrowDownSelect} className={cn(styles.arrow, { [styles.arrowReverse]: isOpen })} />
        </div>
        {isOpen && (
          <div className={styles.content}>
            <div className={styles.actionsGroup}>
              {hasLinkingSwitch && (
                <Switch
                  className={styles.action}
                  onChange={(e) => onActionChange(ChartActions.linking_editing_enabled, e.target.checked)}
                  checked={Boolean(chartActions[ChartActions.linking_editing_enabled])}
                  label={<span className={styles.actionLabel}>Работа со связями</span>}
                />
              )}
              {hasPlansEditingSwitch && (
                <Switch
                  className={styles.action}
                  onChange={(e) => onActionChange(ChartActions.plans_editing_enabled, e.target.checked)}
                  checked={Boolean(chartActions[ChartActions.plans_editing_enabled])}
                  label={<span className={styles.actionLabel}>Работа с планом</span>}
                />
              )}
              {hasShowExpendituresSwitch && (
                <Switch
                  className={styles.action}
                  onChange={(e) => onActionChange(ChartActions.show_expenditures_in_tree, e.target.checked)}
                  checked={Boolean(chartActions[ChartActions.show_expenditures_in_tree])}
                  label={<span className={styles.actionLabel}>Отображать расценки</span>}
                />
              )}
              <ButtonBase
                secondary
                className={styles.collapseAllButton}
                onClick={() =>
                  onActionChange(
                    ChartActions.default_tree_open_state,
                    !chartActions[ChartActions.default_tree_open_state]
                  )
                }
              >
                {chartActions.default_tree_open_state ? "Свернуть всё" : "Развернуть всё"}
              </ButtonBase>
            </div>
            {/*{hasOutOfEstimateSwitch && (<div className={styles.actionsGroup}>*/}
            {/*  <Switch*/}
            {/*    className={styles.action}*/}
            {/*    onChange={(e) => onActionChange(ChartActions.out_of_estimate_enabled, e.target.checked)}*/}
            {/*    checked={isOutOfEstimateEnabled}*/}
            {/*    label={<span className={styles.actionLabel}>Отображать по РД</span>}*/}
            {/*  />*/}
            {/*</div>)}*/}
            {canDownloadChart && (
              <div className={cn(styles.actionsGroup, styles.center)}>
                <ButtonBase primary medium className={styles.downloadButton} onClick={downloadButtonClickHandler}>
                  Скачать график
                  <DownloadIcon className={styles.downloadIcon} />
                </ButtonBase>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(ChartActionsComponent);
