import React, { Dispatch, ReactNode, SetStateAction, createContext, useContext } from "react";

type IMaterialsModalsContext = {
  setIsOpenModalImport: Dispatch<SetStateAction<boolean>>;
  isOpenModalImport: boolean;
  setIsOpenModalAddSection: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenModalAddSection: boolean;
  setIsOpenModalAddExpenditures: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenModalAddExpenditures: boolean;
};

const MaterialsContext = createContext<IMaterialsModalsContext>({
  setIsOpenModalImport: () => {},
  isOpenModalImport: false,
  setIsOpenModalAddSection: () => {},
  isOpenModalAddSection: false,
  setIsOpenModalAddExpenditures: () => {},
  isOpenModalAddExpenditures: false,
});

interface IProps extends IMaterialsModalsContext {
  children: ReactNode;
}

export const MaterialsModalsContext = ({ children, ...props }: IProps) => {
  return <MaterialsContext.Provider value={props}>{children}</MaterialsContext.Provider>;
};

export const useMaterialModalsContext = () => {
  const vals = useContext(MaterialsContext);

  return vals;
};
