import moment, { Moment } from "moment";

import { xarrowPropsType } from "react-xarrows";

import {
  IDiagramMaterialsData,
  IDiagramMimesData,
  IDiagramPlansMaterial,
  IDiagramPlansMim,
  IDiagramWeekMaterialsData,
  IDiagramWeekMimesData,
  IDiagramWeekWorksData,
  IDiagramWorksData,
  IIntervalBase,
  IMaterialInterval,
  IPlanInterval,
  IProcessedBranch,
  IProcessedBranchElement,
  IProject,
  IProjectMaterialsDataInterval,
  IProjectWorksDataInterval,
  ISpittingTreeElement,
  IWorkStatusPercentage,
  IntervalDaysSetType,
  ManufacturingTabsType,
  MaterialType,
  MaterialsMapType,
  WorkPlanMapType,
} from "./types";

import {
  accumulateIntervalCounts,
  applyProcessProjectIntervalFnIfExists,
  assignWorkStatusesCountToBunch,
  checkWorkInsideOfWork,
  generateMaterialItemKeys,
  getWeeksInYear,
  parseDateTimeToYYYYMMDD,
  processDiagramPlan,
  processDiagramSectionPlan,
  processDiagramWeekPlan,
  processDiagramWeekWork,
  processDiagramWork,
  processMaterialDataElement,
  processMaterialPlanDataElement,
  processMaterialWeekDataElement,
  processProjectMaterialInterval,
  pushMaterialItem,
} from "./utils";

export const MONTH_COLOR_MAP = {
  default: "#DEDEDE",
  confirmed: "#4FB1EB",
  completed: "",
  accepted: "#D5C9DF",
  paid: "#6FC79B",
  to_pay: "#6FC79B",
  todo: "#4FB1EB",
  defaultBorder: "#DEDEDE",
  confirmedBorder: "#D5C9DF",
  completedBorder: "#D5C9DF",
  acceptedBorder: "#D5C9DF",
  paidBorder: "#6FC79B",
  to_payBorder: "#6FC79B",
  todoBorder: "#4FB1EB",
  critical: "red",
};

export const REMARKS_COLOR = "#ef9d1c";

export const MONTH_IDS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const ACCEPTED_MATERIAL: MaterialType = "accepted";
export const ON_STOCK_MATERIAL: MaterialType = "on_stock";
export const PAYED_MATERIAL: MaterialType = "to_pay";
export const PLANS_MATERIAL: MaterialType = "plans";
export const PURCHASES_MATERIAL: MaterialType = "ordered";
export const STOCKLESS_MATERIAL: MaterialType = "issued";
export const TO_PAID_MATERIAL: MaterialType = "todo";

export enum DiagramFilters {
  confirmed_highlight = "confirmed_highlight",
  moderation_highlight = "moderation_highlight",
  canceled_highlight = "canceled_highlight",
  linking_enabled = "linking_enabled",
  remarks_visible = "remarks_visible",
  out_of_estimate_enabled = "out_of_estimate_enabled",
  linking_editing_enabled = "linking_editing_enabled",
  plans_editing_enabled = "plans_editing_enabled",
}

export enum SharedBraceStatuses {
  processed = "processed",
  canceled = "canceled",
  moderation = "moderation",
  confirmed = "confirmed",
}

export type SharedBraceStatusType = keyof typeof SharedBraceStatuses;

export const SharedBraceColors = {
  [SharedBraceStatuses.confirmed]: "#00C1AB",
  [SharedBraceStatuses.moderation]: "#EB7F00",
  [SharedBraceStatuses.canceled]: "#E8527A",
  [SharedBraceStatuses.processed]: "transparent",
};

export const MATERIALS_COLOR_MAP = {
  [ACCEPTED_MATERIAL]: "#6FC79B",
  [ON_STOCK_MATERIAL]: "#9870D0",
  [TO_PAID_MATERIAL]: "#4FB1EB",
  [PAYED_MATERIAL]: "#6fc79b",
  [PLANS_MATERIAL]: "#BABABA",
  [PURCHASES_MATERIAL]: "#AF9AD0",
  [STOCKLESS_MATERIAL]: "#9EDDDB",
  default: "transparent",
};

export const MATERIALS_STATUSES = {
  [PLANS_MATERIAL]: {
    name: "Запланирован",
  },
  [PURCHASES_MATERIAL]: {
    name: "Заказан",
  },
  [ON_STOCK_MATERIAL]: {
    name: "На складе",
  },
  [STOCKLESS_MATERIAL]: {
    name: "Выдан",
  },
  [ACCEPTED_MATERIAL]: {
    name: "Принят",
  },
  [TO_PAID_MATERIAL]: {
    name: "К оплате",
  },
  [PAYED_MATERIAL]: {
    name: "Оплачен",
  },
};

export enum RELATION_TYPES {
  oh = "oh",
  ho = "ho",
  oo = "oo",
  hh = "hh",
}

export type RelationType = keyof typeof RELATION_TYPES;

export enum INTERVAL_TYPES {
  work = "work",
  plan = "plan",
  material = "material",
  equipment = "equipment",
  machine = "machine",
  transport = "transport",
}

export type IntervalType = keyof typeof INTERVAL_TYPES;

export const INTERVAL_NAME_MAP = {
  [INTERVAL_TYPES.work]: "Работа:",
  [INTERVAL_TYPES.plan]: "План:",
  [INTERVAL_TYPES.material]: "Материал:",
  [INTERVAL_TYPES.equipment]: "Оборудование: ",
  [INTERVAL_TYPES.machine]: "Машины: ",
  [INTERVAL_TYPES.transport]: "Транспорт: ",
};

export enum WORK_STATUSES {
  to_pay = "to_pay",
  todo = "todo",
  confirmed = "confirmed",
  accepted = "accepted",
  completed = "completed",
  default = "default",
}

export type WorkStatusType = keyof typeof WORK_STATUSES;

export const TAILS_OFFSET_COMPENSATION_REM = 0.18;
export const TAILS_WIDTH_COMPENSATION_REM = 0.3;

export const WORKS_TAB_ID: ManufacturingTabsType = "work";
export const MATERIALS_TAB_ID: ManufacturingTabsType = "material";
export const MIMES_TAB_ID: ManufacturingTabsType = "mims";
export const RESOURCES_TAB_ID: ManufacturingTabsType = "resources";
export const EQUIPMENT_TAB_ID: ManufacturingTabsType = "equipment";

export const getWorkStatus = (percentage: IWorkStatusPercentage) => {
  const { completed, accepted, confirmed, todo, to_pay } = percentage || {};
  return Number(to_pay) > 0
    ? WORK_STATUSES.to_pay
    : Number(todo) > 0
    ? WORK_STATUSES.todo
    : Number(confirmed) > 0
    ? WORK_STATUSES.confirmed
    : Number(accepted) > 0
    ? WORK_STATUSES.accepted
    : Number(completed) > 0
    ? WORK_STATUSES.completed
    : WORK_STATUSES.default;
};

export const getHighestMaterialStatus = (data: MaterialType[]) => {
  return data.indexOf(PAYED_MATERIAL) !== -1
    ? PAYED_MATERIAL
    : data.indexOf(TO_PAID_MATERIAL) !== -1
    ? TO_PAID_MATERIAL
    : data.indexOf(ACCEPTED_MATERIAL) !== -1
    ? ACCEPTED_MATERIAL
    : data.indexOf(STOCKLESS_MATERIAL) !== -1
    ? STOCKLESS_MATERIAL
    : data.indexOf(ON_STOCK_MATERIAL) !== -1
    ? ON_STOCK_MATERIAL
    : data.indexOf(PURCHASES_MATERIAL) !== -1
    ? PURCHASES_MATERIAL
    : PLANS_MATERIAL;
};

export const WEEK_SCROLL_LENGTH = 7;
export const MT_REM = 2.81;
export const WEEKDAY_BUBBLE_WIDTH_REM = 1.6875;

export const INTERVAL_MAX_Z_INDEX = 89;

export const DIAGRAM_ARROW_CONFIG: Pick<
  xarrowPropsType,
  | "lineColor"
  | "headColor"
  | "strokeWidth"
  | "headSize"
  | "path"
  | "startAnchor"
  | "endAnchor"
  | "showTail"
  | "zIndex"
  | "gridBreak"
  | "arrowBodyProps"
  | "_extendSVGcanvas"
  | "gridRadius"
> = {
  lineColor: "#707070",
  headColor: "#707070",
  strokeWidth: 1.5,
  headSize: 4,
  path: "grid",
  gridBreak: "0%20",
  gridRadius: 10,
  arrowBodyProps: {
    strokeLinejoin: "round",
  },
  startAnchor: "right",
  endAnchor: "left",
  showTail: false,
  zIndex: 0,
  _extendSVGcanvas: 30,
};

export const HIGHLIGHT_COLOR = "#7061ea";
export const LINKS_DISPLAY_COLOR = "#9AD9FF";
export const LINKS_CRITICAL_COLOR = "#FF0000";

export enum DAYS_ABBRS {
  "ПН",
  "ВТ",
  "СР",
  "ЧТ",
  "ПТ",
  "СБ",
  "ВС",
}

export const enumerateDaysBetweenDates = (startDate: Moment, endDate: Moment) => {
  const now = startDate.clone();
  const dates = [];
  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format("YYYY-MM-DD"));
    now.add(1, "days");
  }
  return dates;
};

export const getLocalTree = (
  tree: ISpittingTreeElement[],
  maxDay: number,
  year: number,
  monthNumber: number,
  offsetLeft: number,
  workPlanMap: WorkPlanMapType,
  expenditureDepth: number
) => {
  const localTree: IProcessedBranch[] = [];
  let localObjectId = -1;
  tree.map((projectBranch, index) => {
    const withWorkOverlapCheck = (plan: IPlanInterval) => {
      let dates = enumerateDaysBetweenDates(moment(plan.start), moment(plan.end));
      const isBrace = dates.some((date) => {
        return (
          workPlanMap.get(date)?.[projectBranch.id]?.hasOngoingWorks ||
          !!workPlanMap.get(date)?.[projectBranch.id]?.works?.length
        );
      });
      return { ...plan, type: isBrace ? "brace" : "full" } as IPlanInterval;
    };

    if (projectBranch.lvl === 1) {
      localObjectId = projectBranch.id;
    }
    let elements: IProcessedBranchElement[] = [];
    let worksToUpdate: number[] = [];
    let localWorkIntervalEndDate = "";
    let localWorkIntervalStartDate = "";

    const joinWorkIntervalDates = () => {
      elements.forEach((el) => {
        if (el.work && !el.work["interval_start_date"] && worksToUpdate.indexOf(el.work?.data?.id) !== -1) {
          el.work["interval_start_date"] = localWorkIntervalStartDate;
          el.work["interval_end_date"] = localWorkIntervalEndDate;
        }
      });
      localWorkIntervalEndDate = "";
      localWorkIntervalStartDate = "";
      worksToUpdate = [];
    };

    let plansToUpdate: number[] = [];
    let localPlanIntervalEndDate = "";
    let localPlanIntervalStartDate = "";
    const joinPlanIntervalDates = () => {
      elements.forEach((el) => {
        if (el.plan && !el.plan["interval_start_date"] && plansToUpdate.indexOf(el.plan?.data?.id) !== -1) {
          el.plan["interval_start_date"] = localPlanIntervalStartDate;
          el.plan["interval_end_date"] = localPlanIntervalEndDate;
        }
      });
      localPlanIntervalEndDate = "";
      localPlanIntervalStartDate = "";
      plansToUpdate = [];
    };

    for (let i = 1; i <= maxDay; i++) {
      const localMoment = moment(`${year}-${monthNumber}-${i}`, "YYYY-MM-DD");
      const startDateKey = localMoment.format("YYYY-MM-DD");

      const currentDay = localMoment.day();
      const localWorksPlans = workPlanMap.get(startDateKey)?.[projectBranch.id];
      if (!!localWorksPlans) {
        const { plans, works } = localWorksPlans;
        works?.forEach((work) => {
          let workToAppend = { ...work };
          if (projectBranch.lvl === expenditureDepth) {
            const checkDates = enumerateDaysBetweenDates(moment(work.start), moment(work.end));
            checkDates.forEach((checkDate) => {
              const checkWorks = workPlanMap.get(checkDate)?.[projectBranch.id]?.works;
              checkWorks?.forEach((x) => {
                if (workToAppend.data?.id !== x.data?.id && checkWorkInsideOfWork(workToAppend, x)) {
                  workToAppend = {
                    ...workToAppend,
                    data: accumulateIntervalCounts(workToAppend.data, x.data),
                  };
                }
              });
            });
          }
          elements.push({
            day: i,
            weekend: currentDay === 0 || currentDay === 6,
            work: workToAppend,
            parentId: projectBranch.id,
          });
        });

        works?.forEach((work) => {
          const incrementedEndKey = moment(work.end).add(1, "days").format("YYYY-MM-DD");
          const hasNextWork = !!workPlanMap.get(incrementedEndKey)?.[projectBranch.id]?.works?.length;
          if (!localWorkIntervalStartDate && hasNextWork) {
            localWorkIntervalStartDate = work.start;
          }
          localWorkIntervalStartDate && worksToUpdate.push(work.data?.id);
          if (!hasNextWork && localWorkIntervalStartDate) {
            works?.forEach((x) => {
              if (x.start === work.start && x.end === work.end) {
                worksToUpdate.push(x.data?.id);
              }
            });
            localWorkIntervalEndDate = work.end;
            joinWorkIntervalDates();
          }
        });

        plans?.forEach((plan) => {
          elements.push({
            day: i,
            weekend: currentDay === 0 || currentDay === 6,
            plan: withWorkOverlapCheck(plan),
            parentId: projectBranch.id,
          });
        });

        plans?.forEach((plan) => {
          const incrementedEndKey = moment(plan.end).add(1, "days").format("YYYY-MM-DD");
          const hasNextPlan = !!workPlanMap.get(`${incrementedEndKey}`)?.[projectBranch.id]?.plans?.length;
          if (!localPlanIntervalStartDate && hasNextPlan) {
            localPlanIntervalStartDate = plan.start;
          }
          localPlanIntervalStartDate && plansToUpdate.push(plan.data.id);
          if (!hasNextPlan && localPlanIntervalStartDate) {
            plans?.forEach((x) => {
              if (x.start === plan.start && x.end === plan.end) {
                plansToUpdate.push(x.data?.id);
              }
            });
            localPlanIntervalEndDate = plan.end;
            joinPlanIntervalDates();
          }
        });
      }
    }
    if (!!elements.length)
      localTree.push({
        id: projectBranch.id,
        days: elements,
        lvl: projectBranch.lvl,
        collapsed: projectBranch.collapsed,
        offsetLeft: offsetLeft,
        objectId: localObjectId,
        year: year?.toString(),
        indexInTree: index,
      });
  });
  return localTree;
};

export const getLocalMaterialsTree = (
  tree: ISpittingTreeElement[],
  maxDay: number,
  year: number,
  monthNumber: number,
  offsetLeft: number,
  on_stock: MaterialsMapType,
  plans: MaterialsMapType,
  purchases: MaterialsMapType,
  stockless: MaterialsMapType,
  accepted: MaterialsMapType,
  to_paid: MaterialsMapType,
  payed: MaterialsMapType
) => {
  const localTree: IProcessedBranch[] = [];
  let localObjectId = -1;
  tree?.map((item, index) => {
    if (item.lvl === 1) {
      localObjectId = item.id;
    }

    const elements: IProcessedBranchElement[] = [];

    for (let i = 1; i <= maxDay; i++) {
      const currentMoment = moment(`${year}-${monthNumber}-${i}`, "YYYY-MM-DD");
      const currentDay = currentMoment.day();
      const startDateKey = currentMoment.format("YYYY-MM-DD");
      const itemKey = `${item.id}_${startDateKey}`;
      const data: Record<MaterialType, undefined | IMaterialInterval> = {
        to_pay: undefined,
        ordered: undefined,
        issued: undefined,
        todo: undefined,
        on_stock: undefined,
        plans: undefined,
        accepted: undefined,
      };

      const checkFn = (x: { name: MaterialType; map: MaterialsMapType }) => {
        const candidate = x.map?.get(itemKey);
        if (candidate) {
          data[x.name] = candidate;
        } else {
          delete data[x.name];
        }
      };

      [
        { name: ON_STOCK_MATERIAL, map: on_stock },
        { name: PLANS_MATERIAL, map: plans },
        { name: PURCHASES_MATERIAL, map: purchases },
        { name: STOCKLESS_MATERIAL, map: stockless },
        { name: ACCEPTED_MATERIAL, map: accepted },
        { name: TO_PAID_MATERIAL, map: to_paid },
        { name: PAYED_MATERIAL, map: payed },
      ].map(checkFn);

      if (Object.values(data).length > 0) {
        elements.push({
          day: i,
          weekend: currentDay === 0 || currentDay === 6,
          material: data,
        });
      }
    }
    if (!!elements.length)
      localTree.push({
        id: item.id,
        days: elements,
        lvl: item.lvl,
        collapsed: item.collapsed,
        offsetLeft: offsetLeft,
        objectId: localObjectId,
        year: year?.toString(),
        indexInTree: index,
      });
  });
  return localTree;
};

export const getWorksMaps = (
  data: IDiagramWorksData | IDiagramWeekWorksData,
  projects?: IProject[],
  projectInterval?: IProjectWorksDataInterval
) => {
  const workPlanMap: WorkPlanMapType = new Map();

  data?.sections?.map((section) => {
    /* @ts-ignore */
    section.works.map((work) => processDiagramWork({ work, workPlanMap, entityId: +section.id }));
  });

  data?.works?.map((work) =>
    processDiagramWork({
      /* @ts-ignore */
      work,
      workPlanMap,
      entityId: +work.expenditure_id,
    })
  );

  data?.groupWorks?.map((work) =>
    processDiagramWork({
      /* @ts-ignore */
      work,
      workPlanMap,
      entityId: +work.group?.id,
    })
  );

  if (projects?.length && projectInterval) {
    projects.map((project) => {
      // Сначала обработать работы, потом планы
      if (projectInterval.works?.[project.id]) {
        projectInterval.works[project.id].map((work) =>
          processDiagramWork({
            work,
            workPlanMap,
            entityId: +work.building_id,
          })
        );
      }
      if (projectInterval.plans?.[project.id]) {
        projectInterval.plans[project.id].map((plan) =>
          processDiagramPlan({
            plan,
            workPlanMap,
            entityId: +plan.building_id,
          })
        );
      }

      if (projectInterval.section_plans?.[project.id]) {
        projectInterval.section_plans[project.id].map((plan) =>
          processDiagramSectionPlan({
            plan,
            workPlanMap,
            entityId: +plan.building_id,
          })
        );
      }
    });
  }

  data?.plans?.map((plan) =>
    processDiagramPlan({
      /* @ts-ignore */
      plan,
      workPlanMap,
      entityId: +plan.expenditure_id,
    })
  );

  data?.groupPlans?.map((plan) =>
    processDiagramPlan({
      /* @ts-ignore */
      plan,
      workPlanMap,
      entityId: +plan.group?.id,
    })
  );

  data?.sections?.map((section) => {
    /* @ts-ignore */
    section.plans.map((plan) => processDiagramPlan({ plan, workPlanMap, entityId: +section.id }));
  });

  data?.planned_sections?.map((plan) => {
    processDiagramSectionPlan({
      plan,
      workPlanMap,
      entityId: +plan.section_id,
    });
    processDiagramSectionPlan({
      plan,
      workPlanMap,
      entityId: +plan.parent_id,
    });
  });

  return workPlanMap;
};

export const getWeekWorksMaps = ({
  data,
  projectInterval,
  projects,
  year,
}: {
  data: IDiagramWeekWorksData;
  year: number;
  projectInterval?: IProjectWorksDataInterval;
  projects?: IProject[];
}) => {
  const workPlanMap: WorkPlanMapType = new Map();

  data?.sections?.map((section) => {
    section.works.map((work) => processDiagramWeekWork({ work, year, workPlanMap, entityId: +section.id }));
  });

  data?.works?.forEach((work) =>
    processDiagramWeekWork({
      work,
      workPlanMap,
      year,
      entityId: work.expenditure_id,
    })
  );

  data?.groupWorks?.forEach((work) =>
    processDiagramWork({
      /* @ts-ignore */
      work,
      workPlanMap,
      entityId: work.group?.id,
    })
  );

  if (projects?.length && projectInterval) {
    projects.map((project) => {
      // Сначала обработать работы, потом планы
      if (projectInterval.works?.[project.id]) {
        projectInterval.works[project.id].map((work) =>
          processDiagramWork({
            work,
            workPlanMap,
            entityId: +work.building_id,
          })
        );
      }
      if (projectInterval.plans?.[project.id]) {
        projectInterval.plans[project.id].map((plan) =>
          processDiagramPlan({
            plan,
            workPlanMap,
            entityId: +plan.building_id,
          })
        );
      }

      if (projectInterval.section_plans?.[project.id]) {
        projectInterval.section_plans[project.id].map((plan) =>
          processDiagramSectionPlan({
            plan,
            workPlanMap,
            entityId: +plan.building_id,
          })
        );
      }
    });
  }

  data?.plans?.map((plan) =>
    processDiagramWeekPlan({
      plan,
      year,
      workPlanMap,
      entityId: +plan.expenditure_id,
    })
  );

  data?.groupPlans?.map((plan) =>
    processDiagramPlan({
      /* @ts-ignore */
      plan,
      workPlanMap,
      entityId: +plan.group?.id,
    })
  );

  data?.sections?.map((section) => {
    section.plans.map((plan) => processDiagramWeekPlan({ plan, year, workPlanMap, entityId: +section.id }));
  });

  data?.planned_sections?.map((plan) => {
    processDiagramSectionPlan({
      plan,
      workPlanMap,
      entityId: +plan.section_id,
    });
    processDiagramSectionPlan({
      plan,
      workPlanMap,
      entityId: +plan.parent_id,
    });
  });
  return workPlanMap;
};

export const getMaterialsMaps = (
  data: IDiagramMaterialsData,
  projects?: IProject[],
  projectInterval?: IProjectMaterialsDataInterval
) => {
  const acceptedMap: MaterialsMapType = new Map();
  const onStockMap: MaterialsMapType = new Map();
  const payedMap: MaterialsMapType = new Map();
  const toPaidMap: MaterialsMapType = new Map();
  const plansMap: MaterialsMapType = new Map();
  const purchasesMap: MaterialsMapType = new Map();
  const stocklessMap: MaterialsMapType = new Map();

  applyProcessProjectIntervalFnIfExists({
    projects,
    projectInterval,
    processProjectInterval: processProjectMaterialInterval,
    acceptedMap,
    payedMap,
    onStockMap,
    plansMap,
    purchasesMap,
    stocklessMap,
    toPaidMap,
  });

  const processDataElement = processMaterialDataElement;

  data?.accepted?.map((accept) =>
    accept.using_data?.map((acceptedUsing) => {
      const startDateKey = moment(parseDateTimeToYYYYMMDD(acceptedUsing.confirm_date)).format("YYYY-MM-DD");
      const { itemKey, itemSectionKey, itemParentKey } = generateMaterialItemKeys(accept, startDateKey);
      pushMaterialItem([itemKey, itemSectionKey, itemParentKey], acceptedMap, {
        days: 1,
        data: accept,
        actualItem: acceptedUsing,
        type: ACCEPTED_MATERIAL,
      });
    })
  );

  data?.payed?.map((pay) =>
    pay.using_data?.map((payedUsing) => processDataElement(payedUsing, pay, PAYED_MATERIAL, payedMap))
  );

  data?.to_paid?.map((toPay) =>
    toPay.using_data?.map((toPayUsing) => processDataElement(toPayUsing, toPay, TO_PAID_MATERIAL, toPaidMap))
  );

  data?.on_stock?.map((onStockItem) =>
    onStockItem.packingitems?.map((packingItem) =>
      processDataElement(packingItem, onStockItem, ON_STOCK_MATERIAL, onStockMap)
    )
  );

  data?.plans?.map((plan) => {
    plan.planned_works?.map((plan_work) => processMaterialPlanDataElement(plan, plan_work, PLANS_MATERIAL, plansMap));
  });

  data?.purchases?.map((purchase) =>
    purchase.orderrequest_set?.map((orderRequest) =>
      processDataElement(orderRequest, purchase, PURCHASES_MATERIAL, purchasesMap)
    )
  );

  data?.stockless?.map((stocklessItem) =>
    stocklessItem.using_data?.map((stocklessUsing) =>
      processDataElement(stocklessUsing, stocklessItem, STOCKLESS_MATERIAL, stocklessMap)
    )
  );

  return {
    accepted: acceptedMap,
    payed: payedMap,
    to_paid: toPaidMap,
    on_stock: onStockMap,
    plans: plansMap,
    purchases: purchasesMap,
    stockless: stocklessMap,
  };
};

export const getMimesMaps = (
  data: IDiagramMimesData,
  projects?: IProject[],
  projectInterval?: IProjectMaterialsDataInterval
) => {
  const acceptedMap: MaterialsMapType = new Map();
  const onStockMap: MaterialsMapType = new Map();
  const payedMap: MaterialsMapType = new Map();
  const toPaidMap: MaterialsMapType = new Map();
  const plansMap: MaterialsMapType = new Map();
  const purchasesMap: MaterialsMapType = new Map();
  const stocklessMap: MaterialsMapType = new Map();

  applyProcessProjectIntervalFnIfExists({
    projects,
    projectInterval,
    processProjectInterval: processProjectMaterialInterval,
    acceptedMap,
    payedMap,
    toPaidMap,
    onStockMap,
    plansMap,
    purchasesMap,
    stocklessMap,
  });

  const processDataElement = processMaterialDataElement;

  data?.accepted?.map((accept) =>
    accept.using_data?.map((acceptedUsing: IIntervalBase & { confirm_date?: string }) => {
      const startDateKey = moment(
        parseDateTimeToYYYYMMDD(acceptedUsing.start_at || acceptedUsing.confirm_date || "")
      ).format("YYYY-MM-DD");
      const { itemKey, itemSectionKey, itemParentKey } = generateMaterialItemKeys(accept, startDateKey);
      pushMaterialItem([itemKey, itemSectionKey, itemParentKey], acceptedMap, {
        days: 1,
        data: accept,
        actualItem: acceptedUsing,
        type: ACCEPTED_MATERIAL,
      });
    })
  );

  data?.to_paid?.map((toPay) =>
    toPay.using_data?.map((toPayUsing) => processDataElement(toPayUsing, toPay, TO_PAID_MATERIAL, toPaidMap))
  );

  data?.on_stock?.map((onStockItem) =>
    onStockItem.accepted?.map((packingItem /* @ts-ignore */) =>
      processDataElement(packingItem, onStockItem, ON_STOCK_MATERIAL, onStockMap)
    )
  );

  data?.plans?.map((plan: IDiagramPlansMim | IDiagramPlansMaterial) => {
    /* @ts-ignore */
    plan.planned_count_intervals?.map((plan_work) =>
      processMaterialPlanDataElement(plan, plan_work, PLANS_MATERIAL, plansMap)
    ); /* @ts-ignore */
    plan.planned_works?.map((plan_work) => processMaterialPlanDataElement(plan, plan_work, PLANS_MATERIAL, plansMap));
  });

  data?.purchases?.map((purchase) =>
    purchase.orderrequest_set?.map((orderRequest) =>
      processDataElement(orderRequest, purchase, PURCHASES_MATERIAL, purchasesMap)
    )
  );

  data?.stockless?.map((stocklessItem) =>
    stocklessItem.using_data?.map((stocklessUsing) =>
      processDataElement(stocklessUsing, stocklessItem, STOCKLESS_MATERIAL, stocklessMap)
    )
  );

  return {
    accepted: acceptedMap,
    payed: payedMap,
    on_stock: onStockMap,
    plans: plansMap,
    purchases: purchasesMap,
    stockless: stocklessMap,
    to_paid: toPaidMap,
  };
};

const weekCrossroads = (plan: IPlanInterval, workDays: IntervalDaysSetType, id: number | string) => {
  if (plan.days > 1) {
    const days = enumerateDaysBetweenDates(moment(plan.start), moment(plan.end));
    return !days.every((day) => !workDays.has(`${id}_${day}`));
  }

  return workDays.has(`${id}_${moment(plan.start).format("YYYY-MM-DD")}`);
};

export const getWeekWorksTree = ({
  tree,
  workPlanMap,
  startWeek,
  endWeek,
  year,
  expenditureDepth,
}: {
  tree: ISpittingTreeElement[];
  year: number | string;
  workPlanMap: WorkPlanMapType;
  startWeek: number;
  endWeek: number;
  expenditureDepth: number;
}) => {
  const localTree: IProcessedBranch[] = [];
  let localObjectId = -1;

  tree.map((projectBranch, index) => {
    const withWorkOverlapCheck = (plan: IPlanInterval) => {
      let dates = enumerateDaysBetweenDates(moment(plan.start), moment(plan.end));
      const isBrace = dates.some((date) => {
        return (
          workPlanMap.get(date)?.[projectBranch.id]?.hasOngoingWorks ||
          !!workPlanMap.get(date)?.[projectBranch.id]?.works?.length
        );
      });
      return { ...plan, type: isBrace ? "brace" : "full" } as IPlanInterval;
    };

    if (projectBranch.lvl === 1) {
      localObjectId = projectBranch.id;
    }
    let elements: IProcessedBranchElement[] = [];
    let worksToUpdate: number[] = [];
    let localWorkIntervalEndDate = "";
    let localWorkIntervalStartDate = "";

    const joinWorkIntervalDates = () => {
      elements.forEach((el) => {
        if (el.work && !el.work["interval_start_date"] && worksToUpdate.indexOf(el.work?.data?.id) !== -1) {
          el.work["interval_start_date"] = localWorkIntervalStartDate;
          el.work["interval_end_date"] = localWorkIntervalEndDate;
        }
      });
      localWorkIntervalEndDate = "";
      localWorkIntervalStartDate = "";
      worksToUpdate = [];
    };

    let plansToUpdate: number[] = [];
    let localPlanIntervalEndDate = "";
    let localPlanIntervalStartDate = "";
    const joinPlanIntervalDates = () => {
      elements.forEach((el) => {
        if (el.plan && !el.plan["interval_start_date"] && plansToUpdate.indexOf(el.plan?.data?.id) !== -1) {
          el.plan["interval_start_date"] = localPlanIntervalStartDate;
          el.plan["interval_end_date"] = localPlanIntervalEndDate;
        }
      });
      localPlanIntervalEndDate = "";
      localPlanIntervalStartDate = "";
      plansToUpdate = [];
    };

    for (let w = startWeek; w <= endWeek; w++) {
      for (let d = 1; d <= 7; d++) {
        const localMoment = moment().year(+year).week(w).day(d);

        const startDateKey = localMoment.format("YYYY-MM-DD");

        const localWorksPlans = workPlanMap.get(startDateKey)?.[projectBranch.id];
        if (!!localWorksPlans) {
          const { plans, works } = localWorksPlans;
          works?.forEach((work) => {
            let workToAppend = { ...work };
            if (projectBranch.lvl === expenditureDepth) {
              const checkDates = enumerateDaysBetweenDates(moment(work.start), moment(work.end));
              checkDates.forEach((checkDate) => {
                const checkWorks = workPlanMap.get(checkDate)?.[projectBranch.id]?.works;
                checkWorks?.forEach((x) => {
                  if (workToAppend.data?.id !== x.data.id && checkWorkInsideOfWork(workToAppend, x)) {
                    workToAppend = {
                      ...workToAppend,
                      data: accumulateIntervalCounts(workToAppend.data, x.data),
                    };
                  }
                });
              });
            }
            elements.push({
              day: d + (w - 1) * 7,
              weekend: d === 0 || d === 6,
              work: workToAppend,
              parentId: projectBranch.id,
            });
          });

          works?.forEach((work) => {
            const incrementedEndKey = moment(work.end).add(1, "days").format("YYYY-MM-DD");
            const hasNextWork = !!workPlanMap.get(incrementedEndKey)?.[projectBranch.id]?.works?.length;
            if (!localWorkIntervalStartDate && hasNextWork) {
              localWorkIntervalStartDate = work.start;
            }
            localWorkIntervalStartDate && worksToUpdate.push(work.data?.id);
            if (!hasNextWork && localWorkIntervalStartDate) {
              works?.forEach((x) => {
                if (x.start === work.start && x.end === work.end) {
                  worksToUpdate.push(x.data?.id);
                }
              });
              localWorkIntervalEndDate = work.end;
              joinWorkIntervalDates();
            }
          });

          plans?.forEach((plan) => {
            elements.push({
              day: d + (w - 1) * 7,
              weekend: d === 0 || d === 6,
              plan: withWorkOverlapCheck(plan),
              parentId: projectBranch.id,
            });
          });

          plans?.forEach((plan) => {
            const incrementedEndKey = moment(plan.end).add(1, "days").format("YYYY-MM-DD");
            const hasNextPlan = !!workPlanMap.get(`${incrementedEndKey}`)?.[projectBranch.id]?.plans?.length;
            if (!localPlanIntervalStartDate && hasNextPlan) {
              localPlanIntervalStartDate = plan.start;
            }
            localPlanIntervalStartDate && plansToUpdate.push(plan.data.id);
            if (!hasNextPlan && localPlanIntervalStartDate) {
              plans?.forEach((x) => {
                if (x.start === plan.start && x.end === plan.end) {
                  plansToUpdate.push(x.data?.id);
                }
              });
              localPlanIntervalEndDate = plan.end;
              joinPlanIntervalDates();
            }
          });
        }
      }
    }
    if (!!elements.length)
      localTree.push({
        id: projectBranch.id,
        days: elements,
        lvl: projectBranch.lvl,
        collapsed: projectBranch.collapsed,
        objectId: localObjectId,
        year: year?.toString(),
        indexInTree: index,
      });
  });
  return localTree;
};

export const getMaterialsWeekMaps = ({
  projects,
  projectInterval,
  year,
  data,
}: {
  projects?: IProject[];
  projectInterval?: IProjectMaterialsDataInterval;
  year: number | string;
  data: IDiagramWeekMaterialsData;
}) => {
  const acceptedMap = new Map();
  const onStockMap = new Map();
  const payedMap = new Map();
  const plansMap = new Map();
  const purchasesMap = new Map();
  const stocklessMap = new Map();
  const toPaidMap = new Map();

  applyProcessProjectIntervalFnIfExists({
    projects,
    projectInterval,
    processProjectInterval: processProjectMaterialInterval,
    acceptedMap,
    payedMap,
    onStockMap,
    plansMap,
    purchasesMap,
    stocklessMap,
    toPaidMap,
  });

  const processDataElement = processMaterialWeekDataElement(year);

  data?.accepted?.map((accept) =>
    accept.using_data?.map((acceptedUsing) => processDataElement(acceptedUsing, accept, ACCEPTED_MATERIAL, acceptedMap))
  );
  data?.payed?.map((pay) =>
    pay.using_data?.map((payedUsing) => processDataElement(payedUsing, pay, PAYED_MATERIAL, payedMap))
  );
  data?.to_paid?.map((toPay) =>
    toPay.using_data?.map((toPayUsing) => processDataElement(toPayUsing, toPay, TO_PAID_MATERIAL, toPaidMap))
  );
  data?.on_stock?.map((onStockItem) =>
    onStockItem.packingitems?.map((packingItem) =>
      processDataElement(packingItem, onStockItem, ON_STOCK_MATERIAL, onStockMap)
    )
  );
  data?.plans?.map((plan) => {
    plan.planned_works?.map((plan_work) => processDataElement(plan_work, plan, PLANS_MATERIAL, plansMap));
  });
  data?.purchases?.map((purchase) =>
    purchase.orderrequest_set?.map((orderRequest) =>
      processDataElement(orderRequest, purchase, PURCHASES_MATERIAL, purchasesMap)
    )
  );
  data?.stockless?.map((stocklessItem) =>
    stocklessItem.using_data?.map((stocklessUsing) =>
      processDataElement(stocklessUsing, stocklessItem, STOCKLESS_MATERIAL, stocklessMap)
    )
  );

  return {
    accepted: acceptedMap,
    payed: payedMap,
    to_paid: toPaidMap,
    on_stock: onStockMap,
    plans: plansMap,
    purchases: purchasesMap,
    stockless: stocklessMap,
  };
};

export const getMimesWeekMaps = ({
  projects,
  projectInterval,
  year,
  data,
}: {
  projects?: IProject[];
  projectInterval?: IProjectMaterialsDataInterval;
  year: number | string;
  data: IDiagramWeekMimesData | IDiagramWeekMaterialsData;
}) => {
  const acceptedMap = new Map();
  const onStockMap = new Map();
  const payedMap = new Map();
  const toPaidMap = new Map();
  const plansMap = new Map();
  const purchasesMap = new Map();
  const stocklessMap = new Map();

  applyProcessProjectIntervalFnIfExists({
    projects,
    projectInterval,
    processProjectInterval: processProjectMaterialInterval,
    acceptedMap,
    payedMap,
    toPaidMap,
    onStockMap,
    plansMap,
    purchasesMap,
    stocklessMap,
  });

  const processDataElement = processMaterialWeekDataElement(year);

  data?.accepted?.map((accept) =>
    accept.using_data?.map((acceptedUsing) => processDataElement(acceptedUsing, accept, ACCEPTED_MATERIAL, acceptedMap))
  );
  data?.to_paid?.map((toPay) =>
    toPay.using_data?.map((toPayUsing) => processDataElement(toPayUsing, toPay, TO_PAID_MATERIAL, toPaidMap))
  );
  data?.on_stock?.map((onStockItem /* @ts-ignore */) =>
    onStockItem.accepted?.map((packingItem) =>
      processDataElement(packingItem, onStockItem, ON_STOCK_MATERIAL, onStockMap)
    )
  );
  data?.plans?.map((plan) => {
    /* @ts-ignore */
    plan.planned_count_intervals?.map((plan_work) => processDataElement(plan_work, plan, PLANS_MATERIAL, plansMap));
    /* @ts-ignore */
    plan.planned_works?.map((plan_work) => processDataElement(plan_work, plan, PLANS_MATERIAL, plansMap));
  });
  data?.purchases?.map((purchase) =>
    purchase.orderrequest_set?.map((orderRequest) =>
      processDataElement(orderRequest, purchase, PURCHASES_MATERIAL, purchasesMap)
    )
  );
  data?.stockless?.map((stocklessItem) =>
    stocklessItem.using_data?.map((stocklessUsing) =>
      processDataElement(stocklessUsing, stocklessItem, STOCKLESS_MATERIAL, stocklessMap)
    )
  );

  return {
    accepted: acceptedMap,
    payed: payedMap,
    on_stock: onStockMap,
    plans: plansMap,
    purchases: purchasesMap,
    stockless: stocklessMap,
    to_paid: toPaidMap,
  };
};

export const getMaterialsWeekTree = ({
  tree,
  startWeek,
  endWeek,
  year,
  on_stock,
  plans,
  purchases,
  stockless,
  accepted,
  payed,
  to_paid,
}: {
  tree: ISpittingTreeElement[];
  startWeek: number;
  endWeek: number;
  year: number | string;
  on_stock: MaterialsMapType;
  plans: MaterialsMapType;
  purchases: MaterialsMapType;
  stockless: MaterialsMapType;
  accepted: MaterialsMapType;
  payed: MaterialsMapType;
  to_paid: MaterialsMapType;
}) => {
  const localTree: IProcessedBranch[] = [];
  let localObjectId: number | null = null;
  tree?.map((item, index) => {
    if (item.lvl === 1) {
      localObjectId = item.id;
    }
    const elements = [];
    for (let w = startWeek; w <= endWeek; w++) {
      for (let d = 1; d <= 7; d++) {
        const currentMoment = moment().year(+year).week(w).day(d);
        const startDateKey = currentMoment.format("YYYY-MM-DD");
        const itemKey = `${item.id}_${startDateKey}`;
        const data = {
          on_stock: undefined,
          plans: undefined,
          purchases: undefined,
          stockless: undefined,
          accepted: undefined,
          payed: undefined,
          to_paid: undefined,
        };

        const checkFn = (x: { name: MaterialType; map: MaterialsMapType }) => {
          const candidate = x.map?.get(itemKey);
          if (candidate) {
            /* @ts-ignore */
            data[x.name] = candidate;
          } else {
            /* @ts-ignore */
            delete data[x.name];
          }
        };

        [
          { name: ON_STOCK_MATERIAL, map: on_stock },
          { name: PLANS_MATERIAL, map: plans },
          { name: PURCHASES_MATERIAL, map: purchases },
          { name: STOCKLESS_MATERIAL, map: stockless },
          { name: ACCEPTED_MATERIAL, map: accepted },
          { name: TO_PAID_MATERIAL, map: to_paid },
          { name: PAYED_MATERIAL, map: payed },
        ].map(checkFn);

        if (Object.values(data).length > 0) {
          elements.push({
            day: d + (w - 1) * 7,
            weekend: d === 0 || d === 6,
            material: data,
          });
        }
      }
    }
    if (!!elements.length)
      localTree.push({
        id: item.id,
        days: elements,
        lvl: item.lvl,
        collapsed: item.collapsed,
        offsetLeft: 0,
        objectId: localObjectId,
        year: year?.toString(),
        indexInTree: index,
      });
  });
  return localTree;
};

export const getMonthsWorksTree = ({
  tree,
  workPlanMap,
  year,
}: {
  tree: ISpittingTreeElement[];
  year: number | string;
  workPlanMap: WorkPlanMapType;
}) => {
  const weeksInYear = getWeeksInYear(year);
  const localTree: IProcessedBranch[] = [];
  let localObjectId: null | number = null;
  tree.map((projectBranch, index) => {
    if (projectBranch.lvl === 1) {
      localObjectId = projectBranch.id;
    }
    const elements: IProcessedBranchElement[] = [];
    for (let w = 0; w < weeksInYear; w++) {
      const elementsCandidate = {
        worksBunch: {
          works: [],
          plans: [],
          statuses: {},
        },
        work: null,
        plan: null,
        day: w,
        parentId: projectBranch.id,
      };
      for (let d = 1; d <= 7; d++) {
        const startDateKey = moment().year(+year).week(w).day(d).format("YYYY-MM-DD");

        const itemKey = `${projectBranch.id}_${startDateKey}`;

        const localWorksPlans = workPlanMap.get(startDateKey)?.[projectBranch.id];
        if (!!localWorksPlans) {
          const { plans, works } = localWorksPlans;

          let checkWork = works?.[0];
          works?.forEach((work) => {
            /* @ts-ignore */
            elementsCandidate.worksBunch.works.push(work);
            if (work.days > checkWork?.days) checkWork = work;
          });
          let checkPlan = plans?.[0];
          plans?.forEach((plan) => {
            /* @ts-ignore */
            elementsCandidate.worksBunch.plans.push(plan);
            if (plan.days > checkPlan?.days) checkPlan = plan;
          });
          //@ts-ignore
          if (checkPlan && (!elementsCandidate.plan?.days || elementsCandidate.plan.originalDays < checkPlan.days))
            //TODO здесь и далее дни и недели конфликтуют в поле days, сделать универсальное поле юнит для размеров и отступов
            //@ts-ignore
            elementsCandidate.plan = {
              ...checkPlan,
              days: moment(checkPlan.data.end_at).diff(moment(checkPlan.data.start_at), "week") + 1,
              originalDays: checkPlan.days,
            };
          //@ts-ignore
          if (checkWork && (!elementsCandidate.work?.days || elementsCandidate.work.originalDays < checkWork.days))
            //@ts-ignore
            elementsCandidate.work = {
              ...checkWork,
              days: moment(checkWork.data.end_at).diff(moment(checkWork.data.start_at), "week") + 1,
            };
          if (elementsCandidate.plan && checkWork) {
            //@ts-ignore
            elementsCandidate.plan.type = "brace";
          }
          if (checkWork) {
            assignWorkStatusesCountToBunch({
              checkWork,
              statuses: elementsCandidate.worksBunch.statuses,
            }); //@ts-ignore
            elementsCandidate.worksBunch.works.push(checkWork);
          }
          //@ts-ignore
          elements.push(elementsCandidate);
        }
      }
    }
    if (!!elements.length)
      localTree.push({
        id: projectBranch.id,
        days: elements,
        lvl: projectBranch.lvl,
        collapsed: projectBranch.collapsed,
        objectId: localObjectId,
        year: year?.toString(),
        indexInTree: index,
      });
  });
  return localTree;
};
