import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import InputFiles from "../../../../UI/atoms/_TODO/InputFiles/InputFiles";

import { tasksV2Actions } from "../../model_v2/slice";
import { attachFilesToTaskFromStorage } from "../../model_v2/thunks";
import TaskFilesPopup, { IUploadedFile } from "./TaskFilesPopup/TaskFilesPopup";
import emptySvg from "./empty.svg";
import { useTypedSelector } from "app/store/typedUseSelector";
import FileViewer from "widgets/FileViewer/FileViewer";
import { IFileFromS3 } from "widgets/FilesFromStorage/FilesFromStorage";

import { TASK_MODAL_TYPES } from "../../constants";
import { ItaskFile } from "../../model/types";

import { useTaskFiles } from "../../hooks/useTaskFiles";

import downloadFileByUrl from "../../../../../utils/helpers/downloadFileByUrl";
import { stopEventPropagation } from "../../../../../utils/helpers/stopEventPropagation";

import styles from "./TaskFiles.module.scss";

interface ITaskFilesProps {
  files: ItaskFile[];
  taskId?: number;
  variant: TASK_MODAL_TYPES;
  task?: { building: number };
  popupClassName?: string;
}

const TaskFiles: React.FC<ITaskFilesProps> = ({ files, taskId, variant, task, popupClassName }) => {
  const {
    files: filesAddBuffer,
    uploadFilesHandler,
    deleteFileHandler,
    isPending,
  } = useTaskFiles({
    taskId,
    variant,
  });

  const preparedFiles = useMemo(() => files && files.map((x) => ({ ...x, name: x.file_name })), [files]);
  const selectedFiles = useTypedSelector((state) => state.tasksV2Reducer.persistedFilesFromStorage);
  const filesToShow = variant === TASK_MODAL_TYPES.ADD ? [...filesAddBuffer, ...selectedFiles] : preparedFiles;

  if (!files) return null;

  const canExtractFiles = variant !== TASK_MODAL_TYPES.ADD;
  const canUploadFiles = variant !== TASK_MODAL_TYPES.VIEW;
  const canDeleteFiles = variant !== TASK_MODAL_TYPES.VIEW;

  const getFilesHandler = (e: React.MouseEvent) => {
    stopEventPropagation(e);
    preparedFiles?.forEach((el) => {
      downloadFileByUrl(el.file, el.name || el.file_name);
    });
  };

  const serializedFiles = useMemo(() => {
    return filesToShow?.map((el) => ({
      ...el,
      name: (el as any).file_name,
      id: el.id ?? Math.random(),
      link: (el as any).link ?? (el as any).file,
      file: (el as any).file as string,
    }));
  }, [filesToShow]);

  const [fileViewerIndex, setFileViewerIndex] = useState(-1);

  const [isPopup, setIsPopup] = useState(false);
  const dispatch = useDispatch();

  const setSelectedFiles = (files: IFileFromS3[] | ((files: IFileFromS3[]) => IFileFromS3[])) => {
    if (variant === TASK_MODAL_TYPES.ADD) {
      if (Array.isArray(files)) {
        dispatch(tasksV2Actions.setPersistedFilesFromStorage(files));
      } else {
        dispatch(tasksV2Actions.setPersistedFilesFromStorage(files(selectedFiles)));
      }
    } else {
      if (Array.isArray(files)) {
        dispatch(
          attachFilesToTaskFromStorage(
            taskId!,
            files.map((el) => el.id)
          )
        );
      } else {
        dispatch(
          attachFilesToTaskFromStorage(
            taskId!,
            files(selectedFiles).map((el) => el.id)
          )
        );
      }
    }
  };

  const setFiles = (files: IUploadedFile[] | ((files: IUploadedFile[]) => IUploadedFile[])) => {
    if (Array.isArray(files)) {
      uploadFilesHandler(files);
    } else {
      uploadFilesHandler(files(filesAddBuffer));
    }
  };

  const selectedFilesIds = useMemo(() => {
    return selectedFiles.map((el) => el.id);
  }, [selectedFiles]);

  return (
    <div className={styles.taskFiles}>
      <div className={styles.filesHead}>
        <span>
          Список вложений: <b>{filesToShow?.length || "вложений нет"}</b>
        </span>
        {false && canExtractFiles && !!files.length && <div onClick={getFilesHandler}>Выгрузить вложения</div>}
        {canUploadFiles && <div onClick={() => setIsPopup(true)}>Добавить вложение</div>}
      </div>
      {!filesToShow.length && (
        <div className={styles.empty}>
          <img src={emptySvg} />
        </div>
      )}
      {!!filesToShow.length && (
        <InputFiles /* @ts-expect-error */
          value={filesToShow} /* @t2s-expect-error */
          //setValue={handleSetFiles} /* @ts-2expect-error */
          uploadFilesDirectly={(file: File) => uploadFilesHandler([file] as any)}
          removeFileDirectly={(id) => {
            setSelectedFiles((prev) => prev.filter((p) => p.id !== id));
            deleteFileHandler(id);
          }}
          permissions={{
            addFiles: false,
            deleteFiles: canDeleteFiles,
          }}
          canExpand={undefined}
          disabled={undefined}
          _TODO_onDirectlyClick={setFileViewerIndex}
          isLoading={isPending}
        />
      )}
      {variant !== TASK_MODAL_TYPES.ADD && (
        <FileViewer
          startIndex={fileViewerIndex}
          isOpen={fileViewerIndex !== -1}
          onClose={() => setFileViewerIndex(-1)}
          files={serializedFiles}
        />
      )}
      {isPopup && (
        <TaskFilesPopup
          task={task! as any}
          setFiles={setFiles}
          selectdFilesIds={selectedFilesIds}
          onClose={() => setIsPopup(false)}
          setFilesFromStorage={setSelectedFiles}
          popupClassName={popupClassName}
        />
      )}
    </div>
  );
};

export default TaskFiles;
