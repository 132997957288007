import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import {
  deleteSection,
  getSpecificationsSections,
  isLoadingSpecificationSelector,
  specificationSectionsSelector,
} from "../../../../../redux/modules/common/building/materials/specification";

import { useMaterialModalsContext } from "../../common/MaterialsModalsContext/MaterialsModalsContext";
import MaterialsCreateExpenditure from "../../widgets/MaterialsCreateExpenditure/MaterialsCreateExpenditure";
import MaterialsCreateSection from "../../widgets/MaterialsCreateSection/MaterialsCreateSection";
import SpecificationExpenditures from "./levels/SpecificationExpenditures/SpecificationExpenditures";
import SpecificationSections from "./levels/SpecificationSections/SpecificationSections";
import SpecificationSubSections from "./levels/SpecificationSubSections/SpecificationSubSections";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import ButtonBase from "shared/ui/controls/ButtonBase";
import ListPageHeaderTemplate from "shared/ui/layout/ListPageHeaderTemplate/ListPageHeaderTemplate";

import { SPECIFICATION } from "../../constants";

import { materialsUtils } from "../../utils";

import styles from "./Specification.module.scss";

const Specification = ({ objectId, canEdit = true, children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoading = useSelector(isLoadingSpecificationSelector);
  const specificationSections = useSelector(specificationSectionsSelector);
  const [isVisibleExpenditures, setIsVisibleExpenditures] = useState(false);
  const [isVisibleSubSection, setIsVisibleSubSection] = useState(false);
  /* const [positionEditModalOpen, setPositionEditModalOpen] = useState(false); */

  useEffect(() => {
    dispatch(getSpecificationsSections(objectId, SPECIFICATION));
  }, [objectId]);

  useEffect(() => {
    setWasAutoExpanded(false);
    setIsVisibleSubSection(false);
    setIsVisibleExpenditures(false);
  }, []);

  //TODO вынести в переиспользуемый хук
  const [wasAutoExpanded, setWasAutoExpanded] = useState(false);
  useEffect(() => {
    if (wasAutoExpanded || isLoading) return;
    if (specificationSections?.results?.length === 1) {
      setWasAutoExpanded(true);
      history.push(`/objects/${objectId}/materials/specification/${specificationSections?.results[0].id}`);
    }
  }, [isLoading, specificationSections.results, isVisibleSubSection, wasAutoExpanded, objectId]);

  /*  const closePositionEditModal = () => {
    dispatch(deleteSection(objectId));
    setPositionEditModalOpen(false);
  }; */

  const { setIsOpenModalImport } = useMaterialModalsContext();

  const { haveEditSpecificationPermission } = materialsUtils.useMaterialsPermissions();

  return (
    <>
      <TemplateBase>
        <div
          className={cn(styles.container, {
            [styles.active]: isVisibleExpenditures,
          })}
        >
          <ListPageHeaderTemplate className={styles.header}>
            {children}
            {haveEditSpecificationPermission && (
              <div className={styles.actionBlock}>
                <Switch>
                  <Route
                    path={`/objects/:objectId/materials/specification/`}
                    exact
                    render={() => <MaterialsCreateSection title="Добавить раздел" type="section" />}
                  />
                  <Route
                    path={`/objects/:objectId/materials/specification/:sectionId`}
                    exact
                    render={() => <MaterialsCreateSection title="Добавить подраздел" type="subSection" />}
                  />
                  <Route
                    path={`/objects/:objectId/materials/specification/:sectionId/:subSectionId`}
                    exact
                    render={() => <MaterialsCreateExpenditure />}
                  />
                </Switch>
                <ButtonBase
                  className={styles.importButton}
                  onClick={() => setIsOpenModalImport(true)}
                  medium
                  buttonPrimaryEmpty
                >
                  Импорт
                </ButtonBase>
              </div>
            )}
          </ListPageHeaderTemplate>
          <Switch>
            <Route
              path={`/objects/:objectId/materials/specification/`}
              exact
              render={() => <SpecificationSections canEdit={canEdit} />}
            />
            <Route
              ref={() => setWasAutoExpanded(true)}
              path={`/objects/:objectId/materials/specification/:sectionId`}
              exact
              render={() => <SpecificationSubSections canEdit={canEdit} />}
            />
            <Route
              ref={() => setWasAutoExpanded(true)}
              path={`/objects/:objectId/materials/specification/:sectionId/:subSectionId`}
              exact
              render={() => <SpecificationExpenditures canEdit={canEdit} />}
            />
            <Redirect to="/objects/:objectId/materials/specification/" />
          </Switch>
          {/* <BottomSliderMaterials isOpen={positionEditModalOpen} closeFunc={closePositionEditModal}>
            <Chapter objId={objectId} close={closePositionEditModal} />
          </BottomSliderMaterials> */}
        </div>
      </TemplateBase>
    </>
  );
};
export default Specification;
