import React, { FC } from "react";

import { CreationControls } from "./CreationControls/CreationControls";
import { EditingControls } from "./EditingControls/EditingControls";

import styles from "./Footer.module.scss";

interface IProps {
  isShowButtons?: boolean;
  haveUngroupGroupsPermission: boolean;
  haveEditGroupsPermission: boolean;
  isEditing: boolean;
  isCreation: boolean;
  hasChanges: boolean;
  isPending?: boolean;

  toggleEdit: () => void;
  handleCompleteEdit: () => void;
  handleUngroup?: () => void;
  onClose?: () => void;
  onCreate?: () => void;
}

export const Footer: FC<IProps> = ({
  isShowButtons = true,
  haveUngroupGroupsPermission,
  haveEditGroupsPermission,
  isEditing,
  isCreation,
  hasChanges,
  isPending,
  toggleEdit,
  handleCompleteEdit,
  handleUngroup,
  onClose = () => {},
  onCreate = () => {},
}) => {
  return (
    <footer className={styles.footer}>
      {isCreation ? (
        <CreationControls
          isShowButtons={isShowButtons}
          haveUngroupGroupsPermission={haveUngroupGroupsPermission}
          haveEditGroupsPermission={haveEditGroupsPermission}
          isEditing={isEditing}
          hasChanges={hasChanges}
          toggleEdit={toggleEdit}
          handleCompleteEdit={handleCompleteEdit}
          onClose={onClose}
          onCreate={onCreate}
          isPending={isPending}
        />
      ) : (
        <EditingControls
          isShowButtons={isShowButtons}
          haveUngroupGroupsPermission={haveUngroupGroupsPermission}
          haveEditGroupsPermission={haveEditGroupsPermission}
          isEditing={isEditing}
          hasChanges={hasChanges}
          toggleEdit={toggleEdit}
          handleCompleteEdit={handleCompleteEdit}
          handleUngroup={handleUngroup}
        />
      )}
    </footer>
  );
};
