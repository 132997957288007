import React, { useMemo } from "react";

import FormItem from "_LEGACY/UI/__TODO/LabeledItem/FormItem/FormItem";
import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";
import Expandable from "components/UI/atoms/_TODO/Expandable/Expandable";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { useGetDrawingsQuery } from "pages/Estimate/Blueprints/model/blueprintsQuery";

import FilesClipButton from "shared/ui/controls/FilesClipButton/FilesClipButton";
import FilesList from "shared/ui/dataDisplay/FilesList/FilesList";
import { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { IAxesInList } from "widgets/AxesAndMarks/model/types";

import styles from "./AxesAndMarksInfo.module.scss";

interface IProps {
  info: IAxesInList[];
  customHeader?: React.ReactNode;
  objectIdExternal?: string;
}

const AxesAndMarksInfo: React.FC<IProps> = ({ info, customHeader, objectIdExternal }) => {
  const item = info?.[0];
  const buildingId = objectIdExternal || useObjectId();

  const { data: drawingsData } = useGetDrawingsQuery({ buildingId } as any);
  const drawing = useMemo(() => {
    return drawingsData?.results?.find(({ id }) => +id === +item?.drawing);
  }, [drawingsData, item]);

  if (!item) {
    return null;
  }

  return (
    <Expandable
      additionalHeadline={
        <div className={styles.header}>
          <span className={styles.title}>Документация:</span>
          <span className={styles.edit}>{customHeader}</span>
        </div>
      }
    >
      <div className={styles.container}>
        <div className={styles.first}>
          <FormItem isDefaultHeight={false} title="Чертеж:">
            {drawing?.title}
          </FormItem>
          <FormItem isDefaultHeight={false} title="Номера листов:">
            {drawing?.sheet_numbers?.join(", ")}
          </FormItem>
          {!!item?.files?.length && (
            <FormItem isDefaultHeight={false} title="Вложения:">
              <PopoverOverlay
                placement="bottom-start"
                portalClassName={styles.filesPortal}
                openType="click"
                content={
                  <div className={styles.filesPopup}>
                    <FilesList files={item?.files as any} removeFile={() => {}} canRemove={false} />
                  </div>
                }
                popoverBorderColor="primary"
              >
                <FilesClipButton count={item?.files?.length} />
              </PopoverOverlay>
            </FormItem>
          )}
        </div>
        <div className={styles.second}>
          <div className={styles.row}>
            <label>Ось</label>
            <label>Отметка</label>
          </div>
          {item.items.map((el) => {
            const isSecondMark = el.marks[0] !== el.marks[1] && !!el.marks[1];
            return (
              <div className={styles.row} key={el.id}>
                <TableReusableCell className={styles.tableCell} isNoBreak>
                  {el.axis}
                </TableReusableCell>
                <div>{el.marks[0]}</div>
                {isSecondMark && <div className={styles.secondMark}>{el.marks[1]}</div>}
              </div>
            );
          })}
        </div>
      </div>
    </Expandable>
  );
};

export default AxesAndMarksInfo;
