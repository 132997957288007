import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { getTaskHistory } from "../../model/thunks";
import TaskHistoryItem from "./TaskHistoryItem";
import { useTypedSelector } from "app/store/typedUseSelector";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import MessageIcon from "images/icons/MessageIcon";

import styles from "./TaskHistory.module.scss";

interface IProps {
  taskId: number | string;
}

const TaskHistory: React.FC<IProps> = ({ taskId }) => {
  const dispatch = useDispatch();
  const dataForDisplay = useTypedSelector((state) => state.tasks.history)[taskId];
  const historyLoading = useTypedSelector((state) => state.tasks.historyLoading)[taskId];

  useEffect(() => {
    dispatch(getTaskHistory(taskId));
  }, [taskId]);

  return (
    <div className={styles.root}>
      {historyLoading && <Spinner />}
      {dataForDisplay?.map((el, i) => (
        <TaskHistoryItem key={el.history_date + i} item={el} />
      ))}
      {!historyLoading && !dataForDisplay?.length && <EmptyPlaceholder text="Действий нет" svgJsx={<MessageIcon />} />}
    </div>
  );
};

export default TaskHistory;
