import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import { IUploadedFile } from "../components/TaskFiles/TaskFilesPopup/TaskFilesPopup";
import { tasksApi } from "components/pages/Tasks/model/api";
import { getOneTask } from "components/pages/Tasks/model/thunks";

import { addFileIdToTaskModalBuffer, persistNewTaskFiles, unPersistNewTaskFiles } from "../model/actions";
import { taskModalSelector } from "../model/selectors";
import { tasksV2Actions } from "../model_v2/slice";
import { useTypedSelector } from "app/store/typedUseSelector";

import { TASK_MODAL_TYPES } from "../constants";
import { ItaskFile } from "../model/types";
import { IFile } from "types/interfaces/Files";

import { errorCatcher } from "utils/helpers/errorCatcher";

/* @ts-ignore */
const computeLocalId = (file: IFile): number => file.lastModified + file.size;

interface IuseTaskFiles {
  taskId?: number;
  variant: TASK_MODAL_TYPES;
}

export const useTaskFiles = ({ taskId, variant }: IuseTaskFiles) => {
  const dispatch = useDispatch();
  const files = useTypedSelector((state) => state.tasks.persistedNewTaskFiles);
  const { submission } = useSelector(taskModalSelector);

  const attachFilesToNewTask = useCallback(
    (newTaskId: number) => {
      tasksApi
        .postTaskFiles(
          /* @ts-ignore */
          files.map((fileWithId) => fileWithId.file),
          newTaskId
        )
        .then(() => {
          dispatch(getOneTask(newTaskId));
        });
    },
    [files]
  );

  const [isPending, setIsPending] = useState(false);

  const uploadFilesHandler = useCallback(
    (newFiles: IUploadedFile[]) => {
      if (variant === TASK_MODAL_TYPES.ADD) {
        dispatch(persistNewTaskFiles(newFiles));
      } else {
        if (!taskId) return;
        setIsPending(true);
        tasksApi
          .postTaskFiles(newFiles, taskId)
          .then(({ data }) => {
            dispatch(getOneTask(taskId));
            dispatch(tasksV2Actions.invalidateKey());
            data.data.forEach((file: ItaskFile) => compose(dispatch, addFileIdToTaskModalBuffer)(file.id));
          })
          .catch(errorCatcher)
          .finally(() => {
            setIsPending(false);
          });
      }
    },
    [variant, taskId]
  );

  const deleteFileHandler = useCallback(
    (fileId: number) => {
      if (variant === TASK_MODAL_TYPES.ADD) {
        dispatch(unPersistNewTaskFiles(fileId));
      } else {
        if (!taskId) return;
        setIsPending(true);
        tasksApi
          .deleteTaskFile(fileId)
          .then(() => {
            dispatch(getOneTask(taskId));
          })
          .catch(errorCatcher)
          .finally(() => {
            setIsPending(false);
          });
      }
    },
    [variant, taskId]
  );

  return {
    uploadFilesHandler,
    deleteFileHandler,
    computeLocalId,
    files,
    isPending,
  };
};
