import { useEffect, useMemo, useState } from "react";

import { SectionForRelations, getLiteEstimateSectionsTree } from "redux/modules/common/building/sections/sectionsApi";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

interface IProps {
  isOpen: boolean;
}

export const useSpecificationRelationsData = ({ isOpen }: IProps) => {
  const [tab, setTab] = useState("estimate");
  const [isLoading, setIsLoading] = useState(false);
  const objectId = useObjectId();
  const [data, setData] = useState<IStructWithRecursiveChildren[]>();

  useEffect(() => {
    if (!isOpen) return;
    setIsLoading(true);
    const reqs = [getLiteEstimateSectionsTree(objectId, 0), getLiteEstimateSectionsTree(objectId, 1)];
    Promise.all(reqs).then((res) => {
      const [outOfEstimateSections, estimateSections] = res as unknown as [SectionForRelations, SectionForRelations];
      setData(prepareSections([outOfEstimateSections, estimateSections]));
      setIsLoading(false);
    });
  }, [isOpen]);

  const tabs = useMemo(() => {
    return [
      { id: "estimate", text: "По смете", disabled: !data?.[1]?.children?.length },
      { id: "rd", text: "По проекту", disabled: !data?.[0]?.children?.length },
    ];
  }, [data]);

  useEffect(() => {
    const targetTab = tabs.find((el) => !el.disabled);
    if (!targetTab) return;
    setTab(targetTab?.id);
  }, [tabs]);

  const rdData = data?.[0].children;
  const estimateData = data?.[1]?.children;

  const actualData = tab === "rd" ? rdData : estimateData;

  return { isLoading, tabs, tab, setTab, actualData };
};

//TODO вынести в общие типы, добавить дженерик для возможности обогощения типа другими полями
export interface IStructWithRecursiveChildren {
  id: number;
  name: string;
  key: string;
  children?: IStructWithRecursiveChildren[];
}

function prepareSections(rawData: [SectionForRelations, SectionForRelations]) {
  const res: IStructWithRecursiveChildren[] = rawData.map((el, i) => {
    return {
      id: el.id,
      name: i === 0 ? "Вне Сметы" : "По смете",
      key: i === 0 ? "Вне Сметы" : "По смете",
      children: el.sections.map((sect) => ({
        id: sect.id,
        name: sect.name,
        key: `section_${sect.id}`,
        children: sect.subsections.map((sub) => ({
          id: sub.id,
          name: sub.name,
          key: `subsection_${sub.id}`,
          children: sub.expenditures?.map((exp) => ({
            id: exp.id,
            name: exp.name,
            key: `expenditure_${exp.id}`,
          })),
        })),
      })),
    };
  });

  return res;
}
