import classNames from "classnames";
import React, { FC, useEffect, useRef } from "react";

import ReactMarkdown from "markdown-to-jsx";
import Draggable, { DraggableData, DraggableEventHandler } from "react-draggable";
import Portal from "shared/ui/atoms/Portal";
import ButtonBack from "shared/ui/controls/ButtonBack/ButtonBack";
import ButtonBase from "shared/ui/controls/ButtonBase";

import { ILearningItem } from "../../model/types";

import { useSyncVideoPlayersContext } from "../../hooks/useSyncVideoPlayers";
import { useChatPosition } from "widgets/Chat/hooks/useChatPosition";

import CrossIcon from "images/icons/CrossIcon";

import styles from "./LearningVIdeoModal.module.scss";

interface IProps {
  item?: ILearningItem;
  isOpen: boolean;
  onClose: () => void;
  onOpenLearning: () => void;
}

const LearningVideoModal: FC<IProps> = ({ isOpen, onClose, item, onOpenLearning }) => {
  const { position, handleDrag } = useChatPosition({
    x: +(localStorage.getItem("learningVideoX") ?? 0),
    y: +(localStorage.getItem("learningVideoY") ?? 0),
  });

  const onDrag: DraggableEventHandler = (e, data) => {
    localStorage.setItem("learningVideoX", String(data.x));
    localStorage.setItem("learningVideoY", String(data.y));
    handleDrag(e, data);
  };

  const { addPlayer, removePlayer } = useSyncVideoPlayersContext();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (isOpen) {
      addPlayer?.(videoRef.current!);
    }
    return () => {
      if (isOpen) {
        removePlayer?.(videoRef.current!);
      }
    };
  }, [isOpen]);

  //if (!isOpen) return null;

  return (
    <Portal>
      <div className={classNames(styles.root, !isOpen && styles.hidden)}>
        <Draggable defaultPosition={{ x: 0, y: 0 }} position={position} onStop={onDrag}>
          <div className={styles.modal}>
            <div className={styles.handle}></div>
            <div className={styles.close} onClick={onClose}>
              <CrossIcon width={"1rem"} color={"#fff"} />
            </div>
            <div className={styles.videoWrapper}>
              <video src={item?.edu_data?.videos?.[0]?.url} controls ref={videoRef} />
            </div>
            <div className={styles.title}>{item?.edu_data.title}</div>
            <div className={styles.content}>
              <ReactMarkdown>{item?.edu_data?.description as string}</ReactMarkdown>
            </div>
            <div className={styles.controls}>
              <ButtonBase primary onClick={onOpenLearning}>
                Продолжить в шторке заданий
              </ButtonBase>
            </div>
          </div>
        </Draggable>
      </div>
    </Portal>
  );
};

export default LearningVideoModal;
