import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { configureChart, getChartStatuses } from "redux/modules/common/chart/thunks";

import { useUrlModule } from "utils/hooks/useUrlModule";

export const useChartConfig = () => {
  const dispatch = useDispatch();
  const module = useUrlModule();

  useEffect(() => {
    dispatch(configureChart(module));
    dispatch(getChartStatuses());
  }, [module]);
};
