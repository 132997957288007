import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import { objectDetailSelector } from "../../../../../redux/modules/common/building/object/nowObject";
import {
  loadSections,
  loadSectionsWithConfirmedChildStatus,
  loadSectionsWithNewChildStatus,
  resetSectionsAction,
  sectionsSelector,
  sectionsWithConfirmedChildStatusSelector,
  sectionsWithNewChildStatusSelector,
} from "../../../../../redux/modules/common/building/sections/sections";
import { userSelector } from "redux/modules/_TODO/auth";

import { aggregationsInvalidateKeySelector } from "../../Aggregations/model/selectors";
import { handlerIndexesInvalidateKeySelector } from "../widgets/HandlerIndexes/model/selectors";

import { ESTIMATE_ITEM_STATUSES, ESTIMATE_STATES_IDS } from "../constants";

import { errorCatcher } from "../../../../../utils/helpers/errorCatcher";

userSelector;

const useGetSections = ({ buildingId, estimateStateId, sectionId, subsectionId, status, chapterId }) => {
  const dispatch = useDispatch();
  const sectionsWithNewChildStatus = useSelector(sectionsWithNewChildStatusSelector);
  const sectionsWithConfirmedChildStatus = useSelector(sectionsWithConfirmedChildStatusSelector);
  const sections = useSelector(sectionsSelector);
  const aggregationsInvalidateKey = useSelector(aggregationsInvalidateKeySelector);
  const indexesInvalidateKey = useSelector(handlerIndexesInvalidateKeySelector);

  const user = useSelector(userSelector);
  const building = useSelector(objectDetailSelector);
  const userIsResponsibleEmployee = user?.id === building?.responsible_estimate?.id;

  const consolidateStateWithoutChapter = estimateStateId === ESTIMATE_STATES_IDS.CONSOLIDATE && !chapterId;

  const [areLoading, setAreLoading] = useState(true);

  const requestParams = useMemo(() => {
    const result = { buildingId, estimateStateId, parentId: sectionId };
    if (estimateStateId === ESTIMATE_STATES_IDS.CONSOLIDATE) result.chapterId = chapterId;
    return result;
  }, [buildingId, chapterId, estimateStateId, sectionId]);

  const getSectionsByStatus = useCallback(async () => {
    if (status === ESTIMATE_ITEM_STATUSES.NEW) {
      await dispatch(loadSectionsWithNewChildStatus(requestParams));
    } else if (status === ESTIMATE_ITEM_STATUSES.CONFIRMED) {
      await dispatch(loadSectionsWithConfirmedChildStatus(requestParams));
    }
  }, [status, requestParams]);

  const getSections = useCallback(async () => {
    const estimateStateIsDraftOrProduction =
      estimateStateId === ESTIMATE_STATES_IDS.DRAFT || estimateStateId === ESTIMATE_STATES_IDS.PRODUCTION;

    if (estimateStateIsDraftOrProduction /* || !userIsResponsibleEmployee */) {
      await dispatch(loadSections(requestParams));
    } else if (status) {
      await getSectionsByStatus();
    } else {
      await dispatch(loadSectionsWithNewChildStatus(requestParams));
      await dispatch(loadSectionsWithConfirmedChildStatus(requestParams));
    }
  }, [
    estimateStateId,
    userIsResponsibleEmployee,
    status,
    requestParams,
    getSectionsByStatus,
    aggregationsInvalidateKey,
    indexesInvalidateKey,
  ]);

  const notThrowedGetSections = useCallback(() => {
    return getSections().catch(errorCatcher);
  }, [getSections]);

  const resetStates = useCallback(() => {
    setAreLoading(true);
    dispatch(resetSectionsAction());
  }, []);

  const allSections = useMemo(() => {
    if (sections) return sections;

    const result = [];
    if (sectionsWithConfirmedChildStatus) result.push(...sectionsWithConfirmedChildStatus);
    if (sectionsWithNewChildStatus) result.push(...sectionsWithNewChildStatus);
    return result;
  }, [sectionsWithNewChildStatus, sectionsWithConfirmedChildStatus, sections]);

  const cachedSectionId = useRef(-1);

  useEffect(() => {
    if (subsectionId || !estimateStateId || !user || !building || consolidateStateWithoutChapter) return;
    if (+sectionId !== +cachedSectionId.current) {
      resetStates();
    }
    cachedSectionId.current = sectionId;
    getSections()
      .then(() => setAreLoading(false))
      .catch((e) => {
        if (!e instanceof axios.Cancel) errorCatcher(e);
      });
  }, [
    sectionId,
    status,
    estimateStateId,
    buildingId,
    subsectionId,
    building,
    chapterId,
    user,
    aggregationsInvalidateKey,
    indexesInvalidateKey,
  ]);

  return { areLoading, refreshSections: notThrowedGetSections, allSections };
};

export default useGetSections;
