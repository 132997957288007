import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";

import { chartViewModeSelector } from "redux/modules/common/chart/selectors";
import { CHART_VIEW_MODE } from "redux/modules/common/chart/types";

import { useRem } from "components/pages/Manufacturing/hooks/useRem";

import { useChartUnitMultiplier } from "./useChartUnitMultiplier";

export const YEAR_SELECTED_MONTHS_COUNT_DEFAULT = 6;
const AVERAGE_WEEKS_IN_MONTH = 4.345;

export const useChartYearSelectedMonths = ({ contentRef }: { contentRef: React.RefObject<HTMLElement> }) => {
  const unitMultiplier = useChartUnitMultiplier();
  const chartViewMode = useSelector(chartViewModeSelector);
  const { REM } = useRem();

  const [yearSelectedMonths, setYearSelectedMonths] = useState(YEAR_SELECTED_MONTHS_COUNT_DEFAULT);

  useLayoutEffect(() => {
    if (chartViewMode !== CHART_VIEW_MODE.MONTHS || !contentRef?.current) return;
    // 35% занимает дерево расценок, такой же отступ у IntersectionObserver скролла графика
    const visibleMonthsWidthPx = contentRef.current.clientWidth * 0.65;
    setYearSelectedMonths(
      Math.round(visibleMonthsWidthPx / (AVERAGE_WEEKS_IN_MONTH * unitMultiplier * REM)) ||
        YEAR_SELECTED_MONTHS_COUNT_DEFAULT
    );
  }, [unitMultiplier, REM, contentRef?.current, chartViewMode]);

  return { yearSelectedMonths, setYearSelectedMonths };
};
