import React, { useMemo, useRef, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";

import { addOutOfEstimateExpendituresList } from "redux/modules/common/building/sections/sections";

import ExpenditureFormRow from "components/UI/_TODO/ExpenditureForm/ExpenditureFormRow";

import { EXPENDITURE_TYPES } from "../../../../../../constants/constant";
import { OUT_OF_ESTIMATE_REQUIRED_FIELDS } from "../../constants";
import { IHelpExpanditure } from "../../widgets/AutoCompleteOutOfEstimate/model/types";

import {
  IAddExpenditureListRawValues,
  getInitialManualExpenditureFormAfterAutoComplete,
  prepareRawExpendituresList,
} from "./utils";

import styles from "./AddExpenditure.module.scss";

interface IProps {
  buildingId: number;
  sectionId: number;
  isOutOfEstimate: boolean;
  tempId: number;
  onDelete: (id: number) => void;
  isWithNumberVariant?: boolean;
}

const AddOneExpenditure: React.FC<IProps> = ({
  onDelete,
  tempId,
  isOutOfEstimate,
  sectionId,
  buildingId,
  isWithNumberVariant = true,
}) => {
  const dispatch = useDispatch();
  const formRef = useRef<HTMLFormElement>(null);

  const deleteHandler = () => {
    onDelete(tempId);
  };

  const submit = (values: any) => {
    if (isOutOfEstimate) {
      deleteHandler();
      dispatch(addOutOfEstimateExpendituresList(buildingId, prepareRawExpendituresList(values, materials, sectionId)));
      return;
    }
  };

  const [materials, setMaterials] = useState<number[]>([]);

  const [selectedWork, setSelectedWork] = useState<IHelpExpanditure>();
  const initialValues = useMemo(() => {
    if (!selectedWork) {
      return { expenditure_type: EXPENDITURE_TYPES.WORK };
    } else {
      const [formVals] = getInitialManualExpenditureFormAfterAutoComplete(selectedWork);
      return formVals;
    }
  }, [selectedWork]);

  const onSelectWork = (work: IHelpExpanditure) => {
    const [_, prodIds] = getInitialManualExpenditureFormAfterAutoComplete(work);
    setSelectedWork(work);
    setMaterials(prodIds ?? []);
  };

  return (
    <Form
      onSubmit={submit}
      key={(initialValues as IAddExpenditureListRawValues)?.name}
      initialValues={initialValues}
      render={({ values, handleSubmit, form }) => {
        return (
          <form ref={formRef} onSubmit={handleSubmit} onReset={() => form.restart()}>
            <div className={styles.formWrapper}>
              <ExpenditureFormRow
                submit={submit}
                requiredFields={OUT_OF_ESTIMATE_REQUIRED_FIELDS}
                onCancel={deleteHandler}
                onAddNew={() => setMaterials((prev) => [...prev, Date.now()])}
                handleSubmitForm={handleSubmit}
                expType="work"
                onSelectExpenditure={onSelectWork}
                form={form}
              />
              {materials.map((el) => (
                <div key={el}>
                  <ExpenditureFormRow
                    submit={() => {}}
                    requiredFields={OUT_OF_ESTIMATE_REQUIRED_FIELDS}
                    onCancel={() => setMaterials((prev) => prev.filter((m) => m !== el))}
                    isWork={false}
                    id={el}
                    showLabels={false}
                    onSelectExpenditure={() => {}}
                    expType={(initialValues as IAddExpenditureListRawValues)[`id:${el}`]?.expenditure_type}
                    form={form}
                  />
                </div>
              ))}
            </div>
          </form>
        );
      }}
    />
  );
};

export default AddOneExpenditure;
