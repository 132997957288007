import moment, { Moment, isMoment } from "moment";
import { Dispatch, SetStateAction, useLayoutEffect, useState } from "react";

export const planFactUtils = {
  getRoute: (locationArray: string[]) => {
    const planFactIndex = locationArray.indexOf("plan-fact");
    const sectionIndex = planFactIndex + 2;
    const subSectionIndex = planFactIndex + 3;

    const tab = locationArray[planFactIndex + 1];

    const sectionId = !isNaN(+locationArray[sectionIndex]!) ? locationArray[sectionIndex] : undefined;
    const subSectionId = !isNaN(+locationArray[subSectionIndex]!) ? locationArray[subSectionIndex] : undefined;

    return [sectionId, subSectionId].filter(Boolean).join("/");
  },
  getTab: (locationArray: string[]) => {
    const planFactIndex = locationArray.indexOf("plan-fact");

    return locationArray[planFactIndex + 1];
  },
  getRouteIds: (locationArray: string[]) => {
    const planFactIndex = locationArray.indexOf("plan-fact");
    const sectionIndex = planFactIndex + 2;
    const subSectionIndex = planFactIndex + 3;

    const sectionId = !isNaN(+locationArray[sectionIndex]!) ? locationArray[sectionIndex] : undefined;
    const subSectionId = !isNaN(+locationArray[subSectionIndex]!) ? locationArray[subSectionIndex] : undefined;

    return { sectionId, subSectionId };
  },
  _TEMP_usePersistDate: () => {
    const [date, setDate] = useState<Moment>(moment());

    /* useLayoutEffect(() => {
      const persistedDate = sessionStorage.getItem("planFactDate");
      if (persistedDate) {
        setDateState(moment(persistedDate));
      }
    }, []);

    const setDate: Dispatch<SetStateAction<Moment>> = (value) => {
      if (isMoment(value)) {
        setDateState(value);
        sessionStorage.setItem("planFactDate", value.toString());
      }
      if (typeof value === "function") {
        const momentDate = value(date);
        sessionStorage.setItem("planFactDate", momentDate.toString());
        setDateState(date);
      }
    }; */

    return {
      date,
      setDate,
    };
  },
};
