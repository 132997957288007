import cn from "classnames";
import moment from "moment";
import React, { useCallback, useMemo } from "react";

import { SIZES } from "../../../../components/UI/atoms/Select";

import YearSelect from "../YearSelect/YearSelect";
import MonthSlider from "./MonthsSlider/MonthsSlider";

import styles from "./MonthsYearsSlider.module.scss";

const DEFAULT_DATE = moment();

interface IProps {
  date: moment.Moment;
  onChange: (date: moment.Moment) => void;
  className?: string;
  selectingMonthsCount?: number;
  onDirectlyClickLeftArrow?: () => void;
  onDirectlyClickRightArrow?: () => void;
  monthsCount?: number;
  variant?: "default" | "display";
  disabled?: boolean;
  maxYear?: number;
  minYear?: number;
}

function MonthsYearsSlider({
  date = DEFAULT_DATE,
  onChange,
  className,
  selectingMonthsCount = 1,
  onDirectlyClickLeftArrow,
  onDirectlyClickRightArrow,
  monthsCount = 12,
  variant = "default",
  disabled,
  maxYear,
  minYear,
}: IProps) {
  const year = date.year();
  const monthIndex = date.month();

  const changeDate = useCallback(
    (year: number, monthIndex: number) => onChange(moment(new Date(year, monthIndex, 1))),
    [onChange]
  );

  const onChangeYear = useCallback(
    (changedYear: number) => {
      changeDate(changedYear, monthIndex);
    },
    [monthIndex, changeDate]
  );

  const startMoment = useMemo(() => moment(date), [date]);

  const endMoment = useMemo(
    () => moment(date).add(selectingMonthsCount, "months").endOf("month"),
    [date, selectingMonthsCount]
  );

  return (
    <div className={cn(styles.container, className)}>
      {/* @ts-ignore */}
      {variant === "display" && (
        <div className={styles.yearDisplay}>
          {startMoment.isSame(endMoment, "year")
            ? startMoment.format("YYYY")
            : `${startMoment.format("YY")} - ${endMoment.format("YY")}`}
        </div>
      )}
      {variant === "default" && (
        <YearSelect
          size={SIZES.SMALL}
          value={year}
          // @ts-ignore
          onChange={onChangeYear}
          disabled={disabled}
          maxYear={maxYear}
          minYear={minYear}
        />
      )}
      <MonthSlider
        date={date}
        changeDate={changeDate}
        selectingMonthsCount={selectingMonthsCount}
        onDirectlyClickLeftArrow={onDirectlyClickLeftArrow}
        onDirectlyClickRightArrow={onDirectlyClickRightArrow}
        monthsCount={monthsCount}
        disabled={disabled || variant === "display"}
      />
    </div>
  );
}

export default React.memo(MonthsYearsSlider);
