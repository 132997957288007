import cn from "classnames";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";
import { approveTask } from "components/pages/Tasks/model/thunks";
import { ICertainTaskResponse, ITaskApprover } from "components/pages/Tasks/model/types";
import { tasksV2Actions } from "components/pages/Tasks/model_v2/slice";

import { ReactComponent as AcceptedIcon } from "./accepted.svg";
import { ReactComponent as CalendarIcon } from "./calendar.svg";
import { ReactComponent as ClockIcon } from "./clock.svg";
import { ReactComponent as PendingIcon } from "./pending.svg";
import { useTypedSelector } from "app/store/typedUseSelector";
import ButtonBase from "shared/ui/controls/ButtonBase";
import ApprovalBar from "shared/ui/dataDisplay/ApprovalBar/ApprovalBar";
import Avatar from "shared/ui/dataDisplay/Avatar/Avatar";
import { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import getShortFullName from "utils/formatters/getShortFullName";
import { generateStorageKey } from "utils/helpers/generateStorageKey";
import { getFullNameString } from "utils/helpers/helpers";

import styles from "./TaskModalStatus.module.scss";

interface IProps {
  task?: ICertainTaskResponse;
}

const TaskModalStatus = ({ task }: IProps) => {
  const dispatch = useDispatch();
  const approvers = task?.approves_data ?? [];
  const isEmpty = !approvers?.length;
  const { id: myId } = useTypedSelector((state) => state.auth.user);

  const myApprove = approvers.find((el) => el.approver.id === myId);
  const showSubmitBtn = !!myApprove && !myApprove?.approved_at;
  const showApproveDate = !!myApprove && myApprove?.approved_at;

  const allCount = approvers.length;
  const approvedCount = useMemo(() => {
    return approvers.filter((el) => el.approved_at).length;
  }, [approvers]);

  const approveId = useMemo(() => {
    return approvers?.find((approver) => +approver.approver.id === +myId)?.id;
  }, [approvers, myId]);

  const isApproved = approvedCount === allCount;

  useEffect(() => {
    dispatch(tasksV2Actions.setIsLoading({ status: false, key: generateStorageKey({ type: "approve" }) }));
  }, [task]);
  const isPending = useTypedSelector((state) => state.tasksV2Reducer.loadings)[generateStorageKey({ type: "approve" })];

  if (isEmpty) {
    return null;
  }

  return (
    <div className={styles.root}>
      <PopoverOverlay
        openType="hover"
        placement="bottom-start"
        content={
          <div className={styles.popup}>
            <div className={styles.head}>Согласования:</div>
            <div className={styles.content}>
              <div className={styles.subtitle}>
                Согласовано:
                <span>
                  {approvedCount}/{allCount}
                </span>
              </div>
              {approvers.map((el) => (
                <div key={el.id} className={styles.entry}>
                  <Avatar person={el.approver as any} />
                  <TableReusableCell isNoBreak className={styles.name} isFont13={false}>
                    {getShortFullName(el.approver as any)}
                  </TableReusableCell>
                  {!!el.approved_at ? <AcceptedIcon /> : <PendingIcon />}
                </div>
              ))}
            </div>

            {showSubmitBtn && (
              <div className={styles.controls}>
                <ButtonBase isLoading={isPending} primary onClick={() => dispatch(approveTask(task?.id!, approveId!))}>
                  Согласовать
                </ButtonBase>
              </div>
            )}
            {showApproveDate && !!myApprove?.approved_at && (
              <div className={styles.date}>
                {moment(myApprove.approved_at).format("DD/MM/YYYY")}
                <CalendarIcon />
                <div className={styles.divider}></div>
                {moment(myApprove.approved_at).format("HH:mm")}
                <ClockIcon />
              </div>
            )}
          </div>
        }
        popoverBorderColor="gray"
        portalClassName={styles.portal}
      >
        <div className={cn(styles.status, isApproved && styles.statusApproved)}>
          {isApproved ? <AcceptedIcon /> : <PendingIcon />}
          <div className={styles.count}>
            {approvedCount}/{allCount}
          </div>
          {isApproved ? "Согласовано" : "Ожидает согласования"}
        </div>
      </PopoverOverlay>
    </div>
  );
};

export default TaskModalStatus;
