import cn from "classnames";
import React, { ReactElement, ReactNode } from "react";

import { Placement, Popover2, Popover2InteractionKind } from "@blueprintjs/popover2";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

import styles from "./PopoverOverlay.module.scss";

export interface IPopoverProps {
  children: ReactNode;
  content: ReactElement;
  placement?: Placement;
  className?: string;
  popoverClassName?: string;
  portalClassName?: string;
  popoverBorderColor?: string | "default" | "primary";
  isDisabled?: boolean;
  isOpen?: boolean;
  openType?: Popover2InteractionKind;
  onInteraction?: (nextOpenState: boolean, e?: React.SyntheticEvent<HTMLElement>) => void;
  onOpen?: () => void;
  onClose?: () => void;
  isMinimal?: boolean;
  modifiers?: any;
  usePortal?: boolean;
  portalContainer?: HTMLElement | undefined;
}

const PopoverOverlay = React.forwardRef<HTMLElement, IPopoverProps>(
  (
    {
      isOpen,
      onClose,
      onInteraction,
      placement = "auto",
      children,
      content,
      className,
      popoverBorderColor = "default",
      popoverClassName,
      isDisabled = false,
      openType = "click",
      onOpen,
      portalClassName,
      isMinimal,
      modifiers,
      usePortal = true,
      portalContainer,
    },
    ref
  ) => {
    const onOpened = (popoverElement: HTMLElement) => {
      onOpen?.();
      // Специальные значения проставляются через классы
      if (
        popoverBorderColor &&
        popoverElement &&
        popoverBorderColor !== "default" &&
        popoverBorderColor !== "primary"
      ) {
        // Граница попапа
        popoverElement.style.borderColor = popoverBorderColor;
        // Цвет границы стрелки
        popoverElement.style.fill = popoverBorderColor;
      }
    };

    return (
      <Popover2
        onClose={onClose}
        popoverRef={ref}
        isOpen={isOpen}
        usePortal={usePortal}
        onInteraction={onInteraction}
        content={content}
        placement={placement}
        disabled={isDisabled}
        interactionKind={openType}
        rootBoundary={"viewport"}
        portalContainer={portalContainer}
        className={className}
        popoverClassName={cn(
          styles.popover,
          {
            [styles.borderPrimary]: popoverBorderColor === "primary",
            [styles.borderDefault]: popoverBorderColor === "default",
          },
          popoverClassName
        )}
        portalClassName={cn(styles.portal, portalClassName)}
        onOpened={onOpened}
        hoverOpenDelay={0}
        hoverCloseDelay={0}
        transitionDuration={0}
        enforceFocus={false}
        minimal={isMinimal}
        modifiers={modifiers}
      >
        {children}
      </Popover2>
    );
  }
);

export default React.memo(PopoverOverlay);
