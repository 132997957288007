import cn from "classnames";
import React, { KeyboardEventHandler, useCallback, useState } from "react";

import styles from "pages/Handler/ui/ProHandler/components/CreateSection/components/CreateSectionInput/CreateSectionInput.module.scss";

import Actions from "shared/ui/controls/Actions/Actions";

import CrossRoundedIcon from "images/icons/CrossBoldRoundedIcon";

interface ICreateSectionInputProps {
  close: () => void;
  onSubmit?: (name: string) => void;
  placeholder?: string;
  defaultValue?: string;
  className?: string;
}

const CreateSectionInput = ({
  close,
  onSubmit,
  placeholder,
  className,
  defaultValue = "",
}: ICreateSectionInputProps) => {
  const [title, setTitle] = useState(defaultValue);

  const submit = useCallback(() => {
    onSubmit?.(title);
    setTitle("");
    close();
  }, [close, onSubmit, title]);

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.key !== "Enter") return;
      onSubmit?.(title);
      setTitle("");
      close();
    },
    [close, onSubmit, title]
  );

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.blockTop}>
        <div className={styles.boxInput}>
          <span>*</span>
          <input
            placeholder={placeholder || "Введите наименование"}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onKeyDown={onKeyDown}
          />
        </div>
        {/* <div className={styles.boxBtn} onClick={close}>
          <CrossRoundedIcon width="14.4" color="#606060" />
        </div> */}
        <Actions canRemove canSave className={styles.actions} onRemoveDirectly={close} onSave={submit} />
      </div>
    </div>
  );
};

export default React.memo(CreateSectionInput);
