import React from "react";
import { Field } from "react-final-form";

import CheckboxFieldComponent from "_LEGACY/UI/CheckboxFieldComponent/CheckboxFieldComponent";
import Select from "components/UI/atoms/Select";

import MeasureSelect from "entities/MeasureSelect/MeasureSelect";
import { FormApi } from "final-form";
import Actions from "shared/ui/controls/Actions/Actions";
import InputBase, { VALUE_TYPES } from "shared/ui/inputs/InputBase";
import RowGrid from "shared/ui/layout/RowGrid/RowGrid";

import { materialsType } from "constants/constant";

import { composeFieldValidators, maxLength, mustBeNumber, required } from "utils/formHelpers/validations";

import styles from "./MaterialsCreateExpenditure.module.scss";

const EXPENDITURE_TYPES = [
  { name: "Материал", id: "material" },
  { name: "Транспорт", id: "transport" },
  { name: "Оборудование", id: "equipment" },
];

interface IProps {
  values: any;
  index: number;
  onDelete?: (index: number) => void;
  form: FormApi<Record<string, any>, Partial<Record<string, any>>>;
  isPending?: boolean;
}

const MaterialsCreateExpenditureForm = ({ values, index, onDelete, form, isPending }: IProps) => {
  const getName = getNameWithIndex(index);

  const onRemove = () => {
    const newValues = form.getState().values.items;

    form.change(
      "items",
      newValues.filter((_: unknown, i: number) => i !== index)
    );
    onDelete?.(index);
  };

  return (
    <div className={styles.form}>
      <div className={styles.row1}>
        <RowGrid percents={[50, 15, 15, 15, 5]}>
          <Field name={getName("name")} component={InputBase} label="Наименование позиции:" />
          <Field
            name={getName("measure")}
            validate={composeFieldValidators(required())}
            render={({ input }) => <MeasureSelect input={input} label={`Ед.изм.`} />}
            //isServices={isServices}
            //defaultValue={values?.measure}
          />
          <Field
            name={getName("count")}
            label={`Кол-во`}
            type="number"
            render={(props) => <InputBase {...props} valueType={VALUE_TYPES.NUMBER} />}
            validate={composeFieldValidators(mustBeNumber, required(), maxLength(15))}
          />
          <Field name={getName("price")} label={`Цена`} component={InputBase} type="number" validate={required()} />
          {index > 0 && <Actions canRemove onRemoveDirectly={onRemove} disabled={isPending} />}
        </RowGrid>
      </div>
      <RowGrid percents={[25, 25, 20, 20, 10]}>
        <Field
          name={getName("address")}
          label={`Производитель:`}
          component={InputBase}
          //validate={requiredFields.includes("address") ? required() : undefined}
        />

        <Field
          name={getName("number")}
          label={`Код:`}
          component={InputBase}
          //validate={requiredFields.includes("number") ? required() : undefined}
        />

        <Field
          name={getName("brand")}
          label={`Марка:`}
          component={InputBase}
          //validate={requiredFields.includes("brand") ? required() : undefined}
        />
        <Field
          name={getName("type")}
          label={`Тип:`}
          component={Select as any}
          options={materialsType}
          disabled={values.items[index]?.from_estimate}
          //validate={requiredFields.includes("type") ? required() : undefined}
        />
        <Field
          className={styles.checkbox}
          name={getName("from_estimate")}
          label={`Доп `}
          type="checkbox"
          component={CheckboxFieldComponent as any}
        />
      </RowGrid>
    </div>
  );
};

export default MaterialsCreateExpenditureForm;

function getNameWithIndex(index: number) {
  return (name: string) => {
    return `items.[${index}].${name}`;
  };
}
