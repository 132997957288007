import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  getSpecificationsSections,
  isLoadingSpecificationSelector,
  specificationSectionsSelector,
} from "redux/modules/common/building/materials/specification";

import Section from "../../components/Section";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { SPECIFICATION } from "components/pages/Materials/constants";

import Spinner from "shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import materialsIcon from "images/icons/navigation/materials.svg";

interface IProps {
  canEdit?: boolean;
}

const SpecificationSections = ({ canEdit }: IProps) => {
  const rootPath = useUrlModuleWithObjectId();
  const history = useHistory();
  const dispatch = useDispatch();
  const objectId = useObjectId();
  //TODO
  const specificationSections = useSelector(specificationSectionsSelector);
  const isLoading = useSelector(isLoadingSpecificationSelector);

  useEffect(() => {
    dispatch(getSpecificationsSections(objectId, SPECIFICATION));
  }, [objectId]);

  return (
    <div>
      {isLoading && <Spinner isFixed />}
      {!isLoading && specificationSections?.results && specificationSections?.results?.length === 0 && (
        <EmptyPlaceholder img={materialsIcon} />
      )}
      {specificationSections?.results?.map(
        //TODO
        (item: any) =>
          item.subsections_count > 0 && (
            <Section
              key={item.id}
              objectId={objectId}
              data={item}
              //TODO
              blockOpenSubSections={(item: any) => {
                //TODO
                history.push(`/${rootPath}/materials/specification/${item.id}`);
              }}
              canEdit={canEdit}
              count={item.products_count}
            />
          )
      )}
    </div>
  );
};

export default SpecificationSections;
