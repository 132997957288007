import React from "react";
import { useDispatch, useSelector } from "react-redux";

import VisibilityModal from "pages/Handler/ui/ProHandler/components/VisibilityModal/VisibilityModal";
import { checkedItemsSelector } from "pages/Handler/ui/SimpleHandler/model/selectors";
import { simpleHandlerActions } from "pages/Handler/ui/SimpleHandler/model/slice";

import styles from "./SimpleHandlerVisibilityModal.module.scss";

interface IProps {
  buildingId: string;
}

const SimpleHandlerVisibilityModal = ({ buildingId }: IProps) => {
  const dispatch = useDispatch();
  const checkedList = useSelector(checkedItemsSelector);

  return (
    <VisibilityModal
      buildingId={+buildingId}
      refreshSections={() => {
        dispatch(simpleHandlerActions.invalidate());
        dispatch(simpleHandlerActions.clearCheckedItems());
      }}
      checkedList={checkedList as any}
      className={styles.visibility}
    />
  );
};

export default SimpleHandlerVisibilityModal;
