import cn from "classnames";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import {
  analyticsInvalidateKeySelector,
  analyticsLoadingsSelector,
  analyticsProgressDataSelector,
} from "../../../../../redux/modules/common/analytics/selectors";
import { loadAnalyticsProgress } from "../../../../../redux/modules/common/analytics/thunks";

import DisplayNumber from "./components/DisplayNumber/DisplayNumber";

import { useTypedSelector } from "../../../../../app/store/typedUseSelector";
import Island, { IIslandElement } from "../Island/Island";

import { LOCALIZATION_CONFIG } from "../../../../../constants/localization";
import { ANALYTICS_PROGRESS_COLORS, ANALYTICS_PROGRESS_LABELS, generateFills, makeFill } from "./constants";

import { transformDigitToFinancial } from "../../../../../utils/formatters/transformDigitToFinancial";
import { percentsFormatter } from "../../../../../utils/percentsFormatter";

import commonStyles from "../../Analytics.module.scss";
import styles from "./AnalyticsProgress.module.scss";

export interface IAnalyticsProgressProps extends IIslandElement {}

const AnalyticsProgress: React.FC<IAnalyticsProgressProps> = ({ islandParentRef }) => {
  const dispatch = useDispatch();
  const { progress, total, plan, completed, accepted, todo, to_pay, days_until_end, invested } =
    useTypedSelector(analyticsProgressDataSelector);
  const isLoading = useTypedSelector(analyticsLoadingsSelector)["progress"];

  const invalidateKey = useTypedSelector(analyticsInvalidateKeySelector);

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(loadAnalyticsProgress(abortController.signal));
    return () => {
      abortController.abort();
    };
  }, [invalidateKey]);

  const fills = useMemo(
    () => generateFills(plan, completed, accepted, todo, to_pay, total),
    [plan, completed, accepted, todo, to_pay, total]
  );

  return (
    <Island
      islandParentRef={islandParentRef}
      heading={
        isLoading ? (
          <h3 className={cn(styles.heading, commonStyles.islandHeading)}>Прогресс выполнения</h3>
        ) : (
          <>
            <h3 className={cn(styles.heading, commonStyles.islandHeading)}>
              Прогресс выполнения: <b>{percentsFormatter(progress || 0)}</b>
            </h3>
            <h3 className={cn(styles.heading, commonStyles.islandHeading)}>
              Итого:{" "}
              <b>{transformDigitToFinancial(total, { withFloat: true, dropZeros: true, withCurrencySign: true })}</b>
            </h3>
          </>
        )
      }
      isLoading={isLoading}
      className={styles.progressIsland}
    >
      <div className={styles.content}>
        <div className={styles.scene}>
          <div className={styles.cube}>
            <div className={cn(styles.cubeFace, styles.cubeFaceFront)} style={{ backgroundImage: fills.front }} />
            <div className={cn(styles.cubeFace, styles.cubeFaceRight)} style={{ backgroundImage: fills.right }} />
            <div className={cn(styles.cubeFace, styles.cubeFaceTop)} style={{ backgroundImage: fills.top }} />
          </div>
        </div>
        <div className={styles.displayNumbers}>
          <DisplayNumber
            largeTitle={transformDigitToFinancial(plan, { withFloat: true, dropZeros: true })}
            smallTitle={LOCALIZATION_CONFIG.currency}
            subtitle={ANALYTICS_PROGRESS_LABELS.plan}
            markBackground={ANALYTICS_PROGRESS_COLORS.plan}
          />
          <DisplayNumber
            largeTitle={transformDigitToFinancial(invested ?? 0, { withFloat: true, dropZeros: true })}
            smallTitle={LOCALIZATION_CONFIG.currency}
            subtitle={ANALYTICS_PROGRESS_LABELS.invested}
            markBackground={ANALYTICS_PROGRESS_COLORS.invested}
          />
          <DisplayNumber
            largeTitle={transformDigitToFinancial(completed, { withFloat: true, dropZeros: true })}
            smallTitle={LOCALIZATION_CONFIG.currency}
            subtitle={ANALYTICS_PROGRESS_LABELS.completed}
            markBackground={ANALYTICS_PROGRESS_COLORS.completed}
          />
          <DisplayNumber
            largeTitle={transformDigitToFinancial(accepted, { withFloat: true, dropZeros: true })}
            smallTitle={LOCALIZATION_CONFIG.currency}
            subtitle={ANALYTICS_PROGRESS_LABELS.accepted}
            markBackground={ANALYTICS_PROGRESS_COLORS.accepted}
          />
          <DisplayNumber
            largeTitle={transformDigitToFinancial(todo, { withFloat: true, dropZeros: true })}
            smallTitle={LOCALIZATION_CONFIG.currency}
            subtitle={ANALYTICS_PROGRESS_LABELS.todo}
            markBackground={ANALYTICS_PROGRESS_COLORS.todo}
          />
          <DisplayNumber
            largeTitle={transformDigitToFinancial(to_pay, { withFloat: true, dropZeros: true })}
            smallTitle={LOCALIZATION_CONFIG.currency}
            subtitle={ANALYTICS_PROGRESS_LABELS.to_pay}
            markBackground={ANALYTICS_PROGRESS_COLORS.to_pay}
          />
          <div />
          <DisplayNumber
            largeTitle={days_until_end || "-"}
            smallTitle={"дней"}
            subtitle={"До завершения проекта"}
            markBackground={"transparent"}
          />
        </div>
      </div>
    </Island>
  );
};

export default React.memo(AnalyticsProgress);
