import { CHART_TREE_LVL } from "components/pages/Chart/constants";
import { IntervalType } from "components/pages/Manufacturing/constants";

import moment from "moment/moment";

import { IPostInTree, IProjectTreeResponse, IProjectWorkersTreeResponse } from "../building/manufacturing/types";
import {
  CHART_TABS,
  ChartStatusDetailed,
  ChartStatusDetails,
  ChartStatusRecord,
  ChartTreeMatcherType,
  ChartTreeUpdaterType,
  CheckpointKeys,
  IChartProcessedIntervals,
  IChartTree,
  INTERVAL_MAPPING_TYPES,
} from "./types";

import createDateDistanceTracker from "utils/dateDistanceTracker";
import intToRoman from "utils/formatters/intToRoman";

export type UpdateTreeParams = {
  matcher: ChartTreeMatcherType;
  updater: ChartTreeUpdaterType;
  continueTraversalCondition?: () => boolean;
  /** Обновление с определённым projectId ускрояет поиск нужного проекта */
  projectId: number;
};

export const updateTree = (
  rootNode: IChartTree,
  { projectId, matcher, updater, continueTraversalCondition }: UpdateTreeParams
) => {
  let isUpdated = false;
  const projectIdx = rootNode.children?.findIndex((t) => t.id == projectId);
  const rootNodeCopy = window.structuredClone(rootNode);
  const updateStack = [(projectIdx >= 0 && rootNodeCopy.children?.[projectIdx]) || rootNodeCopy];

  function update(currentTree: IChartTree) {
    if (!currentTree) return;
    if (matcher(currentTree)) {
      currentTree = updater(currentTree);
      isUpdated = true;
    }
    if (
      (continueTraversalCondition !== undefined ? continueTraversalCondition() : !isUpdated) &&
      currentTree.children?.length
    ) {
      for (let i = currentTree.children.length - 1; i >= 0; i--) {
        updateStack.push(currentTree.children[i]);
      }
    }
  }

  while (updateStack.length) {
    update(updateStack.pop());
  }

  return rootNodeCopy;
};

export const findChartTreeNode = (_id: string, tree: IChartTree) => {
  const visited = new Set<string>();
  const stack: IChartTree[] = [tree];

  while (stack.length) {
    const currentNode = stack.pop();
    if (!currentNode?._id || visited.has(currentNode?._id)) {
      continue;
    }
    visited.add(currentNode?._id);

    if (currentNode?._id === _id) {
      return currentNode;
    }

    if (currentNode?.children) {
      for (let i = currentNode.children.length - 1; i >= 0; i--) {
        stack.push(currentNode.children[i]);
      }
    }
  }

  return null;
};

export const treeTypesByTab = (tab: CHART_TABS): IntervalType[] => {
  switch (tab) {
    case CHART_TABS.WORK:
      return ["work"];
    case CHART_TABS.RESOURCES:
      return ["material", "equipment", "machine", "transport"];
    case CHART_TABS.MATERIALS:
      return ["material"];
    case CHART_TABS.EQUIPMENT:
      return ["equipment"];
    case CHART_TABS.MIM:
      return ["machine", "transport"];
    default:
      return [];
  }
};

export const makeTreeId = (id: number | string, lvl: number, extra?: string) =>
  extra ? `${id}_${lvl}_${extra}` : `${id}_${lvl}`;

const initProcessedIntervals: () => IChartProcessedIntervals = () => ({
  [INTERVAL_MAPPING_TYPES.PLAN_SECTIONS]: [],
  [INTERVAL_MAPPING_TYPES.PLAN_WORKS]: [],
  [INTERVAL_MAPPING_TYPES.FACT_WORKS]: [],
  [INTERVAL_MAPPING_TYPES.PLAN_RESOURCES]: [],
  [INTERVAL_MAPPING_TYPES.FACT_RESOURCES]: [],
});

export const patchTreeOnLoad = (tree: IProjectTreeResponse & IChartTree): void => {
  if (!tree) return;
  const dateTracker = createDateDistanceTracker();
  tree.lvl = CHART_TREE_LVL.PROJECT;
  // tree._parent = null;
  tree._id = makeTreeId(tree.id, tree.lvl);
  tree.projectId = tree.id;
  tree.isSection = true;
  tree.processedIntervals = initProcessedIntervals();
  tree.bubbledIntervals = initProcessedIntervals();
  // @ts-ignore
  tree.children = tree.sections;
  tree.children.forEach((section: IChartTree) => {
    section.lvl = CHART_TREE_LVL.LSR;
    section.parent = tree;
    section._id = makeTreeId(section.id, section.lvl);
    section.projectId = tree.id;
    section.isSection = true;
    section.processedIntervals = initProcessedIntervals();
    section.bubbledIntervals = initProcessedIntervals();
    if (section.plan_start && section.plan_end) {
      dateTracker.track(section.plan_start, section.plan_end);
    }
    // @ts-ignore
    section.children = section.subsections;
    section.children.forEach((subsection: IChartTree) => {
      subsection.lvl = CHART_TREE_LVL.SECTION;
      subsection.parent = section;
      subsection._id = makeTreeId(subsection.id, subsection.lvl);
      subsection.projectId = tree.id;
      subsection.isSection = true;
      subsection.processedIntervals = initProcessedIntervals();
      subsection.bubbledIntervals = initProcessedIntervals();
      if (subsection.plan_start && subsection.plan_end) {
        dateTracker.track(subsection.plan_start, subsection.plan_end);
      }
      // @ts-ignore
      subsection.expenditures.forEach((expenditure: IChartTree) => {
        expenditure.lvl = CHART_TREE_LVL.EXPENDITURE;
        expenditure.parent = subsection;
        expenditure._id = makeTreeId(expenditure.id, expenditure.lvl, "e");
        expenditure.projectId = tree.id;
        expenditure.isExpenditure = true;
        expenditure.processedIntervals = initProcessedIntervals();
        if (expenditure.plan_start && expenditure.plan_end) {
          dateTracker.track(expenditure.plan_start, expenditure.plan_end);
        }
        expenditure.children = [];
      });
      // @ts-ignore
      subsection.groups.forEach((group: IChartTree) => {
        group.lvl = CHART_TREE_LVL.EXPENDITURE;
        group.parent = subsection;
        group._id = makeTreeId(group.id, group.lvl, "g");
        group.isGroup = true;
        group.projectId = tree.id;
        group.processedIntervals = initProcessedIntervals();
        if (group.plan_start && group.plan_end) {
          dateTracker.track(group.plan_start, group.plan_end);
        }
        group.children = [];
      });
      // @ts-ignore
      subsection.children = subsection.expenditures.concat(subsection.groups);
    });
  });
  tree.datesDistance = dateTracker.getMaxDistance();
};

export const patchWorkersTreeOnLoad = (tree: IProjectWorkersTreeResponse & IChartTree): void => {
  if (!tree) return;
  const dateTracker = createDateDistanceTracker();
  tree.lvl = CHART_TREE_LVL.PROJECT;
  tree.id = tree.building_id;
  tree._id = makeTreeId(tree.id, tree.lvl);
  tree.projectId = tree.id;
  tree.isSection = true;
  tree.processedIntervals = initProcessedIntervals();
  tree.bubbledIntervals = initProcessedIntervals();
  // @ts-ignore
  tree.children = tree.posts;
  tree.children.forEach(
    // @ts-ignore
    (post: IPostInTree & IChartTree) => {
      post.lvl = CHART_TREE_LVL.LSR;
      post.parent = tree;
      post.id = Math.random();
      post._id = makeTreeId(post.id, post.lvl);
      post.projectId = tree.id;
      post.isSection = true;
      post.processedIntervals = initProcessedIntervals();
      post.bubbledIntervals = initProcessedIntervals();
      if (post.plan_start && post.plan_end) {
        dateTracker.track(post.plan_start, post.plan_end);
      }
      // @ts-ignore
      post.children = post.ranks;
      post.children.forEach(
        // @ts-ignore
        (rank: IRankInTree & IChartTree) => {
          rank.lvl = CHART_TREE_LVL.SECTION;
          rank.parent = post;
          rank.id = Math.random();
          rank.name = `Разряд ${intToRoman(rank.rank)}`;
          rank._id = makeTreeId(rank.id, rank.lvl);
          rank.projectId = tree.id;
          rank.isSection = true;
          rank.processedIntervals = initProcessedIntervals();
          rank.bubbledIntervals = initProcessedIntervals();
          if (rank.plan_start && rank.plan_end) {
            dateTracker.track(rank.plan_start, rank.plan_end);
          }
          rank.children = rank.workers;
          rank.children.forEach(
            // @ts-ignore
            (worker: IIdAndName & IChartTree) => {
              worker.lvl = CHART_TREE_LVL.EXPENDITURE;
              worker.parent = rank;
              worker._id = makeTreeId(worker.id, worker.lvl, "e");
              worker.projectId = tree.id;
              worker.isExpenditure = true;
              worker.processedIntervals = initProcessedIntervals();
              if (worker.plan_start && worker.plan_end) {
                dateTracker.track(worker.plan_start, worker.plan_end);
              }
              worker.children = [];
            }
          );
        }
      );
    }
  );
  tree.datesDistance = dateTracker.getMaxDistance();
};

export const getInitialTouchedYears = ({ year, month }: { year: number; month: number }): number[] => {
  if (month <= 1) {
    return [year - 1, year];
  }
  if (month >= 10) {
    return [year, year + 1];
  }
  return [year];
};

export const getCheckpointKeys = (checkpointDate: string): Record<`checkpointKey${CheckpointKeys}`, string> => {
  const checkpointDateMoment = moment(checkpointDate);
  return {
    checkpointKeydays: checkpointDate,
    checkpointKeyyearWeeks: `${checkpointDateMoment.year()}-${checkpointDateMoment.week()}`,
  };
};

export const extractChartStatusesDetails = (rawStatuses: ChartStatusRecord): ChartStatusDetailed => {
  // @ts-ignore
  const config: ChartStatusDetailed = {};
  Object.entries(rawStatuses).forEach(([key, value]: [string, string]) => {
    const isColor = key.endsWith("_color");
    const status = key.replace("_color", "");
    // @ts-ignore
    config[status] ||= {};
    if (isColor) {
      // @ts-ignore
      config[status].color = value;
    } else {
      // @ts-ignore
      config[status].title = value;
    }
  });
  return config;
};

export const packChartStatuses = (statuses: ChartStatusDetailed): ChartStatusRecord => {
  // @ts-ignore
  const config: ChartStatusRecord = {};
  Object.entries(statuses).forEach(([key, details]: [string, ChartStatusDetails]) => {
    // @ts-ignore
    config[key] = details.title;
    // @ts-ignore
    config[`${key}_color`] = details.color;
  });
  return config;
};

export const patchRootTreeDatesDistance = (rootTree: IChartTree) => {
  const dateTracker = createDateDistanceTracker();
  rootTree.children.forEach((child) => {
    if (child.datesDistance && child.datesDistance.start && child.datesDistance.end) {
      dateTracker.track(child.datesDistance.start, child.datesDistance.end);
    }
  });
  rootTree.datesDistance = dateTracker.getMaxDistance();
};
