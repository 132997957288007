import cn from "classnames";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";

import Icon from "../../../../../_LEGACY/UI/_LEGACY_Icon/Icon";

import TaskLabel from "../TasksModalV2/ui/TaskLabel/TaskLabel";
import TaskPerson from "../TasksModalV2/ui/TaskPerson/TaskPerson";

import { IdNameLabelType } from "../../../../../types/IdNameLabelType";
import { IIdAndName } from "../../../../../types/idAndName";

import arrowDownSelect from "images/icons/arrowDownSelect.svg";

import styles from "./TasksPersonsSelect.module.scss";

export interface ITasksPersonsSelectProps<Option extends IdNameLabelType = IdNameLabelType> {
  options: Option[];
  defaultValue?: string;
  name: string;
  placeholder?: string;
  input: any;
  className?: string;
  optionsBlockClassName?: string;
  label: string;
  disabled?: boolean;
  meta?: any;
  icon?: any;
  isSingleSelect?: boolean;
  value?: number | number[];
}

const TasksPersonsSelect = <Option extends IdNameLabelType = IdNameLabelType>({
  options = [],
  defaultValue = "",
  name,
  placeholder = "",
  input,
  className,
  optionsBlockClassName,
  label,
  disabled = false,
  meta,
  icon = arrowDownSelect,
  isSingleSelect,
  value,
}: ITasksPersonsSelectProps<Option>) => {
  const wrapperRef = useRef<HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState<string | undefined>("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const clearFilter = useCallback(() => {
    setFilter(undefined);
    setFilteredOptions(options);
  }, []);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options, input?.value]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as HTMLElement)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newFilter = e.target.value;
    setFilter(newFilter);
    setFilteredOptions(options.filter((item) => item.name.toLowerCase().indexOf(newFilter?.toLowerCase()) !== -1));
  };

  const handleElementClick = (item: IIdAndName) => {
    let newState;

    if (isSingleSelect) {
      if (isItemChecked(item.id, value)) {
        newState = undefined;
      } else {
        newState = item.id;
      }
    } else if (Array.isArray(value)) {
      if (isItemChecked(item.id, value)) {
        newState = value.filter((el) => el !== item.id);
      } else {
        newState = [...value, item.id];
      }
    }

    input?.onChange?.(newState);
  };

  const removeSelected = (item: IdNameLabelType) => {
    let newState;

    if (isSingleSelect) {
      newState = undefined;
    } else if (Array.isArray(value)) {
      newState = value.filter((el) => el !== item.id);
    }

    input?.onChange?.(newState);
  };

  const setToggle = () => setIsOpen((prevState) => !prevState);

  const displayIds = useMemo(() => {
    if (!value) return [];
    if (typeof value === "number") return [value];
    return value;
  }, [value]);

  return (
    <div>
      {label && <TaskLabel>{label}</TaskLabel>}
      {displayIds.length > 0 && (
        <div className={styles.bubbles}>
          {/* TODO */}
          {options.map(
            (item) =>
              displayIds.includes(item.id) && (
                <TaskPerson
                  key={item.id}
                  person={item.name}
                  onDelete={() => removeSelected(item)}
                  canDelete
                  className={styles.person}
                />
              )
          )}
        </div>
      )}
      <div
        className={cn(styles.select, className, {
          [styles.isOpen]: isOpen && !disabled,
          [styles.disabled]: disabled,
        })}
        onClick={() => setIsOpen(true)}
        ref={wrapperRef as any}
      >
        <input
          type=""
          placeholder={placeholder}
          value={filter || ""}
          onChange={handleFilterChange}
          className={styles.filterInput}
        />
        <div className={styles.controls}>
          {(filter?.length ?? 0) > 0 && (
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.clearFilterIcon}
              onClick={clearFilter}
            >
              <path
                d="M5.77051 5.69238L0.577148 10.8828L5.77051 5.69238ZM5.77051 5.69238L0.577148 0.5L5.77051 5.69238ZM5.77051 5.69238L10.9629 0.5L5.77051 5.69238ZM5.77051 5.69238L10.9629 10.8828L5.77051 5.69238Z"
                stroke="#707070"
              />
            </svg>
          )}
          <div
            onClick={(e) => {
              e.stopPropagation();
              setToggle();
            }}
          >
            <Icon icon={icon} className={cn(styles.arrow, { [styles.arrowReverse]: isOpen && !disabled })} />
          </div>
        </div>
        {!disabled && (
          <div
            className={cn(styles.optionsBlock, optionsBlockClassName, {
              [styles.isOpen]: isOpen,
            })}
          >
            {filteredOptions &&
              filteredOptions.length > 0 &&
              filteredOptions.map((item) => (
                <div key={item.id} className={styles.option} onClick={() => handleElementClick(item)}>
                  <div className={styles.checkbox}>
                    {isItemChecked(item.id, value!) && (
                      <svg
                        width="16"
                        height="15"
                        viewBox="0 0 16 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={styles.check}
                      >
                        <path d="M1.49023 7.93304L6.50723 12.95L14.5072 1.05005" stroke="#707070" strokeWidth="1.6" />
                      </svg>
                    )}
                  </div>
                  <span title={item.name}>{item.name}</span>
                  <span className={styles.pos} title={item.position}>
                    {item.position}
                  </span>
                </div>
              ))}
            {(!filteredOptions || (filteredOptions && filteredOptions.length === 0)) && (
              <div className={styles.option}>Нет данных</div>
            )}
          </div>
        )}
      </div>
      {meta?.touched && meta?.error && <div className={cn(styles.errorMessage)}>{meta.error}</div>}
    </div>
  );
};

export default React.memo(TasksPersonsSelect);

function isItemChecked(id: number, value?: number | number[]) {
  if (typeof value === "number") {
    return +id === +value;
  } else if (Array.isArray(value)) {
    return value.includes(+id);
  }
  return false;
}
