export const beautifyNumberValue = (
  value: number | string,
  floatConstraint = 2,
  allowedSymbols: { symbol: string; allowedIndex?: number }[] = []
) => {
  const allowedSymbolsMap = getAllowedSymbolsMap(allowedSymbols);

  const stringValue = String(value);

  let result = "";

  let dotIndex = null;

  for (let i = 0; i < stringValue.length; i++) {
    if (dotIndex && i > dotIndex + floatConstraint) break;

    const symbol = stringValue[i];

    if (symbol === "-") {
      if (i === 0) {
        result += symbol;
        continue;
      } else {
        continue;
      }
    }

    if (symbol === "." || symbol === ",") {
      result += dotIndex || i === 0 || floatConstraint === 0 ? "" : ".";
      dotIndex = i;
      continue;
    }

    if (allowedSymbolsMap.has(symbol)) {
      const allowedIndex = allowedSymbolsMap.get(symbol);
      if (allowedIndex === null) {
        result += symbol;
        continue;
      } else {
        if (i === allowedIndex) {
          result += symbol;
        }
        continue;
      }
    }

    if (isNaN(+symbol) || symbol === " ") {
      result += "";
      continue;
    }

    result += symbol;
  }
  return result;
};

function getAllowedSymbolsMap(allowedSymbols: { symbol: string; allowedIndex?: number }[] = []) {
  const res: Map<string, number | null> = new Map();
  allowedSymbols.forEach(({ symbol, allowedIndex }) => {
    res.set(symbol, allowedIndex ?? null);
  });

  return res;
}
