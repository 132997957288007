import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Select from "components/UI/atoms/Select";

import { settingsObjectApi } from "../../model/api";
import ObjectSettingsControlEmployee from "./ObjectSettingsControlEmployee/ObjectSettingsControlEmployee";
import ObjectSettingsControlModal from "./ObjectSettingsControlModal/ObjectSettingsControlModal";

import { IConstControlInBuilding, JournalConstControlSteps } from "../../model/types";

import styles from "./SettingsObjectConstructionControl.module.scss";

interface IProps {
  objectId: string;
}

const SettingsObjectConstructionControl: FC<IProps> = ({ objectId }) => {
  const dispatch = useDispatch();
  const [isModal, setIsModal] = useState(false);
  const [data, setData] = useState<IConstControlInBuilding>();
  const [type, setType] = useState<JournalConstControlSteps | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const refetch = useCallback(() => {
    setIsLoading(true);
    settingsObjectApi
      .getBuildingConstrControl(objectId)
      .then(({ data }) => {
        setData(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [objectId]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div>
      <div className={styles.grid}>
        <div className={styles.row}>
          <ObjectSettingsControlEmployee
            title="Приемка"
            objectId={objectId}
            onClick={() => {
              setIsModal(true);
              setType("ACCEPTANCE");
            }}
            items={data?.acceptance as any}
            isLoading={isLoading}
            refetch={refetch}
            step="ACCEPTANCE"
          />
        </div>
        <div className={styles.row}>
          <ObjectSettingsControlEmployee
            title="Выполнение"
            objectId={objectId}
            onClick={() => {
              setIsModal(true);
              setType("PERFORMANCE");
            }}
            items={data?.performance as any}
            isLoading={isLoading}
            refetch={refetch}
            step="PERFORMANCE"
          />
        </div>
        <div className={styles.row}>
          <ObjectSettingsControlEmployee
            title="Актирование"
            objectId={objectId}
            onClick={() => {
              setIsModal(true);
              setType("ACTING");
            }}
            items={data?.acting as any}
            isLoading={isLoading}
            refetch={refetch}
            step="ACTING"
          />
        </div>
      </div>
      <ObjectSettingsControlModal
        refetch={refetch}
        objectId={objectId}
        isOpen={isModal}
        onClose={() => {
          setIsModal(false);
          setType(null);
        }}
        type={type}
      />
    </div>
  );
};

export default SettingsObjectConstructionControl;
