import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { IExpenditureInActing } from "redux/modules/common/building/journal/acting/types";
import { journalExecutionActions } from "redux/modules/common/building/journal/journalExecution/actions";
import { passportsActions } from "redux/modules/common/passportsAndCertificates/actions";
import {
  attachDocumentToExpenditure,
  attachFileToExpenditure,
} from "redux/modules/common/passportsAndCertificates/thunks";

import { ExpenditureTypeEnum } from "types/enums/ExpenditureTypeEnum";

import { useTypedParams } from "utils/hooks/useTypedParams";

import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";
import downloadFile from "utils/helpers/download";
import { errorCatcher } from "utils/helpers/errorCatcher";
import { serializeFiles } from "utils/serializeFiles";

interface IFile {
  id: number;
  originalname: string;
  file: string;
  resource: boolean;
  file_url: string;
  type: string;
  link: string;
}

interface IProps {
  sectionId: any;
  itemId: any;
  isOpen: boolean;
  expenditure: IExpenditureInActing;
}

export const useJournalActingFiles = ({ sectionId, itemId, isOpen, expenditure }: IProps) => {
  const { objectId } = useTypedParams();
  const [files, setFiles] = useState<IFile[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [cacheKey, setCacheKey] = useState(1);

  useEffect(() => {
    if (!isOpen) return;
    if (!files?.length) {
      setIsLoading(true);
    }
    axios
      .get<IFile[]>(`/building/${objectId}/estimate/acts/ks2/sections/${sectionId}/items/${itemId}/files/?limit=500`)
      .then(({ data }) => {
        setFiles(data.map((el) => ({ ...el, link: el.file_url })));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [objectId, sectionId, itemId, isOpen, cacheKey]);

  //TODO чзх
  const [isDelete, setIsDelete] = useState<any>(null);
  const [isDeletePending, setIsDeletePending] = useState(false);
  const dispatch = useDispatch();
  const deleteDocument = async (externalId?: number) => {
    const id = externalId || isDelete?.id;
    const isResource = files?.find((el: any) => el.id === id)?.resource;

    setIsDeletePending(true);
    if (isResource) {
      await axios.delete(`/estimate/documents/resource_attachments/${itemId}/${id}/`);
    } else {
      await axios.delete(`/building/${objectId}/estimate/attachments/work/${itemId}/${id}/`);
    }
    dispatch(passportsActions.invalidateKey());
    setIsDeletePending(false);
    setIsDelete(null);
  };

  const downloadHandler = (file: any) => {
    downloadFile(file.file, file.name);
  };

  const onSubmit = async (vals: any) => {
    const [serializedDocumentData, link] = await prepareData(expenditure, vals, itemId);
    axios
      .post(link, serializedDocumentData)
      .then(() => {
        dispatch(passportsActions.invalidateKey());
        dispatch(journalExecutionActions.changeInvalidateKey());
        setCacheKey((prev) => prev + 1);
      })
      .catch(errorCatcher);
  };

  const onUpload = async (vals: any) => {
    const [serializedDocumentData, link] = await prepareData(expenditure, vals, itemId);
    axios
      .post(link, serializedDocumentData)
      .then(() => {
        dispatch(passportsActions.invalidateKey());
        dispatch(journalExecutionActions.changeInvalidateKey());
        setCacheKey((prev) => prev + 1);
      })
      .catch(errorCatcher);
  };

  return { files, isLoading, isDelete, setIsDelete, deleteDocument, downloadHandler, onSubmit, onUpload, cacheKey };
};

async function prepareData(expenditure: IExpenditureInActing, vals: any, itemId: number) {
  const fileInB64 = await getArrayFilesInBase64([vals]);
  const serializedDocumentData = {
    ...vals,
    file: fileInB64[0]?.file as string,
    originalname: (vals?.name as string) ?? fileInB64[0]?.name,
  };
  const url =
    expenditure.expenditure_type === ExpenditureTypeEnum.work
      ? `/estimate/documents/expenditure/${itemId}/`
      : `/estimate/documents/resource_attachments/${itemId}/`;

  return [serializedDocumentData, url] as const;
}
