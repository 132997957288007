import { useEffect, useLayoutEffect, useRef, useState } from "react";

interface IProps {
  commentText: string;
  comments: any[];
}

export const useTaskCommentsLayout = ({ commentText, comments }: IProps) => {
  //TODO naming
  const a = useRef<HTMLDivElement>(null);
  const b = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(1);

  useLayoutEffect(() => {
    const handler = () => {
      if (!b.current || !a.current) return;
      setHeight(b.current.getBoundingClientRect().top - a.current.getBoundingClientRect().top);
    };
    handler();
    window.addEventListener("resize", handler);
    return () => {
      window.removeEventListener("resize", handler);
    };
  }, [commentText]);

  const listRef = useRef<HTMLDivElement>(null);
  const scrolled = useRef(false);
  useEffect(() => {
    if (!scrolled.current) {
      listRef.current?.scrollTo({ top: 10000, behavior: "auto" });
    }
    scrolled.current = true;
  }, [comments]);

  return { height, listRef, a, b };
};
