import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { PROJECTS_INDICATORS } from "../../../../../../redux/modules/common/uiIndicators/types";

import { useUIIndicator } from "../../../../../../hooks/uiIndicators/useUIIndicator";
import SelectStatus from "../../../../../UI/atoms/_TODO/SelectStatus/SelectStatus";
import ChartPopup from "components/pages/Building/components/ChartPopup/ChartPopup";

import ProgressBar from "shared/ui/dataDisplay/ProgressBar/ProgressBar";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { MODULES_ENUM } from "../../../../../../types/enums/ModulesEnum";
import { TRANSFERRED_STATUS_OPTIONS } from "../../constants";
import { IObjectInList } from "features/objectsList/model/types";
import { IBuildingInList } from "types/interfaces/Building";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import SettingGear from "images/SettingGear";
import EstimateIcon from "images/icons/EstimateIcon";

import styles from "./ObjectsRow.module.scss";

interface IProps {
  object: IObjectInList;
  viewHandlerPermission: boolean;
  viewEditBuildingPermission: boolean;
  viewBuildingPermission: boolean;
  isTransferred?: boolean;
  onStatusChange?: (object: IObjectInList, status: string) => void;
  isSharingPending?: boolean;
  projectId: string;
  onRowDirectClick?: () => void;
}

const ObjectsRow: React.FC<IProps> = ({
  object,
  viewEditBuildingPermission,
  viewHandlerPermission,
  viewBuildingPermission,
  isTransferred,
  onStatusChange,
  isSharingPending,
  projectId,
  onRowDirectClick,
}) => {
  const history = useHistory();

  const { isIndicatorDisplayed } = useUIIndicator({
    data: {},
    module: MODULES_ENUM.CONSTRUCTING,
    type: PROJECTS_INDICATORS as any,
    buildingId: object.id,
  });

  const onSettingsClick = useCallback(() => {
    history.push(`/constructing/projects/edit-object/${projectId}/${object.id}/passport`);
  }, [history, object.id]);

  const onEstimateClick = useCallback(() => {
    history.push(`/constructing/handler/${projectId}/${object.id}`);
  }, [history, object.id, projectId]);

  const onRowClick = useCallback(() => {
    if (onRowDirectClick) {
      onRowDirectClick();
      return;
    }
    if (viewBuildingPermission) history.push(`/constructing/projects/${projectId}/object/${object.id}`);
  }, [viewBuildingPermission, object.id, history, projectId, onRowDirectClick]);

  const statusChangeHandler = useCallback(
    (status: string) => {
      onStatusChange?.(object, status);
    },
    [object, onStatusChange]
  );

  const rowTestId = `object_row_${object.id}${isTransferred ? "_transferred" : ""}`;

  const estimateTestId = `object_row_${object.id}_estimate${isTransferred ? "_transferred" : ""}`;

  return (
    <TableReusableRow
      className={styles.row}
      onClick={onRowClick}
      dataTestId={rowTestId}
      isWithIndicator={isIndicatorDisplayed}
    >
      <TableReusableCell isNoBreak>{object.name}</TableReusableCell>
      <TableReusableCell isNoBreak>{object.entity_name}</TableReusableCell>
      <TableReusableCell isNoBreak>{object.customer}</TableReusableCell>
      <TableReusableCell className={styles.actionsContainer}>
        {!isTransferred && (
          <ProgressBar
            completed={Math.round(+object?.calculation?.complete_percent * 100 || 0)}
            className={styles.progress}
            direction={undefined}
          />
        )}{" "}
        {/* @ts-ignore */}
        {!isTransferred && (
          <ChartPopup
            all={Number(object?.calculation?.budget || 0)}
            completed={Number(object?.calculation?.doned || 0)}
            accepted={Number(object?.calculation?.received || 0)}
            containerClassName={styles.textSize}
            type="currency"
            popupClassName={undefined}
            onHover={undefined}
            isLast={undefined}
            customLabels={undefined}
          />
        )}
        {isTransferred && (
          <div onClick={stopEventPropagation} className={styles.sharingWrapper}>
            <SelectStatus
              options={TRANSFERRED_STATUS_OPTIONS}
              onChange={statusChangeHandler}
              value={"accepted"}
              isLoading={isSharingPending}
            />
          </div>
        )}
        <div className={styles.buttonGroup} onClick={stopEventPropagation}>
          {viewHandlerPermission && (
            <div className={styles.actionButton} onClick={onEstimateClick} data-testid={estimateTestId}>
              Смета
              <EstimateIcon />
            </div>
          )}
          {!isTransferred && viewEditBuildingPermission && (
            <div
              className={styles.actionButton}
              onClick={onSettingsClick}
              data-testid={`object_row_${object.id}_settings`}
            >
              <SettingGear color="#fff" />
            </div>
          )}
        </div>
      </TableReusableCell>
    </TableReusableRow>
  );
};

export default ObjectsRow;
