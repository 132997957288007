import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import ObjectsHeaderRow from "components/pages/Constructing/Objects/components/ObjectsRow/ObjectsHeaderRow";
import ObjectsRow from "components/pages/Constructing/Objects/components/ObjectsRow/ObjectsRow";
import { DEFAULT_ACCEPTED_FILTERS } from "components/pages/Constructing/Objects/constants";

import { changeConstructionControlObjectStatus, getConstructionControlObjects } from "./model/thunks";
import { useTypedSelector } from "app/store/typedUseSelector";
import CustomBreadcrumbs from "entities/CustomBreadcrumbs/CustomBreadcrumbs";
import BreadcrumbsProjectSelect from "features/templates/BreadcrumbsSelects/BreadcrumbsProjectSelect";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import TabBarNotLinks from "shared/ui/controls/TabBar/TabBarNotLinks";
import BackNavigationBar from "shared/ui/layout/BackNavigationBar/BackNavigationBar";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import ListPageHeaderTemplate from "shared/ui/layout/ListPageHeaderTemplate/ListPageHeaderTemplate";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

import projectsIcon from "images/icons/navigation/estimateIcon.svg";

import styles from "./ConstructionControlProjects.module.scss";

const tabs = [
  { text: "Все объекты", id: "all" },
  { text: "Выполненные", id: "sent" },
  { text: "Выполняются", id: "accepted" },
];

const ConstructionControlObjects = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams<Record<string, string>>();
  const invalidateKey = useTypedSelector((state) => state.constructionControlProjectsReducer.invalidationKey);
  const history = useHistory();
  const [tab, setTab] = useState("all");

  const handleTabChange = (newTab: string) => {
    setTab(newTab);
  };

  const [acceptedFilters, setAcceptedFilters] = useState(DEFAULT_ACCEPTED_FILTERS);
  const changeAcceptedFilters = (name: string, value: any) => {
    setAcceptedFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    dispatch(getConstructionControlObjects(projectId));
  }, [projectId, invalidateKey]);

  const objects = useTypedSelector((state) => state.constructionControlProjectsReducer.objects)[projectId];
  const isLoading = useTypedSelector((state) => state.constructionControlProjectsReducer.loadings)[projectId];
  const pendings = useTypedSelector((state) => state.constructionControlProjectsReducer.loadings);

  const onRowDirectClick = (objectId: number) => {
    history.push(`/construction-control/projects/${projectId}/object/${objectId}/progress`);
  };

  return (
    <TemplateBase
      dataTestId="page_objects"
      breadcrumbs={
        <CustomBreadcrumbs title="Стройконтроль" subtitle="Проекты">
          <BreadcrumbsProjectSelect
            constructLink={({ projectId }) => `/construction-control/projects/objects-list/${projectId}/`}
            customFirstOption={{
              id: 0,
              name: "Все проекты",
              link: `/construction-control/projects/`,
            }}
          />
        </CustomBreadcrumbs>
      }
    >
      {/* <ListPageHeaderTemplate>
        <TabBarNotLinks tabs={tabs} activeId={tab} onClick={handleTabChange} />
      </ListPageHeaderTemplate> */}
      <BackNavigationBar
        className={styles.back}
        title="Все проекты"
        onBack={() => history.push("/construction-control/projects")}
      />
      <div className={styles.header}>
        <ObjectsHeaderRow onChangeFilter={changeAcceptedFilters} className={styles.th} />
      </div>
      {!isLoading && !objects?.length && <EmptyPlaceholder text="Нет объектов" img={projectsIcon} />}
      {objects?.map((el) => (
        <ObjectsRow
          key={el.building}
          object={el as any}
          viewBuildingPermission={true}
          viewEditBuildingPermission={false}
          viewHandlerPermission={false}
          projectId={projectId}
          onRowDirectClick={() => onRowDirectClick(el.building)}
          isTransferred={el.status === "NEW"}
          onStatusChange={(_, status) => {
            dispatch(changeConstructionControlObjectStatus(el.building, status === "accepted"));
          }}
          isSharingPending={pendings[generateStorageKey({ buildingId: el.building, type: "changeStatus" })]}
        />
      ))}
      {isLoading && <Spinner />}
    </TemplateBase>
  );
};

export default ConstructionControlObjects;
