import cn from "classnames";
import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";

import Select from "components/UI/atoms/Select";
import { useTaskObjectsSectionsUsers } from "components/pages/Tasks/hooks/useTaskObjectsSectionsUsers";
import { useTasksModal } from "components/pages/Tasks/hooks/useTasksModal";
import { useUpdateTask } from "components/pages/Tasks/hooks/useUpdateTask";
import { updateTask } from "components/pages/Tasks/model/thunks";
import { ICertainTaskResponse } from "components/pages/Tasks/model/types";

import bannerIcon from "./banner.png";
import ButtonBase from "shared/ui/controls/ButtonBase";
import Avatar from "shared/ui/dataDisplay/Avatar/Avatar";

import { IIdAndName } from "types/idAndName";

import CrossIcon from "images/icons/CrossIcon";

import styles from "./TaskReassignPopup.module.scss";

interface IProps {
  buildingId: number;
  onClose: () => void;
  task: ICertainTaskResponse;
}

const TaskReassignPopup: FC<IProps> = ({ buildingId, onClose, task }) => {
  const { type, isOpen, taskId, changeModalType, deleteFilesOnCancel, placementType, listType, list_id } =
    useTasksModal();

  const { section, users } = useTaskObjectsSectionsUsers({ building_id: buildingId });
  const [isExpanded, setIsExpanded] = useState(false);
  const [value, setValue] = useState<number>();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);

  const onClick = () => {
    setIsPending(true);
    dispatch(
      updateTask(
        { id: task.id, data: { executor: value } as any } as any,
        () => {
          setIsPending(false);
          //onClose();
          setIsSuccess(true);
          setTimeout(() => {
            onClose();
          }, 1000);
        },
        ""
      )
    );
  };

  return (
    <div className={styles.modal}>
      <button className={styles.close} onClick={onClose}>
        <CrossIcon />
      </button>
      {isSuccess && (
        <div className={styles.success}>
          <img src={bannerIcon} className={styles.banner} />
        </div>
      )}
      {!isSuccess && (
        <>
          <label>Выберите кому передать задачу</label>
          <Select
            options={users}
            containerClassName={cn(styles.select, isExpanded && styles.selectOpened)}
            classNameOptions={styles.options}
            onOpen={() => setIsExpanded(true)}
            onClose={() => setIsExpanded(false)}
            onChange={(v) => {
              setValue(+v);
            }}
            value={value}
            renderOption={(opt: any, onClick) => {
              //TODO
              const option = opt as {
                id: number;
                name: string;
                label: string;
                position: string;
              };
              return (
                <div key={option.id} className={styles.option} onClick={() => onClick?.(option.id, option.name)}>
                  <Avatar className={styles.avatar} person={option.name} />
                  <span>{option.name}</span>
                  <span className={styles.position}>{option.position}</span>
                </div>
              );
            }}
          />
          <div className={styles.bottom}>
            <ButtonBase tertiary onClick={onClose}>
              Отменить
            </ButtonBase>
            {!!value && (
              <ButtonBase primary isLoading={isPending} onClick={onClick}>
                Подтвердить
              </ButtonBase>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TaskReassignPopup;
