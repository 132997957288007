import classNames from "classnames";
import React, { PropsWithChildren, ReactNode, useCallback } from "react";
import { useDispatch } from "react-redux";

import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";
import List from "pages/Finance/_TODO/List";
import ListRow from "pages/Finance/_TODO/List/_internal/ListRow";
import { useSummaryScroll } from "pages/Finance/_TODO/useSummaryScroll";
import { IFinanceTreeItem } from "pages/Finance/common/model/interfaces";

import { toggleForecastTree } from "../../model/thunks";
import { useForecastTreeSelector } from "../../model/useForecastTreeSelector";
import { useTypedSelector } from "app/store/typedUseSelector";

import {
  VIEW_FINANCE_DELETE_EXTRA_FORECAST,
  VIEW_FINANCE_EDIT_EXTRA_FORECAST,
} from "constants/permissions/constructingPermissions";

import usePermission from "hooks/usePermission";

import EditIcon from "images/icons/EditIcon";

import localStyles from "./ForecastListSide.module.scss";
import styles from "./ListSide.module.scss";

export interface IListSideProps extends PropsWithChildren {
  additionalHeader?: ReactNode;
  onClickExtraItem?: (id: number) => void;
  onDeleteExtraItem?: (id: number) => void;
}

const ListSide: React.FC<IListSideProps> = ({ children, additionalHeader, onClickExtraItem, onDeleteExtraItem }) => {
  const dispatch = useDispatch();
  const { year } = useSummaryScroll();

  const handleListItemClick = (subTree: IFinanceTreeItem) => {
    dispatch(toggleForecastTree(subTree, year));
  };

  const canEditExtraExpense = usePermission(VIEW_FINANCE_EDIT_EXTRA_FORECAST);
  const canDeleteExtraExpense = usePermission(VIEW_FINANCE_DELETE_EXTRA_FORECAST);

  const renderExtraControl = useCallback(
    (data: IFinanceTreeItem) => {
      return (
        <div className={classNames(localStyles.extraControl, "extraControl")}>
          {canEditExtraExpense && (
            <PopoverOverlay
              placement="top"
              openType="hover"
              content={
                <div className={localStyles.popover} onClick={() => onClickExtraItem?.(data.id)}>
                  Редактировать
                </div>
              }
              popoverBorderColor="primary"
            >
              <EditIcon onClick={() => onClickExtraItem?.(data.id)} />
            </PopoverOverlay>
          )}
          {canDeleteExtraExpense && (
            <PopoverOverlay
              placement="top"
              openType="hover"
              content={
                <div className={localStyles.popover} onClick={() => onDeleteExtraItem?.(data.id)}>
                  Удалить
                </div>
              }
              popoverBorderColor="primary"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => onDeleteExtraItem?.(data.id)}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.05025 1.63664C2.65972 1.24612 2.02656 1.24612 1.63603 1.63664C1.24551 2.02717 1.24551 2.66033 1.63603 3.05086L6.58579 8.00061L1.63605 12.9504C1.24552 13.3409 1.24552 13.974 1.63605 14.3646C2.02657 14.7551 2.65973 14.7551 3.05026 14.3646L8 9.41482L12.9497 14.3646C13.3403 14.7551 13.9734 14.7551 14.364 14.3646C14.7545 13.974 14.7545 13.3409 14.364 12.9504L9.41421 8.00061L14.364 3.05086C14.7545 2.66033 14.7545 2.02717 14.364 1.63664C13.9734 1.24612 13.3403 1.24612 12.9498 1.63664L8 6.5864L3.05025 1.63664Z"
                  fill="#606060"
                />
              </svg>
            </PopoverOverlay>
          )}
        </div>
      );
    },
    [canEditExtraExpense, canDeleteExtraExpense]
  );

  const { displayingTree, displayingTotals, showSpinner } = useForecastTreeSelector({ year });
  const loadings = useTypedSelector((state) => state.financeReducer.forecast.loadings);

  const getIsWithBorderTop = useCallback(
    (row: IFinanceTreeItem) => {
      if (displayingTree?.some((el) => el.type === "advance")) {
        return row.type === "advance";
      }
      if (displayingTree?.some((el) => el.type === "warranty")) {
        return row.type === "warranty";
      }
      return row.type === "extraRoot";
    },
    [displayingTree]
  );

  return (
    <div className={styles.listSide}>
      <div className={classNames(styles.sticky, localStyles.sticky)}>
        {children}
        <div className={styles.listSide__header}>
          <span className={styles.listSide__title}>Прогнозные показатели</span>
          {additionalHeader}
        </div>
      </div>
      <div className={styles.listSide__list}>
        {!showSpinner && (
          <List
            data={displayingTree ?? []}
            onClickItem={handleListItemClick}
            renderExtraControl={renderExtraControl}
            loadings={loadings}
            getIsWithBorderTop={getIsWithBorderTop}
          />
        )}
        {!!displayingTotals && (
          <ListRow
            data={displayingTotals?.[0]}
            depth={2}
            handleListItemClick={handleListItemClick}
            className={styles.total}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(ListSide);
