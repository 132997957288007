import cn from "classnames";
import React from "react";

import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";

import { Placement } from "@blueprintjs/popover2";
import Spinner from "shared/ui/atoms/Spinner/Spinner";

import checkSvg from "images/icons/blackCheckIcon";
import fillBlueApproveIcon from "images/icons/filledBlueCheckIcon";

import styles from "./AcceptCheckBtn.module.scss";

interface IProps {
  title?: string;
  isPending?: boolean;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  isAccepted?: boolean;
  isPopupDisabled?: boolean;
  placement?: Placement;
}

const AcceptCheckBtn: React.FC<IProps> = ({
  title = "Подтвердить",
  className,
  onClick,
  isPending,
  disabled,
  isAccepted,
  isPopupDisabled,
  placement = "bottom",
}) => {
  const iconClasses = cn({
    [styles.icon]: true,
    [styles.enabled]: !disabled,
    [styles.disabled]: disabled,
    [styles.accepted]: isAccepted,
  });

  const isDisabled = isPending || isPopupDisabled;

  return (
    <div
      className={className}
      onClick={(e) => {
        e.stopPropagation();
        !disabled && onClick?.();
      }}
    >
      <PopoverOverlay
        content={<div className={styles.popup}>{title}</div>}
        openType="hover"
        popoverBorderColor="default"
        placement={placement}
        isDisabled={isDisabled}
      >
        {isPending && (
          <div className={styles.icon}>
            <Spinner isSmallGray isStatic />
          </div>
        )}
        {!isPending && <div className={iconClasses}>{isAccepted ? fillBlueApproveIcon : checkSvg}</div>}
      </PopoverOverlay>
    </div>
  );
};

export default AcceptCheckBtn;
