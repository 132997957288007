import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
  isLoadingSectionSelector,
  materialSectionsSelector,
} from "../../../../../redux/modules/common/building/materials/materials";

import ExpendituresLevel from "./levels/ExpendituresLevel";
import SectionsLevel from "./levels/SectionsLevel";
import SubSectionsLevel from "./levels/SubSectionsLevel";

import { useFoldersNavigation } from "hooks/navigation/useFoldersNavigation";

import styles from "./Estimate.module.scss";

export interface IEstimateProps {
  objectId: string;
  isSwitch: boolean;
}

const Estimate: React.FC<IEstimateProps> = ({ objectId, isSwitch }) => {
  const isLoading = useSelector(isLoadingSectionSelector);
  const materialSections = useSelector(materialSectionsSelector)[objectId];

  const { currentFolderId, foldersDepth, openFolderHandler, backHandler } = useFoldersNavigation("section");
  const isSectionsLevel = !foldersDepth;
  const isSubSectionsLevel = foldersDepth == 1;
  const isExpendituresLevel = foldersDepth > 1;

  const [wasAutoExpanded, setWasAutoExpanded] = useState(false);
  const lastObjectId = useRef(objectId);
  useEffect(() => {
    if (isLoading) return;
    if (!!foldersDepth) return;
    if (!isSubSectionsLevel && wasAutoExpanded) return;
    if (isSubSectionsLevel && lastObjectId.current === objectId && wasAutoExpanded) return;
    if (materialSections?.length === 1) {
      setWasAutoExpanded(true);
      openFolderHandler(materialSections?.[0]?.id);
    }
  }, [isLoading, materialSections, isSubSectionsLevel, wasAutoExpanded, foldersDepth]);

  useEffect(() => {
    setWasAutoExpanded(false);
    lastObjectId.current = objectId;
  }, [objectId]);

  return (
    <div
      className={cn(styles.container, {
        [styles.active]: isExpendituresLevel,
      })}
      key={objectId}
    >
      {isSectionsLevel && <SectionsLevel onClick={openFolderHandler} />}
      {isSubSectionsLevel && (
        <SubSectionsLevel onClick={openFolderHandler} sectionId={currentFolderId} onBack={backHandler} />
      )}
      {isExpendituresLevel && (
        <ExpendituresLevel
          onClick={openFolderHandler}
          sectionId={currentFolderId}
          onBack={backHandler}
          isSwitch={isSwitch}
        />
      )}
    </div>
  );
};
export default Estimate;
