import React from "react";

import FinanceFooter from "pages/Finance/common/ui/FinanceFooter/FinanceFooter";

import { useForecastTreeSelector } from "../../model/useForecastTreeSelector";
import Table from "./../../../Summary/ui/TableSide/Table/Table";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import financeIcon from "images/icons/navigation/finance.svg";

import styles from "./TableSide.module.scss";

interface IProps {
  year: number;
}

const TableSide = ({ year }: IProps) => {
  const { tableData, showSpinner, displayingTotals } = useForecastTreeSelector({ year });

  if (showSpinner) return <Spinner isStatic />;

  if (!tableData?.length) return <EmptyPlaceholder img={financeIcon} />;

  return (
    <>
      <div className={styles.tableSide}>
        <Table data={tableData} totalsData={displayingTotals?.[0]} />
      </div>
      {!!displayingTotals && <FinanceFooter totalsData={displayingTotals?.[0]} />}
    </>
  );
};

export default TableSide;
