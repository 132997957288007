import { throttle } from "lodash";
import { useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { chartViewModeSelector } from "redux/modules/common/chart/selectors";

import treeStyles from "../components/ChartTree/ChartTree.module.scss";
import { useRem } from "components/pages/Manufacturing/hooks/useRem";

import { ChartContext } from "../Chart";

import { getCorrespondingTreeElement } from "../utils/getElementsFromPointer";

export const useChartRunningLine = () => {
  const { contentRef, containerRef } = useContext(ChartContext);
  const { REM } = useRem();
  const prevSelectedElement = useRef<Element | null>(null);
  const prevScroll = useRef<number | null>(null);
  const runningLineRef = useRef<HTMLDivElement | null>(null);
  const chartViewMode = useSelector(chartViewModeSelector);

  useEffect(() => {
    const contentElement = contentRef?.current;
    const scrollContainerElement = containerRef?.current?.parentElement;
    const runningLineElement = runningLineRef.current;
    if (!contentElement || !scrollContainerElement || !runningLineElement) return;
    const handleRunningLine = throttle((e: Event) => {
      const treeElement =
        (e instanceof MouseEvent && getCorrespondingTreeElement(e, REM)?.parentElement) || prevSelectedElement.current;
      if (
        !treeElement ||
        (treeElement === prevSelectedElement.current && prevScroll.current === scrollContainerElement.scrollLeft)
      )
        return;
      Array.from(
        treeElement.closest(`.${treeStyles.tree}`)?.querySelectorAll(`.${treeStyles.branchElement}`) || []
      ).forEach((el: Element) => el !== treeElement && el.classList.remove(treeStyles.activeBranch));
      treeElement.classList.add(treeStyles.activeBranch);
      if (runningLineElement) {
        runningLineElement.style.transform = `translate(calc(${scrollContainerElement.scrollLeft}px), ${
          (treeElement as HTMLElement).style.top
        })`;
      }
      prevSelectedElement.current = treeElement;
      prevScroll.current = scrollContainerElement.scrollLeft;
    }, 10);
    contentElement.addEventListener("mousemove", handleRunningLine);
    scrollContainerElement.addEventListener("scroll", handleRunningLine);
    return () => {
      contentElement?.removeEventListener("mousemove", handleRunningLine);
      scrollContainerElement?.removeEventListener("scroll", handleRunningLine);
    };
  }, [contentRef?.current, containerRef?.current, runningLineRef.current, REM]);

  useEffect(() => {
    if (!runningLineRef.current) return;
    runningLineRef.current.style.transform = "";
  }, [chartViewMode]);

  return runningLineRef;
};
