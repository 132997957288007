import cn from "classnames";
import React, { FC, MouseEvent, memo, useCallback, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import ConfirmSelect from "../../../../../../../../../../../_LEGACY/UI/__trash/ConfirmSelect/ConfirmSelect";
import { IActions } from "../../../../../../../../../../../components/UI/_TODO/EstimateSection/EstimateSection";
import { AMOUNTS_VARIANTS } from "../../../../../../../../../../../components/UI/_TODO/EstimateSection/components/Amounts/Amounts";
import EstimateStateMark from "../../../../../../../../../../../components/UI/_TODO/EstimateSection/components/EstimateStateMark/EstimateStateMark";
import PricingModeBudgetIndicators from "../../../../../../../../../../../components/UI/_TODO/EstimateSection/components/PricingModeBudgetIndicators/PricingModeBudgetIndicators";
import PricingModeEstimatedPriceIndicators from "../../../../../../../../../../../components/UI/_TODO/EstimateSection/components/PricingModeEstimatedPriceIndicators/PricingModeEstimatedPriceIndicators";
import VisibilityMark from "components/UI/_TODO/EstimateSection/components/VisibilityMark/VisibilityMark";

import { PricingModeMarkupIndicators } from "../../../../../../../../../../../features/PricingModeSurchargeIndicators/ui/PricingModeMarkupIndicators";
import Actions from "../../../../../../../../../../../shared/ui/controls/Actions/Actions";
import Amount from "../../../../../../../../../../../shared/ui/dataDisplay/Amount/Amount";
import Checkbox from "../../../../../../../../../../../shared/ui/inputs/Checkbox/Checkbox";
import InputBase from "../../../../../../../../../../../shared/ui/inputs/InputBase";
import { loadAggregations } from "../../../../../../../../Aggregations/model/thunks";
import { simpleHandlerActions } from "../../../../../../../model/slice";
import { setDiscountCost, setEstimatedCost, setMarkup, simpleHandlerModel } from "../../../../../../../model/thunks";
import { useDrag } from "react-dnd";

import ISection from "../../../../../../../../../../../types/interfaces/Section";
import { EEstimateStatesIds } from "../../../../../../../../ProHandler/enums";
import { ESimpleHandlerMode } from "../../../../../../../types";
import { IExpenditureFormState } from "../../../../ExpendituresForm/types";

import { useEditSectionName } from "../../../hooks/useEditSectionName";

import { sliceTextByConstraint } from "../../../../../../../../../../../utils/formatters/sliceTextByConstraint";
import { stopEventPropagation } from "../../../../../../../../../../../utils/helpers/stopEventPropagation";
import { getSectionAmount } from "../../../../../../../../ProHandler/utils/getSectionAmount";

import styles from "./CommonSectionView.module.scss";

interface IProps {
  section: ISection;
  estimateStateId?: EEstimateStatesIds | null;
  onCheck?: (section: ISection) => void;
  onClick?: (section: ISection) => void;
  checked?: boolean;
  actions?: Partial<IActions>;
  mode?: ESimpleHandlerMode;
  isCheckboxVisible?: boolean;
  status?: "new" | "confirmed";
}

export const CommonSectionView: FC<IProps> = memo(
  ({
    mode = ESimpleHandlerMode.VIEW,
    section,
    estimateStateId,
    status,
    onCheck = (section: ISection) => {},
    onClick = (section: ISection) => {},
    checked = false,
    actions,
    isCheckboxVisible = false,
  }) => {
    const dispatch = useDispatch();

    const isShowActions = !!actions;
    const isDisplayEstimateStateMark = section.current_state === EEstimateStatesIds.LOCALE;

    const { changedName, isEditing, setIsEditing, onSubmitEditedSectionName, onKeyDownNameInput, onChangeNameInput } =
      useEditSectionName(section.name, actions?.onSaveEditName, status);

    const handleClick = () => {
      if (isEditing) return;

      onClick(section);
    };
    const handleCheck = () => onCheck(section);

    const amounts = useMemo(
      () => ({
        all: getSectionAmount(section, estimateStateId),
        completed: section.indicators ? section.indicators.invested : 0,
        completed_percent: section.indicators ? section.indicators.completed_percent : 0,
        accepted: section.indicators ? section.indicators.amount_accepted : 0,
        invested: section.indicators ? section.indicators.invested : 0,
        variant: AMOUNTS_VARIANTS.HIDDEN,
        allSource: section.indicators ? section.indicators.estimate_amount_source : 0,
        discount: section.indicators ? section.indicators.discount_cost : 0,
        estimatedCost: section.indicators ? section.indicators.estimated_cost : 0,
        sumEstimateAmount: section.aggregation_data?.sum_estimate_amount ?? 0,
        markupCost: section.markup_cost,
      }),
      [estimateStateId, section]
    );

    const handleChangeCost = (v: number) => {
      dispatch(
        setEstimatedCost(section.id, v, (estimated_cost) => {
          if (section.parent || section.parent_id) {
            dispatch(
              simpleHandlerActions.setSubSectionEstimatedCost({
                id: section.id,
                estimated_cost,
              })
            );
          } else {
            dispatch(simpleHandlerActions.setSectionEstimatedCost({ id: section.id, estimated_cost }));
          }

          dispatch(loadAggregations());
        })
      );
    };
    const handleChangeDiscountCost = (v: number) => {
      dispatch(
        setDiscountCost(section.id, v, (data) => {
          if (section.parent || section.parent_id) {
            dispatch(simpleHandlerActions.updateSubSectionDiscountCost(data));
          } else {
            dispatch(simpleHandlerActions.updateSectionDiscountCost(data));
          }

          dispatch(loadAggregations());
        })
      );
    };

    const handleChangeMarkupCost = (v: number) => {
      dispatch(
        setMarkup(section.id, v, (markup_cost) => {
          if (section.parent || section.parent_id) {
            dispatch(simpleHandlerActions.setSubSectionMarkupCost({ id: section.id, markup_cost }));
          } else {
            dispatch(simpleHandlerActions.setSectionMarkupCost({ id: section.id, markup_cost }));
          }

          dispatch(loadAggregations());
        })
      );
    };

    return (
      <div key={section.id} className={styles.container} onClick={handleClick}>
        {isCheckboxVisible && <Checkbox className={styles.checkbox} checked={checked} onCheck={handleCheck} />}
        <div className={styles.titleContainer}>
          {isEditing ? (
            <div className={styles.inputContainer} onClick={stopEventPropagation}>
              <InputBase
                classNameInput={styles.input}
                value={changedName}
                onChange={onChangeNameInput}
                onKeyDown={onKeyDownNameInput}
                placeholder="Введите наименование"
                input={undefined}
                className={undefined}
                label={undefined}
                onBlur={undefined}
              />
            </div>
          ) : (
            changedName
          )}
        </div>

        <div className={styles.rightContainer}>
          {estimateStateId === EEstimateStatesIds.PRODUCTION && (
            <VisibilityMark
              variant={
                section.hidden_status === 0
                  ? "allAvailable"
                  : section.hidden_status === 1
                  ? "allHidden"
                  : section.hidden_status === 2
                  ? "partHidden"
                  : "allAvailable"
              }
            />
          )}
          {isShowActions && (
            <div
              className={cn(styles.actions, {
                [styles.actionsMargin]: mode !== ESimpleHandlerMode.VIEW || !isDisplayEstimateStateMark,
              })}
              onClick={stopEventPropagation}
            >
              <Actions
                className={styles.actionBlock}
                canSave={isEditing}
                onSave={onSubmitEditedSectionName}
                canEdit={!isEditing && actions.canEdit}
                onEdit={() => setIsEditing(true)}
                canRemove={actions.canRemove}
                onRemove={actions.onRemove}
              />
            </div>
          )}

          {mode === ESimpleHandlerMode.VIEW && (
            <>
              {isDisplayEstimateStateMark && section.current_state && (
                <EstimateStateMark state={section.current_state} className={styles.mark} />
              )}

              <div className={styles.divider} />

              <Amount
                className={styles.budget}
                titleClassName={styles.budgetTitle}
                value={getSectionAmount(section, estimateStateId)}
                title="Бюджет"
                isLeft
                isBold
              />
            </>
          )}

          {mode === ESimpleHandlerMode.MARKUP && (
            <PricingModeMarkupIndicators amounts={amounts} changeCost={handleChangeMarkupCost} />
          )}
          {mode === ESimpleHandlerMode.BUDGET && (
            <PricingModeBudgetIndicators amounts={amounts} changeDiscountCost={handleChangeDiscountCost} />
          )}
          {mode === ESimpleHandlerMode.ESTIMATED_PRICE && (
            <PricingModeEstimatedPriceIndicators amounts={amounts} changeCost={handleChangeCost} />
          )}
        </div>
      </div>
    );
  }
);
