import cn from "classnames";
import React, { useCallback, useContext, useMemo } from "react";
import { useSelector } from "react-redux";

import {
  chartScrollMonthSelector,
  chartScrollYearSelector,
  chartViewModeSelector,
} from "redux/modules/common/chart/selectors";
import { CHART_VIEW_MODE } from "redux/modules/common/chart/types";

import { ChartContext } from "../../Chart";
import moment from "moment/moment";
import ButtonBase from "shared/ui/controls/ButtonBase";

import { ReactComponent as ArrowRight } from "shared/assets/icons/ArrowRigth.svg";

import styles from "./ChartScrollFAB.module.scss";

export interface ChartScrollFABProps {
  variant: "left" | "right";
}

const NOW_MOMENT = moment();

const ChartScrollFAB: React.FC<ChartScrollFABProps> = ({ variant }) => {
  const { handleDateChange } = useContext(ChartContext);
  const year = useSelector(chartScrollYearSelector);
  const month = useSelector(chartScrollMonthSelector);
  const chartViewMode = useSelector(chartViewModeSelector);
  const { yearSelectedMonths } = useContext(ChartContext);

  const currentDate = useMemo(() => moment({ year, month, date: 1 }), [year, month]);

  const scrollToNow = useCallback(() => {
    handleDateChange(NOW_MOMENT);
  }, [handleDateChange]);

  if (
    variant === "left" &&
    (chartViewMode === CHART_VIEW_MODE.MONTHS
      ? moment(currentDate).subtract(yearSelectedMonths, "months").isSameOrBefore(NOW_MOMENT, "month")
      : currentDate.isSameOrBefore(NOW_MOMENT, "month"))
  ) {
    return null;
  }

  if (
    variant === "right" &&
    (chartViewMode === CHART_VIEW_MODE.MONTHS
      ? moment(currentDate).add(yearSelectedMonths, "months").isSameOrAfter(NOW_MOMENT, "month")
      : currentDate.isSameOrAfter(NOW_MOMENT, "month"))
  ) {
    return null;
  }

  return (
    <ButtonBase
      primary
      onClick={scrollToNow}
      className={cn(styles.root, styles[variant])}
      title="Вернуться к сегодняшней дате"
    >
      {variant === "left" ? (
        <ArrowRight className={cn(styles.icon, styles.iconLeft)} />
      ) : (
        <ArrowRight className={styles.icon} />
      )}
    </ButtonBase>
  );
};

export default React.memo(ChartScrollFAB);
