//@ts-nocheck
import React, { FC, useMemo } from "react";

import ProductIntervalCardContent from "../../../../../pages/Journal/components/JournalDelivery/components/ProductIntervalCard/ProductIntervalCardContent";
import ManufacturingModalContentFact from "../../../../../pages/Manufacturing/components/modals/ManufacturingModal/ManufacturingModalContentFact";
import ManufacturingModalContentPlan from "../../../../../pages/Manufacturing/components/modals/ManufacturingModal/ManufacturingModalContentPlan";
import PlanEditing from "../../../../../pages/Manufacturing/components/modals/ManufacturingModal/PlanEditing/PlanEditing";
import { useApprovePlan } from "../../../../../pages/Manufacturing/components/modals/ManufacturingModal/PlanEditing/useApprovePlan";
import useCanApprovePlan from "../../../../../pages/Manufacturing/components/modals/ManufacturingModal/PlanEditing/useCanApprovePlan";
import { useCanEditPlan } from "../../../../../pages/Manufacturing/components/modals/ManufacturingModal/PlanEditing/useCanEditPlan";
import { manufacturingModalStatusSwitcher } from "../../../../../pages/Manufacturing/components/modals/ManufacturingModal/manufacturingModalStatusSwitcher";
import BottomControls from "../../../WorkOrMaterialsModals/components/BottomControls/BottomControls";
import MaterialBlockInWork from "../../../WorkOrMaterialsModals/components/MaterialsInWork/MaterialBlockInWork";
import ModalContainer from "../../../WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import NavBar from "../../../WorkOrMaterialsModals/components/NavBar/NavBar";
import WorkListItem from "../../../WorkOrMaterialsModals/components/WorkListItem/WorkListItem";
import WorkersList from "../../../WorkOrMaterialsModals/components/WorkersList/WorkersList";

import { Spinner } from "../../../../../../shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "../../../../../../shared/ui/controls/ButtonBase";
import ExpandableFileInput from "../../../../../../shared/ui/inputs/ExpandableFileInput/ExpandableFileInput";
import Expandable from "../../../../atoms/_TODO/Expandable/Expandable";
import { usePlanModalData } from "./usePlanModalData";
import DisplayAxesAndMarks from "widgets/AxesAndMarks/widgets/DisplayAxesAndMarks/DisplayAxesAndMarks";
import PlanEditingHistory from "widgets/EditingHistory/PlanEditingHistory";

import { IExpenditure } from "../../../../../../types/interfaces/Expenditure";

import { useHasEditPlanPermission } from "../../../../../../shared/lib/hooks/useHasEditPlanPermission";

import { splitThousands } from "../../../../../../utils/formatters/splitThousands";

import styles from "./PlanModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  expidentureId: number;
  expidentureObj: IExpenditure;
  sectionName: string;
  date_start: string;
  date_end: string;
  objectId: number;
  canDeleteFiles: boolean;
  loadProduct: () => void;
  sectionId: number;
  defaultOpenedIntervalId?: string | null;
}

const activeModule = "intervals";

const PlanModal: FC<IProps> = ({
  isOpen,
  onClose,
  expidentureId,
  expidentureObj,
  sectionName,
  date_start,
  date_end,
  objectId,
  canDeleteFiles,
  loadProduct,
  sectionId,
  defaultOpenedIntervalId = null,
}) => {
  const {
    fileRemoveHandler,
    fileUploadHandler,
    clearIntervalDataHandler,
    intervalDataFetcher,
    intervalClickHandler,
    backToIntervalsListHandler,
    list,
    intervalId,
    isLoading,
    data,
    files,
  } = usePlanModalData({
    isOpen,
    buildingId: objectId,
    expenditureId: expidentureId,
    dateEnd: date_end,
    dateStart: date_start,
    activeModule,
    defaultOpenedIntervalId,
  });

  const canApprovePlan = useCanApprovePlan({
    isActive: isOpen,
    isSection: false,
    approvals: data?.approvals,
    objectId,
  });

  const closeHandler = () => {
    onClose();
    clearIntervalDataHandler();
    setIsAddingNew(false);
    setIsEditingPlan(false);
  };

  const [isAddingNew, setIsAddingNew] = React.useState(false);
  const [isEditingPlan, setIsEditingPlan] = React.useState(false);

  const canEditPlan = useCanEditPlan({
    isActive: isOpen,
    isSection: false,
    approvals: data?.approvals,
    objectId,
  });

  const approveCallback = (id: number) => {
    intervalDataFetcher(id);
    loadProduct && loadProduct();
  };
  /* @ts-ignore */
  const approveHandler = useApprovePlan(Number(objectId), expidentureId, data?.id, {}, "expenditure", approveCallback);

  const closePlanEditingHandler = () => {
    setIsEditingPlan(false); /* @ts-ignore */
    intervalDataFetcher(data?.id);
  };

  const status = useMemo(
    () =>
      manufacturingModalStatusSwitcher({
        isPlan: true,
        status: data?.work_status,
      }),
    [data?.work_status]
  );

  const hasEditPlanPermission = useHasEditPlanPermission();

  if (isAddingNew) {
    return (
      <ModalContainer
        isOpen={isOpen}
        onClose={closeHandler}
        name={expidentureObj?.name}
        justification={data?.justification}
        statusColor={status.color}
        statusText={status.text}
      >
        {isAddingNew && (
          <NavBar isExist text="Вернуться к работе" onClick={() => setIsAddingNew(false)} className={styles.back} />
        )}
        {isAddingNew && (
          <ProductIntervalCardContent
            objectId={objectId}
            handleClose={() => setIsAddingNew(false)}
            directlySection={data?.section_id ? +data.section_id : undefined}
            directlyWork={data?.expenditure_id}
            isOpen={isOpen}
          />
        )}
      </ModalContainer>
    );
  }

  if (isEditingPlan) {
    return (
      <ModalContainer
        isOpen={isOpen}
        onClose={closeHandler}
        name={expidentureObj?.name}
        justification={data?.justification}
        statusColor={status.color}
        statusText={status.text}
      >
        <PlanEditing
          planCreator={data?.author}
          planCreatingDate={data?.created_at}
          productId={data?.expenditure_id}
          buildingId={objectId}
          intervalId={data?.id}
          planCount={data?.count}
          planDateStart={data?.start_at}
          planDateEnd={data?.end_at}
          onClose={closePlanEditingHandler}
          files={files}
          onDeleteFiles={fileRemoveHandler}
          onUploadFiles={fileUploadHandler}
          defaultMaterials={data?.planned_materials_list}
          defaultMims={data?.count_planned_services}
          defaultWorkers={data?.workers}
          defaultWorkersCounts={data?.count_workers_data}
          sectionId={sectionId}
          paymentDate={data?.payment_date}
          expemdituresIds={[data?.expenditure_id]}
          closeWholeModal={onClose}
          measure={data?.measure}
        />
      </ModalContainer>
    );
  }

  const hasApprovals = data?.approvals?.length;

  // Не находится в будущем
  const canAddInJournal = data?.work_status !== "planed" && !canApprovePlan;

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={closeHandler}
      name={expidentureObj?.name}
      justification={data?.justification}
      statusColor={status.color}
      statusText={status.text}
    >
      {isLoading && <Spinner />}
      {!intervalId && (
        <div className={styles.list}>
          {Array.isArray(list) &&
            list.map((el) => (
              <WorkListItem
                key={el.id}
                name={el.expenditure_name}
                measure={el.measure}
                passAmount={el.completed_amount}
                passCount={el.count}
                passDate={el.created_at}
                passName={el.author}
                acceptAmount={el.accepted?.[0]?.amount}
                acceptCount={el.accepted?.[0]?.count}
                acceptDate={el.accepted?.[0]?.created_at}
                acceptName={el.accepted?.[0]?.approve_user}
                hideAccepted={!el.accepted?.length}
                onClick={() => intervalClickHandler(el.id)}
              />
            ))}
        </div>
      )}
      {intervalId && !isLoading && (
        <>
          <NavBar onClick={backToIntervalsListHandler} className={styles.back} isExist={list?.length > 1} />
          <ManufacturingModalContentPlan
            planCreator={data?.author}
            planCreatingDate={data?.created_at}
            measure={data?.measure}
            dateStart={data?.start_at}
            dateEnd={data?.end_at}
            projectAmount={data?.work_amount}
            projectFullAmount={data?.expenditure_amount}
            projectFullCount={data?.expenditure_count}
            planCount={data?.count}
            planIsInWork={data?.work_status === "actived"}
            isEditing={isEditingPlan}
            approvals={data?.approvals}
            closureDate={data?.payment_date}
          />
          {activeModule === "fact-intervals" && (
            <ManufacturingModalContentFact
              dateStart={data?.start_at}
              dateEnd={data?.end_at}
              measure={data?.measure}
              passingAuthor={data?.author}
              passingDate={data?.created_at}
              passingCount={data?.count}
              investedAmount={data?.work_amount}
              acceptedAmount={data?.accepted?.[0]?.amount}
              acceptedAuthor={data?.accepted?.[0]?.approve_user}
              acceptedCount={data?.accepted?.[0]?.count}
              acceptedDate={data?.accepted?.[0]?.created_at}
              planCreator={data?.from_interval?.author}
              planCreatingDate={data?.from_interval?.created_at}
              planCount={data?.from_interval?.count}
              projectAmount={data?.from_interval?.work_amount}
              planApprover={data?.from_interval?.approved_user}
              planApprovingDate={data?.from_interval?.approved_at}
            />
          )}
          <div className={styles.info}>
            <p className={styles.subtitle}>Раздел:</p>
            <p className={styles.value}>{sectionName}</p>
          </div>
          <WorkersList workers={data?.workers} />
          {data?.planned_materials_list?.length ? (
            <Expandable title={`Материалы: ${data?.planned_materials_list?.length}`}>
              {data?.planned_materials_list?.map((el) => (
                <MaterialBlockInWork
                  key={el.material?.id}
                  name={el.material?.name}
                  measure={el.material?.measure}
                  count={el.count}
                  amount={el.material?.estimate_expenditure?.price * el.count}
                />
              ))}
            </Expandable>
          ) : null}
          {data?.count_planned_services?.length ? (
            <Expandable title={`Машины и Механизмы: ${data?.count_planned_services?.length}`}>
              {data?.count_planned_services?.map((el) => (
                <MaterialBlockInWork
                  key={el.id}
                  name={el.service?.name}
                  measure={el.service?.measure}
                  count={el.count}
                  amount={el.amount}
                />
              ))}
            </Expandable>
          ) : null}
          {!!files?.length && (
            <ExpandableFileInput
              files={files || []}
              onRemoveFileDirectly={fileRemoveHandler}
              onUploadFileDirectly={fileUploadHandler}
              canUploadFiles={false}
              canDeleteFiles={canDeleteFiles}
              isFileViewer
            />
          )}
          <br />
          <DisplayAxesAndMarks
            id={data?.id}
            planOrFact={activeModule === "fact-intervals" ? "fact" : "plan"}
            workOrGroup="work"
          />
          {/* {activeModule !== "fact-intervals" && (
            <PlanEditingHistory objectId={String(objectId)} ticketId={data?.id} isOpen={isOpen} isGroup={false} />
          )} */}
          <PlanEditingHistory objectId={objectId} ticketId={data?.id} isOpen={isOpen} isGroup={false} />
          <BottomControls isExists={activeModule !== "fact-intervals" && data?.work_status === "actived"} isDoubleBtns>
            <ButtonBase secondary onClick={() => setIsEditingPlan(true)}>
              Редактировать
            </ButtonBase>
            <ButtonBase onClick={() => setIsAddingNew(true)}>Добавить запись в ЖУ</ButtonBase>
          </BottomControls>
          <BottomControls
            isExists={(canEditPlan || canAddInJournal || canApprovePlan) && data?.work_status !== "to_payed"}
            isDoubleBtns
          >
            {canEditPlan && hasEditPlanPermission && (
              <ButtonBase secondary onClick={() => setIsEditingPlan(true)}>
                Редактировать
              </ButtonBase>
            )}
            {canAddInJournal && <ButtonBase onClick={() => setIsAddingNew(true)}>Добавить запись в ЖУ</ButtonBase>}
            {canApprovePlan && <ButtonBase onClick={approveHandler}>Согласовать</ButtonBase>}
          </BottomControls>
          <BottomControls isExists={data?.work_status === "to_payed"}>
            <div className={styles.sum}>
              Итого к оплате:
              <strong>{splitThousands(data?.total_sum_amount)}</strong>
            </div>
          </BottomControls>
        </>
      )}
    </ModalContainer>
  );
};

export default React.memo(PlanModal);
