import { message } from "antd";
import { Dispatch } from "redux";

import { refetchForecast } from "pages/Finance/Forecast/model/thunks";

import { extraForecastApi } from "./extraForecastApi";
import { extraForecastActions } from "./extraForecastSlice";
import { RootState } from "app/store/rootReducer";

import { IAddExtraExpenseForm } from "./types";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const createExtraForecast =
  (projectId: string, buildingId: string, data: IAddExtraExpenseForm, year: number) => (dispatch: Dispatch) => {
    const key = generateStorageKey({ buildingId, type: "create" });
    dispatch(extraForecastActions.setIsPending({ status: true, key }));
    extraForecastApi
      .create(buildingId, data)
      .then(() => {
        message.success("Сохранено");
        dispatch(refetchForecast(projectId, buildingId, "extraRoot", year) as any);
        dispatch(extraForecastActions.invalidateKey());
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(extraForecastActions.setIsPending({ status: false, key }));
      });
  };

export const getDetailedExtraForecast =
  (buildingId: string, id: number) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateStorageKey({ buildingId, type: "detail", expenditureId: id });
    if (!getState().extraForecast.detailedExpenses[key]) {
      dispatch(extraForecastActions.setIsPending({ status: true, key }));
    }
    extraForecastApi
      .getDetail(buildingId, id)
      .then(({ data }) => {
        dispatch(extraForecastActions.setDetailed({ key, data }));
      })
      .finally(() => {
        dispatch(extraForecastActions.setIsPending({ status: false, key }));
      });
  };

export const editExtraForecast =
  (projectId: string, buildingId: string, id: number, data: IAddExtraExpenseForm, year: number) =>
  (dispatch: Dispatch) => {
    const key = generateStorageKey({ buildingId, type: "edit" });
    dispatch(extraForecastActions.setIsPending({ status: true, key }));
    extraForecastApi
      .edit(buildingId, id, data)
      .then(() => {
        message.success("Сохранено");
        dispatch(refetchForecast(projectId, buildingId, "extraRoot", year) as any);
        dispatch(extraForecastActions.invalidateKey());
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(extraForecastActions.setIsPending({ status: false, key }));
      });
  };

export const deleteExtraForecast =
  (projectId: string, objectId: string, id: number, year: number) => (dispatch: Dispatch) => {
    const key = generateStorageKey({ buildingId: objectId, type: "delete" });
    dispatch(extraForecastActions.setIsPending({ status: true, key }));
    extraForecastApi
      .delete(objectId, id)
      .then(() => {
        message.success("Удалено");
        dispatch(refetchForecast(projectId, objectId, "extraRoot", year) as any);
        dispatch(extraForecastActions.invalidateKey());
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(extraForecastActions.setIsPending({ status: false, key }));
      });
  };
