import { message } from "antd";
import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { compose } from "redux";

import { getFileColorByExtension } from "../../atoms/_TODO/InputFiles/InputFiles";

import { sliceTextByConstraint } from "../../../../utils/formatters/sliceTextByConstraint";
import { getFileExtension } from "../../../../utils/getFileExtension";
import downloadFile from "../../../../utils/helpers/download";

import CrossInCircle from "../../../../images/icons/CrossInCircle";

import styles from "./FilesGrid.module.scss";

const MAX_FILE_NAME_LENGTH = 18;

const getFileName = (file) => file.originalname || file.name || file.file.name;

const FilesGrid = ({
  files = [],
  removeFile,
  maxDisplayingCount,
  isDisabled,
  canExport = true,
  _TODO_onDirectlyClick,
}) => {
  const [filesLoading, setFilesLoading] = useState({});

  const handleDownloadFile = useCallback(async (file) => {
    const fileUrl =
      (typeof file.file === "object" && file.file.link) ||
      file.link ||
      (typeof file.file === "string" && file.file) ||
      (typeof file.file_path === "string" && file.file_path);
    const fileName = getFileName(file);

    if (!fileUrl) {
      message.error("Невозможно выгрузить несохраненные файл");
      return;
    }

    setFilesLoading((prevState) => ({ ...prevState, [file.id]: true }));
    await downloadFile(fileUrl, fileName).catch(() => {
      message.error("Невозможно выгрузить файл");
    });
    setFilesLoading((prevState) => ({ ...prevState, [file.id]: false }));
  }, []);

  const slicedFilesByMaxDisplayingCount = useMemo(() => {
    if (!maxDisplayingCount) return [];
    return files.slice(0, maxDisplayingCount);
  }, [maxDisplayingCount, files]);

  const downloadIfCan = useCallback(
    (file, i) => () => {
      if (_TODO_onDirectlyClick) {
        _TODO_onDirectlyClick(i);
        return;
      }
      if (!canExport) return;
      handleDownloadFile(file);
    },
    [canExport, handleDownloadFile, _TODO_onDirectlyClick]
  );

  if (!slicedFilesByMaxDisplayingCount?.length && !files?.length) return null;

  return (maxDisplayingCount ? slicedFilesByMaxDisplayingCount : files)?.map((file, i) => {
    const extension = compose(getFileExtension, getFileName)(file);
    return (
      <div
        className={cn(styles.inputFilesList__file, { [styles.loading]: filesLoading[file.id] })}
        title={getFileName(file)}
        key={file.id}
      >
        {!isDisabled && (
          <div className={styles.inputFilesList__file_icon} onClick={() => removeFile(file.id)}>
            <CrossInCircle width={18} color="#FF4089" />
          </div>
        )}
        <div
          className={styles.inputFilesList__fileIcon}
          style={{ backgroundColor: getFileColorByExtension(extension) }}
          onClick={downloadIfCan(file, i)}
        >
          {extension}
        </div>
        <div className={styles.inputFilesList__fileName}>
          {sliceTextByConstraint(getFileName(file), MAX_FILE_NAME_LENGTH)}
        </div>
      </div>
    );
  });
};

export default React.memo(FilesGrid);
