import cn from "classnames";
import React from "react";

import { ReactComponent as Icon } from "./blue.svg";
import { ReactComponent as GroupIcon } from "./group.svg";

import styles from "./MatchingIcon.module.scss";

export enum MatchingStatusesEnum {
  group,
  outOfEstimate,
  manual,
  auto,
  orange,
}

const titlesByStatuses = {
  [MatchingStatusesEnum.group]: "Группа расценок",
  [MatchingStatusesEnum.outOfEstimate]: "Не сопоставлено со сметой. Требуется сопоставление",
  [MatchingStatusesEnum.manual]: "Ручное сопоставление со сметой",
  [MatchingStatusesEnum.auto]: "Автоматическое сопоставление со сметой",
  [MatchingStatusesEnum.orange]: "Сопоставлено с рабочей документацией",
};

interface IMatchingIconProps {
  status: MatchingStatusesEnum;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  disabled?: boolean;
  isWithoutMark?: boolean;
}

const MatchingIcon = ({ onClick, status, className, disabled, isWithoutMark }: IMatchingIconProps) => {
  const icon = status === MatchingStatusesEnum.group ? <GroupIcon /> : <Icon />;

  return (
    <button
      className={cn(styles.container, className)}
      type="button"
      disabled={disabled}
      title={titlesByStatuses[status]}
    >
      <div
        onClick={!disabled ? onClick : undefined}
        className={cn(styles.icon, {
          [styles.black]: status === MatchingStatusesEnum.outOfEstimate,
          [styles.blue]: status === MatchingStatusesEnum.auto,
          [styles.green]: status === MatchingStatusesEnum.manual,
          [styles.group]: status === MatchingStatusesEnum.group,
          [styles.orange]: status === MatchingStatusesEnum.orange,
        })}
      >
        {icon}
      </div>
      {!isWithoutMark && status === MatchingStatusesEnum.outOfEstimate && <span className={styles.attention}>!</span>}
    </button>
  );
};

export default React.memo(MatchingIcon);
