import classNames from "classnames";
import moment from "moment";
import React, { PropsWithChildren, ReactNode } from "react";

import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";
import { TASK_MODAL_TYPES, TASK_REMEMBER, TASK_REPEAT, getTaskModalTabs } from "components/pages/Tasks/constants";
import { ICertainTaskResponse } from "components/pages/Tasks/model/types";

import TaskHeadPriority from "../TaskHeadPriority/TaskHeadPriority";
import TaskModalStatus from "../TaskModalStatus/TaskModalStatus";
import { ReactComponent as CalendarIcon } from "./../TaskModalStatus/calendar.svg";
import { ReactComponent as ClockIcon } from "./../TaskModalStatus/clock.svg";
import { ReactComponent as RepeatIcon } from "./repeat.svg";
import TabBarNotLinks from "shared/ui/controls/TabBar/TabBarNotLinks";
import Avatar from "shared/ui/dataDisplay/Avatar/Avatar";
import { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import getShortFullName from "utils/formatters/getShortFullName";

import styles from "./TasksModalHead.module.scss";

const tabs: any = [];

interface IPropsNew {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  showTabs?: boolean;
}

const New = ({ activeTab, setActiveTab, showTabs = true }: IPropsNew) => {
  return (
    <header className={styles.new}>
      <div className={styles.title}>Новая задача</div>
      {showTabs && (
        <div className={styles.tabsContainer}>
          <TabBarNotLinks
            isOnlyTabsView
            tabs={getTaskModalTabs(TASK_MODAL_TYPES.ADD)}
            activeId={activeTab}
            onClick={setActiveTab}
            className={styles.tabs}
          />
        </div>
      )}
    </header>
  );
};

interface IPropsDisplay {
  task: ICertainTaskResponse;
}

const Display = ({ task }: IPropsDisplay) => {
  return (
    <header className={styles.display}>
      <div className={styles.head}>
        <div className={styles.title} title={task.name}>
          {task.name}
        </div>
        <TaskModalStatus task={task} />
      </div>
      <div className={styles.row2}>
        <LabeledItem label="Создал:">
          <div className={classNames(styles.bubble, styles.creator)}>
            <Avatar person={task?.creator_user} className={styles.avatar} />
            <TableReusableCell isNoBreak>{getShortFullName(task?.creator_user)}</TableReusableCell>
          </div>
        </LabeledItem>
        <LabeledItem label="Создано:">
          <div className={classNames(styles.bubble, styles.date)}>
            {moment(task.created_at).format("DD/MM/YYYY")}
            <CalendarIcon />
            <div className={styles.divider}></div>
            {moment(task.created_at).format("HH:mm")}
            <ClockIcon />
          </div>
        </LabeledItem>
        <LabeledItem label="Повтор:">
          <PopoverOverlay
            portalClassName={styles.repeatPortal}
            content={
              <div className={styles.repeatPopup}>
                <header>Напоминания и повторы:</header>
                <main>
                  {task.remember_task ? (
                    <span>{TASK_REMEMBER[task.remember_task[0] as keyof typeof TASK_REMEMBER]}</span>
                  ) : (
                    <span>Нет</span>
                  )}
                  <div className={styles.divider} />
                  {task.repeat_task ? (
                    <span>{TASK_REPEAT[task.repeat_task as keyof typeof TASK_REPEAT]}</span>
                  ) : (
                    <span>Нет</span>
                  )}
                </main>
              </div>
            }
            popoverBorderColor="gray"
            placement="bottom-end"
            openType="hover"
          >
            <div className={styles.bubble}>
              <RepeatIcon />
            </div>
          </PopoverOverlay>
        </LabeledItem>
      </div>
      <div className={styles.row2}>
        <LabeledItem label="Проект:">
          <TableReusableCell isFont13={false} isNoBreak className={styles.building}>
            {task.building_obj.name}
          </TableReusableCell>
        </LabeledItem>
        <TaskHeadPriority task={task} />
      </div>
      {!!task.section_obj?.name && (
        <div className={styles.row2}>
          <LabeledItem label="Раздел:">
            <TableReusableCell isFont13={false} isNoBreak className={styles.section}>
              {task.section_obj?.name}
            </TableReusableCell>
          </LabeledItem>
        </div>
      )}
    </header>
  );
};

const Edit = ({ task }: IPropsDisplay) => {
  return (
    <header className={styles.display}>
      <div className={styles.head}>
        <div className={styles.title} title={task.name}>
          Редактирование задачи
        </div>
      </div>
      <div className={styles.row2}>
        <LabeledItem label="Создал:">
          <div className={classNames(styles.bubble, styles.creator)}>
            <Avatar person={task?.creator_user} className={styles.avatar} />
            <TableReusableCell isNoBreak>{getShortFullName(task?.creator_user)}</TableReusableCell>
          </div>
        </LabeledItem>
        <LabeledItem label="Создано:">
          <div className={classNames(styles.bubble, styles.date)}>
            {moment(task.created_at).format("DD/MM/YYYY")}
            <CalendarIcon />
            <div className={styles.divider}></div>
            {moment(task.created_at).format("HH:mm")}
            <ClockIcon />
          </div>
        </LabeledItem>
      </div>
    </header>
  );
};

export const TasksModalHead = { New, Display, Edit };

function LabeledItem({ children, label }: PropsWithChildren & { label: ReactNode }) {
  return (
    <div className={styles.labeledItem}>
      <div className={styles.label}>{label}</div>
      {children}
    </div>
  );
}
