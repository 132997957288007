import { Dispatch } from "redux";

import { checkpointsViewListSelector, stateSelector } from "./selectors";
import { viewCheckpointApi } from "./viewCheckpointApi";
import { viewCheckpointStateActions } from "./viewCheckpointSlice";
import { RootState } from "app/store/rootReducer";
import { Checkpoint } from "widgets/AddCheckpoint";

import { errorCatcher } from "utils/helpers/errorCatcher";

export const openViewCheckpointModalList =
  ({ startDate, endDate, objectId }: { startDate?: string; endDate?: string; objectId: string }) =>
  (dispatch: Dispatch) => {
    dispatch(viewCheckpointStateActions.setIsLoading({ key: "list", status: true }));
    dispatch(viewCheckpointStateActions.setOpen(true));
    dispatch(viewCheckpointStateActions.setDates({ startDate, endDate }));
    dispatch(viewCheckpointStateActions.setObjectId(objectId));
    viewCheckpointApi
      .getList({ objectId, startDate, endDate })
      .then(({ data }) => {
        dispatch(viewCheckpointStateActions.setList(data.results));
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(viewCheckpointStateActions.setIsLoading({ key: "list", status: false }));
      });
  };

export const reloadViewCheckpointModalList = () => (dispatch: Dispatch, getState: () => RootState) => {
  dispatch(viewCheckpointStateActions.setIsLoading({ key: "list", status: true }));
  const {
    objectId,
    dates: { start, end },
  } = stateSelector(getState());
  viewCheckpointApi
    .getList({ objectId: objectId!, startDate: start, endDate: end })
    .then(({ data }) => {
      dispatch(viewCheckpointStateActions.setList(data.results));
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(viewCheckpointStateActions.setIsLoading({ key: "list", status: false }));
    });
};

export const closeCheckpointModal = () => (dispatch: Dispatch) => {
  dispatch(viewCheckpointStateActions.drop());
};

export const openCheckpointDetails = (checkpointId: number) => (dispatch: Dispatch) => {
  dispatch(viewCheckpointStateActions.setOpen(true));
  dispatch(viewCheckpointStateActions.setIsLoading({ key: "details", status: true }));
  viewCheckpointApi
    .get(checkpointId)
    .then(({ data }) => {
      dispatch(viewCheckpointStateActions.setDetails(data));
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(viewCheckpointStateActions.setIsLoading({ key: "details", status: false }));
    });
};

export const closeCheckpointDetails = () => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const checkpointsList = checkpointsViewListSelector(state);
  dispatch(viewCheckpointStateActions.setDetails(null));
  if (!checkpointsList.length) {
    dispatch(viewCheckpointStateActions.setOpen(false));
  }
};

export const deleteCurrentCheckpoint = () => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const { details, list } = state.viewCheckpointState;
  if (!details) return;
  dispatch(viewCheckpointStateActions.setList(list.filter((c) => c.id !== details.id)));
  //@ts-ignore
  dispatch(closeCheckpointDetails());
};

export const updateCheckpoint = (checkpoint: Checkpoint) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const { details, list } = state.viewCheckpointState;
  if (details?.id === checkpoint.id) {
    dispatch(viewCheckpointStateActions.setDetails(checkpoint));
  }
  dispatch(viewCheckpointStateActions.setList(list.map((c) => (c.id === checkpoint.id ? checkpoint : c))));
};
