import cn from "classnames";
import React, { memo } from "react";
import { useSelector } from "react-redux";

import Amount from "../../../../../../../../../../../shared/ui/dataDisplay/Amount/Amount";
import { estimateStateIdSelector, modeSelector } from "../../../../../../../model/selectors";

import ISection from "../../../../../../../../../../../types/interfaces/Section";
import { ESimpleHandlerMode } from "../../../../../../../types";

import { transformDigitToFinancial } from "../../../../../../../../../../../utils/formatters/transformDigitToFinancial";
import { getSectionAmount } from "../../../../../../../../ProHandler/utils/getSectionAmount";

import styles from "./SectionInfo.module.scss";

interface IProps {
  section: ISection | null;
}

const formatNumber = (v?: string | number) =>
  transformDigitToFinancial(v ?? 0, { withFloat: true, withCurrencySign: true, dropZeros: true });

export const SectionInfo = memo<IProps>(({ section }) => {
  const mode = useSelector(modeSelector);
  const activeEstimateStateId = useSelector(estimateStateIdSelector);

  const estimateAmountSource = +(
    section?.indicators?.estimate_amount_source ??
    section?.indicators?.estimate_amount ??
    0
  );
  const estimateAmount = +(getSectionAmount(section, activeEstimateStateId) || 0);
  const discountCost = +(section?.indicators?.discount_cost ?? 0);
  const discountPercent = +(section?.indicators?.discount_percent ?? 0);
  const markupCost = +(section?.markup_cost ?? 0);
  const markupPercent = estimateAmountSource === 0 ? 0 : (markupCost * 100) / estimateAmountSource;
  const estimatedCost = +(section?.indicators?.estimated_cost ?? 0);

  return (
    <div className={styles.sectionInfo}>
      <div className={styles.title}>{section?.name}</div>
      <div className={styles.budgetWrapper}>
        {mode === ESimpleHandlerMode.VIEW && (
          <div className={cn(styles.amountWrapper, styles.wide)}>
            <Amount value={getSectionAmount(section, activeEstimateStateId)} title="Бюджет1" isBold />
          </div>
        )}

        {mode === ESimpleHandlerMode.BUDGET && (
          <>
            <div className={cn(styles.amountWrapper, styles.wide)}>
              <Amount isLeft value={formatNumber(estimateAmountSource)} title="По смете" isBold />
            </div>

            <div className={styles.divider} />

            <div className={cn(styles.amountWrapper)}>
              <Amount isLeft value={`${discountPercent.toFixed(2)} %`} title="% скидки" isBold isPercent />
            </div>

            <div className={cn(styles.amountWrapper, styles.wide)}>
              <Amount isLeft value={formatNumber(discountCost)} title="Скидка в руб." isBold />
            </div>

            <div className={styles.divider} />

            <div className={cn(styles.amountWrapper, styles.wide)}>
              <Amount isLeft value={formatNumber(estimateAmount)} title="Бюджет" isBold />
            </div>
          </>
        )}

        {mode === ESimpleHandlerMode.ESTIMATED_PRICE && (
          <>
            <div className={cn(styles.amountWrapper)}>
              <Amount isLeft value={formatNumber(estimateAmount)} title="По смете" isBold />
            </div>

            <div className={cn(styles.amountWrapper, styles.wide)}>
              <Amount isLeft value={formatNumber(estimatedCost)} title="Рыночная стоимость" isBold />
            </div>

            <div className={styles.divider} />

            <div className={cn(styles.amountWrapper, styles.wide)}>
              <Amount isLeft value={formatNumber(estimateAmount - estimatedCost)} title="Разница" isBold />
            </div>
          </>
        )}

        {mode === ESimpleHandlerMode.MARKUP && (
          <>
            <div className={cn(styles.amountWrapper, styles.wide)}>
              <Amount isLeft value={formatNumber(estimateAmountSource)} title="По смете" isBold />
            </div>

            <div className={styles.divider} />

            <div className={cn(styles.amountWrapper)}>
              <Amount isLeft value={`${markupPercent.toFixed(2)} %`} title="% наценки" isBold isPercent />
            </div>

            <div className={cn(styles.amountWrapper, styles.wide)}>
              <Amount isLeft value={formatNumber(markupCost)} title="Наценка в руб." isBold />
            </div>

            <div className={styles.divider} />

            <div className={cn(styles.amountWrapper, styles.wide)}>
              <Amount isLeft value={formatNumber(estimateAmountSource + markupCost)} title="С учетом наценки" isBold />
            </div>
          </>
        )}
      </div>
    </div>
  );
});

SectionInfo.displayName = "SectionInfo";
