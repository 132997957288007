import React, { PropsWithChildren, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import AddExtraExpenseModal from "pages/Finance/Forecast/widgets/AddExtraExpenseModal/AddExtraExpenseModal";
import EditExtraExpenseModal from "pages/Finance/Forecast/widgets/AddExtraExpenseModal/EditExtraExpenseModal";
import { deleteExtraForecast } from "pages/Finance/Forecast/widgets/AddExtraExpenseModal/model/thunks";
import { useSummaryScroll } from "pages/Finance/_TODO/useSummaryScroll";
import SummaryHeadline from "pages/Finance/common/ui/SummaryOrForecastHeadline/SummaryOrForecastHeadline";

import { getForecastList, getForecastObject, getForecastProject } from "./model/thunks";
import ForecastTableHead from "./ui/ForecastTableHead/ForecastTableHead";
import ListSide from "./ui/ListSide/ListSide";
import TableSide from "./ui/TableSide/TableSide";
import ConfirmModal from "entities/ConfirmationModal/ConfirmModal";
import AddButton from "shared/ui/controls/AddButton/AddButton";

import { VIEW_FINANCE_ADD_EXTRA_FORECAST } from "constants/permissions/constructingPermissions";

import usePermission from "hooks/usePermission";

import styles from "./Forecast.module.scss";

interface IForecastProps extends PropsWithChildren {}

const Forecast: React.FC<IForecastProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { projectId, objectId } = useParams<Record<string, string>>();
  const { tableRef, year } = useSummaryScroll();
  const canAddExtraExpense = usePermission(VIEW_FINANCE_ADD_EXTRA_FORECAST);

  const showAdditionalExpenses = !!objectId && objectId !== "0";
  const showAddExtraExpenses = showAdditionalExpenses && canAddExtraExpense;

  const [isExtraModal, setIsExtraModal] = useState(false);
  const [openedExtraCost, setOpenedExtraCost] = useState(0);

  const [deletingExtra, setDeletingExtra] = useState(0);

  const confirmDelete = () => {
    dispatch(deleteExtraForecast(projectId, objectId, deletingExtra, year));
    setDeletingExtra(0);
  };

  useEffect(() => {
    if (projectId === "0") {
      dispatch(getForecastList(year));
    } else if (objectId === "0") {
      dispatch(getForecastProject(projectId, year));
    } else {
      dispatch(getForecastObject(projectId, objectId, year));
    }
  }, [projectId, objectId, year]);

  return (
    <div className={styles.summary}>
      <div className={styles.summary__listSide}>
        <ListSide
          onDeleteExtraItem={setDeletingExtra}
          onClickExtraItem={setOpenedExtraCost}
          additionalHeader={
            showAddExtraExpenses ? (
              <AddButton onClick={() => setIsExtraModal(true)} text="Статья расхода" textPosition="left" />
            ) : (
              <div style={{ height: "calc(2rem + 1px)" }}></div>
            )
          }
        >
          {children}
        </ListSide>
      </div>
      <div className={styles.summary__tableSide}>
        <div className={styles.sticky} ref={tableRef}>
          <SummaryHeadline location="forecast" />
          <ForecastTableHead />
        </div>
        <TableSide year={year} />
      </div>
      <AddExtraExpenseModal
        isOpen={isExtraModal}
        onClose={() => setIsExtraModal(false)}
        buildingId={objectId}
        year={year}
      />
      <EditExtraExpenseModal
        expenseId={openedExtraCost}
        isOpen={!!openedExtraCost}
        onClose={() => setOpenedExtraCost(0)}
        buildingId={objectId}
        year={year}
        /* TODO вынести этот year везде*/
      />
      <ConfirmModal
        title="Вы уверены что хотите удалить?"
        variant="secondary"
        isOpen={!!deletingExtra}
        onClose={() => setDeletingExtra(0)}
        action={confirmDelete}
        acceptButtonText="Удалить"
      />
    </div>
  );
};

export default Forecast;
