import React, { useState } from "react";

import Chapter from "../../components/Chapter";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import ButtonBase from "shared/ui/controls/ButtonBase";
import Modal from "shared/ui/modal/Modal";

import { useTypedParams } from "utils/hooks/useTypedParams";

interface IProps {
  title: string;
  activeTabId?: string;
  type: "section" | "subSection";
}

const MaterialsCreateSection = ({ title, activeTabId, type }: IProps) => {
  const objectId = useObjectId();
  const { sectionId: idSubsection } = useTypedParams<{ sectionId: string }>();
  const [isOpenModalAddSection, setIsOpenModalAddSection] = useState(false);

  return (
    <>
      {/* @ts-ignore */}
      <ButtonBase buttonPrimaryEmpty onClick={() => setIsOpenModalAddSection(true)}>
        {title}
      </ButtonBase>
      <Modal isOpen={isOpenModalAddSection} onClose={() => setIsOpenModalAddSection(false)} title={title}>
        <Chapter
          titleBtn={undefined}
          type={type}
          id={idSubsection}
          label={idSubsection ? "подраздел" : "раздел"}
          name={idSubsection ? "Наименование подраздела" : "Наименование раздела"}
          placeholder={idSubsection ? "Введите наименование подраздела" : "Введите наименование раздела"}
          objId={objectId}
          //typeUrl={activeTabId}
          close={() => setIsOpenModalAddSection(false)}
        />
      </Modal>
    </>
  );
};

export default MaterialsCreateSection;
