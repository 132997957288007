import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import ThreeDotsDropdown from "../../../../../../_LEGACY/UI/ThreeDotsDropdown/ThreeDotsDropdown";
import { useOpenPlanApprove } from "../../../../../pages/Building/hooks/useOpenPlanApprove";

import ReducedPlanSectionModal from "../../../../../modals/ManufacturingPlanSectionModal/ReducedPlanSectionModal";
import CreateFactModal from "../CreateFactModal/CreateFactModal";
import CreateGroupFactModal from "../CreateGroupFactModal/CreateGroupFactModal";
import FactModal from "../FactModal/FactModal";
import IntervalGroupModal from "../IntervalGroupModal/IntervalGroupModal";
import Intervals from "../Intervals/Intervals";
import PlanModal from "../PlanModal/PlanModal";
import CreatePlanModal from "../ProductIntervalCard/CreatePlanModal";
import RelatedWork from "../RelatedWork/RelatedWork";

import { EXPENDITURE_TYPES } from "../../../../../../constants/constant";
import { VARIANTS } from "../../constants";

import { transformDigitToFinancial } from "../../../../../../utils/formatters/transformDigitToFinancial";

import plusIcon from "../../../../../../images/icons/addOrOpenBtn.svg";

import styles from "../../Expenditure.module.scss";

const DefaultColumns = ({
  variant,
  expenditure,
  loadProduct,
  expenditureCount,
  currentInProductionIntervals,
  isExpendituresGroup,
  buildingId,
  onExpand,
  groupedPlanGroupIntervals,
  groupedFactGroupIntervals,
  loadSectionGroups,
  canAddIntervals,
  isInProductionIntervalCompleted,
  isShared,
  isExpanded,
  planIntervals,
  isPlanIntervalsCompleted,
  lsrPlanData,
  canDeleteFiles,
  factIntervals,
  directlyAction,
  sectionName,
  actions,
  sectionId,
}) => {
  const isHandlerDraftVariant = variant === VARIANTS.HANDLER_DRAFT;
  const isProgressVariant = variant === VARIANTS.PROGRESS;
  const isPlanVariant = variant === VARIANTS.PLAN;

  const history = useHistory();

  const [activeDateStart, setActiveDateStart] = useState("");
  const [activeDateEnd, setActiveDateEnd] = useState("");
  const [activeExpId, setActiveExpId] = useState(null);
  const [isFactIntervalInPlanning, setIsFactIntervalInPlanning] = useState(false);
  const [isOpenAddFactInterval, setIsOpenAddFactInterval] = useState(false);
  const [isOpenAddInterval, setIsOpenAddInterval] = useState(false);
  const [isLsrPlanModal, setIsLsrPlanModal] = useState(false);

  const { isNeedToOpenPlan, openPlanId, openPlanType, entityId } = useOpenPlanApprove();

  const currentGroupedGroupIntervals = useMemo(
    () =>
      variant === VARIANTS.PLAN && !isFactIntervalInPlanning ? groupedPlanGroupIntervals : groupedFactGroupIntervals,
    [variant, groupedFactGroupIntervals, groupedPlanGroupIntervals, isFactIntervalInPlanning]
  );

  const clickIntervalHandler = (id) => setActiveExpId(id);

  useEffect(() => {
    if (isNeedToOpenPlan) {
      if (+expenditure.id === +entityId) {
        if (openPlanType === "group") {
          setActiveExpId(openPlanId);
        }
        if (openPlanType === "work") {
          setActiveExpId(entityId);
        }
      }
    }
  }, [isNeedToOpenPlan, entityId]);

  const isShowInterval = isExpendituresGroup || expenditure.expenditure_type === EXPENDITURE_TYPES.WORK;

  const isSomeOfHandlersVariant = useMemo(
    () => [VARIANTS.HANDLER_PRODUCTION, VARIANTS.HANDLER_DRAFT, VARIANTS.HANDLER].indexOf(variant) !== -1,
    [variant]
  );

  const [aloneIntervalId, setAloneIntervalId] = useState();
  const handleCloseIntervalModal = useCallback(() => {
    setActiveExpId(null);
    setIsFactIntervalInPlanning(false);
    history.replace({ search: "" });
    setAloneIntervalId(null);
  }, []);

  const addNewHandler = useCallback(() => {
    switch (variant) {
      case VARIANTS.PROGRESS:
        setIsOpenAddFactInterval(true);
        break;
      case VARIANTS.PLAN:
        setIsOpenAddInterval(true);
        break;
      default:
    }
  }, [variant]);

  const isFactModalOpened =
    !!activeExpId && !isExpendituresGroup && (isSomeOfHandlersVariant || isProgressVariant || isFactIntervalInPlanning);
  const isPlanModalOpened = !!activeExpId && !isExpendituresGroup && !isFactModalOpened;

  return (
    <>
      {isHandlerDraftVariant && <div className={styles.priceCol}>{transformDigitToFinancial(expenditure.price)}</div>}
      {!isSomeOfHandlersVariant && (
        <>
          {canAddIntervals || isExpendituresGroup ? (
            <div className={classNames(styles.addIntervalCol, "addIntervalCol")}>
              <img className={styles.addIntervalIcon} src={plusIcon} alt="+" onClick={addNewHandler} />
            </div>
          ) : (
            <div className={styles.addIntervalCol} />
          )}
          {isShowInterval && (
            <div className={styles.intervalCol}>
              <Intervals
                items={currentInProductionIntervals}
                isExpendituresGroup={isExpendituresGroup}
                isExpand={isExpanded}
                onExpand={onExpand}
                fullCount={expenditureCount}
                intervalsColor="blue"
                approvedIntervalColor="green"
                isCompleted={isInProductionIntervalCompleted}
                isShared={isShared}
                onClickInterval={(id, date_start, date_end, _, intervalId) => {
                  setAloneIntervalId(intervalId);
                  clickIntervalHandler(id);
                  setActiveDateEnd(date_end);
                  setActiveDateStart(date_start);
                }}
                displayItemsConstraint={isSomeOfHandlersVariant ? 3 : 2}
              />
            </div>
          )}
        </>
      )}
      {expenditure.expenditure_type !== EXPENDITURE_TYPES.WORK && expenditure.related_work && (
        <RelatedWork relatedWork={expenditure.related_work} />
      )}
      {isShowInterval && (
        <>
          <div className={styles.intervalCol}>
            <Intervals
              items={planIntervals}
              isExpendituresGroup={isExpendituresGroup}
              isExpand={isExpanded}
              onExpand={onExpand}
              fullCount={expenditureCount}
              approvedIntervalColor="green"
              intervalsColor="green"
              isCompleted={isPlanIntervalsCompleted}
              isShared={isShared}
              onClickInterval={(id, date_start, date_end, lsrId) => {
                if (lsrId) {
                  setIsLsrPlanModal(lsrId);
                } else {
                  clickIntervalHandler(id);
                }
                setActiveDateEnd(date_end);
                setActiveDateStart(date_start);
              }}
              displayItemsConstraint={isSomeOfHandlersVariant ? 3 : 2}
            />
          </div>
          <div className={styles.intervalCol}>
            <Intervals
              items={factIntervals}
              isExpendituresGroup={isExpendituresGroup}
              isExpand={isExpanded}
              onExpand={onExpand}
              fullCount={expenditureCount}
              approvedIntervalColor="lightGreen"
              intervalsColor="lightGreen"
              isShared={isShared}
              onClickInterval={(id, date_start, date_end) => {
                isPlanVariant && setIsFactIntervalInPlanning(true);
                clickIntervalHandler(id);
                setActiveDateEnd(date_end);
                setActiveDateStart(date_start);
              }}
              displayItemsConstraint={isSomeOfHandlersVariant ? 3 : 2}
              hideArrow
            />
          </div>
        </>
      )}
      {(actions?.length !== 0 || directlyAction) && (
        <div className={styles.actionsCol}>
          {directlyAction ? directlyAction : <ThreeDotsDropdown items={actions} hoverTitle="Действия" />}
        </div>
      )}
      {isExpanded && (
        <button className={styles.collapseBtn} onClick={onExpand}>
          <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.853516 6.06641L6.00051 0.919434L11.1475 6.06641" fill="white" />
          </svg>
        </button>
      )}
      {isFactModalOpened && (
        <FactModal
          isOpen
          objectId={buildingId}
          expidentureId={+activeExpId}
          onClose={handleCloseIntervalModal}
          expidentureObj={expenditure}
          sectionName={sectionName}
          date_start={activeDateStart}
          date_end={activeDateEnd}
          canDeleteFiles={canDeleteFiles}
          loadProduct={loadProduct}
          sectionId={sectionId}
          defaultOpenedIntervalId={openPlanId}
        />
      )}
      {isPlanModalOpened && (
        <PlanModal
          objectId={buildingId}
          isOpen={isPlanModalOpened}
          expidentureId={+activeExpId}
          onClose={handleCloseIntervalModal}
          expidentureObj={expenditure}
          sectionName={sectionName}
          date_start={activeDateStart}
          date_end={activeDateEnd}
          canDeleteFiles={canDeleteFiles}
          loadProduct={loadProduct}
          sectionId={sectionId}
          defaultOpenedIntervalId={openPlanId || aloneIntervalId}
        />
      )}
      {!!activeExpId && isExpendituresGroup && (
        <IntervalGroupModal
          objectId={buildingId}
          isOpen={!!activeExpId && isExpendituresGroup}
          expenditureId={activeExpId}
          onClose={handleCloseIntervalModal}
          expenditure={currentGroupedGroupIntervals}
          sectionName={sectionName}
          activeModule={isProgressVariant || isFactIntervalInPlanning ? "facts" : "plans"}
          date_start={activeDateStart}
          date_end={activeDateEnd}
          sectionId={sectionId}
          defaultOpenedIntervalId={openPlanId}
        />
      )}
      {isOpenAddFactInterval && !isExpendituresGroup && (
        <CreateFactModal
          isOpen={isOpenAddFactInterval}
          handleClose={() => {
            loadProduct();
            setIsOpenAddFactInterval(false);
          }}
          product={expenditure}
          objectId={buildingId}
        />
      )}
      {isOpenAddFactInterval && isExpendituresGroup && (
        <CreateGroupFactModal
          isOpen={isOpenAddFactInterval}
          handleClose={() => {
            loadProduct();
            setIsOpenAddFactInterval(false);
          }}
          product={expenditure}
          objectId={buildingId}
          groupId={expenditure.id}
          loadSectionGroups={loadSectionGroups}
        />
      )}
      {isLsrPlanModal && (
        <ReducedPlanSectionModal
          isOpen={isLsrPlanModal}
          onClose={() => {
            setIsLsrPlanModal(false);
          }}
          lsrPlanData={lsrPlanData}
          objectId={buildingId}
          intervaldata={{ id: -1 }}
          sectionId={isLsrPlanModal}
        />
      )}
      {(isProgressVariant || isPlanVariant) && isOpenAddInterval && (
        <CreatePlanModal
          isOpen={isOpenAddInterval}
          handleClose={() => {
            loadProduct();
            setIsOpenAddInterval(false);
          }}
          product={expenditure}
          selectedWorkGroupId={isExpendituresGroup ? expenditure.id : null}
          loadSectionGroups={loadSectionGroups}
          sectionId={sectionId}
        />
      )}
    </>
  );
};

export default React.memo(DefaultColumns);
