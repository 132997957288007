import axios from "axios";

import { Checkpoint, CheckpointEditValues } from "widgets/AddCheckpoint";

import { CheckpointListItem } from "../types";
import { IListResponseData } from "types/ListResponse";

export const viewCheckpointApi = {
  getList: ({ objectId, startDate, endDate }: { objectId: string; startDate: string | undefined; endDate: string | undefined }) =>
    axios.get<IListResponseData<CheckpointListItem>>(`/check_points/`, {
      params: {
        building: objectId,
        check_point_date_gte: startDate,
        check_point_date_lte: endDate,
        limit: 500,
        ordering: "check_point_date",
      },
    }),
  get: (checkpointId: number) => axios.get<Checkpoint>(`/check_points/${checkpointId}/`),
  edit: (checkpointId: number, payload: Partial<CheckpointEditValues>) =>
    axios.put<Checkpoint>(`/check_points/${checkpointId}/`, payload),
  delete: (checkpointId: number) => axios.delete(`/check_points/${checkpointId}/`),
};
