import React from "react";

import { useCheckIsConstructingControl } from "features/constructingControl";

import { TEMPLATE_VARIANTS_ENUM } from "../../../../types/enums";
import { INotification } from "../../../../types/types";

interface IProps {
  notification: INotification;
}

const FormatNotificationConstrControl = ({ notification }: IProps) => {
  const isConstructingControl = useCheckIsConstructingControl();

  switch (notification.data.template) {
    case TEMPLATE_VARIANTS_ENUM.CONSTR_CONTROL_DECLINE:
      return isConstructingControl ? (
        <span>Доступен журнал учета объекта {notification.data.building_name}</span>
      ) : (
        <span>Стройконтроль отклонил работу на объекте {notification.data.building_name}</span>
      );
    case TEMPLATE_VARIANTS_ENUM.CONSTR_CONTROL_FACT_ACCEPTANCE_SEND:
      return isConstructingControl ? (
        <span>На объекте {notification.data.building_name} необходимо принять факт работ</span>
      ) : (
        <span>Факт работ отправлен на проверку Стройконтролю</span>
      );
    case TEMPLATE_VARIANTS_ENUM.CONSTR_CONTROL_ACCEPT:
      return isConstructingControl ? (
        <span>Доступен журнал учета объекта {notification.data.building_name}</span>
      ) : (
        <span>Стройконтроль начал работу на объекте {notification.data.building_name}</span>
      );
    case TEMPLATE_VARIANTS_ENUM.CONSTR_CONTROL_FACT_FULFILLMENT_SEND:
      return isConstructingControl ? (
        <span>На объекте {notification.data.building_name} необходимо принять факт работ</span>
      ) : (
        <span>Факт работ отправлен на проверку Стройконтролю</span>
      );
    case TEMPLATE_VARIANTS_ENUM.CONSTR_CONTROL_FACT_FULFILLMENT_ACCEPT:
      return isConstructingControl ? <span>Факт работ принят</span> : <span>Стройконтроль принял факт</span>;
    case TEMPLATE_VARIANTS_ENUM.CONSTR_CONTROL_FACT_KS3_SEND:
      return isConstructingControl ? (
        <span>На объекте {notification.data.building_name} необходимо принять акт работ</span>
      ) : (
        <span>Акт работ отправлен на проверку Стройконтролю</span>
      );
    case TEMPLATE_VARIANTS_ENUM.CONSTR_CONTROL_FACT_KS3_ACCEPT:
      return isConstructingControl ? <span>Акт работ принят</span> : <span>Стройконтроль принял акт</span>;
    default:
      return "";
  }
};

export default FormatNotificationConstrControl;

export function checkIsNotificationConstrControl(variant: TEMPLATE_VARIANTS_ENUM) {
  if (
    [
      TEMPLATE_VARIANTS_ENUM.CONSTR_CONTROL_DECLINE,
      TEMPLATE_VARIANTS_ENUM.CONSTR_CONTROL_FACT_ACCEPTANCE_SEND,
      TEMPLATE_VARIANTS_ENUM.CONSTR_CONTROL_ACCEPT,
      TEMPLATE_VARIANTS_ENUM.CONSTR_CONTROL_FACT_FULFILLMENT_SEND,
      TEMPLATE_VARIANTS_ENUM.CONSTR_CONTROL_FACT_FULFILLMENT_ACCEPT,
      TEMPLATE_VARIANTS_ENUM.CONSTR_CONTROL_FACT_KS3_SEND,
      TEMPLATE_VARIANTS_ENUM.CONSTR_CONTROL_FACT_KS3_ACCEPT,
    ].includes(variant)
  )
    return true;
  return false;
}
