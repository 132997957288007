import cn from "classnames";
import { memoize } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import { objectDetailSelector } from "../../../../../../../../redux/modules/common/building/object/nowObject";
import { intervalsLoadingSelector, loadIntervals } from "../../../../../../../../redux/modules/common/building/process";
import {
  changeEstimateItemCost,
  changeEstimateItemDiscount,
  deleteDefaultWorkAndRelatedResourcesInHandler,
  expendituresAreLoadingSelector,
  expendituresInvalidateKeySelector,
  expendituresSelector,
  getSectionWithoutLoading,
  handlerInvalidateKeySelector,
  loadExpenditures,
  resetExpendituresAction,
  resetSectionAction,
  sectionIsLoadingSelector,
  sectionSelector,
  swapExpenditures,
} from "../../../../../../../../redux/modules/common/building/sections/sections";
import { userSelector } from "redux/modules/_TODO/auth";

import { VARIANTS } from "../../../../../../../../components/UI/_TODO/Expenditure/constants";
import ExpenditureInHandlerAbleToEdit from "./components/Expenditure/ExpenditureInHandlerAbleToEdit";
import ManageExpenditureModal, {
  VARIANTS as MANAGE_EXPENDITURE_MODAL_VARIANTS,
} from "./components/ManageExpenditureModal/ManageExpenditureModal";
import ExpenditureTree from "components/UI/_TODO/ExpenditureTree/ExpenditureTree";

import { aggregationsInvalidateKeySelector } from "../../../../../Aggregations/model/selectors";
import AddExpenditures from "../../../AddExpenditure/AddExpenditures";
import EmptyMessage from "../../../EmptyMessage/EmptyMessage";
import ReplacedExpenditure, { IReplacementInExpenditure } from "./ReplacedExpenditure/ReplacedExpenditure";
import ReplacedExpenditureFilesModal from "./ReplacedExpenditure/ReplacedExpenditureFilesModal/ReplacedExpenditureFilesModal";
import { useDrag } from "react-dnd";
import { numberSortFunction } from "shared/lib/numberSortFunction/numberSortFunction";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import Actions from "shared/ui/controls/Actions/Actions";
import Amount from "shared/ui/dataDisplay/Amount/Amount";

import { ESTIMATE_ITEM_STATUSES, ESTIMATE_STATES_IDS } from "../../../../constants";
import { IExpenditureInHandlerProduction } from "./types";
import { LOCALIZATION_CONFIG } from "constants/localization";

import { PricingModes } from "../../../../hooks/usePricingMode";

import { beautifyNumberValue } from "../../../../../../../../utils/formatters/beautifyNumberValue";
import { transformDigitToFinancial } from "../../../../../../../../utils/formatters/transformDigitToFinancial";
import { stringifyArgs } from "../../../../../../../../utils/helpers/stringifyArgs";
import { buildExpendituresDictionaryByIds } from "../../../../utils/buildExpendituresDictionaryByIds";
import { getSectionAmount } from "../../../../utils/getSectionAmount";
import { groupHandlerExpendituresForEdit, prepareWorkAndRelatedResourcesForDeleting } from "./utils";

import PlusFillCircleIcon from "../../../../../../../../images/icons/PlusFillCircleIcon";

import styles from "./Expenditures.module.scss";

interface IProps {
  stickyHeaderTop: string;
  activeEstimateStateId: string;
  checkerItems: unknown;
  checkOnce: (a: any, b: boolean) => void;
  subsectionId: number;
  permissions: Record<string, boolean>;
  activePricingMode: unknown;
  visibilityChecked?: (id: number) => boolean;
  handleVisibilityCheck?: (item: any) => void;
}

const Expenditures: React.FC<IProps> = ({
  stickyHeaderTop = "0",
  activeEstimateStateId,
  checkerItems,
  checkOnce,
  subsectionId,
  permissions,
  activePricingMode,
  visibilityChecked,
  handleVisibilityCheck,
}) => {
  const statusFromQueryParams = new URL(window.location.href).searchParams.get("status");

  const dispatch = useDispatch();
  const activeSection = useSelector(sectionSelector);
  const activeSectionIsLoading = useSelector(sectionIsLoadingSelector);
  const expenditures = useSelector(expendituresSelector);
  const expendituresAreLoading = useSelector(expendituresAreLoadingSelector);
  const invalidateKey = useSelector(expendituresInvalidateKeySelector);
  const aggregationsInvalidateKey = useSelector(aggregationsInvalidateKeySelector);

  const user = useSelector(userSelector);
  const building = useSelector(objectDetailSelector);
  //@ts-ignore
  const userIsResponsibleEmployee = user?.id === building?.responsible_estimate?.id;
  //@ts-ignore
  const intervals = useSelector((state) => state.process.intervals);
  const intervalsAreLoading = useSelector(intervalsLoadingSelector);

  const [changedExpenditure, setChangedExpenditure] = useState(null);
  const [isManageExpenditureModalOpen, setIsManageExpenditureModalOpen] = useState(false);

  const [swappedExpenditure, setSwappedExpenditure] = useState(null);
  const [isSwapExpenditureModalOpen, setIsSwapExpenditureModalOpen] = useState(false);

  const isDraft = activeEstimateStateId === ESTIMATE_STATES_IDS.DRAFT;
  const isProduction = activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION;

  const [addedExpenditures, setAddedExpenditures] = useState<any[]>([]);
  const [editingWorks, setEditingWorks] = useState<number[]>([]);
  const isProductionOutOfEstimate = isProduction && activeSection?.is_default;
  const openManageExpenditureModal = useCallback(
    (expId?: number, expenditure?: IExpenditureInHandlerProduction) => {
      if (isProductionOutOfEstimate) {
        if (!expId) {
          setAddedExpenditures((prev) => [...prev, Date.now()]);
          return;
        } else {
          if (expenditure?.expenditure_type === "work") {
            setEditingWorks((prev) => [...prev, expId]);
          } else {
            !!expenditure?.related_work?.id && setEditingWorks((prev) => [...prev, expenditure?.related_work?.id!]);
          }
          return;
        }
      }
      setIsManageExpenditureModalOpen(true);
    },
    [isProductionOutOfEstimate]
  );

  const openSwapExpenditureModal = useCallback(() => setIsSwapExpenditureModalOpen(true), []);
  const closeSwapExpenditureModal = useCallback(() => setIsSwapExpenditureModalOpen(false), []);

  const resetManageExpenditure = useCallback(() => {
    setIsManageExpenditureModalOpen(false);
    setChangedExpenditure(null);
  }, []);

  const onEditExpenditure = useCallback(
    //@ts-ignore
    (expenditureId) => {
      if (!expenditures) return;
      //@ts-ignore
      const selectedExp = expenditures.results.find((expenditure) => expenditure?.id === expenditureId);
      //@ts-ignore
      setChangedExpenditure(selectedExp);
      openManageExpenditureModal(expenditureId, selectedExp);
    },
    [openManageExpenditureModal, expenditures?.results]
  );

  const onSwapExpenditure = useCallback(
    //@ts-ignore
    (expenditureId) => {
      if (!expenditures) return;
      //@ts-ignore
      setSwappedExpenditure(expenditures.results.find((expenditure) => expenditure?.id === expenditureId));
      openSwapExpenditureModal();
    },
    [openManageExpenditureModal, expenditures?.results]
  );

  const invalidateExpendituresKey = useSelector(handlerInvalidateKeySelector);
  const handleLoadExpenditures = useCallback(
    (isWithoutLoader = false) =>
      compose(dispatch, loadExpenditures)(
        //@ts-ignore
        { building: building.id, section: subsectionId, estimateState: activeEstimateStateId },
        statusFromQueryParams,
        isWithoutLoader
      ),
    [building?.id, subsectionId, activeEstimateStateId, statusFromQueryParams, invalidateExpendituresKey, invalidateKey]
  );

  const handleSwapExpenditures = useCallback(
    //@ts-ignore
    (swapWithId, path) => {
      //@ts-ignore
      if (!building?.id || !swappedExpenditure?.id || !swapWithId) return;
      //@ts-ignore
      dispatch(swapExpenditures(building.id, swappedExpenditure?.id, swapWithId, () => handleLoadExpenditures(true)));
    },
    [swappedExpenditure, building, handleLoadExpenditures]
  );

  const isShared = building?.shared_estimate_item_count !== 0;

  const [, drag] = useDrag(
    () => ({
      type: "box",
      item: [activeSection],
      canDrag: statusFromQueryParams !== ESTIMATE_ITEM_STATUSES.NEW && !isProduction,
    }),
    [activeSection, statusFromQueryParams, isProduction]
  );

  const handleCheckOnce = useMemo(
    () => memoize((estimateItemId) => (isChecked: boolean) => checkOnce(estimateItemId, isChecked), stringifyArgs),
    [checkOnce]
  );

  const checkedExpenditures = useMemo(() => {
    if (!expenditures) return {};

    const displayedExpendituresIds = buildExpendituresDictionaryByIds(expenditures.results);
    const checkedExpendituresDictionaryByIds = {};
    //@ts-ignore
    Object.entries(checkerItems)
      .filter(([, isChecked]) => isChecked)
      .forEach(([checkedExpenditureId]) => {
        //@ts-ignore
        if (!displayedExpendituresIds[checkedExpenditureId]) return;
        //@ts-ignore
        checkedExpendituresDictionaryByIds[checkedExpenditureId] = displayedExpendituresIds[checkedExpenditureId];
      });

    return checkedExpendituresDictionaryByIds;
  }, [checkerItems, expenditures?.results]);

  const expendituresByStatusAndPermission = useMemo<IExpenditureInHandlerProduction[]>(() => {
    if (!expenditures) return [];
    //if (statusFromQueryParams === ESTIMATE_ITEM_STATUSES.NEW && !userIsResponsibleEmployee) return [];
    //@ts-ignore
    if (!isProduction) return expenditures.results?.filter((exp) => !exp?.linked_expenditure_id);
    return expenditures.results;
  }, [expenditures, statusFromQueryParams, userIsResponsibleEmployee, isProduction]);

  const expendituresAbleToEdit = useMemo(() => {
    if (!isProductionOutOfEstimate) {
      return expendituresByStatusAndPermission.sort((a, b) => numberSortFunction(a.number, b.number));
    }
    return groupHandlerExpendituresForEdit(expendituresByStatusAndPermission, editingWorks);
  }, [expendituresByStatusAndPermission, editingWorks, isProductionOutOfEstimate]);

  const getExpenditureVariant = useCallback(() => {
    if (activePricingMode === PricingModes.budget) return VARIANTS.PRICING_MODE_BUDGET;
    if (activePricingMode === PricingModes.estimatedPrice) return VARIANTS.PRICING_MODE_ESTIMATED_PRICE;
    if (isDraft) return VARIANTS.HANDLER_DRAFT;
    if (isProduction) return VARIANTS.HANDLER_PRODUCTION;
    return VARIANTS.HANDLER;
  }, [isDraft, isProduction, activePricingMode]);

  const reloadActiveSection = useCallback(() => {
    if (!building?.id || !activeSection?.id) return;
    dispatch(getSectionWithoutLoading(building.id, activeSection.id, activeEstimateStateId));
  }, [building?.id, activeSection?.id, activeEstimateStateId]);

  const handleChangeExpenditureCost = useCallback(
    //@ts-ignore
    (expenditureId, cost) => {
      //@ts-ignore
      compose(dispatch, changeEstimateItemCost)(building.id, expenditureId, cost, reloadActiveSection);
      handleLoadExpenditures(true);
    },
    [building?.id, reloadActiveSection, handleLoadExpenditures]
  );

  const handleChangeExpenditureDiscount = useCallback(
    //@ts-ignore
    (expenditureId, discount) => {
      //@ts-ignore
      compose(dispatch, changeEstimateItemDiscount)(building.id, expenditureId, discount, reloadActiveSection);
      handleLoadExpenditures(true);
    },
    [building?.id, reloadActiveSection, handleLoadExpenditures]
  );
  //@ts-ignore
  useEffect(() => {
    handleLoadExpenditures();
    return () => compose(dispatch, resetExpendituresAction)();
  }, [building?.id, subsectionId, aggregationsInvalidateKey, invalidateExpendituresKey, invalidateKey]);

  useEffect(() => {
    //@ts-ignore
    compose(dispatch, loadIntervals)(building.id, subsectionId, "progress", null, null, true);
  }, []);
  //@ts-ignore
  useEffect(() => () => compose(dispatch, resetSectionAction)(), []);

  const isWithActions = userIsResponsibleEmployee;

  const [replacementsOpenId, setReplacementsOpenId] = React.useState<IReplacementInExpenditure | null>(null);

  const [deletingResourcesCount, setDeletingResourcesCount] = useState(0);
  const removeWorkAndRelatedResources = (expenditure: IExpenditureInHandlerProduction) => {
    const relatedResources = prepareWorkAndRelatedResourcesForDeleting(expenditure?.id, expendituresAbleToEdit);
    setDeletingResourcesCount(0);
    dispatch(deleteDefaultWorkAndRelatedResourcesInHandler(building?.id!, expenditure?.id, relatedResources));
  };

  const reCountDeletingResources = (expId: number) => {
    const relatedResources = prepareWorkAndRelatedResourcesForDeleting(expId, expendituresAbleToEdit);
    setDeletingResourcesCount(relatedResources.length);
  };

  if (expendituresAreLoading || activeSectionIsLoading || intervalsAreLoading) return <Spinner isStatic />;

  if (!activeSection || !intervals || !expenditures) return null;

  return (
    <div>
      <div className={styles.container} ref={drag}>
        <div className={styles.titleContainer}>
          <div className={styles.name}>{activeSection.name}</div>
          {(isDraft || isProductionOutOfEstimate) && (
            <button className={styles.addButton} onClick={() => openManageExpenditureModal() as any}>
              <span className={styles.text}>Добавить {isProductionOutOfEstimate ? "работу" : "позицию"}</span>
              <PlusFillCircleIcon />
            </button>
          )}
          {activePricingMode === PricingModes.view && (
            <>
              {!isShared && !isDraft && (!isProduction || activeSection.sum_child_subsections) && (
                <>
                  {/* @ts-ignore */}
                  <Amount
                    className={styles.amount}
                    title="Израсходовано"
                    value={activeSection.indicators.invested}
                    isLeft
                  />
                  {/* @ts-ignore */}
                  <Amount
                    className={styles.amount}
                    title="Выполнено"
                    value={activeSection.indicators.work_completed}
                    isLeft
                  />
                  {/* @ts-ignore */}
                  <Amount
                    className={styles.amount}
                    title="Принято"
                    value={activeSection.indicators.amount_accepted}
                    isLeft
                  />
                  <div className={styles.divider} />
                </>
              )}
              {/* @ts-ignore */}
              <Amount
                className={cn(styles.amount, styles.budget)}
                title="Бюджет"
                value={getSectionAmount(activeSection, activeEstimateStateId)}
                isLeft
              />
            </>
          )}
          {activePricingMode === PricingModes.budget && (
            <>
              {/* @ts-ignore */}
              <Amount
                className={styles.amount}
                title="По смете"
                value={activeSection.indicators.estimate_amount_source}
                isLeft
              />
              <div className={styles.divider} />
              {/* @ts-ignore */}
              <Amount
                className={styles.amount}
                title="% скидки"
                value={activeSection.indicators.discount_percent}
                isLeft
                isPercent
              />
              {/* @ts-ignore */}
              <Amount
                className={styles.amount}
                title="скидка в руб"
                value={activeSection.indicators.discount_cost}
                isLeft
              />
              <div className={styles.divider} />
              {/* @ts-ignore */}
              <Amount
                className={cn(styles.amount, styles.budget)}
                title="С учетом скидки"
                value={beautifyNumberValue(
                  +activeSection.indicators.estimate_amount_source - +activeSection.indicators.discount_cost
                )}
                isLeft
              />
            </>
          )}
          {activePricingMode === PricingModes.estimatedPrice && (
            <>
              {/* @ts-ignore */}
              <Amount
                className={styles.amount}
                title="По смете"
                value={getSectionAmount(activeSection, activeEstimateStateId)}
                isLeft
              />
              {/* @ts-ignore */}
              <Amount
                className={styles.amount}
                title="Расчетная цена"
                value={activeSection.indicators.estimated_cost}
                isLeft
              />
              <div className={styles.divider} />
              {/* @ts-ignore */}
              <Amount
                className={cn(styles.amount, styles.budget)}
                title="Разница"
                value={transformDigitToFinancial(
                  getSectionAmount(activeSection, activeEstimateStateId) - +activeSection.indicators.estimated_cost,
                  { withFloat: true, dropZeros: true }
                )}
                isLeft
              />
            </>
          )}
        </div>
        {expendituresByStatusAndPermission.length !== 0 && (
          <div>
            <header
              className={cn(styles.header, {
                [styles.draft]: isDraft,
                [styles.pricingModeBudget]: activePricingMode === PricingModes.budget,
                [styles.pricingModeEstimatedPrice]: activePricingMode === PricingModes.estimatedPrice,
              })}
              style={{ top: stickyHeaderTop }}
            >
              <div className={styles.numberCol}>№</div>
              <div className={styles.nameCol}>Наименование</div>
              <div className={styles.countCol}>
                Количество
                {activePricingMode !== PricingModes.view && <div className={styles.pricingModeDivider} />}
              </div>
              {activePricingMode === PricingModes.view && (
                <>
                  {isDraft && <div className={styles.priceCol}>Цена, {LOCALIZATION_CONFIG.currency}</div>}
                  <div className={styles.completedCol}>Выполнено</div>
                  <div className={styles.acceptedCol}>Принято</div>
                  {isWithActions && <div className={styles.actionsCol}>{!isProductionOutOfEstimate && "Действия"}</div>}
                </>
              )}
              {activePricingMode === PricingModes.budget && (
                <>
                  <div className={styles.priceCol}>
                    <div className={styles.pricingModeDivider} />
                    По смете
                  </div>
                  <div className={styles.discount}>
                    <div className={styles.pricingModeDivider} />
                    Скидка от подрядчика
                  </div>
                  <div className={styles.withDiscountAmount}>С учетом скидки, ₽</div>
                </>
              )}
              {activePricingMode === PricingModes.estimatedPrice && (
                <>
                  <div className={styles.budgetCol}>
                    <div className={styles.pricingModeDivider} />
                    Бюджет
                  </div>
                  <div className={styles.estimatePriceCol}>
                    <div className={styles.pricingModeDivider} />
                    Расчетная цена, ₽
                  </div>
                  <div className={styles.diffCol}>
                    <span className={styles.diffHeading}>Разница</span>
                    <span className={styles.diffPercent}>в %</span>
                    <span className={styles.diffPrice}>в ₽</span>
                  </div>
                </>
              )}
            </header>
            {expendituresAbleToEdit.map((expenditure) => (
              <React.Fragment key={`${expenditure?.id}_${invalidateKey}`}>
                <ExpenditureInHandlerAbleToEdit
                  onReloadExpenditures={handleLoadExpenditures} /* @ts-ignore */
                  changeExpenditureCost={handleChangeExpenditureCost}
                  changeExpenditureDiscount={handleChangeExpenditureDiscount}
                  activeEstimateStateId={activeEstimateStateId}
                  checkedExpenditures={checkedExpenditures} /* @ts-ignore */
                  isParentSectionChecked={checkerItems[+subsectionId]}
                  withActions={isWithActions}
                  isKSHidden={isProductionOutOfEstimate}
                  expenditure={expenditure}
                  intervals={intervals[expenditure?.id]}
                  buildingId={building?.id!}
                  loadProduct={handleLoadExpenditures}
                  variant={getExpenditureVariant()}
                  isShared={isShared} /* @ts-ignore */
                  isChecked={checkerItems[expenditure?.id]}
                  check={handleCheckOnce(expenditure?.id)}
                  sectionName={activeSection.name}
                  onEdit={onEditExpenditure}
                  onSwap={onSwapExpenditure}
                  permissions={permissions}
                  handleVisibilityCheck={handleVisibilityCheck}
                  visibilityChecked={visibilityChecked}
                  onFileDirectlyClick={
                    /* @ts-ignore */
                    !!expenditure?.replacements?.[0] /* @ts-ignore */
                      ? () => setReplacementsOpenId(expenditure?.replacements?.[0])
                      : undefined
                  }
                  isEditing={editingWorks.includes(expenditure?.id)}
                  sectionId={activeSection.id}
                  onCancelEdit={() => setEditingWorks((prev) => prev.filter((el) => el !== expenditure?.id))}
                  directlyAction={
                    isProductionOutOfEstimate && userIsResponsibleEmployee ? (
                      <Actions
                        canEdit={userIsResponsibleEmployee}
                        onEdit={() => openManageExpenditureModal(expenditure?.id, expenditure)}
                        canRemove={userIsResponsibleEmployee}
                        onRemove={() => removeWorkAndRelatedResources(expenditure)}
                        confirmDeleteText={
                          <span>
                            Вы действительно хотите удалить позицию?
                            <br />
                            {!!deletingResourcesCount &&
                              `Связанные ресурсы (${deletingResourcesCount} шт) тоже будут удалены.`}
                          </span>
                        }
                        onOpenConfirmModal={() => reCountDeletingResources(expenditure?.id)}
                      />
                    ) : undefined
                  }
                />
                {/* @ts-ignore */}
                {expenditure?.replacements?.map((el: IReplacementInExpenditure) => (
                  <ReplacedExpenditure
                    key={el.id}
                    expenditure={el}
                    buildingId={String(building?.id)}
                    intervals={intervals[expenditure?.id]} /* @ts-ignore */
                    allReplacements={expenditure?.replacements} /* @ts-ignore */
                    initialExpenditure={expenditure}
                  />
                ))}
              </React.Fragment>
            ))}
          </div>
        )}
        {isProductionOutOfEstimate && (
          <AddExpenditures
            buildingId={building?.id!}
            isOutOfEstimate={isProductionOutOfEstimate}
            sectionId={subsectionId}
            addedExpenditures={addedExpenditures}
            setAddedExpenditures={setAddedExpenditures}
            isBtnHidden={!!addedExpenditures?.length}
          />
        )}
      </div>
      {expendituresByStatusAndPermission.length === 0 && !isProductionOutOfEstimate && (
        <EmptyMessage message={isDraft ? "Чтобы продолжить, добавьте позицию сметы" : "Нет позиций"} />
      )}
      <ManageExpenditureModal
        variant={changedExpenditure ? MANAGE_EXPENDITURE_MODAL_VARIANTS.EDIT : MANAGE_EXPENDITURE_MODAL_VARIANTS.ADD}
        sectionId={subsectionId}
        buildingId={building?.id!}
        activeEstimateStateId={activeEstimateStateId}
        isOpen={isManageExpenditureModalOpen}
        onClose={resetManageExpenditure}
        initialValues={changedExpenditure}
        isOutOfEstimate={isProductionOutOfEstimate}
        isDraft={isDraft}
      />
      <ExpenditureTree
        currentExpenditure={swappedExpenditure}
        isOpen={isSwapExpenditureModalOpen} /* @ts-ignore */
        onClose={closeSwapExpenditureModal} /* @ts-ignore */
        buildingId={building?.id} /* @ts-ignore */
        onSubmit={handleSwapExpenditures}
        title={"Замена позиции"} /* @ts-ignore */
        subtitle={swappedExpenditure?.name}
        actionLabel={"Заменить"} /* @ts-ignore */
        expenditureType={swappedExpenditure?.expenditure_type}
        cantChoseShared
        targetStateId={
          /* @ts-ignore */
          swappedExpenditure?.confirmed || activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION
            ? ESTIMATE_STATES_IDS.PRODUCTION
            : ESTIMATE_STATES_IDS.DRAFT
        }
      />
      <ReplacedExpenditureFilesModal
        buildingId={building?.id!}
        isOpen={!!replacementsOpenId}
        onClose={() => setReplacementsOpenId(null)}
        replacementId={replacementsOpenId?.id!}
        name={replacementsOpenId?.data.name!}
        key={replacementsOpenId?.id}
      />
    </div>
  );
};

export default React.memo(Expenditures);
