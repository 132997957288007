import _ from "lodash";
import React from "react";

import { TotoRowTable } from "../_LEGACY_TotoRowTable/TotoRowTable";
import { OslaLoader } from "./OslaLoader/OslaLoader";
import { OslaPaginator } from "./OslaPaginator/OslaPaginator";

/**
 * @deprecated since version 2.0
 */
export class TableOslaLocal extends React.Component {
  state = {
    data: null,
    pagination: {
      params: { limit: 50, offset: 0 },
      page: 1,
    },
    sorting: null,
    isLoading: true,
    isStaticLoader: false,
    isFixedLoader: false,
    count: 150,
  };

  static getDerivedStateFromProps(props) {
    return {
      data: props.data,
      count: _.isEmpty(props.data) || !props.data ? null : props.data.length,
      isLoading: props.loadingState === undefined ? !props.data : props.loadingState,
      isStaticLoader: props.isStaticLoader,
      isFixedLoader: props.isFixedLoader,
    };
  }

  fetchPagination = (val) => {
    const { pagination } = this.state;

    const new_pagination = {
      params: {
        limit: pagination.params.limit,
        offset: pagination.params.limit * val,
      },
      page: val + 1,
    };

    this.setState({
      pagination: { ...new_pagination },
    });
  };

  fetchLimit = (val) => {
    const { pagination } = this.state;

    const new_pagination = {
      params: {
        limit: val,
        offset: val > pagination.params.offset ? 0 : pagination.params.offset,
      },
      page: val + 1,
    };

    this.setState({
      pagination: { ...new_pagination },
    });
  };

  fetchSorting = (val) => {
    this.props.changeSorting && this.props.changeSorting(val);
    this.setState({
      sorting: val,
    });
  };

  render() {
    const { data, pagination, isStaticLoader, isFixedLoader } = this.state;
    const { paginationHide } = this.props;

    const slicedData = data
      ? data.slice(pagination.params.offset, pagination.params.offset + pagination.params.limit)
      : null;

    return (
      <TotoRowTable
        tableClass={["table", "custom-table"]}
        headerClass={["custom-table__header"]}
        rowClass={["custom-table__row"]}
        containerTableClass={["table-container_custom"]}
        containerTbodyClass={["custom-table__body"]}
        cellClass={["custom-table__cell", this.props.cellClassName || ""]}
        headerSortClass={["sort-column"]}
        customLoader={<OslaLoader isStaticLoader={isStaticLoader} isFixedLoader={isFixedLoader} />}
        skeletonData={false}
        isLoading={this.props.isLoading}
        pagination={{ params: { ...pagination.params }, count: this.state.count }}
        limitChange={this.fetchLimit}
        pageChange={this.fetchPagination}
        limiter={{ values: [10, 20, 30, 50] }}
        customPaginator={OslaPaginator}
        paginator={{ maxValue: 5 }}
        changeSorting={this.fetchSorting}
        isMobileView={false}
        noDataPlate={this.props.emptyPlaceholder}
        {...this.props}
        data={paginationHide ? data : slicedData}
      >
        {this.props.children}
      </TotoRowTable>
    );
  }
}
