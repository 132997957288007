import classNames from "classnames";
import cn from "classnames";
import moment from "moment";
import React, { FC, useRef, useState } from "react";

import DownIcon2 from "./bottom.svg";
import { hours, minutes } from "./constatnts";
import TimeIcon from "./time.svg";
import UpIcon2 from "./top.svg";

import useOnClickOutside from "hooks/useOnClickOutside";

import DownIcon from "../../../../images/arrow-down-filled.svg";
import UpIcon from "../../../../images/arrow-up-filled.svg";

import styles from "./TimePicker.module.scss";

interface IProps {
  deadline: string;
  setValue: (date: moment.Moment) => void;
  disabled?: boolean;
  isCommonControls?: boolean;
}

export const TimePicker: FC<IProps> = ({ deadline, setValue, disabled, isCommonControls = true }) => {
  const [isOpen, setIsOpen] = useState(false);

  const deadlineHours = moment(deadline).format("HH");
  const deadlineMinutes = moment(deadline).format("mm");

  const wrapperRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(wrapperRef, () => {
    setIsOpen(false);
  });

  const openPickerHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isCommonControls) return;
    setIsOpen(true);
  };

  const increaseHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const result = moment(deadline).set("minute", +deadlineMinutes + 1);
    setValue(result);
  };

  const decreaseHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const result = moment(deadline).set("minute", +deadlineMinutes - 1);
    setValue(result);
  };

  const selectHourHandler = (value: string) => {
    const result = moment(deadline).set("hour", +value);
    setValue(result);
  };

  const selectMinuteHandler = (value: string) => {
    const result = moment(deadline).set("minute", +value);
    setValue(result);
  };

  const changeTimeHandler = (e: React.MouseEvent, unit: "minute" | "hour", direction: "top" | "down") => {
    e.preventDefault();
    e.stopPropagation();
    const offsetUnit = unit === "hour" ? deadlineHours : deadlineMinutes;
    const offset = direction === "top" ? +offsetUnit + 1 : +offsetUnit - 1;
    const result = moment(deadline).set(unit, offset);
    setValue(result);
  };

  return (
    <div
      className={classNames(
        styles.inputContainer,
        disabled && styles.disabled,
        !isCommonControls && styles.inputContainerV2
      )}
      tabIndex={1}
      onClick={openPickerHandler}
      ref={wrapperRef}
    >
      {!isCommonControls && (
        <div className={styles.timeBlock}>
          <span className={cn(styles.time, styles.timeV2)}>
            <span>{deadlineHours}</span>

            {!disabled && (
              <div className={styles.buttonsV2}>
                <button onClick={(e) => changeTimeHandler(e, "hour", "top")}>
                  <img src={UpIcon2} alt="" />
                </button>
                <button onClick={(e) => changeTimeHandler(e, "hour", "down")}>
                  <img src={DownIcon2} alt="" />
                </button>
              </div>
            )}
          </span>{" "}
          :
          <span className={cn(styles.time, styles.timeV2)}>
            <span>{deadlineMinutes}</span>
            {!disabled && (
              <div className={styles.buttonsV2}>
                <button onClick={(e) => changeTimeHandler(e, "minute", "top")}>
                  <img src={UpIcon2} alt="" />
                </button>
                <button onClick={(e) => changeTimeHandler(e, "minute", "down")}>
                  <img src={DownIcon2} alt="" />
                </button>
              </div>
            )}
          </span>
        </div>
      )}
      {!isCommonControls && <img src={TimeIcon} className={styles.timeIcon} />}
      {isCommonControls && (
        <>
          <span className={styles.time}>{deadlineHours}</span> : <span className={styles.time}>{deadlineMinutes}</span>
        </>
      )}
      {!disabled && isCommonControls && (
        <div className={styles.buttons}>
          <button onClick={increaseHandler}>
            <img src={DownIcon} alt="" />
          </button>
          <button onClick={decreaseHandler}>
            <img src={UpIcon} alt="" />
          </button>
        </div>
      )}
      {isOpen && (
        <div className={styles.timeSelectContainer}>
          <ul className={styles.timeList}>
            {hours.map((hour) => (
              <li onClick={() => selectHourHandler(hour)}>{hour}</li>
            ))}
          </ul>
          <ul className={styles.timeList}>
            {minutes.map((minute) => (
              <li onClick={() => selectMinuteHandler(minute)}>{minute}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
