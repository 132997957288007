import classNames from "classnames";
import cn from "classnames";
import React, { ChangeEventHandler, FC, ReactNode } from "react";

import { LabelPlacement } from "../Checkbox/Checkbox";

import styles from "./Switch.module.scss";

interface IProps {
  onChange: ChangeEventHandler<HTMLInputElement>;
  value?: string | number;
  checked: boolean;
  disabled?: boolean;
  label?: ReactNode;
  className?: string;
  labelPlacement?: LabelPlacement;
  labelRight?: ReactNode;
}

const SwitchComponent: FC<IProps> = ({
  onChange,
  value,
  checked,
  disabled,
  label,
  className,
  labelPlacement = LabelPlacement.LEFT,
  labelRight,
  ...props
}) => {
  return (
    <div {...props} className={classNames(styles.container, className)}>
      {label && labelPlacement === LabelPlacement.LEFT && <label className={styles.label}>{label}</label>}
      <input
        className={styles.switcher}
        type="checkbox"
        value={value}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        id={`switcher`}
      />
      {((label && labelPlacement === LabelPlacement.RIGHT) || labelRight) && (
        <label htmlFor="switcher" className={cn(styles.label, styles.right)}>
          {labelRight ?? label}
        </label>
      )}
    </div>
  );
};

export const Switch = React.memo(SwitchComponent);
