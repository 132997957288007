import classNames from "classnames";
import moment from "moment";
import React, { useMemo } from "react";

import { ICertainTaskResponse } from "components/pages/Tasks/model/types";

import { ReactComponent as CalendarIcon } from "./../../../../../TasksModalV2/ui/TaskModalStatus/calendar.svg";
import { ReactComponent as ClockIcon } from "./../../../../../TasksModalV2/ui/TaskModalStatus/clock.svg";

import declOfNum from "utils/formatters/declOfNum";

import styles from "./TaskModalDisplayDeadline.module.scss";

interface IProps {
  task: ICertainTaskResponse;
}

const TaskModalDisplayDeadline = ({ task }: IProps) => {
  const { days, hours, isExpired } = useMemo(() => {
    const restTimeSec = moment(task.deadline).diff(moment());
    const days = Math.floor(restTimeSec / 60 / 60 / 24 / 1000);
    const hours = Math.floor((restTimeSec - days * 60 * 60 * 24 * 1000) / 60 / 60 / 1000);
    return {
      days,
      hours,
      isExpired: restTimeSec < 0,
    };
  }, [task]);

  return (
    <div className={styles.root}>
      <>
        <span>{moment(task.deadline).format("DD/MM/YYYY")}</span>
        <CalendarIcon />
        <div className={styles.divider}></div>
        <span>{moment(task.deadline).format("HH:mm")}</span>
        <ClockIcon />
        {!isExpired && (
          <div className={classNames(styles.deadline)}>
            осталось: <>{!!days ? `${declOfNum(days, ["день", "дня", "дней"])} и` : ""}</>{" "}
            {declOfNum(hours, ["час", "часа", "часов"])}
          </div>
        )}
        {isExpired && <div className={classNames(styles.deadline, styles.expired)}>Задача просрочена</div>}
      </>
    </div>
  );
};

export default TaskModalDisplayDeadline;
