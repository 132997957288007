import {
  VIEW_MANUFACTURING_MATERIALS_ADDITIONAL,
  VIEW_MANUFACTURING_MATERIALS_ADDITIONAL_EDITING,
  VIEW_MANUFACTURING_MATERIALS_ESTIMATE,
  VIEW_MANUFACTURING_MATERIALS_ESTIMATE_EDITING,
  VIEW_MANUFACTURING_MATERIALS_SPECIFICATION,
  VIEW_MANUFACTURING_MATERIALS_SPECIFICATION_EDITING,
} from "constants/permissions/manufacturingPermissions";

import usePermission from "hooks/usePermission";

export const materialsUtils = {
  useMaterialsPermissions: () => {
    const haveEstimatePermission = usePermission(VIEW_MANUFACTURING_MATERIALS_ESTIMATE);
    const haveSpecificationPermission = usePermission(VIEW_MANUFACTURING_MATERIALS_SPECIFICATION);
    const haveAdditionalPermission = usePermission(VIEW_MANUFACTURING_MATERIALS_ADDITIONAL);

    const haveEditEstimatePermission = usePermission(VIEW_MANUFACTURING_MATERIALS_ESTIMATE_EDITING);
    const haveEditSpecificationPermission = usePermission(VIEW_MANUFACTURING_MATERIALS_SPECIFICATION_EDITING);
    const haveEditAdditionalPermission = usePermission(VIEW_MANUFACTURING_MATERIALS_ADDITIONAL_EDITING);

    return {
      haveEstimatePermission,
      haveSpecificationPermission,
      haveAdditionalPermission,
      haveEditEstimatePermission,
      haveEditSpecificationPermission,
      haveEditAdditionalPermission,
    };
  },
};
