import axios from "axios";

import { IConstrControlAcc } from "./types";
import { IListResponseData } from "types/ListResponse";

export const settingsProjectApi = {
  getConstControlAccounts: () => {
    return axios.get<IListResponseData<IConstrControlAcc>>("/construction_control/accounts/", {
      params: { limit: 500 },
    });
  },
};
