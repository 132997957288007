import React, { FC, useCallback, useEffect, useState } from "react";

import ConfirmWriteOffExpenditure, {
  ActionHandler,
  IExpenditure,
} from "components/pages/Stock/components/WriteOff/components/ConfirmDeliveryToProduction/ConfirmWriteOffExpenditure/ConfirmWriteOffExpenditure";

import ConfirmRepository from "./domain/ConfirmRepository";
import ButtonBase from "shared/ui/controls/ButtonBase";
import Modal from "shared/ui/modal/Modal";

import Check from "images/icons/Check";

import styles from "./ConfirmDeliveryToProduction.module.scss";

export interface IConfirmDeliveryToProductionProps {
  expenditures: IExpenditure[];
  onSubmit: (expendituresIds: IExpenditure["id"][]) => void;
  onCancel: () => void;
  onChangeProductWriteOffTarget: ActionHandler;
  onConfirm: (productId: number, status: boolean) => void;
}

const ConfirmDeliveryToProduction: FC<IConfirmDeliveryToProductionProps> = ({
  expenditures,
  onSubmit,
  onCancel,
  onChangeProductWriteOffTarget,
  onConfirm,
}) => {
  const [confirmRepository, setConfirmRepository] = useState(new ConfirmRepository<IExpenditure>(expenditures));

  const onConfirmExpenditure: ActionHandler = useCallback(
    (expenditureId) => {
      confirmRepository.confirm(expenditureId);
      setConfirmRepository(new ConfirmRepository<IExpenditure>(confirmRepository.confirmableItems));
      onConfirm(+expenditureId, true);
    },
    [confirmRepository, onConfirm]
  );

  const onCancelExpenditure: ActionHandler = useCallback(
    (expenditureId) => {
      confirmRepository.cancel(expenditureId);

      if (confirmRepository.confirmableItems.length !== 0) {
        setConfirmRepository(new ConfirmRepository<IExpenditure>(confirmRepository.confirmableItems));
      } else {
        onCancel();
      }
      onConfirm(+expenditureId, false);
    },
    [confirmRepository, onCancel, onConfirm]
  );

  const handleOnSubmit = useCallback(() => {
    onSubmit(confirmRepository.confirmableItems.map((item) => item.id));
  }, [onSubmit, confirmRepository]);

  return (
    <Modal
      title={`Подтвердить назначение: ${confirmRepository.toConfirmCount > 0 ? confirmRepository.toConfirmCount : ""}`}
      onClose={onCancel}
      closeOnClickOutside={false}
      isOpen
    >
      <div data-testid="confirmDeliveryToProduction">
        <div className={styles.expenditures}>
          {confirmRepository.confirmableItems.map((expenditure) => (
            <ConfirmWriteOffExpenditure
              expenditure={expenditure}
              onConfirm={onConfirmExpenditure}
              onCancel={onCancelExpenditure}
              onChangeWriteOffTarget={onChangeProductWriteOffTarget}
              key={expenditure.id}
            />
          ))}
        </div>
        <footer className={styles.footer}>
          <ButtonBase data-testid="cancelDeliveryButton" onClick={onCancel} secondary>
            Отменить
          </ButtonBase>
          <ButtonBase
            data-testid="submitDeliveryButton"
            disabled={!confirmRepository.checkAreAllConfirm()}
            onClick={handleOnSubmit}
            primary
          >
            <Check />
            Подтвердить
          </ButtonBase>
        </footer>
      </div>
    </Modal>
  );
};

export default React.memo(ConfirmDeliveryToProduction);
