import React, { FC, RefObject, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import { MAIN_MODAL_TAB, TASK_MODAL_TYPES } from "components/pages/Tasks/constants";
import { useGetTask } from "components/pages/Tasks/hooks/useGetTask";
import { useTasksModal } from "components/pages/Tasks/hooks/useTasksModal";
import { useUpdateTask } from "components/pages/Tasks/hooks/useUpdateTask";
import { approveTask } from "components/pages/Tasks/model/thunks";

import { TasksModalHead } from "../../../TasksModalV2/ui/TasksModalHead/TasksModalHead";
import ModalEditTask from "../ModalEditTask/ModalEditTask";
import ModalViewTask from "../ModalViewTask/ModalViewTask";
import TaskReassignPopup from "../ModalViewTask/ui/TaskReassignPopup/TaskReassignPopup";
import { useTypedSelector } from "app/store/typedUseSelector";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";

import tasksAll from "images/menu-icons/tasksAll.svg";

import styles from "./ModalTaskExisting.module.scss";

interface IProps {
  handleClose: any;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  formRef: RefObject<HTMLFormElement>;
  //TODO
  handleCancelButton: any;
  handleSaveButton: any;
}

const ModalTaskExisting: FC<IProps> = ({
  handleClose,
  activeTab,
  setActiveTab,
  formRef,
  handleCancelButton,
  handleSaveButton,
}) => {
  const dispatch = useDispatch();
  const { id: myId } = useTypedSelector((state) => state.auth.user);
  const { onClose, type, isOpen, taskId, changeModalType, deleteFilesOnCancel, placementType, listType, list_id } =
    useTasksModal();
  const { certainTask, isCertainTaskLoading } = useGetTask(taskId, isOpen);
  const {
    onSubmit,
    building,
    section_id,
    setSectionId,
    executor,
    setExecutor,
    viewers,
    setViewers,
    isTaskStatusEditable,
    updateTaskStatusDirectly,
  } = useUpdateTask({ taskId, placementType, listType, list_id, onSuccess: () => {} });

  const isLoading = isCertainTaskLoading && type !== TASK_MODAL_TYPES.ADD;
  const isDeletedTask = type !== TASK_MODAL_TYPES.ADD && !isCertainTaskLoading && !certainTask;

  const isMeExecutor = +certainTask?.executor === +myId;
  const isMeReviewer = certainTask?.viewers.some((id) => +id === +myId);
  const isMeCreator = +certainTask?.creator === +myId;

  const showApproveButton = useMemo(() => {
    return certainTask?.approves_data?.some(
      (approver) => +approver.approver.id === +myId && approver.status !== "approved"
    );
  }, [certainTask, myId]);

  const approveId = useMemo(() => {
    return certainTask?.approves_data?.find((approver) => +approver.approver.id === +myId)?.id;
  }, [certainTask, myId]);

  const hideStatusBtns = useMemo(() => {
    return certainTask?.approves_data?.some((el) => el.status !== "approved");
  }, [certainTask?.approves_data]);

  const showEditButton =
    (isMeExecutor || isMeReviewer || isMeCreator) &&
    (certainTask?.status === "to_do" || certainTask?.status === "in_work" || certainTask?.status === "expired") &&
    type === TASK_MODAL_TYPES.VIEW;

  const [isAssignPopup, setIsAssignPopup] = useState(false);

  if (isLoading) {
    return (
      <SliderModal isOpen={isOpen} closeHandler={handleClose} className={styles.modal}>
        <Spinner />
      </SliderModal>
    );
  }

  if (isDeletedTask) {
    return (
      <SliderModal isOpen={isOpen} closeHandler={handleClose} className={styles.modal}>
        <EmptyPlaceholder text="Задача была удалена" img={tasksAll} />
      </SliderModal>
    );
  }

  if (type === TASK_MODAL_TYPES.EDIT) {
    return (
      <SliderModal isOpen={isOpen} closeHandler={handleClose} className={styles.modal}>
        <TasksModalHead.Edit task={certainTask} />
        <ModalEditTask
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          formRef={formRef}
          handleCloseModal={handleClose}
          onSave={() => setActiveTab(MAIN_MODAL_TAB)}
          handleCancelButton={handleCancelButton}
          handleSaveButton={handleSaveButton}
        />
      </SliderModal>
    );
  }

  const handleEditButton = () => changeModalType(TASK_MODAL_TYPES.EDIT);

  const showAcceptButton =
    (isMeCreator || isMeReviewer) &&
    certainTask?.status === "completed" &&
    type === TASK_MODAL_TYPES.VIEW &&
    !hideStatusBtns;

  const showToReworkButton =
    (isMeCreator || isMeReviewer) &&
    certainTask?.status === "completed" &&
    type === TASK_MODAL_TYPES.VIEW &&
    !hideStatusBtns;

  const showStartWorkButton =
    isMeExecutor && certainTask?.status === "to_do" && type === TASK_MODAL_TYPES.VIEW && !hideStatusBtns;

  const showSendToReviewButton =
    isMeExecutor && certainTask?.status === "in_work" && type === TASK_MODAL_TYPES.VIEW && !hideStatusBtns;

  return (
    <SliderModal isOpen={isOpen} closeHandler={handleClose} className={styles.modal}>
      <TasksModalHead.Display task={certainTask} />
      <ModalViewTask activeTab={activeTab} setActiveTab={setActiveTab} />
      <BottomControls isExists isDoubleBtns className={styles.bottom} borderVariant="blue">
        {showEditButton && (
          <ButtonBase secondary onClick={handleEditButton}>
            Редактировать
          </ButtonBase>
        )}
        <ButtonBase secondary onClick={() => setIsAssignPopup(true)}>
          Передать задачу
        </ButtonBase>

        {showAcceptButton && (
          <ButtonBase primary onClick={() => updateTaskStatusDirectly("closed")}>
            Принять задачу
          </ButtonBase>
        )}
        {showToReworkButton && (
          <ButtonBase secondary onClick={() => updateTaskStatusDirectly("to_do")}>
            На доработку
          </ButtonBase>
        )}
        {showStartWorkButton && (
          <ButtonBase primary onClick={() => updateTaskStatusDirectly("in_work")}>
            Взять в работу
          </ButtonBase>
        )}
        {showSendToReviewButton && (
          <ButtonBase primary onClick={() => updateTaskStatusDirectly("completed")}>
            Отправить на проверку
          </ButtonBase>
        )}
      </BottomControls>
      {isAssignPopup && (
        <TaskReassignPopup task={certainTask} buildingId={building?.id} onClose={() => setIsAssignPopup(false)} />
      )}
    </SliderModal>
  );
};

export default ModalTaskExisting;
