import { message } from "antd";
import moment from "moment";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import ModalContainer from "components/UI/_TODO/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import ModalContentSplitter from "components/UI/atoms/ModalContentSplitter/ModalContentSplitter";

import {
  checkpointsViewDatesSelector,
  checkpointsViewDetailsSelector,
  checkpointsViewIsLoadingSelector,
  checkpointsViewIsOpenSelector,
  checkpointsViewListSelector,
} from "./model/selectors";
import {
  closeCheckpointDetails,
  closeCheckpointModal,
  deleteCurrentCheckpoint,
  openCheckpointDetails,
  reloadViewCheckpointModalList,
  updateCheckpoint,
} from "./model/thunks";
import { viewCheckpointApi } from "./model/viewCheckpointApi";
import { CheckpointDetails } from "./ui/CheckpointDetails/CheckpointDetails";
import { CheckpointPreview } from "./ui/CheckpointPreview/CheckpointPreview";
import ConfirmationModal from "entities/ConfirmationModal/ConfirmModal";
import { fileUploadActions } from "entities/FileUpload/model/slice";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import {
  AddCheckpoint,
  Checkpoint,
  CheckpointEditValues,
  CheckpointForm,
  useEditCheckpoint,
} from "widgets/AddCheckpoint";

import { IFilesBase64Format } from "types/interfaces/Files";

import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";
import { errorCatcher } from "utils/helpers/errorCatcher";

import checkIcon from "images/icons/check-blue.svg";

export interface ViewCheckpointProps {
  objectId: string;
  deleteSuccessCallback?: (deletedCheckpoint: Checkpoint) => void;
  editSuccessCallback?: (prevCheckpoint: Checkpoint, nextCheckpoint: Checkpoint) => void;
  addCheckpointSuccessCallback?: (checkpoint: Checkpoint) => void;
}

export const ViewCheckpoint: React.FC<ViewCheckpointProps> = ({
  objectId,
  deleteSuccessCallback,
  editSuccessCallback,
  addCheckpointSuccessCallback,
}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(checkpointsViewIsOpenSelector);
  const dates = useSelector(checkpointsViewDatesSelector);
  const loadings = useSelector(checkpointsViewIsLoadingSelector);
  const details = useSelector(checkpointsViewDetailsSelector);
  const list = useSelector(checkpointsViewListSelector);

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const [isEdit, setEdit] = useState(false);

  const closeModal = useCallback(() => {
    dispatch(closeCheckpointModal());
    setEdit(false);
  }, []);

  const backToModalList = useCallback(() => {
    dispatch(closeCheckpointDetails());
  }, []);

  const headline = useMemo(() => {
    if (!dates.start && !dates.end) {
      return undefined;
    }
    if (dates.start === dates.end) {
      return moment(dates.start).format("DD.MM.YY");
    }
    return `${moment(dates.start).format("DD.MM.YY")} – ${moment(dates.end).format("DD.MM.YY")}`;
  }, [dates]);

  const openDetails = useCallback((checkpointId: number) => {
    dispatch(openCheckpointDetails(checkpointId));
  }, []);

  const editFormRef = useRef<HTMLFormElement | null>(null);

  const {
    object,
    section,
    objects,
    sections,
    users,
    selectObjectById,
    selectObjectByName,
    selectSectionById,
    fileStorageId,
    files,
  } = useEditCheckpoint({
    objectId,
    isActive: isOpen && isEdit,
    key: details?.id,
  });

  const onAddCheckpoint = useCallback(
    (checkpoint: Checkpoint) => {
      dispatch(reloadViewCheckpointModalList());
      addCheckpointSuccessCallback?.(checkpoint);
    },
    [addCheckpointSuccessCallback]
  );

  const handleSaveButton = useCallback(() => {
    if (!editFormRef.current) return;
    editFormRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  }, [editFormRef.current]);

  const handleCancelButton = useCallback(() => {
    setEdit(false);
  }, [fileStorageId]);

  const deleteCheckpoint = (id: number) => () => {
    viewCheckpointApi
      .delete(id)
      .then(() => {
        message.success("Контрольная точка удалена");
        deleteSuccessCallback?.(details!);
        dispatch(deleteCurrentCheckpoint());
      })
      .catch(errorCatcher)
      .finally(() => setOpenDeleteModal(false));
  };

  const onSubmitEdit = useCallback(
    (values: Omit<CheckpointEditValues, "building" | "files">) => {
      if (!object || !details) return Promise.reject();
      return (async () => {
        const editValues = { ...values, building: object.id };
        // @ts-ignore
        let rawFiles: IFilesBase64Format[] = files.filter((f) => !f.file_path);
        if (rawFiles.length) {
          // @ts-ignore
          rawFiles = await getArrayFilesInBase64(rawFiles).catch();
          // @ts-ignore
          rawFiles.forEach((f) => (f.originalname = f.name));
          // @ts-ignore
          editValues.files = rawFiles;
        } else {
          // @ts-ignore
          delete editValues.files;
        }
        return viewCheckpointApi
          .edit(details.id, editValues)
          .then(({ data }) => {
            message.success("Контрольная точка обновлена");
            editSuccessCallback?.(details, data);
            dispatch(updateCheckpoint(data));
            dispatch(fileUploadActions.flushStorage(fileStorageId));
            setEdit(false);
          })
          .catch(errorCatcher);
      })();
    },
    [files, object, details, editSuccessCallback, fileStorageId]
  );

  if (isEdit && details) {
    return (
      <ModalContainer isOpen={isOpen} onClose={closeModal} name={`Редактировать контрольную точку ${details.name}`}>
        <CheckpointForm
          checkpoint={details}
          defaultObjectId={details.building?.toString()}
          object={object}
          section={section}
          onSumbmit={onSubmitEdit}
          objects={objects}
          sections={sections}
          users={users}
          selectObjectById={selectObjectById}
          selectObjectByName={selectObjectByName}
          selectSectionById={selectSectionById}
          formRef={editFormRef}
          fileStorageId={fileStorageId}
        />
        <BottomControls isExists isDoubleBtns>
          <ButtonBase secondary onClick={handleCancelButton}>
            Отменить
          </ButtonBase>
          <ButtonBase primary onClick={handleSaveButton}>
            <span>Сохранить</span>
          </ButtonBase>
        </BottomControls>
      </ModalContainer>
    );
  }

  if (details || loadings.details) {
    return (
      <ModalContainer isOpen={isOpen} onClose={closeModal} name={`Контрольная точка ${details?.name || ""}`}>
        {loadings.details ? (
          <Spinner />
        ) : details ? (
          <>
            <CheckpointDetails checkpoint={details} onBack={backToModalList} fileStorageId={fileStorageId} />
            {isOpenDeleteModal ? (
              <ConfirmationModal
                isOpen
                onClose={() => setOpenDeleteModal(false)}
                variant="secondary"
                acceptButtonText="Подтвердить"
                cancelButtonText="Отменить"
                action={deleteCheckpoint(details.id)}
              >
                <span>Вы действительно хотите удалить контрольную точку?</span>
              </ConfirmationModal>
            ) : null}
            <BottomControls isExists isDoubleBtns>
              <ButtonBase danger onClick={() => setOpenDeleteModal(true)}>
                Удалить
              </ButtonBase>
              <ButtonBase secondary onClick={() => setEdit(true)}>
                Редактировать
              </ButtonBase>
            </BottomControls>
          </>
        ) : null}
      </ModalContainer>
    );
  }

  return (
    <ModalContainer isOpen={isOpen} onClose={closeModal} name={"Контрольные точки"} customHeadline={headline}>
      {loadings.list ? (
        <Spinner />
      ) : list.length > 0 ? (
        list.map((preview, index) => {
          return (
            <>
              {(index === 0 || preview.check_point_date !== list[index - 1]?.check_point_date) && (
                <ModalContentSplitter label={moment(preview.check_point_date).format("DD.MM.YY")} />
              )}
              <CheckpointPreview checkpoint={preview} key={preview.id} onClick={openDetails} />
            </>
          );
        })
      ) : (
        <EmptyPlaceholder textCenter text={"Добавьте первую контрольную точку"} img={checkIcon} />
      )}
      <BottomControls isExists>
        <AddCheckpoint objectId={objectId} successCallback={onAddCheckpoint} />
      </BottomControls>
    </ModalContainer>
  );
};
