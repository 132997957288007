import { PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from "react";

const SyncVideoPlayersContext = createContext<TSyncVideoPlayersContext>({});

export const SyncVideoPlayersContextProvider = ({ children }: PropsWithChildren) => {
  const [players, setPlayers] = useState<HTMLVideoElement[]>([]);
  const time = useRef(0);

  const addPlayer = (player?: HTMLVideoElement) => {
    if (!player) return;
    setPlayers((prev) => Array.from(new Set([...prev, player])));
  };

  const removePlayer = (player?: HTMLVideoElement) => {
    if (!player) return;
    player.pause();
    time.current = player.currentTime;
    setPlayers((prev) => prev.filter((el) => el !== player));
  };

  useEffect(() => {
    players.forEach((el) => {
      el.currentTime = time.current;
    });
    setPlayers((prev) => prev.map((el) => ({ ...el, currentTime: time.current })));
  }, [players.length]);

  return (
    <SyncVideoPlayersContext.Provider value={{ removePlayer, addPlayer }}>{children}</SyncVideoPlayersContext.Provider>
  );
};

export const useSyncVideoPlayersContext = () => useContext(SyncVideoPlayersContext);

type TSyncVideoPlayersContext = {
  removePlayer?: (player?: HTMLVideoElement) => void;
  addPlayer?: (player?: HTMLVideoElement) => void;
};
