import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { deleteSubSection } from "redux/modules/common/building/materials/specification";

import BottomSliderMaterials from "../../../../components/BottomSliderMaterials";
import Chapter from "../../../../components/Chapter";
import ModalDelete from "../../../../components/ModalDelete";

import CrossRoundedIcon from "images/icons/CrossRoundedIcon";

import styles from "./index.module.scss";

const SubSection = ({ data, objectId, blockOpenExpenditure, canEdit }) => {
  const dispatch = useDispatch();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const openModalDeleteChapter = (e) => {
    e.stopPropagation();
    setIsDeleteModalOpen(true);
  };
  const closeModalAddChapter = (e) => {
    e?.stopPropagation();
    setIsAddModalOpen(false);
  };
  const removeSubSection = (id) => {
    dispatch(deleteSubSection(objectId, id));
    setIsDeleteModalOpen(false);
  };

  return (
    <div onClick={() => blockOpenExpenditure(data)}>
      <BottomSliderMaterials isOpen={isAddModalOpen} closeFunc={closeModalAddChapter}>
        <Chapter
          name={data.name}
          id={data.id}
          objId={objectId}
          label="подраздел"
          close={(e) => closeModalAddChapter(e)}
        />
      </BottomSliderMaterials>
      {isDeleteModalOpen && (
        <ModalDelete
          id={data.id}
          title={data.name}
          deleteSection={removeSubSection}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
        />
      )}
      <div className={styles.container}>
        <div className={styles.name}>{data?.name}</div>
        <div className={styles.count}>
          {/* <Line height='3rem'/>*/}
          <span>Количество: </span>
          {data?.products_count}
        </div>
        {canEdit && (
          <div className={styles.actions} onClick={openModalDeleteChapter}>
            <div>
              <CrossRoundedIcon />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubSection;
