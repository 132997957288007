import React from "react";

import { TASK_PRIORITY } from "components/pages/Tasks/constants";
import { ICertainTaskResponse } from "components/pages/Tasks/model/types";

import styles from "./TaskHeadPriority.module.scss";

const colors: Record<TASK_PRIORITY, string> = {
  [TASK_PRIORITY.gray]: "gray",
  [TASK_PRIORITY.light_green]: "lightgreen",
  [TASK_PRIORITY.orange]: "#ffc402",
  [TASK_PRIORITY.red]: "red",
  [TASK_PRIORITY.yellow]: "#E1CD1F",
};

interface IProps {
  task: ICertainTaskResponse;
}

const TaskHeadPriority = ({ task }: IProps) => {
  return (
    <div className={styles.root} style={{ border: `1px solid ${colors[TASK_PRIORITY[task.priority]]}` }}>
      {TASK_PRIORITY[task.priority]}
      <div className={styles.title} style={{ background: colors[TASK_PRIORITY[task.priority]] }}>
        Приоритет
      </div>
    </div>
  );
};

export default TaskHeadPriority;
